import React, { Component } from 'react';
import { s3StaticPath } from 'helper/helperFunctions';
// import MyImage from 'components/Upload/MyImage';

class AboutUs extends Component{
 	render(){
  	return(
      <React.Fragment>
        <img src={s3StaticPath('img/about_banner.jpg')}  alt="Group Travel Videos"/>

        <section id="since" className="section bg_alter">
          <h2 className="panel_heading">Formerly Known As <img src={s3StaticPath('img/gtv_old_logo.png')} alt="Create A Video" style={{verticalAlign: "middle"}} /> Create A Video</h2>

          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <p className="since_text smaller">
                PhotoVision, Inc. was incorporated in 1997 in Charlotte, NC. Originally called Create A Video, we created the name to Group Travel Videos&trade; to reflect the main market it serves, the group travel industry. We retain the name Create A Video&trade; for our retail business where we transfer analog films, tapes, photos and slides to a digital format (<a href="https://www.createavideo.com" target="_blank" className="blue_link">www.createavideo.com</a>). We have a passion to teach our youth the value of memories, and to keep life changing memories from going into a digital wasteland. We encourage travelers to download and save these precious memories with their other digital photo and video memories so to last for generations. We have served over 2,000,000 families since 1999 and understand how important these memories are to them.
                </p>
                
                <p className="since_text">We make your memories come to life!</p>
                <p className="since_text smaller mt-md">If you need assistance or a customized package, please call us <strong className="medium">1.888.533.7637 x209</strong>.</p>

              </div>
            </div>
          </div>
        </section>

        <section className="section" id="keyTeam">
          <h2 className="panel_heading">Meet the Key Team Members</h2>

          <div className="container">
            <div className="row">
              <div className="col-md-10 col-md-offset-1">
                <div className="teamMember">
                  <div className="teamMemberName">
                    <div className="avatar">
                      <span className="profilePic dave"></span>
                    </div>
                    <div className="name">
                      <div className="text_18 mt-md">Dave Martinson</div>
                      <div className="text_16 text-muted dark">Founder &amp; President</div>
                    </div>
                  </div>

                  <div className="teamMemberContent">
                    <p className="text_14">Always having a passion for photography, Dave began his career as a news video/film photographer in Charlotte NC. After graduating with a BA in Business Administration from UNC Charlotte&#39;s Belk College of Business in 1977, he pursued his passion with the nation&#39;s largest portrait photography company. He served in operations, research, marketing, and advertising. In 1985 he co-founded CVS, Inc. which was a video transfer company serving a national market through Sears. Later his business partner Ed Tepera developed the very first computer generated photo-to-video montage. The company put their new invention in 45 Wal-Mart stores primarily in the southeast. It was there when he discovered the powerful emotional response people have seeing their photos come to life on their TV and has focused on this niche ever since. This business was sold in 1995 to American Studios. He bought back the assets and trademarks and started PhotoVision Inc. in April 1997. Since then Dave has guided the company through the never-ending technological changes in photography and video that have occurred and has created new markets and products offered to groups and individuals through a variety of industries. He has been fortunate to find creative and intelligent talent, who share the same vision and are the backbone of his passion....saving and creating keepsake memories for families.</p>

                    <p className="mt-md text_14">Dave enjoys his family and friends, golf, the Panthers, Hornets, and travel. He and his wife of 46 years have been blessed with two children and now have two grandchildren.</p>
                  </div>
                </div>
                
                {/* <div className="teamMember">
                  <div className="teamMemberName">
                    <div className="avatar">
                      <span className="profilePic john"></span>
                    </div>
                    <div className="name">
                      <div className="text_18 mt-md">John O'Malley</div>
                      <div className="text_16 text-muted dark">VP of Group Sales</div>
                    </div>
                  </div>

                  <div className="teamMemberContent">
                    <p className="text_14">John started his sales career at PhotoVision back in January of 1999, after being in outside sales all of his life. A trip to St. Maarten in the summer of 1998 with his wife Lee and good friends Dave &amp; Wrenn Martinson set the stage for what is now called "Group Travel Videos".</p>
                    <p className="mt-md text_14">As an avid photographer, Dave was taking pictures during their vacation. The idea of "creating a video" of their trip was brought up one day while sunning on the beaches. Martinson owned a video production business and had developed a program of using still pictures to make videos with music and special effects. They gathered their favorite 150 printed pictures, dropped in some island music, and the first Group Travel Video was created. When watching it, it was if they were back in the islands relieving their vacation all over again and it literally brought tears to their eyes. Lights were flashing with ideas as they felt this would be a perfect product for the travel industry.</p>
                    <p className="mt-md text_14">In late 1998, Dave met with a local travel agency and offered to do a test for a 6th grade class traveling to DC. A few weeks later, a call from the agent confirmed their hunch as both the teacher &amp; agent flipped when seeing the finished product. John was on his way to FL for a national sales meeting when Dave called to share the good news. He'll never forget the call when Dave said "we have something here" and offered him a sales job. Three weeks later John joined PhotoVision. "It was without question the best move I’ve ever made" and he has never looked back. It took a few months to develop the correct formula but they still remember the response they heard from tour operators after their first travel convention saying "what a fantastic idea". They still hear those comments today 16 years later.</p>
                    <p className="mt-md text_14">Say's John.."follow your dreams, take chances, believe in what you do and you and the people around you will be successful" John has been married for 36 years to his wife Lee and they have 3 kids, Chris, Mark &amp; Erin and granddaughter Camille. He enjoys following The Carolina Panthers and Charlotte Hornets, and of course trips to St. Maarten.</p>
                  </div>
                </div> */}
                
                <div className="teamMember">
                  <div className="teamMemberName">
                    <div className="avatar">
                      <span className="profilePic alex"></span>
                    </div>
                    <div className="name">
                      <div className="text_18 mt-md">Alex Palafox</div>
                      <div className="text_16 text-muted dark">VP Production</div>
                    </div>
                  </div>

                  <div className="teamMemberContent">
                    <p className="text_14">Alex has been working in the video industry his entire career which started in 1981 in Mexico City as a producer for government special events for radio and TV broadcasts. He attended California State University and the University of Austin Texas to learn English and to hone his skills in audio and video production.</p>
                    
                    <p className="text_14 mt-md">He joined CVS Inc after moving to Charlotte in 1989 and worked in various operational capacities including technical design, equipment repair and maintenance, including supervision of a large video transfer production staff. In 1995, he became a proud citizen of the United States, but shares the pride for his native country of Mexico.</p>
                    
                    <p className="text_14 mt-md">A few months after PhotoVision began operations in 1997, he joined the company as the key technical director, and is currently the VP of Production for the Company. He shares the passion of photography, producing memories for our customers and is one of the company’s owners.</p>
                    
                    <p className="text_14 mt-md">Favorite interests are golfing, soccer, and the professional sports teams in Charlotte, the Carolina Panthers and Charlotte Hornets. He has two smart and beautiful daughters who live in Charlotte close to home.</p>
                  </div>
                </div>
                
                <div className="teamMember">
                  <div className="teamMemberName">
                    <div className="avatar">
                      <span className="profilePic sherri"></span>
                    </div>
                    <div className="name">
                      <div className="text_18 mt-md">Sherri Taylor</div>
                      <div className="text_16 text-muted dark">Controller</div>
                    </div>
                  </div>

                  <div className="teamMemberContent">
                    <p className="text_14">Sherri graduated from Wingate College in 1994 with a bachelor&#39;s degree in Business and a emphasis in Accounting.</p>
                    
                    <p className="text_14 mt-md">Sherri was one of the original employees in 1997 and is one of the owners. She ran the accounting department and was our retail store manager in the beginning years of PhotoVision, Inc. In 2004, Sherri took a break from the professional world to become a mom. It was not long before she returned to managing the accounting and oversee the day to day operations. After being with the company for over 18 years, she provides critical controls required to make an efficient operation that touches all aspects of the business. She helps keep Dave straight and on task!</p>
                    
                    <p className="text_14 mt-md">In her spare time Sherri enjoys spending time with her husband of 20 years and her two boys ages 17 and 13. Cooking, couponing, photography and watching her boys play sports are also some of her favorite pastimes.</p>
                  </div>
                </div>

                <div className="teamMember">
                  <div className="teamMemberName">
                    <div className="avatar">
                      <span className="profilePic clint"></span>
                    </div>
                    <div className="name">
                      <div className="text_18 mt-md">Clint Hall</div>
                      <div className="text_16 text-muted dark">Create A Video Retail Manager</div>
                    </div>
                  </div>

                  <div className="teamMemberContent">
                    <p className="text_14">Clint has always had a passion in the photography and film industry. He began by making short films of his own at a very young age and getting involved in the independent film community. He continued his studies of film production as a native of Virginia at Northern Virginia Community College (NOVA). Once he relocated to Charlotte, NC he continued his education at Central Piedmont Community College.</p>

                    <p className="text_14 mt-md">It was shortly after that he joined PhotoVision in 2006 working as a seasonal editor &amp; quality control for the group travel business. He is currently Manager for our retail arm of the company, Create A Video. He specializes in producing promotional videos as well as capturing the memories of our customers from video transfers to custom video montages made from photos. He assists in the creation of informational videos for the group travel side of our company. He also has ownership in the company.</p>
                    
                    <p className="text_14 mt-md">In his spare time Clint enjoys outdoor activities such as hiking, camping and fishing with his wife Rachel, family, friends and loves his many dogs!</p>
                  </div>
                </div>
                
                <div className="teamMember">
                  <div className="teamMemberName">
                    <div className="avatar">
                      <span className="profilePic zach"></span>
                    </div>
                    <div className="name">
                      <div className="text_18 mt-md">Zach Taylor</div>
                      <div className="text_16 text-muted dark">Account Manager</div>
                    </div>
                  </div>

                  <div className="teamMemberContent">
                    <p className="text_14">Zach is a former student of Xavier University of Louisiana where he graduated with a Business Degree focused on Sales &amp; Marketing. While attending Xavier on a full tennis scholarship, he contributed to the Team&#39;s first top 10 National Ranking in the tennis program&#39;s history and was a 2 Time &#39;All-American&#39; for the NAIA Division. Some of his hobbies include traveling, camping, fly-fishing, homesteading, and teaching kids how to play tennis.</p>

                    <p className="text_14 mt-md">One of his favorite travel experiences was a 1 month backpacking excursion throughout the Philippines Islands with his wife Brittany.  &quot;There&#39;s nothing like being completely immersed among a culture that&#39;s different from your own… It&#39;s truly an incredible experience.&quot;  They now live outside of Charlotte, NC along with their 2 dogs, 3 chickens, 2 ducks, and (currently) 3 rabbits.</p>

                    <p className="text_14 mt-md">He joined the company in 2019 and is now focused on business development of the group travel markets for student, senior adult, and incentive tripkit.</p>
                  </div>
                </div>
                
                <div className="teamMember">
                  <div className="teamMemberName">
                    <div className="avatar">
                      <span className="profilePic katie"></span>
                    </div>
                    <div className="name">
                      <div className="text_18 mt-md">Katie Hudgens</div>
                      <div className="text_16 text-muted dark">Social Media and Swiss Army Knife</div>
                    </div>
                  </div>

                  <div className="teamMemberContent">
                    <p className="text_14">Katie is one of the youngest members of our team. She joined PhotoVision in 2012 soon after graduating from high school. A rare find for her age, her work ethic and unique ability to multi-task has made her one of our key &#39;go-to&#39; team players. Her responsibilities have varied greatly, and why we call her our Swiss Army Knife! She has recently taken over our social media marketing, too. Katie is also very passionate about creating memories of a lifetime for our customers, and this is clearly evident in everything she does.</p>

                    <p className="text_14 mt-md">A former high school athlete, she enjoys disc golf and is an advocate for women in the sport by being very involved in disc golf organizations. She also enjoys writing music, going hunting, spending time with family and friends, her dog Minny, and is a Carolina Panthers and Charlotte Hornets fan, too.</p>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
  	);
  }
}
export default AboutUs;