import React, { Component } from 'react';
import $ from 'jquery';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GoFilePdf } from 'react-icons/go';
import Magnifier from 'react-magnifier';

// import client from 'assets/img/client_icon.jpg';
// import MainClients from './MainClients';
import { s3StaticPath, scrollLogin } from 'helper/helperFunctions'
// import MyImage from 'components/Upload/MyImage';

class Mozaix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
    };
    this.handleHover = this.handleHover.bind(this);
  }
  handleHover() {
    // this.setState(prevState => ({
    //     isHovered: !prevState.isHovered
    // }));

    $("#clients div.client").hover(function () {
      $("#clients div.client").removeClass("pink_gradient")
      $(this).addClass("pink_gradient")
    }, function () {
      $("#clients div.client").removeClass("pink_gradient")
    })
  }
  render() {
    // const hoverClass = this.state.isHovered ? "client pink_gradient" : "client";
    // let sliderSettings = {
    //   dots: true,
    //   autoplay: true,
    //   infinite: true,
    //   speed: 500,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   accessibility: false,
    //   className: 'benefits',
    //   prevArrow: <div><MdArrowBack /></div>,
    //   nextArrow: <div><MdArrowForward /></div>,
    // };

    return (
      <React.Fragment>

        <section id="photoMozaix" className="section">
          <h2 className="panel_heading">PhotoMozaix&trade;</h2>

          <div className="container">
            <div className="row">
              <div className="col-md-5 mt-xlg">
                <div className="text-muted dark">
                  <p className="text_22 gothicBold text-black">PhotoMozaix&trade; are made from your trip pictures!</p>
                  <p className="text_16 mt-xs">This beautiful print measures 20 x 30 inches and is printed on high-quality satin photo paper. 
                    A PhotoMozaix is one large image created from a collection of your trip photos made from an
                    iconic image representing the destination! . <strong>"Wow"</strong> is the only way customers seem to react to our PhotoMozaix&trade;.
                  </p>
                  <p className="text_16 mt-lg">To order PhotoMozaix™ from you trip, please <span onClick={scrollLogin} className='blue_link'>login</span> with your group's username &amp; password, order by phone (1-888-533-7637 ext. 209) Your group will receive One PhotoMozaix with their order.
                  {/* or print and mail this PhotoMozaix™ <a href='https://grouptravelvideos.s3.amazonaws.com/global/GTV_Order_Awesome_Keepsakes_electronic.pdf' target="_blank" rel="noopener noreferrer" className='blue_link'>Order Form (pdf)</a>. */}
                  </p>

                  <p className="text_16 mt-md">To order PhotoMozaix™ before you travel, please contact your tour operator and ask them to include it in your travel package.</p>
                  <div className="mt-md"><a target="_blank" rel="noopener noreferrer" href="https://grouptravelvideos.s3.amazonaws.com/global/GTV_Order_Awesome_Keepsakes_electronic.pdf" className="themeBtn_12 purple_gradient hover_dark leftIconBtn"><GoFilePdf /> Print Order Form</a></div>
                </div>
              </div>

              <div className="col-md-7 mt-xlg">
                <div className="">
                  <Magnifier src={s3StaticPath('img/GTV_Mosaic_Sample_large.jpg')} width={"100%"} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Mozaix;