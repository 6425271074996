import React from 'react';
import {
	Button
} from "react-bootstrap";
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { storeBrand, editBrand } from 'redux/actions/brand-actions';
import FileUpload from "components/Upload/FileUpload";
import ImagePreview from "components/Upload/ImagePreview";
import { createFormData } from 'helper/helperFunctions';

class BrandFormPage extends React.Component {
	constructor(props) {
		super(props);
		this.validatorBrand = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
		this.state = {
			brand: {
				...this.props.brandPro
			},
			submitted: false,
			formType: this.props.formTypePro || '',
		};

		this.handleBrand = this.handleBrand.bind(this);
		this.handleBrandSubmit = this.handleBrandSubmit.bind(this);
	}

	handleBrand(e) {
		const { name, value } = e.target;
		const brandState = { ...this.state.brand };
		brandState[name] = value;
		this.setState({ brand: brandState });
	}
	handleBrandSubmit(e) {
		e.preventDefault();
		if (this.validatorBrand.allValid()) {
			this.setState({ submitted: true }, function () {
				const { formType, brand } = this.state;


				const fdData = createFormData({
					'resource_file': brand.resource_file,
					'title': brand.title,
					'domain_slug': brand.domain_slug,
					'_method': formType === 'edit' ? 'PUT' : 'POST',
				});


				if (formType === 'add') {
					this.props.storeBrandCall(fdData).then(
						response => {
							this.props.closeModel();
						}
					);
				}
				else if (formType === 'edit') {
					this.props.editBrandCall(fdData, this.state.brand.id).then(
						response => {
							this.props.closeModel();
						}
					);
				}
			});
		} else {
			this.setState({ submitted: false });
			this.validatorBrand.showMessages();
			this.forceUpdate();
		}
	}
	setResourceFile = (key, value) => {
		this.setState({ brand: { ...this.state.brand, resource_file: value } }, function () {
			console.log('pro show file', this.state.brand);
		})
	}
	removeFile = (key, value) => {
		this.setState(prevState => ({
			brand: {
				...prevState.brand,
				resource_file: ''
			}
		}), () => {
			console.log('pro show file', this.state.brand);
		});
	}
	render() {
		const { brand } = this.state;
		console.log('brand', brand);
		return (
			<form >
				<div className="row">
					<div className="col-md-6">
						<div className='form-group'>
							<label htmlFor="name">Brand Name<span className="requiredClass">*</span></label>
							<input type="text" name="title" className="form-control" value={brand.title} onChange={this.handleBrand} />
							{this.validatorBrand.message('brandName', brand.title, 'required')}
						</div>
					</div>

					<div className="col-md-6">
						<div className='form-group'>
							<label htmlFor="name">Domain slug<span className="requiredClass">*</span></label>
							<input type="text" name="domain_slug" className="form-control" value={brand.domain_slug} onChange={this.handleBrand} />
							{
								this.validatorBrand.message('domain_slug', brand.domain_slug, ['required'])

							}
						</div>
					</div>

					<div className="col-md-12" id="File">
						<div className="form-group">
							<div className="row">
								<div className="col-md-12 lbl_container">
									<label htmlFor="name">Upload File<span className="requiredClass">*</span> </label>
								</div>
							</div>
							<div className="col-md-6 pofilePicContainer">
								{
									!brand.resource_file ?
										<div className='form-group dropzone_profileImage'>
											<FileUpload
												files={brand.resource_file}
												setResourceFile={this.setResourceFile}
												allowedFormatsStringMimePro={'image/jpeg, image/png'}
												allowedFormatsStringExtPro={'jpg, png'}
												multiple={false}
											/>
										</div>
										:
										<ImagePreview
											filesToPreview={brand.resource_file}
											removeFile={this.removeFile}
										/>
								}
								{
									this.validatorBrand.message('Logo', brand.resource_file, 'required')
								}
							</div>
						</div>
					</div>

				</div>
				<div className="row">
					<div className="col-md-12">
						<div className='text-center'>
							<Button onClick={this.handleBrandSubmit} bsSize="large" bsStyle="info" className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm btn-default  btn btn-lg btn-info" >Save</Button>
							<Button bsSize="large" bsStyle="info" onClick={() => this.props.closeModel()} className="backButton pt-sm no_radius pb-sm primary btn btn-lg ml-sm mt-sm btn-info " >Cancel</Button>
						</div>
					</div>
				</div>
			</form>
		);
	}
}


const mapDispatchToProps = dispatch => {
	return ({
		storeBrandCall: (brandData) => { return dispatch(storeBrand(brandData)) },
		editBrandCall: (brandData, id) => { return dispatch(editBrand(brandData, id)) },
	});
};

function mapStateToProps(state) {
	const { loadingBrand } = state.brandsReducer;
	return {
		loadingBrand,
	};
};

const BrandForm = connect(mapStateToProps, mapDispatchToProps)(BrandFormPage);

export default BrandForm;