import React, { Component } from "react";
import { connect } from 'react-redux';
import { orderService } from 'services/order';
import {
	Grid,
	Row,
	Col,
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import Pagination from 'components/ThemeComponents/Pagination';
import { dateFormatMy } from 'helper/helperFunctions';
import { Link } from 'react-router-dom';
import { ThemeFunctions as TF } from 'helper/ThemeFunctions';
const queryString = require('query-string');

class OrdersContactListSearchBy extends Component {
	constructor(props) {
		super(props);
		const parsed = queryString.parse(window.location.search);

		this.state = {
			queryString: parsed.code ? parsed.code : '',
			queryValue: parsed.value ? parsed.value : '',
		}
	}

	paginationCall = (data) => {
		const { queryString, queryValue } = this.state;
		return orderService.getOrdersSearchByContactList({ ...data, queryString: queryString, queryValue: queryValue }).then(response => {
			return response;
		});
	}

	render() {
		const { queryString } = this.state;
		var temp = '';
		if (queryString === 'groupName')
			temp = 'with same group name';
		else if (queryString === 'groupLeaderName')
			temp = 'with same group leader name';
		else if (queryString === 'groupLeaderEmail')
			temp = 'with same group leader email';

		const columns = [
			{
				Header: "Orders Listing",
				headerClassName: 'headerPagination',
				columns: [
					{
						Header: "Barcode",
						accessor: "barcode",
                        width: 200,
						className: "action-center",
						Cell: ({ value, original }) => {
							return <Link target="_blank" to={'/Admin/' + original.id + '/order-edit'} className="blue_link">{value}</Link>
						},
						sortable: false,
					},
					{
						Header: "Group Name",
						accessor: "group_name",
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Contact Name",
						accessor: "group_leader_contact",
						Cell: ({ value }) => (
							<div>
								{
									value.last_name ? value.first_name.concat(value.last_name) : value.first_name
								}
							</div>
						),
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Contact Email",
						accessor: "group_leader_contact",
						Cell: ({ value }) => (
							<div>
								{
									value.email
								}
							</div>
						),
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Departure Date",
						accessor: "orderDates",
						className: "action-center",

						Cell: ({ value }) => (
							<div>
								{
									dateFormatMy(value.departure_date.value)
								}
							</div>
						),
						sortable: false,
					},
					{
						Header: "Destinations",
						accessor: "destinationsText",
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Program Title",
						accessor: "programtype",
						Cell: ({ value }) => (
							<div>
								{
									value.title + '@' + value.price
								}
							</div>
						),
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Production Status",
						accessor: "id",
						Cell: ({ row, original }) => (
							<div>
								{
									TF.productionStatus(row._original.orderSettings, row._original.status, row._original.csr_steps, row._original.onHoldLastComment, row._original.orderCancelComment, row._original.orderDates, row._original.shippingDetails)

								}
							</div>
						),
						className: "action-center",
						sortable: false,
					},
				]
			}
		];
		return (
			<div className="content">
				<Grid fluid>
					<Row>
						<Col md={12}>
							<Card
								ctTableResponsive
								content={
									<div>
										<Grid fluid>
											<Row>
												<Col md={12}>
													<div className="secHeading">{`Orders list ${temp}`}</div>
												</Col>


												<Pagination
													ref={(p) => this.pagination = p}
													// showPagination={false}
													columns={columns}
													pageSize={50}
													getDataCall={this.paginationCall}
													filterView={false}
													// filterPlaceHolder={'users'}
													// downloadData={true}
													// downloadFileName={'Orders'}
													// lowerContent = {null}
													// filterPlaceHolder = {'Orders'}
													noDataText='No orders found'
												// getRowProps={this.getRowProps}
												// showAllToggle={true}
												/>
											</Row>
										</Grid>


									</div>
								} />
						</Col>
					</Row>
				</Grid>
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return ({
		// getOrdersSearchByContactListCall: (data, queryString) => { dispatch(getOrdersSearchByContactList(data, queryString)) },//example function
	});
};

function mapStateToProps(state) {
	// const { exampleConstant } = state.exampleReducer;

	return {
		// exampleConstant
	};
}


export default OrdersContactListSearchBy = connect(mapStateToProps, mapDispatchToProps)(OrdersContactListSearchBy);
// export default Test;
