import {
	adminConstants
} from '../constant/admin-constant';
import { frontEndUserConstants } from '../constant/front-end-constant';
import { objectsConstants } from '../constant/objects-constant';


const initialState = {
	loadingOrder: false,
	ordersData: [],
	resourceType: {},
	submittedResource: false,
	resourceFiles: [],
	filesPreview: [],
	loadingResources: false,
	loadingIsInvoice: false,
	loadingFolderResources: false,
	loadingAgencyFolderResources:false,
	AgencyFolderDocuments:[],
	orderResources: [],
	orderResourcesDocuments: [],
	orderFolderDocuments: [],
	deletingResource: false,
	loadingTripOrderUsers: false,
	loadingFollowingOrderUsers: false,
	tripUsers: [],
	followingUsers: [],
	deleteingOrderUser: false,
	userTripOrderFormSubmit: false,
	userFollowingOrderFormSubmit: false,
	specialUser: {},
	loadingOrderSettings: false,
	order_details: objectsConstants.ORDERDETAIL,
	pricing_details: objectsConstants.PRICINGDETAIL,
	csr_steps: [],
	flagged_orders: objectsConstants.flagged_ordres,
	multiple_shipment_orders: objectsConstants.multiple_shipment_orders,
	pendingOrdersData: [],
	vtData: [],
    allow_reorder: false,
    allowSave: false,
    allow_reorder_days_diff: {},
	orderSettings: {
		approved_for_video_upload: {
			value: 0
		},
		do_not_allow_mapping_messaging: {
			value: 0
		},
		do_not_allow_traveler_to_use_map: {
			value: 0
		},
		do_not_allow_traveler_to_see_all_on_trip: {
			value: 0
		},
		hash_tag: {},
		photos_submitted_for_video_production: {
			value: 0
		},
		max_allowed_photos: {},
		auto_approve_photos: {
			value: 0
		},
		approved_for_upload: {
			value: 0
		},
		uploadCutOffDate: {
			value: {}
		},
		disable_traveler_for_upload: {
			value: 0
		}
	},
	production_details: {
		order_dates: {
			receive_date: {},
			finish_by_date: {}
		},
		video_title_templates: {
			ot1: {
				pivot: {}
			},
			ct1: {
				pivot: {}
			},
			ct2: {
				pivot: {}
			},
			ct3: {
				pivot: {}
			}
		}
	},
	camerasToShip: {
		projected_date: new Date().toDateString()
	},
	requiredAssets: {
		required_assets: {},
		cam_count: {}
	},
	recenltyProcessedOrders: {
		projected_date: {}
	},
	imagesData: [],
	allImagesByPhase: {
		images: [],
		type: null
	},
	orderItems: [],
	LPR_Data: {
		shipping_details: {
			video_shipping_address: {
				state: {},
				country: {}
			},
			order_shipping_items: []
		},
		order_details: {
			music_selection_criteria: '',
			settings: {
				non_uploaded_photos_received: {
					value: ''
				},
				on_hold: {
					value: '0'
				}
			},
			agency: {},
			order_dates: {
				departure_date: {
					value: ''
				},
				return_date: {
					value: ''
				},
				video_special_need_date: {
					value: ''
				},
				last_day_of_school: {
					value: ''
				},
				finish_by_date: {
					value: ''
				},
				receive_date: {
					value: ''
				}
			}
		},
		pricing_details: {
			order_items: {
				'20x30 Mozaix': {},
			}
		},
		production_details: {
			video_title_templates: {}
		}
	},
	ReOrders: {},
	ReOrder: {
		video_shipping_address: {
			country: {},
			state: {}
		},
		group_leader_contact: {},
		orderTotals: {
			production: {},
			grand_total: {}
		},
		order_items: [],
		program_type: {},
		agency: {},
		parent_order: {
			producer: {}
		}
	},
	shippingSettings: [],
	orderWithStatusData: [],
	csOrderData: [],
	ordersCountWithModerator: [],
	PreAwarenessFlyersHistory: [],
	orderMosaic: {},
	orderMaterials: {},
	orderMosaics: {},
	countAllOrders: 0,
	preShipmentEmailList: [],
	days_from_now: {},
	preDepartureList: {},
	orderCheckInList: {},
	searchParams: {
		advanceSearch: { ...objectsConstants.ADVANCESEARCH },
		additionalFilters: { ...objectsConstants.ADITIONALFILTERS },
		userPreferences: { ...objectsConstants.USERPREFERENCES },
	},
    scheduledCalls: [],
    orderImagePath: '',
    is_invoiced: 0,
	is_ttk: false,
};

export function ordersReducer(state = initialState, action) {

	switch (action.type) {

		case adminConstants.STORE_ORDER_REQUEST:
			return {
				...state, loadingOrder: true
			}
		case adminConstants.STORE_ORDER_SUCCESS:
			return {
				...state, loadingOrder: false, order: action.payload.order
			};
		case adminConstants.STORE_ORDER_FAILURE:
			return {
				...state, loadingOrder: false, error: action.payload
			};

		case adminConstants.PAGINATION_ORDERS_REQUEST:
			return {
				...state, loadingOrder: true
			};
		case adminConstants.PAGINATION_ORDERS_SUCCESS:
			return {
				...state,
				ordersData: action.payload.orders.data,
				pages: action.payload.orders.last_page,
				loadingOrder: false,
				current_page: action.payload.orders.current_page,
				total_orders: action.payload.orders.total
			};
		case adminConstants.PAGINATION_ORDERS_FAILURE:
			return {
				...state, loadingOrder: false, error: action.payload
			}
		case adminConstants.ORDER_RESOURCE_FORMATS_REQUEST:
			return {
				...state,
				loadingFormats: true
			}
		case adminConstants.ORDER_RESOURCE_FORMATS_SUCCESS:
			return {
				...state,
				loadingFormats: false,
				resourceType: action.payload.resourceType
			}
		case adminConstants.ORDER_RESOURCE_FORMATS_FAILURE:
			return {
				...state,
				loadingFormats: false,
				error: action.paylaod
			}
		case adminConstants.GET_ORDER_REQUEST:
			return {
				...state, loadingOrder: true
			};
		case adminConstants.GET_ORDER_SUCCESS:
			// return {
			// 	...state,
			// 	loadingOrder: false,
			// 	order_details: action.payload.order.order_details,
			// 	// orderSettings: action.payload.order.order_details.settings,
			// 	shipping_details: action.payload.order.shipping_details,
			// 	pricing_details: action.payload.order.pricing_details,
			// 	production_details: action.payload.order.production_details,
			// 	csr_steps: action.payload.order.csr_steps,
			// 	step8_projected_send_date: action.payload.order.step8_projected_send_date,
			// 	step3_projected_send_date: action.payload.order.step3_projected_send_date,
			// 	order: action.payload.order
			// };
			console.log('action.payload', action.payload);
			return {
				...state,
				loadingOrder: false,
				order_details: action.payload && action.payload.order && action.payload.order.order_details ? action.payload.order.order_details : objectsConstants.ORDERDETAIL,
				// orderSettings: action.payload.order.order_details.settings,
				shipping_details: action.payload && action.payload.order && action.payload.order.shipping_details && action.payload.order.shipping_details,
				pricing_details: action.payload && action.payload.order && action.payload.order.pricing_details ? action.payload.order.pricing_details : objectsConstants.PRICINGDETAIL,
				production_details: action.payload && action.payload.order ? action.payload.order.production_details : initialState.production_details,
				csr_steps: action.payload && action.payload.order && action.payload.order.csr_steps && action.payload.order.csr_steps,
				step8_projected_send_date: action.payload && action.payload.order && action.payload.order.step8_projected_send_date && action.payload.order.step8_projected_send_date,
				step3_projected_send_date: action.payload && action.payload.order && action.payload.order.step3_projected_send_date && action.payload.order.step3_projected_send_date,
				order: action.payload && action.payload.order && action.payload.order,
                is_ttk: action.payload && action.payload.order && action.payload.order.order_details && action.payload.order.order_details.agency && action.payload.order.order_details.agency.accounts && action.payload.order.order_details.agency.accounts[0].domain_slug ==='ttk' ? true : false,
			};
		case adminConstants.GET_ORDER_FAILURE:
			return {
				...state, loadingOrder: false, error: action.payload
			};

		case adminConstants.ORDER_DETAIL_EDIT_REQUEST:
			return {
				...state, order_details: action.order_details
			}
		case adminConstants.UPLOAD_FILE:
			return {
				...state,
				resourceFiles: action.files,
				filesPreview: action.files,
				resource: action.resource
			}
		case adminConstants.UPLOAD_FILE_REQUEST:
			return {
				...state,
				submittedResource: true
			}
		case adminConstants.UPLOAD_FILE_SUCCESS:
			return {
				...state,
				resourceFiles: [],
				filesPreview: [],
				submittedResource: false,
				order_details: {
					...state.order_details,
					order_resources_count: action.payload
				}
			}
		case adminConstants.SHIPPING_DETAIL_EDIT_REQUEST:
			return {
				...state, shipping_details: action.shipping_details
			}

		case adminConstants.LOADING_ORDER_RESOURCES_REQUEST:
			return {
				...state,
				loadingResources: true
			}
		case adminConstants.LOADING_ORDER_RESOURCES_SUCCESS:
			return {
				...state,
				orderResourcesDocuments: action.payload
			}
		case adminConstants.LOADING_ORDER_RESOURCES_FAILURE:
			return {
				...state,
				error: action.payload.error
			}
		case adminConstants.UPDATE_IS_INVOICED_REQUEST:
			return {
				...state,
				loadingIsInvoice: true
			}
		case adminConstants.UPDATE_IS_INVOICED_SUCCESS:
			return {
				...state,
				is_invoiced : action.payload.rec.is_invoiced
			}
		case adminConstants.UPDATE_IS_INVOICED_FAILURE:
			return {
				...state,
				error: action.payload.error
			}
		case adminConstants.GET_IS_INVOICED_REQUEST:
			return {
				...state,
				loadingIsInvoice: true
			}
		case adminConstants.GET_IS_INVOICED_SUCCESS:
			return {
				...state,
				is_invoiced : action.payload.data.is_invoiced
			}
		case adminConstants.GET_IS_INVOICED_FAILURE:
			return {
				...state,
				error: action.payload.error
			}
		case adminConstants.LOADING_ORDER_FOLDER_RESOURCES_REQUEST:
				return {
					...state,
					loadingFolderResources: true
				}
		case adminConstants.LOADING_ORDER_FOLDER_RESOURCES_FAILURE:
				return {
					...state,
					error: action.payload.error
				}
		case adminConstants.LOADING_ORDER_FOLDER_RESOURCES_SUCCESS:
				return {
					...state,
					orderFolderDocuments: action.payload
				}
		case adminConstants.UUID_LOADING_ORDER_FOLDER_RESOURCES_REQUEST:
				return {
					...state,
					loadingFolderResources: true
				}
		case adminConstants.UUID_LOADING_ORDER_FOLDER_RESOURCES_FAILURE:
				return {
					...state,
					error: action.payload.error
				}
		case adminConstants.UUID_LOADING_ORDER_FOLDER_RESOURCES_SUCCESS:
				return {
					...state,
					orderFolderDocuments: action.payload
				}
		case adminConstants.LOADING_AGENCY_FOLDER_RESOURCES_REQUEST:
			return {
				...state,
				loadingAgencyFolderResources: true
			}
		case adminConstants.LOADING_AGENCY_FOLDER_RESOURCES_FAILURE:
			return {
				...state,
				error: action.payload.error
			}
		case adminConstants.LOADING_AGENCY_FOLDER_RESOURCES_SUCCESS:
			return {
				...state,
				AgencyFolderDocuments: action.payload
			}
		case adminConstants.ORDER_RESOURCE_DELETE_REQUEST:
			return {
				...state,
				deletingResource: true
			}
		case adminConstants.ORDER_RESOURCE_DELETE_SUCCESS:
			return {
				...state,
				orderResources: action.payload,
				order_details: {
					...state.order_details,
					order_resources_count: state.order_details.order_resources_count - 1
				}
			}
		case adminConstants.ORDER_RESOURCE_DELETE_FAILURE:
			return {
				...state,
				error: action.payload
			}
		case adminConstants.ORDER_UPLOAD_CANCEL:
			return {
				...state,
				resource: {},
				resourceFiles: [],
				filesPreview: []
			}
		case adminConstants.GET_TRIP_ORDER_USERS_REQUEST:
			return {
				...state,
				loadingTripOrderUsers: true,
			}
		case adminConstants.GET_TRIP_ORDER_USERS_SUCCESS:
			return {
				...state,
				tripUsers: action.payload.data.pagination.data,
				tripUserPages: action.payload.data.pagination.last_page,
				loadingTripOrderUsers: false
			}
		case adminConstants.GET_TRIP_ORDER_USERS_FAILURE:
			return {
				...state,
				error: action.payload
			}
		case adminConstants.GET_FOLLOWING_ORDER_USERS_REQUEST:
			return {
				...state,
				loadingFollowingOrderUsers: true
			}
		case adminConstants.GET_FOLLOWING_ORDER_USERS_SUCCESS:
			return {
				...state,
				followingUsers: action.payload.data.pagination.data,
				followingUserPages: action.payload.data.pagination.last_page,
				loadingFollowingOrderUsers: false
			}
		case adminConstants.GET_SPECIAL_ORDER_USER_REQUEST:
			return {
				...state,
				loadingSpecialUser: true
			}
		case adminConstants.GET_SPECIAL_ORDER_USER_SUCCESS:
			return {
				...state,
				specialUser: action.payload.data.specialUser,
				loadingSpecialUser: false
			}
		case adminConstants.CREATE_SPECIAL_ORDER_USER_SUCCESS:
			return {
				...state,
				specialUser: action.payload.data
			}
		case adminConstants.DELETE_ORDER_USER_REQUEST:
			return {
				...state,
				deleteingOrderUser: true
			}
		case adminConstants.DELETE_ORDER_USER_SUCCESS:
			return {
				...state,
				[action.userType]: action.users,
				order_details: {
					...state.order_details,
					[action.countColumn]: state.order_details[action.countColumn] - 1
				}
			}
		case adminConstants.DELETE_ORDER_USER_FAILURE:
			return {
				...state,
				error: action.paylaod
			}

		case adminConstants.ORDER_USER_UPDATE_REQUEST:
			return {
				...state,
				userTripOrderFormSubmit: action.tripUser,
				userFollowingOrderFormSubmit: action.followingUser,
				loadingTripOrderUsers: true,
				loadingFollowingOrderUsers: true,
			}
		case adminConstants.ORDER_USER_UPDATE_SUCCESS:
			return {
				...state,
				userTripOrderFormSubmit: false,
				userFollowingOrderFormSubmit: false,
				loadingTripOrderUsers: false,
				loadingFollowingOrderUsers: false
			}
		case adminConstants.GET_ORDER_SETTINGS_REQUEST:
			return {
				...state,
				loadingOrderSettings: true
			}
		case adminConstants.GET_ORDER_SETTINGS_SUCCESS:
			return {
				...state,
				orderSettings: { ...action.payload.data, ...action.payload.data.order_settings },
				loadingOrderSettings: false
			}
		case adminConstants.GET_ORDER_SETTINGS_FAILURE:
			return {
				...state,
				error: action.payload
			}
		case adminConstants.ORDER_UPLOAD_CUT_OFF_DATE_SUCCESS:
			return {
				...state,
				orderSettings: {
					...state.orderSettings,
					uploadCutOffDate: action.payload.data
				}
			}
		case adminConstants.ORDER_UPLOAD_CUT_OFF_DATE_FAILURE:
			return {
				...state,
				error: action.payload
			}
		case adminConstants.ORDER_UPLOAD_CUT_OFF_DATE_BY_EMAIL_SUCCESS:
			return {
				...state,
				orderSettings: {
					...state.orderSettings,
					uploadCutOffDate: action.payload.data
				}
			}
		case adminConstants.ORDER_UPLOAD_CUT_OFF_DATE_BY_EMAIL_FAILURE:
			return {
				...state,
				error: action.payload
			}

        case adminConstants.ALLOW_REORDER_STATUS_SUCCESS:
			return {
				...state,
				allow_reorder: { ...action.payload, ...action.payload.order.allow_reorder },
			}
		case adminConstants.ALLOW_REORDER_STATUS_FAILURE:
			return {
				...state,
				error: action.payload
			}

        case adminConstants.GET_ALLOW_REORDER_SUCCESS:
			return {
				...state,
				allow_reorder_days_diff: { ...action.payload.data },
			}
		case adminConstants.GET_ALLOW_REORDER_FAILURE:
			return {
				...state,
				error: action.payload
			}

        case adminConstants.GET_ORDER_IMAGE_PATH_SUCCESS:
			return {
				...state,
				orderImagePath: action.payload && action.payload.data && action.payload.data.images_path ? action.payload.data.images_path : initialState.orderImagePath,
			}
		case adminConstants.GET_ORDER_IMAGE_PATH_FAILURE:
			return {
				...state,
				error: action.payload
			}


		case adminConstants.UPDATE_USERS_MODERATOR_REQUEST:
			return {
				...state, loadingOrder: true
			}
		case adminConstants.UPDATE_USERS_MODERATOR_SUCCESS:
			return {
				...state, loadingOrder: false,
				order_details: { ...state.order_details, ...action.payload.data }
			};
		case adminConstants.UPDATE_USERS_MODERATOR_FAILURE:
			return {
				...state, loadingOrder: false, error: action.payload
			};
		case adminConstants.UPDATE_ORDER_VIDEO_TITLES_SUCCESS:
			return {
				...state,
				production_details: {
					...state.production_details,
					video_title_templates: action.payload.data
				}
			}
		case adminConstants.UPDATE_ORDER_VIDEO_TITLES_FAILURE:
			return {
				...state,
				error: action.payload
			}
		case adminConstants.UPDATE_ORDER_EQUIPMENT_UPLOAD_SUCCESS:
			return {
				...state,
				production_details: {
					...state.production_details,
					no_equipment_upload_only: action.payload.data.no_equipment_upload_only
				}
			}
		case adminConstants.UPDATE_ORDER_EQUIPMENT_UPLOAD_FAILURE:
			return {
				...state,
				error: action.payload
			}
		case adminConstants.UPDATE_ORDER_CAMERA_SHIP_STATUS_SUCCESS:
			return {
				...state,
				shipping_details: { ...state.shipping_details, camera_ship_status: action.payload.data.camera_ship_status }
			}
		case adminConstants.UPDATE_ORDER_VIDEO_SHIP_STATUS_SUCCESS:
			return {
				...state,
				shipping_details: { ...state.shipping_details, video_ship_status: action.payload.data.video_ship_status }
			}
		case adminConstants.UPDATE_ORDER_MOSAIC_SHIP_STATUS_SUCCESS:
			return {
				...state,
				shipping_details: { ...state.shipping_details, mosaic_ship_status: action.payload.data.mosaic_ship_status }
			}
		case adminConstants.UPDATE_ORDER_RETURN_SHIP_STATUS_SUCCESS:
			return {
				...state,
				shipping_details: { ...state.shipping_details, return_label_ship_status: action.payload.data.return_label_ship_status }
			}
		case adminConstants.UPDATE_ORDER_CAMERA_SHIP_STATUS_FAILURE:
			return { ...state, error: action.payload }
		case adminConstants.UPDATE_SPECIAL_ORDER_USER_SUCCESS:
			return {
				...state,
				specialUser: action.payload.data
			}
		case adminConstants.UPDATE_ORDER_DETAIL_SUCCESS:
			return {
				...state,
				order_details: action.payload.data.order_details,
				// shipping_details: action.payload.data.shipping_details
			}
		case adminConstants.UPDATE_SPECIAL_ORDER_USER_FAILURE:
			return {
				...state,
				error: action.payload
			}
		case adminConstants.UPDATE_SHIPPING_DETAIL_SUCCESS:
			return {
				...state,
				shipping_details: action.payload.data.shipping_details,
				// pricing_details: action.payload.data.pricing_details
			}
		case adminConstants.UPDATE_PROJECTED_PTM_DATE_SUCCESS:
			return {
				...state,
				shipping_details: {
					...state.shipping_details,
					order_dates:{
						projected_camera_ship_date: action.payload.data.projected_camera_ship_date
					}
				}
		}
		case adminConstants.GET_ALL_CAMERAS_TO_SHIP_SUCCESS:
			return {
				...state,
				camerasToShip: {
					...action.payload.data.pagination,
					projected_date: action.payload.data.projected_date,
					countAllOrdersToday: action.payload.data.countAllOrdersToday,
				},
				countAllOrders: action.payload.data.countAllOrders,
			}
		case adminConstants.GET_ALL_CAMERAS_TO_SHIP_FAILURE:
			return {
				...state,
				error: action.payload
			}
		case adminConstants.UPDATE_PRICING_DETAIL_SUCCESS:
			return {
				...state,
				pricing_details: action.payload.data.pricing_details
			}

		case adminConstants.UPDATE_PRODUCTION_DETAIL_SUCCESS:
			return {
				...state,
				production_details: action.payload.data.production_details
			}

		case adminConstants.GET_ORDER_IMAGES_REQUEST:
			return {
				...state, loadingOrder: true
			}
		case adminConstants.GET_ORDER_IMAGES_SUCCESS:
			return {
				...state, loadingOrder: false, imagesData: action.payload.data.pagination.data, pages: action.payload.data.pagination.last_page, current_page: action.payload.data.pagination.current_page, perPage: action.payload.data.pagination.per_page, total_images: action.payload.data.pagination.total, from_page: action.payload.data.pagination.from
			};
		case adminConstants.GET_ORDER_IMAGES_FAILURE:
			return {
				...state, loadingOrder: false, error: action.payload
			};

		case adminConstants.GET_ALL_ORDER_PHASE_IMAGES_REQUEST:
			return {
				...state, loadingOrder: true
			}
		case adminConstants.GET_ALL_ORDER_PHASE_IMAGES_SUCCESS:
			return {
				...state, loadingOrder: false, allImagesByPhase: action.payload.downloadImages
			};
		case adminConstants.GET_ALL_ORDER_PHASE_IMAGES_FAILURE:
			return {
				...state, loadingOrder: false, error: action.payload
			};
		case adminConstants.GET_ALL_REQUIRED_ASSESTS_SUCCESS:
			return {
				...state,
				requiredAssets: action.payload
			}
		case adminConstants.GET_ALL_REQUIRED_ASSESTS_FAILURE:
			return {
				...state,
				error: action.payload
			}

		case adminConstants.GET_ALL_RECENTLY_PROCESSED_ORDERS_SUCCESS:
			return {
				...state,
				recenltyProcessedOrders: {
					...action.payload.data.orders,
					projected_date: action.payload.data.projected_date
				}
			}
		case adminConstants.GET_ALL_RECENTLY_PROCESSED_ORDERS_FAILURE:
			return {
				...state,
				error: action.payload
			}

		case adminConstants.STORE_EDITOR_ORDER_IMG_REQUEST:
			return {
				...state, loadingOrder: true
			}
		case adminConstants.STORE_EDITOR_ORDER_IMG_SUCCESS:
			return {
				...state,
				loadingOrder: false,
				imageUpdates: action.payload.imageUpdates,
				order_details: {
					...state.order_details,
					images_count: action.payload.imageUpdates.images_count
				}
			};
		case adminConstants.STORE_EDITOR_ORDER_IMG_FAILURE:
			return {
				...state, loadingOrder: false, error: action.payload
			};
		case adminConstants.STORE_ORDER_IMAGES_IN_DB_SUCCESS:
			return {
				...state,
				loadingOrder: false,
				imageUpdates: action.payload.imageUpdates,
				order_details: {
					...state.order_details,
					images_count: action.payload.imageUpdates.images_count,
					originalImages: action.payload.imageUpdates.images_count
				}
			};
		case adminConstants.STORE_ORDER_IMAGES_IN_DB_FAILURE:
			return {
				...state, loadingOrder: false, error: action.payload
			};

		case adminConstants.ASSIGN_USER_TO_ORDER_REQUEST:
			return {
				...state, loadingOrder: true
			}
		case adminConstants.ASSIGN_USER_TO_ORDER_SUCCESS:
			return {
				...state, loadingOrder: false, order_details: action.payload.order_details
			};
		case adminConstants.ASSIGN_USER_TO_ORDER_FAILURE:
			return {
				...state, loadingOrder: false, error: action.payload
			};
		case adminConstants.UPDATE_ORDER_SETTINGS_SUCCESS:
			return {
				...state,
				orderSettings: {
					...state.orderSettings,
					...action.payload.data
				}
			}

		case adminConstants.UPDATE_SETTINGS_IN_ORDER_DETAIL_SUCCESS:
			return {
				...state,
				order_details: {
					...state.order_details,
					settings: {
						...state.order_details.settings,
						...action.payload.data
					}
				}
			}

		case frontEndUserConstants.GET_AGENCY_RESOURCES_BY_ROLE_SUCCESS:
			return {
				...state, loadingOrder: false, agencyResources: action.payload.data
			}
		case frontEndUserConstants.GET_AGENCY_RESOURCES_BY_ROLE_FAILURE:
			return {
				...state, loadingOrder: false, error: action.payload
			}
		case frontEndUserConstants.GET_ORDER_ITEMS_SUCCESS:
			return {
				...state,
				orderItems: action.payload.data
			}
		case frontEndUserConstants.GET_ORDER_ITEMS_FAILURE:
			return {
				...state,
				error: action.paylaod
			}

		case adminConstants.GET_ORDERS_RESOURCES_BY_ROLE_SUCCESS:
			return {
				...state, loadingOrder: false, orderResources: action.payload && action.payload.data ? action.payload.data : initialState.orderResources
			}
		case adminConstants.GET_ORDERS_RESOURCES_BY_ROLE_FAILURE:
			return {
				...state, loadingOrder: false, error: action.payload
			}

		case adminConstants.STORE_PM_ORDER_VIDEO_SUCCESS:
			return {
				...state, loadingOrder: false, orderResources: action.payload.data
			}
		case adminConstants.STORE_PM_ORDER_VIDEO_FAILURE:
			return {
				...state, loadingOrder: false, error: action.payload
			}

		case adminConstants.STORE_ORDER_ASSIGNED_ITEMS_REQUEST:
			return {
				...state, loadingOrder: true
			}
		case adminConstants.STORE_ORDER_ASSIGNED_ITEMS_SUCCESS:
			return {
				...state,
				loadingOrder: false,
				shipping_details: {
					...state.shipping_details,
					assignedItems: action.payload.assignedItems
				}
			};
		case adminConstants.STORE_ORDER_ASSIGNED_ITEMS_FAILURE:
			return {
				...state, loadingOrder: false, error: action.payload
			};
		case adminConstants.GET_LPR_DATA_REQUEST:
			return {
				...state, loadingOrder: true
			}
		case adminConstants.GET_LPR_DATA_FAILURE:
			return {
				...state, error: action.payload, loadingOrder: false
			}
		case adminConstants.GET_LPR_DATA_SUCCESS:
			console.log('lpr data', action.payload.data);
			return {
				...state, LPR_Data: action.payload.data, loadingOrder: false
			}
		case adminConstants.GET_ORDER_RECEIVE_STATUS_SUCCESS:
			return {
				...state,
				order_receive_status: action.payload.data
			}
		case adminConstants.GET_REORDERS_SUCCESS:
			return {
				...state,
				ReOrders: action.payload.data.pagination,
				ReOrders_total: action.payload.data.pagination.total,
				shippingSettings: action.payload.data.settings,
				reorders_total_sum: action.payload.data.reorders_total_charges_sum
			}
		case adminConstants.GET_REORDER_SUCCESS:
			return {
				...state,
				ReOrder: action.payload.data.ReOrder,
				shippingSettings: action.payload.data.shippingSettings
			}
		case adminConstants.STORE_IMAGE_FAILURE:
			return {
				...state,
				error: action.payload.error
			}
		case frontEndUserConstants.CLEAR_ORDER_RESOURCES_SUCCESS:
			return {
				...state,
				orderResources: action.payload
			}

		case adminConstants.GET_ALL_ORDERS_WITH_STATUS_REQUEST:
			return {
				...state,
				loadingOrder: true
			}
		case adminConstants.GET_ALL_ORDERS_WITH_STATUS_SUCCESS:
			return {
				...state,
				orderWithStatusData: action.payload.data.pagination.data,
				OWSpages: action.payload.data.pagination.last_page,
				loadingOrder: false,
				OWScurrent_page: action.payload.data.pagination.current_page,
				OWStotal_orders: action.payload.data.pagination.total,
				ordersCountWithModerator: action.payload.data.ordersCountWithModerator,
			};
		case adminConstants.GET_ALL_ORDERS_WITH_STATUS_FAILURE:
			return {
				...state,
				loadingOrder: false,
				error: action.payload.error
			}

		case adminConstants.GET_FLAGGED_ORDERS_SUCCESS:
			return {
				...state,
				flagged_orders: action.payload.data
			}
		case adminConstants.GET_FLAGGED_ORDERS_FAILURE:
			return {
				...state,
				error: action.payload
			}
		case adminConstants.GET_MULTIPLE_SHIPMENT_ORDERS_SUCCESS:
			return {
				...state,
				multiple_shipment_orders: action.payload.data
			}

		case adminConstants.GET_CONTACT_LIST_REQUEST:
			return { ...state, loadingOrder: true };
		case adminConstants.GET_CONTACT_LIST_SUCCESS:
			return {
				...state,
				loadingOrder: false,
				[`${action.payload.data.callback}_data`]: action.payload.data.pagination.data,
				[`${action.payload.data.callback}_pages`]: action.payload.data.pagination.last_page,
				[`${action.payload.data.callback}_current_page`]: action.payload.data.pagination.current_page,
                scheduledCalls: action.payload.data.scheduledCalls
			};
		case adminConstants.GET_CONTACT_LIST_FAILURE:
			return { ...state, loadingOrder: false, error: action.payload };

		case adminConstants.ORDERS_FLYERS_LIST_SUCCESS:
			return {
				...state,
				orders_flyers_list: action.payload.data
			}
		case adminConstants.GET_PREAWARENESS_FLYERS_HISTORY_SUCCESS:
			return {
				...state,
				PreAwarenessFlyersHistory: action.payload.data.orders,
				max_flyer_generation_limit: action.payload.data.max_flyer_generation_limit
			}
		case adminConstants.GET_ORDER_MOSAIC_SUCCESS:
			return {
				...state,
				orderMosaic: action.payload.data
			}
		case adminConstants.GET_ORDER_MATERIALS_SUCCESS:
			return {
				...state,
				orderMaterials: action.payload.data
			}
		case adminConstants.GET_ORDER_MATERIALS_FAILURE:
			return {
				...state,
				error: action.payload
			}
		case adminConstants.GET_ORDER_MOSAICS_SUCCESS:
			return {
				...state,
				orderMosaics: action.payload.data
			}

		case adminConstants.UPDATE_ORDER_PROSHOW_REQUEST:
			return {
				...state, loadingOrder: true
			};
		case adminConstants.UPDATE_ORDER_PROSHOW_SUCCESS:
			return {
				...state,
				loadingOrder: false,
				order_details: {
					...state.order_details,
					pro_show_file_id: action.payload.pro_show_file_id
				}
			}
		case adminConstants.UPDATE_ORDER_PROSHOW_FAILURE:
			return {
				...state, loadingOrder: false, error: action.payload
			};

		case adminConstants.ORDER_TO_MISSING_REQUEST:
			return { ...state, loadingOrder: true };
		case adminConstants.ORDER_TO_MISSING_SUCCESS:
			return {
				...state,
				loadingOrder: false,
				[`${action.payload.data.callback}_data`]: action.payload.data.OrderTOMissing.data,
				[`${action.payload.data.callback}_pages`]: action.payload.data.OrderTOMissing.last_page,
				[`${action.payload.data.callback}_current_page`]: action.payload.data.OrderTOMissing.current_page
			};
		case adminConstants.ORDER_TO_MISSING_FAILURE:
			return { ...state, loadingOrder: false, error: action.payload };


		case adminConstants.GET_PENDING_ORDERS_REQUEST:
			return {
				...state,
				loadingOrder: true
			}
		case adminConstants.GET_PENDING_ORDERS_SUCCESS:
			return {
				...state,
				pendingOrdersData: action.payload.data.data,
				pendingOrderspages: action.payload.data.last_page,
				loadingOrder: false,
				pendingOrderscurrent_page: action.payload.data.current_page,
				pendingOrderstotal_orders: action.payload.data.total,
			};
		case adminConstants.GET_PENDING_ORDERS_FAILURE:
			return {
				...state,
				loadingOrder: false,
				error: action.payload.error
			}


		case adminConstants.STORE_PENDING_ORDERS_REQUEST:
			return {
				...state,
				loadingOrder: true
			}

		case adminConstants.STORE_PENDING_ORDERS_SUCCESS:
			return {
				...state,
				reportsData: action.payload.data,
				REPORTSpages: 1,
				loadingOrder: false,
				REPORTScurrent_page: 1,
				REPORTStotal_orders: 1,
			};
		case adminConstants.STORE_PENDING_ORDERS_FAILURE:
			return {
				...state,
				loadingOrder: false,
				error: action.payload.error
			}



		case adminConstants.CS_ORDERS_REQUEST:
			return {
				...state,
				loadingOrder: true
			}
		case adminConstants.CS_ORDERS_SUCCESS:
			return {
				...state,
				csOrderData: action.payload.data.orderWithStatus.data,
				OWSpages: action.payload.data.orderWithStatus.last_page,
				loadingOrder: false,
				OWScurrent_page: action.payload.data.orderWithStatus.current_page,
				OWStotal_orders: action.payload.data.orderWithStatus.total,
				ordersCountWithModerator: action.payload.data.ordersCountWithModerator,
			};
		case adminConstants.CS_ORDERS_FAILURE:
			return {
				...state,
				loadingOrder: false,
				error: action.payload.error
			}

		case adminConstants.VT_ORDERS_REQUEST:
			return {
				...state,
				loadingOrder: true
			}
		case adminConstants.VT_ORDERS_SUCCESS:
			return {
				...state,
				vtData: action.payload.data,
				loadingOrder: false,
			};
		case adminConstants.VT_ORDERS_FAILURE:
			return {
				...state,
				loadingOrder: false,
				error: action.payload.error
			}
		case adminConstants.UPDATE_ORDER_CSR_STEPS_SUCCESS:
			return {
				...state,
				csr_steps: action.payload.data.csr_steps
			}

		case adminConstants.TRAVELER_PASSWORD_UPDATE_SUCCESS:
			return {
				...state, loadingOrder: false, order_details: {
					...state.order_details,
					password: action.payload.traveler.password,
					username: action.payload.traveler.username
				}
			};
		case adminConstants.TRAVELER_PASSWORD_UPDATE_FAILURE:
			return {
				...state, loadingOrder: false, error: action.payload
			};
		case adminConstants.GET_PRE_SHIPMENT_EMAIL_LIST_SUCCESS:
			return {
				...state,
				preShipmentEmailList: action.payload.data.pagination,
				days_from_now: action.payload.data.days_from_now
			}

		case adminConstants.GET_PRE_DEPARTURE_LIST_SUCCESS:
			return {
				...state,
				preDepartureList: action.payload.data.scheduledCalls,
				days_from_now: action.payload.data.days_from_now
			}
		case adminConstants.GET_ORDER_CHECKIN_LIST_SUCCESS:
			return {
				...state,
				orderCheckInList: action.payload.data.pagination
			}
		case adminConstants.SAVE_SEARCH_PARAMS:
			return {
				...state,
				searchParams: action.payload.data
			}
        case adminConstants.ALLOW_SAVE_WORKING_IMAGES:
            console.log("reducer alow", action.payload);
			return {
				...state,
				allowSave: action.payload
			}
		case adminConstants.DISABLE_SAVE_WORKING_IMAGES:
			return {
				...state,
				allowSave: action.payload
			}


		default:
			return {
				...state
			};
	}
}
