import React, { Component } from "react";
import { connect } from 'react-redux';
import {
  Grid,
  Row,
  Col,
  OverlayTrigger,
  Popover
} from "react-bootstrap";
import ThemeModal from 'components/ThemeComponents/ThemeModal';
import OpenModalButton from 'components/ThemeComponents/openModelButton';
import { closeModal, toggleModal, openModal } from 'helper/helperFunctions';
import { Card } from "components/Card/Card.jsx";
import { hasPermission } from 'helper/hasPermission';
import { adminLabels } from 'redux/constant/admin-label-constant';
import ProShowForm from "./ProShowForm";
import { getAgencyType, getRanges } from 'redux/actions/settings-actions';
import { getAllAgencies } from "redux/actions/agency-actions";
import { getProShowFilesPagination, getProShowFile, deleteProShowFile } from 'redux/actions/proShowFile-action';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import Confirmalertfordelete from 'components/ThemeComponents/confirmAlertForDelete';
import { confirmAlert } from 'react-confirm-alert';
import 'react-table/react-table.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Pagination from "../../ThemeComponents/Pagination";

class ProShowListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addModalProShowFile: this.props.addModalProShowFile,
      editModalProShowFile: this.props.editModalProShowFile,
      onCloseAfterApi: false,
      sorted: '',
      filtered: '',
      pageSize: 10,
      page: 0,
    };
    this.toggleModal = toggleModal.bind(this);
    this.props.getAgencyTypeCall();
    this.props.getAllAgenciesCall();
    this.props.getRangesCall();
  }
  proShowFilesPaginationData = (page = this.state.page, filtered = this.state.filtered, sorted = this.state.sorted, pageSize = this.state.pageSize) => {
    this.props.proShowFilesPaginationDataCall(page, filtered, sorted, pageSize);
  }
  filterSearchHandle = (e) => {
    this.setState({
      'filtered': e.target.value
    });
  }
  deleteProShowFile = (userId) => {
    this.props.deleteProShowFileCall(userId).then(response => {
      this.pagination.dataCall();
    });
  }
  deleteConfirmProShowFile = (userId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete typeName="ProShowFile" description="Are you sure to delete the pro show file?" onClosePro={onClose} deleteType={() => this.deleteProShowFile(userId)} />
        )
      }
    })
  }
  componentDidUpdate(prevProp, prevState) {
    if (prevProp.proShowFileShow !== this.props.proShowFileShow) {
      this.setState({ editModalProShowFile: true })
    }
    if ((prevState.addModalProShowFile && !this.state.addModalProShowFile) || (prevState.editModalProShowFile && !this.state.editModalProShowFile)) {
      this.pagination.dataCall();
    }
  }

  paginationCall = (data) => {
    return this.props.proShowFilesPaginationDataCall(data.page, data.filter, data.sort, data.pageSize);
  }

  render() {
    // const { /*filtered*/ } = this.state;
    const { /*loadingProShowFile, proShowFileData, pages,*/ proShowFileShow } = this.props;
    const columns = [
      {
        Header: "Pro Show Files Listing",
        headerClassName: 'headerPagination',
        columns: [
          {
            Header: "Title",
            accessor: "title",
            className: "action-center"
          },
          {
            Header: "Default",
            accessor: "isDefault",
            className: "action-center",
            Cell: ({ value }) => (
              <span>{value ? 'Yes' : 'No'}</span>
            )
          },
          {
            Header: "Agencies",
            accessor: "agencies",
            className: "action-center",
            Cell: ({ value }) => (
              <div>
                {
                  value.map((prop, key) => {
                    return (<span className="badge badge-success" key={key}>{prop.name}</span>)
                  })
                }
              </div>
            ),
            sortable: false,
          },
          {
            Header: "Agency Types",
            accessor: "agency_types",
            className: "action-center",
            Cell: ({ value }) => (
              <div>
                {
                  value.map((prop, key) => {
                    return (<span className="badge badge-success" key={key}>{prop.title}</span>)
                  })
                }
              </div>
            ),
            sortable: false,
          },
          {
            Header: "Destinations",
            accessor: "destinations",
            className: "action-center",
            Cell: ({ value }) => (
              <div>
                {
                  value.map((prop, key) => {
                    return (<span className="badge badge-success" key={key}>{prop.title}</span>)
                  })
                }
              </div>
            ),
            sortable: false,
          },
          {
            Header: "Range",
            accessor: "range",
            className: "action-center",
            Cell: ({ value }) => (
              <div>
                {
                  value.title
                }
              </div>
            )
          },
          {
            Header: "Actions",
            accessor: "id",
            Cell: ({ value }) => (
              <div>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{hasPermission('destination-edit') ? 'Edit Pro Show File' : adminLabels.DONT_ALLOW}</Popover>}>
                  <span>
                    {
                      hasPermission('destination-edit') &&
                      <button type='button' className="editIcon orderDetailsAction" onClick={() => this.props.getProShowFileCall(value)}>&nbsp;</button>
                    }
                    {
                      !hasPermission('destination-edit') &&
                      <button type='button' className="editIcon orderDetailsAction button_disabled" >&nbsp;</button>
                    }
                  </span>
                </OverlayTrigger>
                {
                  <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{hasPermission('destination-delete') ? 'Delete Pro Show File' : adminLabels.DONT_ALLOW}</Popover>}>
                    <span>
                      {
                        hasPermission('destination-delete') &&
                        <button type='button' className="deleteIcon ml-xs orderDetailsAction" onClick={() => this.deleteConfirmProShowFile(value)}>&nbsp;</button>
                      }
                      {
                        !hasPermission('destination-delete') &&
                        <button type='button' className="deleteIcon ml-xs orderDetailsAction button_disabled" >&nbsp;</button>
                      }
                    </span>
                  </OverlayTrigger>
                }
              </div>
            ),
            className: "action-center",
            sortable: false
          }
        ]
      }
    ];
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                ctTableResponsive
                content={
                  <div>
                    <Grid fluid>
                      <Row>
                        <Col md={12}>
                          <div className="secHeading">
                            Pro Show Files List
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="flexElem flexResponsive" style={{ "alignItems": "flex-start", "justifyContent": "space-between" }}>
                            <div className="">
                              <OpenModalButton
                                openModal={hasPermission('destination-create') ? openModal.bind(this, "addModalProShowFile") : null}
                                classButton={['backButton pt-sm no_radius pb-sm primary mt-none btn-block btn-info btn', !hasPermission('destination-create') ? 'button_disabled' : '']}
                                buttonName="Add ProShowFile"
                                tooltipText={hasPermission('destination-create') ? 'Add ProShowFile' : adminLabels.DONT_ALLOW}
                                classIcon={['fa', 'fa-plus']}
                              />
                            </div>
                            <div className="custom-search-input">
                              {/* <FormInputs
                                ncols={["col-md-12"]}
                                onChange={this.filterSearchHandle}
                                proprieties={[
                                  {
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "Search Pro Show Files Title",
                                    onChange: this.filterSearchHandle,
                                    name: "filter"
                                  }
                                ]}
                              /> */}
                            </div>
                          </div>
                        </Col>
                        {/* <Col md={12} className="mt-md">
                          <ReactTable
                            noDataText= 'No pro show file found'
                            data={proShowFileData}
                            pages={pages}
                            columns={columns}
                            filtered={filtered}
                            defaultPageSize={10}
                            loading={loadingProShowFile}
                            className="-striped listing"
                            loadingText={'Loading...'}
                            pageData={this.proShowFilesPaginationData}
                            manual
                            onFetchData={(state, instance) => {
                              var sort = state.sorted.length === 0 ? '' : state.sorted[0].id + ',desc:' + state.sorted[0].desc;
                              state.pageData(state.page + 1, state.filtered, sort, state.pageSize);
                            }}
                          />
                        </Col> */}
                        <Pagination
                          ref={(p) => this.pagination = p}
                          showPagination={false}
                          columns={columns}
                          pageSize={100}
                          getDataCall={this.paginationCall}
                          filterView={true}
                          // downloadData={true}
                          // downloadFileName={'Orders'}
                          // lowerContent = {null}
                          filterPlaceHolder={'Search Pro Show Files Title'}
                          getRowProps={this.getRowProps}
                        // showAllToggle={true}
                        />
                      </Row>
                    </Grid>

                    {/*Add ProShowFile Modal Start*/}
                    <ThemeModal modalClass="" title='Add ProShowFile' show={this.state.addModalProShowFile} hide={() => { this.toggleModal('addModalProShowFile') }} >
                      <ProShowForm
                        formType="add"
                        pro_show_file={OC.PROSHOWFILE}
                        getPagination={this.pagination ? this.pagination.dataCall : null}
                        closeModel={closeModal.bind(this, "addModalProShowFile")}
                      />
                    </ThemeModal>
                    {/*Add ProShowFile Modal End*/}

                    {/*Edit ProShowFile Modal start*/}
                    {
                      proShowFileShow &&
                      <ThemeModal modalClass="" title='Edit ProShowFile' show={this.state.editModalProShowFile} hide={() => { this.toggleModal('editModalProShowFile') }} >
                        <ProShowForm
                          formType="edit"
                          pro_show_file={proShowFileShow}
                          getPagination={this.pagination ? this.pagination.dataCall : null}
                          closeModel={closeModal.bind(this, "editModalProShowFile")}
                        />
                      </ThemeModal>

                    }
                    {/*Edit ProShowFile Modal End*/}
                  </div>
                } />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return ({
    getAgencyTypeCall: () => { dispatch(getAgencyType()) },
    getAllAgenciesCall: () => { dispatch(getAllAgencies()) },
    getRangesCall: () => { dispatch(getRanges()) },
    proShowFilesPaginationDataCall: (page, filter, sort, pageSize) => { return dispatch(getProShowFilesPagination(page, filter, sort, pageSize)) },
    getProShowFileCall: (id) => { dispatch(getProShowFile(id)) },
    deleteProShowFileCall: (id) => { return dispatch(deleteProShowFile(id)) },
  });
};
function mapStateToProps(state) {
  const { loadingProShowFile, proShowFileData, pages, current_page, proShowFileShow } = state.proShowFileReducer;

  return {
    loadingProShowFile, proShowFileData, pages, current_page, proShowFileShow
  };
}



const ProShowList = connect(mapStateToProps, mapDispatchToProps)(ProShowListPage);
export default ProShowList;
