import React, { Component } from "react";
import SimpleReactValidator from 'simple-react-validator';
import {
	Button,
} from "react-bootstrap";
import { getAgency, storeAgency, editAgency } from 'redux/actions/agency-actions';
import { getSettingValue, getCountries, getProgramType } from 'redux/actions/settings-actions';
import { storeOrder } from 'redux/actions/order-actions';
import { connect } from 'react-redux';
import { getRandomInt, convertMomentIntoDateObj, focusInput } from 'helper/helperFunctions';
import { hasPermission } from 'helper/hasPermission';
import AddressForm from 'components/ThemeComponents/addressForm';
import OrderDetailForm from 'components/Admin/Order/OrderDetailForm';

import PricingDetail from 'components/Admin/Order/PricingDetail';
import { Link } from 'react-router-dom';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { Card } from "components/Card/Card.jsx";
import { withRouter } from 'react-router-dom'
import OrderComments from 'components/Admin/Order/OrderComments';
import cloneDeep from "lodash.clonedeep";
import { getAgencyUser } from "redux/actions/agency-user-actions";
var dateFormat = require('dateformat');

/*
	***Read***
	For validation focus its mandotary to give "id_Name" to parent div of input field with
	col-md-xyz, with validatorOrder('id_Name','','').
	Otherwise it will generate an following error:
	js:22 Uncaught TypeError: Cannot read property 'top' of undefined.
*/
class OrderFormPage extends Component {
	constructor(props) {
		super(props);
		this.validatorOrder = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
		this.state = {
			order_details: { ...OC.ORDERDETAIL, uuid: getRandomInt(100000) },
			pricing_details: OC.PRICINGDETAIL,
			shipping_details: OC.SHIPPINGDETAIL,
			formType: this.props.formType,
			options: {
				statespre_trip_material_address: [],
				statesvideo_shipping_address: []
			},
			submitted: false,
			agency: { settings: { collect_tax: {} } },
			checkedpre_trip_material_address: 0,
			checkedvideo_shipping_address: 0,
			checkedvideo_ship_to_same_address: 0,
			alreadyPreTrip: false,
            is_france: false,
            vsa_is_france: false,
            ptma_is_france: false,
		};
		// this.orderUpdate = this.orderUpdate.bind(this);
		this.pricingDetailUpdate = this.pricingDetailUpdate.bind(this);
		// this.shippingDetailUpdate = this.shippingDetailUpdate.bind(this);
		this.clearState = this.clearState.bind(this);
		this.copyPrimaryFromAgencyTo = this.copyPrimaryFromAgencyTo.bind(this);
		this.copyPriTripToVideoShip = this.copyPriTripToVideoShip.bind(this);
		this.handleOrderSubmit = this.handleOrderSubmit.bind(this);
		this.getPricingFromPD = this.getPricingFromPD.bind(this);
		this.getOrderState = this.getOrderState.bind(this);
		this.returnOrderDetails = this.returnOrderDetails.bind(this);
		this.props.getCountriesCall();
		this.props.getProgramTypeCall();
		// this.props.getSettingValueCall('tax');
	}
	// orderUpdate(orderData){
	// 	this.setState({order_details:orderData});
	// }
	pricingDetailUpdate(pricingDetailData) {
		this.setState({ pricing_details: pricingDetailData });
	}
	// shippingDetailUpdate(shippingDetailData, type){
	// 	this.setState(prevState => ({
	//        	...prevState, shipping_details:{
	//        		...prevState.shipping_details, [type] : shippingDetailData
	//        	}
	//        }));
	// }
	clearState(type) {
		this.setState(prevState => ({
			...prevState, options: {
				...prevState.options, ['states' + type]: []
			}
		}));
	}
	//handle copy data logic
	copyPrimaryFromAgencyTo(e, fieldName) {
		const copyFrom = this.state.agency.primary_address;
		// console.log(this.state.order_details)
		// delete copyFrom['zipcode']
		let isChecked = e.target.checked;
		var tempSelect = 'checked' + fieldName;
		var valueTemp = isChecked;
		// var countryLabel = valueTemp?returnLabelWithValue(copyFrom.country_id, this.state.options.countries):null;

		// var stateLabel = null;
		// var countryTempId = fieldName+'_country';
		// var stateTempId = fieldName+'_state';
		if (fieldName === 'video_shipping_address' && this.state.checkedvideo_ship_to_same_address) {
			this.setState({ checkedvideo_ship_to_same_address: false })
		}
		this.setCopyAddress(copyFrom, valueTemp, fieldName, tempSelect);
	}
	copyPriTripToVideoShip(e) {
		var fieldName = 'video_shipping_address';
		const copyFrom = this.ctrl_PTMA.returnAddressData();
		let isChecked = e.target.checked;
		var tempSelect = 'checkedvideo_ship_to_same_address';
		var valueTemp = isChecked;
		// var countryLabel = valueTemp?returnLabelWithValue(copyFrom.country_id, this.state.options.countries):null;

		// var stateLabel = null;
		// var countryTempId = fieldName+'_country';
		// var stateTempId = fieldName+'_state';
		if (this.state.checkedvideo_shipping_address) {
			this.setState({ checkedvideo_shipping_address: false })
		}
		this.setCopyAddress(copyFrom, valueTemp, fieldName, tempSelect);
	}

	setCopyAddress(copyFrom, valueTemp, fieldName, tempSelect) {
		this.setState(prevState => ({
			...prevState, shipping_details: {
				...prevState.shipping_details, [fieldName]: valueTemp ? copyFrom : OC.SHIPPINGDETAIL[fieldName]
			}
		}), function () {
			if (tempSelect === 'checkedpre_trip_material_address' || tempSelect === 'checkedvideo_shipping_address') {
				if (tempSelect && this.state.order_details.agency_sales_rep_id !== '') {
					this.props.getAgencyUserCall(this.state.order_details.agency_id, this.state.order_details.agency_sales_rep_id).then(
						res => {
							if (res) {
								this.setState({
									[tempSelect]: valueTemp
								})
							}
						}
					);
				} else {
					this.setState({
						[tempSelect]: valueTemp
					})
				}
			} else {
				this.setState({ [tempSelect]: valueTemp });
			}
		});
	}

	returnOrderDetails(order_details) {
		this.setState(prevState => ({
			order_details: {
				...prevState.order_details,
				...order_details
			}
		}))
	}

	//handle submit form

	handleOrderSubmit(e) {
		// console.log('sdfsfd');
		e.preventDefault();

		// console.log('one', this.validatorOrder);
		// console.log('two', this.validatorOrder.allValid()?'yes valid':'not valid');
		if (this.validatorOrder.allValid()) {
			// console.log('three', this.validatorOrder);
			// console.log('four', this.validatorOrder.getErrorMessages());
			// console.log('five', this.validatorOrder.errorMessages );
			this.setState({ submitted: true }, function () {
				if (this.state.formType === 'add') {
					let data = {
						order_details: this.ctrl_ODF.returnOrderDetailData(),
						pricing_details: this.ctrl_PD.returnPricingDetailData(),
						shipping_details: {
							pre_trip_material_address: this.ctrl_PTMA.returnAddressData(),
							video_shipping_address: this.ctrl_VSA.returnAddressData()
						}
					};
					let data_clone = cloneDeep(data);
					for (let key in data_clone.order_details.order_dates) {
						if (data_clone.order_details.order_dates[key].value) {
							data_clone.order_details.order_dates[key]['value'] = dateFormat(convertMomentIntoDateObj(data_clone.order_details.order_dates[key].value), "yyyy-mm-dd HH:MM ")
						}
					}
					this.props.storeOrder(data_clone).then(
						response => {
							console.log(response)
							this.props.history.push(`/Admin/${response.data.order_id}/order-edit`);
						}
					);
				}
				else if (this.state.formType === 'edit') {
					this.props.updateAgency(this.state.agency, this.props.agency_idPro);
				}
			});
		} else {
			this.setState({ submitted: false });
			// console.log('error message', this.validatorOrder.getErrorMessages());
			focusInput(this.validatorOrder.getErrorMessages());
			this.validatorOrder.showMessages();
			this.forceUpdate();
		}
	}
	setAddressStates(type, statesData) {
		this.setState(prevState => ({
			...prevState, options: {
				...prevState.options, ['states' + type]: statesData
			}
		}));
	}

	getPricingFromPD(state) {
		this.setState((prevState) => {
			return {
				...prevState,
				pricing_details: state
			}
		})
	}

	getOrderState(state) {
		this.setState((prevState) => {
			return {
				...prevState,
				order_details: state
			}
		})
	}

	componentDidUpdate(prevProps, prevState) {
		if ((prevProps.editAgency !== this.props.editAgency) && !this.props.loadingAgency) {

			this.setState(prevState => ({
				...prevState, order_details: {
					...this.ctrl_ODF.returnOrderDetailData(),
					gtv_sales_rep_id: this.props.editAgency.sales_rep_id,
					agency_id: this.props.editAgency.id,
					agency_type_id: this.props.editAgency.agency_type_id,
					agency: this.props.editAgency,
				}
			}), function () {
				//start if agency primary address state is north carolina then call tax
				if (this.props.editAgency.primary_address && this.props.editAgency.primary_address.state && this.props.editAgency.primary_address.state.title && this.props.editAgency.primary_address.state.title === 'North Carolina') {
					this.props.getSettingValueCall('tax', 'carolina');
				}
				else {
					const taxOT = { ...this.state.pricing_details };
					const order_totals = { ...taxOT.order_totals };
					delete order_totals['tax'];
					this.setState({
						pricing_details: {
							...taxOT,
							order_totals: order_totals
						}
					})
				}
				//end if agency primary address state is north carolina then call tax

				this.setState(prevState => ({
					...prevState, pricing_details: {
						...prevState.pricing_details,
						program_type_id: this.props.editAgency.program_type_id,
						free_dvd_to_agency: this.props.editAgency.free_dvd_to_agency,
						free_dvd_to_client: this.props.editAgency.free_dvd_to_agency,
						settings: {
							...prevState.pricing_details.settings,
							no_gl_copy: this.props.editAgency.settings.no_gl_copy,
							send_gl_fd_copy: this.props.editAgency.settings.send_gl_fd_copy,
						}
					}
				}), function () {
					// console.log('pricing_details ttt', this.state.pricing_details);
					this.setState({ agency: this.props.editAgency });
				});
			});
		}

		if ((this.props.items !== prevProps.items) && !this.props.loadingSettings) {
			const orderItemsState = { ...this.state.pricing_details.order_items };

			for (var key in this.props.items) {
				var priceTemp = this.props.items[key].item_program_price ? parseFloat(this.props.items[key].item_program_price.pivot.price) : this.props.items[key].price;
				orderItemsState[this.props.items[key].title] = {
					item_id: this.props.items[key].id,
					title: this.props.items[key].title,
					quantity: 0,
					price: priceTemp,
					total: 0,
				};
			}

			this.setState(prevState => ({
				...prevState, pricing_details: {
					...prevState.pricing_details, order_items: orderItemsState
				}
			}));
		}

		if ((this.props.settings !== prevProps.settings) && !this.props.loadingSettings) {

			if (this.props.settings.key === 'tax' || this.props.settings.key === 'carolina_tax') {

				const taxOT = { ...this.state.pricing_details };
				const order_totals = { ...taxOT.order_totals };
				var tempOT = {
					code: 'tax',
					value: this.props.settings.value,
				};
				order_totals['tax'] = tempOT;
				this.setState({
					pricing_details: {
						...taxOT,
						order_totals: order_totals
					}
				})
			}
		}
		if (this.state.checkedpre_trip_material_address !== prevState.checkedpre_trip_material_address && this.props.editAgencyUser !== '' && this.state.checkedpre_trip_material_address) {
			this.setState({
				shipping_details: {
					...prevState.shipping_details,
					pre_trip_material_address: {
						...prevState.shipping_details.pre_trip_material_address,
						street_address_2: this.props.editAgencyUser.name
					}
				}
			})
		}
		if ((this.state.checkedvideo_shipping_address !== prevState.checkedvideo_shipping_address) && this.state.checkedvideo_shipping_address && this.props.editAgencyUser !== '') {
			this.setState({
				shipping_details: {
					...prevState.shipping_details,
					video_shipping_address: {
						...prevState.shipping_details.video_shipping_address,
						street_address_2: this.props.editAgencyUser.name
					}
				}
			})
		}
	}

    getChildState = ( childState ) => {
        this.setState({
            is_france: childState
        }, () => {
            // console.log(this.state.is_france, 'is not');
        })
    }
    getChildrenStateVsa = (state) =>{
        this.setState({
            vsa_is_france: state
        })
    }

    getChildrenStatePtma = ( childrenState ) => {
        this.setState({
            ptma_is_france : childrenState
        })
    }
	render() {
		// this.validatorOrder.purgeFields();
		// console.log('validator instance', this.state.agency);
		const {
			order_details,
			pricing_details,
			shipping_details,
			agency,
			checkedpre_trip_material_address,
			checkedvideo_shipping_address,
			checkedvideo_ship_to_same_address,
			formType,
            is_france,
            vsa_is_france,
            ptma_is_france
		} = this.state;
		const { countries, programType } = this.props;
		return (
			<div>
				<form onSubmit={this.handleOrderSubmit} id="order-form">
					<div className="row">
						<span></span>
						<div className="col-md-12">
							<Card title="Order Details" bsClass={['innerCard']} content={
								<OrderDetailForm
									provideCtrlOPF={ctrlOPF => this.ctrl_ODF = ctrlOPF}
									htmlElement="OrderDetail"
									getAgencyCall={this.props.getAgencyCall}
									returnState={this.getOrderState}
									order_details={order_details}
									validatorInstance={this.validatorOrder}
									requiredField={{}}
									formType={formType}
									returnOrderDetails={this.returnOrderDetails}
									defaultGroupTypeByName={true}
                                    agency={agency}
                                    vsa_is_france={vsa_is_france}
                                    ptma_is_france={ptma_is_france}
                                    is_france={ptma_is_france}
								/>
							} />
						</div>
						<span></span>
					</div>
					<div className="row customHalf">
						<span></span>
						<div className="col-md-6">
							<div>
								<Card title="Pre-Trip Material Address" bsClass={['innerCard']} content={
									<React.Fragment>
										<AddressForm
											provideCtrl={ctrl => this.ctrl_PTMA = ctrl}
											htmlElement="PreTrip"
											replaceText="pre trip"
											orderForm
											// getCarolinaTax={this.props.getSettingValueCall}
											// addressUpdate = { this.shippingDetailUpdate }
											clearState={this.clearState}
											type="pre_trip_material_address"
											options={
												{
													'countries': countries
												}
											}
											adressObject={shipping_details.pre_trip_material_address}
											title="Pre-Trip Material Address"
											validatorInstance={this.validatorOrder}
											requiredField={
												{
													'street_address_1': 'required',
													'country_id': 'required',
													'city': 'required',
                                                    'state_id': !ptma_is_france ? ['required'] : '',
													//, 'zipcode': 'integer'
												}
											}
											isClearableCountryPro={false}
											defaultCountry={true}
                                            childrenStatePtma={this.getChildrenStatePtma}
                                            // childState={this.getChildState}
										/>
										{
											agency.primary_address &&
											<div className='clearfix'>
												<span></span>
												<div className="col-md-12">
													<label className="container-check">Use Agency Address
														<input
															type="checkbox"
															name="priTrip"
															className="checkbox"
															value={checkedpre_trip_material_address}
															onChange={(event) => this.copyPrimaryFromAgencyTo(event, 'pre_trip_material_address')}
															checked={checkedpre_trip_material_address}
														/>
														<span className="checkmark"></span>
													</label>
												</div>
											</div>
										}
									</React.Fragment>
								}
								/>
							</div>
						</div>

						<div className="col-md-6 pull-right">
							<Card title="Video Shipping Address" bsClass={['innerCard']} content={
								<React.Fragment>
									<AddressForm
										provideCtrl={ctrl => this.ctrl_VSA = ctrl}
										htmlElement="VidShipping"
										replaceText="vid shipping"
										clearState={this.clearState}
										orderForm
										options={
											{
												'countries': countries
											}
										}
										type="video_shipping_address"
										adressObject={shipping_details.video_shipping_address}
										title="Video Shipping Address"
										validatorInstance={this.validatorOrder}
										requiredField={
											{
												'street_address_1': 'required',
												'country_id': 'required',
												'city': 'required',
                                                'state_id': !vsa_is_france ? ['required'] : '',
												//, 'zipcode': 'integer'
											}
										}
										isClearableCountryPro={false}
										defaultCountry={true}
                                        childState={this.getChildState}
                                        childrenStateVsa={this.getChildrenStateVsa}
									/>

									<div className="clearfix">
										<span></span>
										<div className='col-md-7 ' >
											<label className="container-check">Ship Videos To Same Address
											<input
													type="checkbox"
													name="videoShip"
													className="checkbox"
													value={checkedvideo_ship_to_same_address}
													onChange={this.copyPriTripToVideoShip}
													checked={checkedvideo_ship_to_same_address}
												/>
												<span className="checkmark"></span>
											</label>
										</div>
										{
											agency.primary_address &&

											<div className='col-md-5' >
												<label className="container-check">Use Agency Address
												<input
														type="checkbox"
														name="videoShip"
														className="checkbox"
														value={checkedvideo_shipping_address}
														onChange={(event) => this.copyPrimaryFromAgencyTo(event, 'video_shipping_address')}
														checked={checkedvideo_shipping_address}
													/>
													<span className="checkmark"></span>
												</label>
											</div>
										}
									</div>
								</React.Fragment>
							}
							/>
						</div>
						<span></span>
					</div>
					<div className="row">
						<span></span>
						<div className="col-md-12">
							<Card title="Pricing Details" bsClass={["innerCard"]} content={
								<PricingDetail
									provideCtrlPD={ctrlPD => this.ctrl_PD = ctrlPD}
									htmlElement="PricingDetail"
									collect_tax={agency.settings.collect_tax}
									pricing_details={pricing_details}
									order_details={order_details}
									// returnState={this.getPricingFromPD}
									validatorInstance={this.validatorOrder}
									requiredField={{}}
									hasCard={true}
									programType={programType}
									formType='create'
								/>
							}
							/>
						</div>
						<span></span>
					</div>
					<OrderComments formType={this.props.formType} contactLog={this.props.location.state} modelId={order_details.uuid} withUuid={true} order_details={order_details} showOnly={['Admin', 'PreTripMaterialsShip', 'Production', 'DVD_MozaixShip', 'ContactLog']} addExceptTab={[]} />
					<hr />
					<div className="text-center">
						{

							(
								(
									(this.props.formType === 'add') && hasPermission('order-create')
								)
							) &&
							<Button type='submit' form="order-form" className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm" >Save</Button>
						}
						<Link to="/Admin/orders" className="backButton pt-sm no_radius pb-sm primary btn btn-lg btn-info ml-sm mt-sm">Cancel</Link>
					</div>
				</form>

			</div>
		);
	};
}

const mapDispatchToProps = dispatch => {
	return ({
		getAgencyCall: (id) => { dispatch(getAgency(id)) },
		getSettingValueCall: (key, stateName) => { dispatch(getSettingValue(key, stateName)) },
		getCountriesCall: () => { dispatch(getCountries()) },
		storeAgency: (agencyData) => { dispatch(storeAgency(agencyData)) },
		storeOrder: (orderData) => { return dispatch(storeOrder(orderData)) },
		updateAgency: (agencyData, agency_id) => { dispatch(editAgency(agencyData, agency_id)) },
		getProgramTypeCall: () => { dispatch(getProgramType()) },
		getAgencyUserCall: (agency_id, id) => dispatch(getAgencyUser(agency_id, id))
	});
};
function mapStateToProps(state) {
	const { loadingAgency, editAgency } = state.agenciesReducer;
	const { editAgencyUser } = state.agencyUserReducer;
	const { loadingOrder, order } = state.ordersReducer;
	const { loadingSettings, countries, items, settings, programType } = state.settingsReducer;
	// console.log('programType', programType);
	return {
		loadingAgency, editAgency,
		loadingOrder, order,
		loadingSettings, countries, items, settings, programType,
		editAgencyUser
	};
}

const OrderForm = connect(mapStateToProps, mapDispatchToProps)(OrderFormPage);

export default withRouter(OrderForm);
