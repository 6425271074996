import { adminConstants } from '../constant/admin-constant';
import { alertActions } from './alert-actions';
// import { history } from '../../helper/history';
import { agencyUserService } from '../../services/agencyUser';

export function storeAgencyUser(agencyId, AgencyUserData) {
    return dispatch => {
        // dispatch(request());
        return agencyUserService.storeAgencyUser(agencyId, AgencyUserData)
            .then(
                response => {
                    // dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                    return response
                    // setTimeout(function(){ 
                    //     history.go(0);
                    // }, 1500);
                },
                error => {
                    // dispatch(failure(error));
                    dispatch(alertActions.error(error));
                    return Promise.reject(error)
                }
            );
    }

    // function request() { return { type: adminConstants.STORE_AGENCY_USER_REQUEST } }
    // function success(response) { return { type: adminConstants.STORE_AGENCY_USER_SUCCESS, payload: response } }
    // function failure(error) { return { type: adminConstants.STORE_AGENCY_USER_FAILURE, payload: error } }
}

export function getAgencyUser(agency_id, id) {
    return dispatch => {
        dispatch(request());
        return agencyUserService.getAgencyUser(agency_id, id)
            .then(
                AgencyUser => {
                    dispatch(success(AgencyUser));
                    return AgencyUser;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                    Promise.reject(error)
                }
            );
    }

    function request() { return { type: adminConstants.GET_AGENCY_USER_REQUEST } }
    function success(AgencyUser) { return { type: adminConstants.GET_AGENCY_USER_SUCCESS, payload: AgencyUser } }
    function failure(error) { return { type: adminConstants.GET_AGENCY_USER_FAILURE, payload: error } }
}

export function editAgencyUser(AgencyUserData, userId, agency_id) {

    return dispatch => {
        // dispatch(request());
        return agencyUserService.editAgencyUser(AgencyUserData, userId, agency_id)
            .then(
                response => {
                    // dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                    return response;
                    // setTimeout(function(){ 
                    //     history.go(0);
                    // }, 1500);
                },
                error => {
                    // dispatch(failure(error));
                    dispatch(alertActions.error(error));
                    return Promise.reject(error)
                }
            );
    }

    // function request() { return { type: adminConstants.EDIT_AGENCY_USER_REQUEST } }
    // function success(response) { return { type: adminConstants.EDIT_AGENCY_USER_SUCCESS, payload: response } }
    // function failure(error) { return { type: adminConstants.EDIT_AGENCY_USER_FAILURE, payload: error } }
}

export function deleteAgencyUser(agency_id, userId) {
    return dispatch => {
        // dispatch(request());
        return agencyUserService.deleteAgencyUser(agency_id, userId)
            .then(
                response => {
                    // dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                    return response;
                    // setTimeout(function(){ 
                    //     history.go(0);
                    // }, 1500);
                },
                error => {
                    // dispatch(failure(error));

                    dispatch(alertActions.error(error));
                    return Promise.reject(error)
                }
            );
    }

    // function request() { return { type: adminConstants.DELETE_AGENCY_USER_REQUEST } }
    // function success(response) { return { type: adminConstants.DELETE_AGENCY_USER_SUCCESS, payload: response } }
    // function failure(error) { return { type: adminConstants.DELETE_AGENCY_USER_FAILURE, payload: error } }
}

export function getAgencyUsersPagination(agency_id, page, filter, sort, pageSize) {
    return dispatch => {
        // dispatch(request())
        return agencyUserService.getAgencyUsersPagination(agency_id, page, filter, sort, pageSize)
            .then(
                response => {
                    return response;
                    // dispatch(success(response));
                },
                error => {
                    return Promise.reject(error)
                    // dispatch(failure(error));
                    // dispatch(alertActions.error(error));
                }
            );
    };

    // function request() { return { type: adminConstants.PAGINATION_AGENCY_USERS_REQUEST } }
    // function success(response) { return { type: adminConstants.PAGINATION_AGENCY_USERS_SUCCESS, payload: response } }
    // function failure(error) { return { type: adminConstants.PAGINATION_AGENCY_USERS_FAILURE, payload: error } }
}