import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Row, Col } from 'react-bootstrap'
import Card from '../../Card/Card';
import Select from 'react-select';
import { getAgencyType, storeAgencyType, updateAgencyType } from '../../../redux/actions/agencyType-action';
import { getBrands } from '../../../redux/actions/account-action';
import ReactValidator from 'simple-react-validator'
import { helperFunctions } from '../../../helper/helperFunctions';
// import { indexOf } from 'lodash';
// import { helperFunctions as HF } from '../../../helper/helperFunctions'

class AgencyTypeForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            agencyType: {
                title: '',
                code: '',
                accounts: [],
            },
            titles: {
                ot1: {
                    title: 'Opening Title',
                    slug: 'ot1',
                    value: ''
                },
                ct1: {
                    title: 'CLOSING TITLE 1',
                    slug: 'ct1',
                    value: ''
                },
                ct2: {
                    title: 'CLOSING TITLE 2',
                    slug: 'ct2',
                    value: ''
                },
                ct3: {
                    title: 'CLOSING TITLE 3',
                    slug: 'ct3',
                    value: ''
                }
            }
        }
        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.agencyTypeSubmitHandler = this.agencyTypeSubmitHandler.bind(this);
        this.selectChangeHandler = this.selectChangeHandler.bind(this);
        this.validator = new ReactValidator();
        if (this.props.formType === 'edit' && (this.props.data.id !== undefined || null)) {
            this.props.getAgencyTypeCall(this.props.data.id);
        }
        this.props.getBrandsCall();
        this.HFInputChangeHandler = helperFunctions.inputChangeHandler.bind(this)
    }

    inputChangeHandler(e) {
        const agencyType = { ...this.state.agencyType };
        agencyType[e.target.name] = e.target.value
        this.setState({
            agencyType: agencyType
        })
    }

    selectChangeHandler(selectedOption, action) {
        const agencyType = { ...this.state.agencyType };
        console.log(selectedOption, action)
        if (Array.isArray(selectedOption)) {
            if (action.action === 'select-option') {
                selectedOption.forEach(option => {
                    if (!agencyType.accounts.includes(option)) {
                        agencyType[action.name].push(option);
                    }
                });
            } else if (action.action === 'remove-value') {
                agencyType[action.name] = agencyType[action.name].filter(item => {
                    return item.value !== action.removedValue.value
                })
            }
        } else {
            if (action.action === 'remove-value') {
                agencyType[action.name] = agencyType[action.name].filter(item => {
                    return item.value !== action.removedValue.value
                })
            } else {
                agencyType[action.name] = selectedOption.value;
            }
        }
        this.setState({
            agencyType: agencyType
        })
    }

    agencyTypeSubmitHandler() {
        if (this.validator.allValid()) {
            const data = { ...this.state.agencyType, titles: this.state.titles };
            console.log(data);
            data.accounts = data.accounts.map(item => item.value);
            if (this.props.formType === 'add') {
                this.props.storeAgencyTypeCall(data).then(
                    response => {
                        if ('hide' in this.props) {
                            this.props.hide();
                            this.props.getPagination();
                        }
                    }
                );
            }
            else if (this.props.formType === 'edit' && (this.props.data.id !== undefined || null)) {
                this.props.updateAgencyTypeCall(this.props.data, data).then(
                    response => {
                        if ('hide' in this.props) {
                            this.props.hide();
                            this.props.getPagination();
                        }
                    }
                )
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.agencyTypeSingle !== this.props.agencyTypeSingle) {
            this.setState({
                agencyType: this.props.agencyTypeSingle,
                titles: this.props.agencyTypeSingle.videoTitles
            })
        }
    }


    render() {
        const { brands } = this.props;
        const { titles } = this.state;
        return (
            <div >
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card bsClass={['innerCard']} content={
                                <React.Fragment>
                                    <div className='row' >
                                        <div className='col-md-6' >
                                            <div className='form-group'>
                                                <label>Title<span className="requiredClass">*</span></label>
                                                <input
                                                    name='title'
                                                    value={this.state.agencyType.title}
                                                    onChange={this.inputChangeHandler}
                                                    type='text'
                                                    className='form-control'
                                                    placeholder='Title...' />
                                                {this.validator.message('Title', this.state.agencyType.title, 'required')}
                                            </div>
                                        </div>
                                        <div className='col-md-6' >
                                            <div className='form-group'>
                                                <label>Code<span className="requiredClass">*</span></label>
                                                <input
                                                    name='code'
                                                    value={this.state.agencyType.code}
                                                    onChange={this.inputChangeHandler}
                                                    type='text'
                                                    className='form-control'
                                                    placeholder='Code...' />
                                                {this.validator.message('Code', this.state.agencyType.code, 'required')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row' >
                                        <div className='col-md-6' >
                                            <div className='form-group'>
                                                <label>Brand<span className="requiredClass">*</span></label>
                                                <Select
                                                    // value={HF.returnLabelWithValue(this.state.agencyType.accounts, brands)}
                                                    value={this.state.agencyType.accounts.map(item => item)}
                                                    onChange={this.selectChangeHandler}
                                                    options={brands}
                                                    isSearchable={true}
                                                    isMulti={true}
                                                    name='accounts'
                                                    placeholder={'Select Brand'}
                                                />
                                                {this.validator.message('Brand', this.state.agencyType.accounts, 'min:1,array')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="">Opening Title<span className='requiredClass'>*</span></label>
                                                <textarea
                                                    value={titles.ot1.value}
                                                    name="titles.ot1.value"
                                                    onChange={this.HFInputChangeHandler}
                                                    className='form-control'
                                                    id=""
                                                    cols="30"
                                                    rows="5"></textarea>
                                                {this.validator.message('Opening Title', titles.ot1.value, 'required')}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="">Closing Title 1<span className='requiredClass'>*</span></label>
                                                <textarea
                                                    value={titles.ct1.value}
                                                    name="titles.ct1.value"
                                                    onChange={this.HFInputChangeHandler}
                                                    className='form-control'
                                                    id=""
                                                    cols="30"
                                                    rows="5"></textarea>
                                                {this.validator.message('Closing Title 1', titles.ct1.value, 'required')}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="">Closing Title 2<span className='requiredClass'>*</span></label>
                                                <textarea
                                                    value={titles.ct2.value}
                                                    onChange={this.HFInputChangeHandler}
                                                    name="titles.ct2.value"
                                                    className='form-control'
                                                    id=""
                                                    cols="30"
                                                    rows="5"></textarea>
                                                {this.validator.message('Closing Title 2', titles.ct2.value, 'required')}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="">Closing Title 3<span className='requiredClass'>*</span></label>
                                                <textarea
                                                    value={titles.ct3.value}
                                                    onChange={this.HFInputChangeHandler}
                                                    name="titles.ct3.value"
                                                    className='form-control'
                                                    id=""
                                                    cols="30"
                                                    rows="5"></textarea>
                                                {this.validator.message('Closing Title 3', titles.ct3.value, 'required')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-right my-20' >
                                        <button onClick={this.agencyTypeSubmitHandler} className='backButton pt-sm no_radius pb-sm primary text-xs btn btn-lg btn-info btn btn-lg btn-info  btn btn-default' >Save</button>
                                    </div>
                                </React.Fragment>
                            } />
                        </Col>
                    </Row>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { brands } = state.accountReducer;
    const { agencyTypeSingle } = state.agencyTypeReducer;
    return { agencyTypeSingle, brands }
}

const mapDispatchToProps = dispatch => {
    return {
        getAgencyTypeCall: (id) => { dispatch(getAgencyType(id)) },
        storeAgencyTypeCall: (data) => { return dispatch(storeAgencyType(data)) },
        updateAgencyTypeCall: (agencyType, data) => { return dispatch(updateAgencyType(agencyType, data)) },
        getBrandsCall: () => { dispatch(getBrands()) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgencyTypeForm)
