import React, { Component } from 'react';
import Scheduler from 'components/ThemeComponents/scheduler';

class SchedulerPage extends Component{
 	render(){
  	return(
      <React.Fragment>
        <div className="container">
            <h4 className="schedulerHeading">Schedule a Call.</h4>
          <div style={{marginTop:'70px'}}>
            <Scheduler />
          </div>
        </div>
      </React.Fragment>
  	);
  }
}
export default SchedulerPage;