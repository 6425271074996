import React, { Component } from "react";
import $ from "jquery";
import { s3StaticPath, localDirectory, scrollLogin } from "../../helper/helperFunctions";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import { Parallax } from "react-parallax";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ScrollAnimation from "react-animate-on-scroll";
import "./../../assets/css/animate.min.css";
import logo from "../../assets/media/splash-logo.png";
import bannerImg from "../../assets/media/banner-image.png";
import feature1 from "../../assets/media/feature-icon-1.png";
import feature2 from "../../assets/media/feature-icon-2.png";
import feature3 from "../../assets/media/feature-icon-3.png";
import footerLogo from "../../assets/media/footerLogo.png";
import document_img from "../../assets/media/Documents.png";
import ttk_demo_poster from "../../assets/media/ttk_demo_poster.png";
import Gallery from "../../assets/media/Gallery.png";
import Locator from "../../assets/media/Locator.png";
import Messaging from "../../assets/media/Messaging.png";
import Traveler from "../../assets/media/Traveler-list.png";
import teacher from "../../assets/media/teacher.png";
import students from "../../assets/media/students.png";
import family from "../../assets/media/family.png";
import playStore from "../../assets/media/play-store-logo.png";
import appStore from "../../assets/media/app-store-logo.png";
import step1 from "../../assets/media/step-1.png";
import step2 from "../../assets/media/step-2.png";
import step3 from "../../assets/media/step-3.png";
import step4 from "../../assets/media/step-4.png";
import step5 from "../../assets/media/step-5.png";
import stepLine from "../../assets/media/step-line.png";
import document_messaging_img from "../../assets/media/document_messaging_img.png";
import group_photo_sharing_img from "../../assets/media/group_photo_sharing_img.png";
import last_memories_img from "../../assets/media/last_memories_img.png";
import step_image_1 from "../../assets/media/step-image-1.png";
import step_image_2 from "../../assets/media/step-image-2.png";
import step_image_3 from "../../assets/media/step-image-3.png";
import step_image_4 from "../../assets/media/step-image-4.png";
import step_image_5 from "../../assets/media/step-image-5.png";

class GtvHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
      isShowMore: false
    };
    this.handleHover = this.handleHover.bind(this);
  }
  handleHover() {
    // this.setState(prevState => ({
    //     isHovered: !prevState.isHovered
    // }));

    $("#clients div.client").hover(
      function () {
        $("#clients div.client").removeClass("pink_gradient");
        $(this).addClass("pink_gradient");
      },
      function () {
        $("#clients div.client").removeClass("pink_gradient");
      }
    );
  }
  handleVideo() {
    let demoVideo = document.getElementById("demoVideo");
    demoVideo.play();
  }

  toggleReadMoreLess = () => {
    this.setState((prevState) => ({
      isShowMore: !prevState.isShowMore
    }));
  };

  render() {
    // const hoverClass = this.state.isHovered ? "client pink_gradient" : "client";
    const SlickButtonFix = ({
      currentSlide,
      slideCount,
      children,
      ...props
    }) => <div {...props}>{children}</div>;

    var sliderSettings = {
      dots: true,
      autoplay: true,
      infinite: true,
      speed: 1000,
      autoplaySpeed: 7000,
      slidesToShow: 5,
      slidesToScroll: 1,
      accessibility: false,
      responsive: [
        {
          breakpoint: 1080,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
      prevArrow: (
        <SlickButtonFix>
          <FaChevronLeft />
        </SlickButtonFix>
      ),
      nextArrow: (
        <SlickButtonFix>
          <FaChevronRight />
        </SlickButtonFix>
      ),
    };
    let photomozaixLink = <a href={'http://new.traveltripkit.com/mozaix'} target='_blank' >Photomozaix</a>;
    const { isShowMore } = this.state;
        return (
      <main id="gtv_home">
        <section className="mainBannerBG flexElem flexResponsive alignCenter">
          <div className="container mxAuto">
            <div className="grid1x2 gridCenter">
              <div className="mt10">
                <div
                  className="secondary fontBase mt5 h4 font-weight-bold row"
                  style={{ lineHeight: 1 }}
                >
                  <div className="col-sm-12 col-md-10">
                    <h3 className="primary font-weight-bold">
                      Group Trip?
                    </h3>
                    <h4 className="font-weight-bold" style={{ lineHeight: 1.25 }}>All you need in one 
                    <br />
                    app to enhance your
                    <br />
                    travel experience.</h4>
                    <h5 className="secondary h4 font-weight-bold mt15 text-right" style={{paddingRight: 30}}>
                      <small className="secondary">
                        <i className="font-weight-bold">Privately</i>.
                      </small>
                    </h5>
                  </div>
                </div>
                <div className="flexElem gap15 mt25" style={{ gap: 15 }}>
                  <Link target="_blank" to="https://apps.apple.com/us/app/travel-tripkit/id1643125077?platform=iphone">
                    <img src={appStore} alt="Apple Store" />
                  </Link>
                  <Link target="_blank" to="https://play.google.com/store/apps/details?id=com.travel.tripkit.pro">
                    <img src={playStore} alt="Play Store" />
                  </Link>
                </div>
              </div>
              <div className="gridRow2">
                <img
                  className="mainLogo"
                  src={bannerImg}
                  alt="Trip Kit Travel"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="securityPoints flexElem flexResponsive alignCenter py40">
          <div className="container">
            <h2 className="h1 secondary text-center font-weight-bold mb50">
              The App Features
            </h2>
            <Row className="mb25">
              <Col xs={12} sm={4} className="mt10 heightFull">
                <div className="border heightFull">
                  <img
                    src={document_messaging_img}
                    alt="App Features 1"
                    className="mw100Force"
                  />
                  <div className="roundedOverlay">
                    <img src={feature1} alt="Documents, Messaging, Map" />
                  </div>
                  <div className="px1-25 py25 text-center" style={{minHeight: '193px'}}>
                    <h6 className="secondary font-weight-bold lineH">
                      Documents, Messaging & Map
                    </h6>
                    <span className="secondary mt25">
                      Easily share trip documents with your traveling group and
                      communicate with built-in messaging and mapping to help
                      keep people safe and in touch.
                    </span>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4} className="mt10 heightFull">
                <div className="border heightFull">
                  <img
                    src={group_photo_sharing_img}
                    alt="App Features 1"
                    className="mw100Force"
                  />
                  <div className="roundedOverlay">
                    <img src={feature2} alt="Group Photo Sharing" height={60} />
                  </div>
                  <div className="px1-25 py25 text-center" style={{minHeight: '193px'}}>
                    <h6 className="secondary font-weight-bold lineH">
                      Group Photo Sharing
                    </h6>
                    <span className="secondary mt25">
                      Travelers can share photos privately with the group and
                      those following from home. No email or phone number
                      required.
                    </span>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4} className="mt10 heightFull">
                <div className="border heightFull">
                  <img
                    src={last_memories_img}
                    alt="App Features 1"
                    className="mw100Force"
                  />
                  <div className="roundedOverlay">
                    <img src={feature3} alt="Lasting Memories" height={60} />
                  </div>
                  <div className="px1-25 py25 text-center pmozaixLink" style={{minHeight: '193px'}}>
                    <h6 className="secondary font-weight-bold lineH">
                      Lasting Memories
                    </h6>
                    <span className="secondary mt25" >
                      Your travels are captured by all the shared photos that
                      tell a story to last a lifetime. <strong> Relive the fun and
                      educational experience in the <br /> One-20x30 
                      {' '} <a href="mozaix" style={{fontWeight: 'bold'}} target="_blank">Photomozaix</a> {''}
                      print your group will receive.</strong>
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </section>
                             {/* ------------------- App Demo Video Section ------------------ */}
        <section className="mb100">
          <div className="container">
              <h2 className="h1 secondary text-center font-weight-bold mb50">
                Travel TripKit App
              </h2>
            <div className="row flexElem flexResponsive alignCenter justifyCenter">
                <div className="col-md-5 mt-lg ttkAppVideo">
                    {/* <div className="wordpressHeading">
                        Safety. Communication. Memories. Privately.
                    </div> */}
                    <h5 className="secondary lineHight1 font-weight-bold">
                      Safety.
                   
                    <br />
                   
                      Communication.
                    <br />
                      Memories.
                    <br />
                    <i className="font-weight-bold">Privately</i>.
                    </h5>
                    
                    <p className="mt-xxlg text_16 black font-weight-bold">
                      Check out this short video
                      <br />
                      to learn more about
                      <br />
                      the Travel TripKit App.
                    </p>
                </div>

                <div className="col-md-1"></div>

                <div className="col-md-6 mt-lg">
                    <div className="contentContainer">
                        <div className="video_placeholder mb-none">
                            {/* <img src={s3StaticPath('img/video_placeholder.jpg')} className="block" alt="Video placeholder" /> */}
                            <video onPlay={() => {
                                $(".video_placeholder .video_play").hide()
                            }} onPause={() => {
                                $(".video_placeholder .video_play").show()
                            }} id="demoVideo" controls src="https://gtvoriginalimages.s3.amazonaws.com/static/ttk-media/Travel-TripKit-App-Demo.mp4"
                                    type="video/mp4"  poster={ttk_demo_poster}></video>

                            <div className="video_play ttk_theme_dark_red hover_dark"
                                  onClick={this.handleVideo}></div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </section>

        <section className="featuresWhile flexElem flexResponsive alignCenter py40">
          <div className="containerMid">
            <div className="row">
              <div className="col-md-12">
                <div className="featuresSlider">
                  <Slider {...sliderSettings}>
                    <div className="py25 heightFull">
                      <h6 className="secondary mb25 font-weight-bold">
                        Documents
                      </h6>
                      <img
                        src={document_img}
                        alt="Feature Gallery"
                        className="mb25"
                      />
                      <p className="secondary">
                        Share documents with your computer or the app. Update in
                        real time.
                      </p>
                    </div>
                    <div className="py25 heightFull">
                      <h6 className="secondary mb25 font-weight-bold">
                        Private Gallery
                      </h6>
                      <img src={Gallery} alt="Feature map" className="mb25" />
                      <p className="secondary">
                        Only your group and family at home can access photos. No
                        commenting, no bullies.
                      </p>
                    </div>
                    <div className="py25 heightFull">
                      <h6 className="secondary mb25 font-weight-bold">
                        Locator
                      </h6>
                      <img src={Locator} alt="Feature group" className="mb25" />
                      <p className="secondary">
                        Turns on at 5am day of departure. Turns off at midnight
                        upon return.
                      </p>
                    </div>
                    <div className="py25 heightFull">
                      <h6 className="secondary mb25 font-weight-bold">
                        Messaging
                      </h6>
                      <img
                        src={Messaging}
                        alt="Feature Docs"
                        className="mb25"
                      />
                      <p className="secondary">
                        Group Leader can message all or individuals and create
                        subgroups.
                      </p>
                    </div>
                    <div className="py25 heightFull">
                      <h6 className="secondary mb25 font-weight-bold">
                        Traveler List
                      </h6>
                      <img src={Traveler} alt="Feature Docs" className="mb25" />
                      <p className="secondary">
                        Easy to locate and message individuals.
                      </p>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="securityPoints securityBG flexElem flexResponsive alignCenter">
          <div className="container">
            <h2 className="mb50 h1 text-center text-white font-weight-bold">
              Everyone Benefits
            </h2>
            <div className="row">
              <div className="col-md-4">
                <div>
                  <div className="mb25">
                    <img src={teacher} alt="Teacher" className="mw100Force" />
                  </div>
                  <div>
                    <h6 className="text-white font-weight-bold">Teacher</h6>
                    <span className="text-white fontBase mwAuto">
                      Teachers can show the previous year's photos to generate
                      excitement & participation. It reinforces the educational
                      experience for your students. Seeing history in real life
                      and having shared photos as keepsakes will make the trip
                      experience live on for years.
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div>
                  <div className="mb25">
                    <img src={students} alt="students" className="mw100Force" />
                  </div>
                  <div>
                    <h6 className="text-white font-weight-bold">Students</h6>
                    <span className="text-white fontBase mwAuto">
                      Students are about to learn and see things they may have
                      only read about. Experiencing it in real life is something
                      they will want to remember and relive for years to come.
                      Taking lots of pictures and privately sharing with others
                      will help tell the story of their trip and recall what
                      they learned.
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div>
                  <div className="mb25">
                    <img src={family} alt="family" className="mw100Force" />
                  </div>
                  <div>
                    <h6 className="text-white font-weight-bold">
                      Family at Home
                    </h6>
                    <span className="text-white fontBase mwAuto">
                      Even from home family can enjoy the journey with the
                      traveler’s username and password on any smartphone, tablet
                      or computer. Enjoy seeing pictures of their loved ones
                      learning and knowing they're safe while creating great
                      memories. Download their favorite photos of their loved
                      one.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="ourTeams flexElem flexResponsive alignCenter">
          <div className="container">
            <h3 className="primarydark font33 text-center font-weight-bold mb50">
              Participating is as easy as 4 steps
            </h3>
            <div className="steps">
              <div className="flexElem gap15">
                <div className="p-1 flexElem alignStart relative avatarWrapper">
                  <img src={step_image_1} atl="Step 1" className="stepAvatar" />
                  <div className="stepsIcon">
                    <img src={step1} atl="Step 1" width={24} />
                  </div>
                </div>
                <div className="">
                  <div className="flexElem">
                    <h6 className="stepsCounter">1</h6>
                    <h6 className="secondary font-weight-bold">
                      Place your order
                    </h6>
                  </div>
                  <p className="dark mt10">Call (888) 533-7637, x209</p>
                  <p className="dark mt10">
                    to talk to our team to get started today, even if your trip
                    is just days away.
                  </p>
                </div>
              </div>
              <div className="flexElem gap15">
                <div className="p-1 flexElem alignStart relative avatarWrapper">
                  <img src={step_image_2} atl="Step 2" className="stepAvatar" />
                  <div className="stepsIcon">
                    <img src={step2} atl="Step 2" height={24} width={24} />
                  </div>
                </div>
                <div className="">
                  <div className="flexElem">
                    <h4 className="stepsCounter">2</h4>
                    <h6 className="secondary font-weight-bold">
                      Download the App
                    </h6>
                  </div>
                  <p className="dark mt10">
                    The group is given a unique ID to login. No email, phone
                    number or personal info required.
                  </p>
                </div>
              </div>
              <div className="flexElem gap15">
                <div className="p-1 flexElem alignStart relative avatarWrapper">
                  <img src={step_image_3} atl="Step 3" className="stepAvatar" />
                  <div className="stepsIcon">
                    <img src={step3} atl="Step 3" width={24} />
                  </div>
                </div>
                <div className="">
                  <div className="flexElem">
                    <h4 className="stepsCounter">3</h4>
                    <h6 className="secondary font-weight-bold">
                      Share the fun
                    </h6>
                  </div>
                  <p className="dark mt10">
                    While on your trip travelers privately share photos,
                    itineraries, passenger lists and more with the entire group.
                    And built-in messaging and mapping tools help your team stay
                    safe and connected.
                  </p>
                </div>
              </div>
              <div className="flexElem gap15">
                <div className="p-1 flexElem alignStart relative avatarWrapper">
                  <img src={step_image_4} atl="Step 3" className="stepAvatar" />
                  <div className="stepsIcon">
                    <img src={step4} atl="Step 1" width={24} />
                  </div>
                </div>
                <div className="">
                  <div className="flexElem">
                    <h4 className="stepsCounter">4</h4>
                    <h6 className="secondary font-weight-bold">
                      Relive the Memories
                    </h6>
                  </div>
                  <p className="dark mt10">
                    The shared photos are available up to three years after your
                    trip. Download and keep your favorites of your trip of
                    lifetime. <strong>Your group will receive One-20x30
                    {' '} <a href="mozaix" style={{fontWeight: 'bold'}} target="_blank" rel="noopener noreferrer">Photomozaix</a> {''}
                    print made from your trip memories.</strong>
                  </p>
                </div>
              </div>
              <div className="flexElem gap15">
                <div className="p-1 flexElem alignStart relative avatarWrapper">
                  <img src={step_image_5} atl="Step 5" className="stepAvatar" />
                  <div className="stepsIcon">
                    <img src={step5} atl="Step 5" width={24} />
                  </div>
                </div>
                <div className="">
                  <div className="flexElem">
                    <h4 className="stepsCounter">5</h4>
                    <h6 className="secondary font-weight-bold">
                      PhotoMozaix
                    </h6>
                  </div>
                  <p className="dark mt10">
                    PhotoMozaix&trade; are made from your trip pictures!
                    This beautiful print measures 20 x 30 inches and is printed on high-quality satin photo paper. 
                    A PhotoMozaix is one large image created from a collection of your trip photos made from an
                    {isShowMore && (
                    <p>iconic image representing the destination! . <strong>"Wow"</strong> is the only way customers seem to react to our       PhotoMozaix&trade;.
                    
                    To order PhotoMozaix™ from you trip, please <span onClick={scrollLogin} className='blue_link'>login</span> with your group's username &amp; password, order by phone (1-888-533-7637 ext. 209) Your group will receive One PhotoMozaix with their order.</p>
                    )}
                    <a style={{color: 'blue', cursor: 'pointer'}} onClick={this.toggleReadMoreLess}>
                      {isShowMore ? " Read Less" : " Read More"}
                    </a>
                  
                  </p>
                </div>
              </div>
            </div>
            <div className="gridAreas" style={{ display: "none" }}>
              <div className="a">
                <div className="">
                  <strong className="">2. Download the App</strong>
                  <div className="mb25">
                    <strong className=""></strong>
                  </div>
                </div>
                <div className="">
                  <strong className="">3. Share the Fun</strong>
                  <div className="mb25">
                    <strong className="">
                      While on your tripm travelers privately share photos,
                      <br />
                      itineraries, passenger lists and more with the entire
                      group.
                      <br />
                      And built-in messaging and mapping tools help your team
                      stay safe and connected.
                    </strong>
                  </div>
                </div>
              </div>
              <div className="b text-center">
                <img src={logo} alt="Team" className="stepsLogo" />
              </div>
              <div className="c">
                <div className="info">
                  <strong className="">4. Relive the Memories</strong>
                  <div className="mb25">
                    <strong className="">
                      The shared photos are available up to three years after
                      your trip. Download and keep your favorites of your trip
                      of lifetime.
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default GtvHome;
