import { SC } from "../helper/ServerCall";

export const proShowFileService = {
    storeProShowFile,
    getProShowFile,
    getProShowFilesPagination,
    updateProShowFileCall,
    deleteProShowFile,
}

function storeProShowFile(data) {
    return SC.postCall(`proShowFile`, data);
}


function getProShowFile(id) {
    return SC.getCall(`proShowFile/${id}`);
}

function getProShowFilesPagination(page, filter, sort, pageSize, pagination) {
    if (pagination)
	    return SC.getCall(`proShowFileGet/${pagination}?page=${page}&filter=${filter}&sort=${sort}&pageSize=${pageSize}`);
    else
        return SC.getCall(`proShowFileGet/${pagination}`);
}

function updateProShowFileCall(data, id) {
	return SC.postCall(`proShowFile/${id}`, data);
}

function deleteProShowFile(destinationId) {

    return SC.deleteCall('proShowFile/' + destinationId);
}
