import React, { Component } from 'react'
import OrdersTOMissingResource from './Reports/OrdersTOMissingResource';
import UploadedImages from "./Order/ImagesTab/UploadedImages";
import TreeView from '@mui/lab/TreeView';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import TreeItem from '@mui/lab/TreeItem';
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
import { SC } from 'helper/ServerCall';


export default class Test extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data:  {resource_id: 6, order_folder_id: 2},
			collapseImageUploaderAndViewer: false,
            treeData: [
                { title: '', children: [{ title: '' }] },
                { title: 'Fish', children: [{ title: 'fingerline' }] },
                // { title: 'Dog', children: [{ title: 'fingerline' }] },
              ],
            callData: ''

        }
        // this.test(this.state.data);
         this.show(30089190).then(response =>{
             this.setState({
                 callData: response.data
             });
         })
    }
    test = (data) => {
        return SC.postCall('orderFolder' , data );
    }
    show = (id) => {
        return SC.getCall(`getOrderFolder/${id}` );
    }
    render() {
        const {callData} = this.state;
        let itemId = 1;
        return (
            <div className="outerParent">

                {/* <TreeView
                    aria-label="multi-select"
                    defaultCollapseIcon={<DoNotDisturbOnOutlinedIcon />}
                    defaultExpandIcon={<AddBoxOutlinedIcon />}
                    sx={{ height: 216, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                    > */}
                    { callData &&
                          Object.keys(callData).map((item, key) => {
                                console.log("index: " , key);
                                console.log("item: " , item);
                                return (
                                    <>
                                    <TreeView
                                        aria-label="multi-select"
                                        className='parent'
                                        defaultCollapseIcon={<DoNotDisturbOnOutlinedIcon />}
                                        defaultExpandIcon={<AddBoxOutlinedIcon />}
                                        // sx={{ height: 216, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                                        >
                                            <TreeItem nodeId={itemId.toString()} alt={itemId++} label={callData[item].name}>

                                                <TreeItem key={itemId} nodeId={itemId.toString()} alt={itemId++}  label={callData[item].name} />
                                            </TreeItem>
                                    </TreeView>
                                    </>
                                )
                            })

                        }
                {/* </TreeView> */}
                {/* <TreeView
                    aria-label="multi-select"
                    defaultCollapseIcon={<DoNotDisturbOnOutlinedIcon />}
                    defaultExpandIcon={<AddBoxOutlinedIcon />}
                    sx={{ height: 216, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                    >

                       <TreeItem nodeId="1" label="Applications">
                         <TreeItem nodeId="2" label="Calendar" />
                         <TreeItem nodeId="3" label="Chrome" />
                         <TreeItem nodeId="4" label="Webstorm" />
                     </TreeItem>
                     <TreeItem nodeId="5" label="Documents">
                     <TreeItem nodeId="6" label="MUI" />


                     </TreeItem>
                 </TreeView> */}
                {/* <SortableTree
                    treeData={this.state.treeData}
                    onChange={treeData => this.setState({ treeData })}
                /> */}
                {/*<ReceiveReports />*/}
                {/*<ScanBarcode />*/}
                {/*<OrdersTOMissingResource />*/}
                {/*<UploadedImages order_details={{editor_id: false, settings: {on_hold: 1}}} />*/}
                {
                    // {} && <div>success</div>
                }
            </div>
        )
    }
}
