import React from 'react';
import {connect} from 'react-redux';
import {
    allOrderImagesByPhase,
    storePMOrderVideo,
    assignUserToOrder,
    storeEditorOrderImages,
    orderResourceDelete
} from 'redux/actions/order-actions';
import {getResourcesByRole, getOrdersResourcesByRole} from 'redux/actions/agency-resource-actions';
import DownloadFile from 'components/ThemeComponents/downloadFile';
// import ImagesList from '../ImagesList';
import FileUpload from 'components/Upload/FileUpload';
import FileUploaderNew from 'components/Upload/FileUploaderNew';
import ImagePreview from 'components/Upload/ImagePreview';
import {hasRole, dateFormatMyReturnNone, s3StaticPath, returnTimeStamp, getFormattedDate, closeModal} from 'helper/helperFunctions';
import {
    OverlayTrigger,
    Popover,
} from "react-bootstrap";
import {updateSettingsData, showLoader, imageProcessingAndDownload} from 'redux/actions/settings-actions';
import {Card} from "components/Card/Card.jsx";
import {ThemeFunctions as TF} from 'helper/ThemeFunctions';

import OrderComments from 'components/Admin/Order/OrderComments';

import {history} from 'helper/history';
import Axios from 'axios';
import OrderTitles from "components/Admin/Order/OrderTitles";
import {map, padStart} from 'lodash';
import ImagesList from 'components/Admin/Order/ImagesList';
import { baseUrl } from '../../../../helper/ApiCall';
import { orderService } from '../../../../services/order';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmAlertForDelete from 'components/ThemeComponents/confirmAlertForDelete';
var Modal = require('react-bootstrap-modal');

const chunk = require('lodash.chunk')
var cloneDeep = require('lodash.clonedeep');

// import { settingsActions } from 'redux/actions/settings-actions';

class ProductionManagerPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            workingImages: [],
            workingImagesTemp: [],
            flashImages: [],
            audio_video: [],
            order_documents: [],
            full_video: {},
            highlight_video: {},
            mosaic_file: {},
            original_mosaic: {},
            collapseFullVidUploaderAndViewer: false,
            collapseHighlightVidUploaderAndViewer: false,
            collapseMosaicFileUploaderAndViewer: false,
            collapseImageUploaderAndViewer: false,
            collapseAudioAndVideoFile: true,
            allowedFormatsStringMime: 'image/jpeg',
            allowedFormatsStringExt: [".jpg"],

            resourcesZip: [],
            audioVideosZip: [],
            documentsZip: [],
            flashResourcesZip: {},
            chunkedImages: {},
            is_highlight_video_check: false,
            showNoteModal: false,
            remakeNoteData:''
        };


        // var t ={
        // 	testthree:[
        // 		{
        // 			c:1,
        // 			d:2,
        // 		}
        // 	]
        // };

        // var b ={
        // 	c:1,
        // 	d:2,
        // };

        // console.log('type of t', t['testthree'] instanceof Array?'yes it is array':'not it is not');
        // console.log('type of b', b instanceof Array?'yes it is array':'not it is not');
    };

    setResourceFile = (key, value) => {
        this.setState((prevState) => {
            return {
                [key]: value
            }
        });

    }
    removeFile = (key, index) => {
        this.setState({
            [key]: {},
        });
    }

    setResourceFileWI = (key, value) => {
        const CancelToken = Axios.CancelToken;
        const source = CancelToken.source();
        value.axiosToken = source;
        this.setState((prevState) => {
            return {
                workingImagesTemp: [
                    ...prevState.workingImagesTemp,
                    value
                ]
            }
        });

    }

    updateResourceFileWI = (index, fileObject) => {
        const workingImagesTempState = cloneDeep(this.state.workingImagesTemp);
        const CancelToken = Axios.CancelToken;
        const source = CancelToken.source();
        fileObject.axiosToken = source;
        workingImagesTempState[index] = fileObject;
        this.setState({
            workingImagesTemp: workingImagesTempState,
        });
    }

    componentDidUpdate(prevProps, nextProps) {

        if (prevProps.imageUpdates !== this.props.imageUpdates) {
            console.log('images updates');
            this.setState({workingImagesTemp: []}, function () {
                // this.ctrl_list.imagesPaginationReload();
            });
        }


        if (this.props.allImagesByPhase !== prevProps.allImagesByPhase) {
            if (this.props.allImagesByPhase.type === 'working') {

                // console.log('testii', this.props.allImagesByPhase.images);

                const imagesTemp = this.props.allImagesByPhase.images.map(item => {
                    // console.log('sfsdf', item.file_path.replace('SIZE', 'full'))
                    return {...item, file_path: item.file_path.replace('SIZE', 'full')}
                });
                const images = {};
                const chunkedImages = chunk(this.props.allImagesByPhase.images, 300);
                chunkedImages.forEach((imgArr, index) => {
                    images[`zip-${index + 1}`] = imgArr.map(item => {
                        return {...item, file_path: item.file_path.replace('SIZE', 'full')}
                    })
                })
                // console.log('imagesTemp', imagesTemp);

                this.setState(prevState => ({
                    ...prevState, resourcesZip: {
                        ...prevState.resourcesZip, pics: imagesTemp
                    },
                    chunkedImages: images
                }));


                this.setState({workingImages: imagesTemp});
            } else if (this.props.allImagesByPhase.type === 'flash') {
                const images = map(cloneDeep(this.props.allImagesByPhase.images), function (item, index) {
                    let n = index;
                    n++;
                    item.file_title = padStart(n, 3, '0');
                    item.file_path.replace('SIZE', 'full')
                    return item
                });

                // const imagesTempFlash = images.map(item=>{
                // 	// console.log('sfsdf', item.file_path.replace('SIZE', 'full'))
                // 	return  {...item,  file_path : item.file_path.replace('SIZE', 'full') }
                // });
                const flashResourcesZip = cloneDeep(this.state.flashResourcesZip);
                flashResourcesZip['flashPics'] = images
                this.setState(prevState => ({
                    ...prevState, resourcesZip: {
                        ...prevState.resourcesZip, flashPics: images
                    },
                    flashResourcesZip: flashResourcesZip
                }));
                this.setState({flashImages: images});
            }
        }
        if (this.props.agencyResources !== prevProps.agencyResources) {
            var tempZip = [];
            for (var i in this.props.agencyResources) {
                var fileTitle = '';
                if (i === 'opening_slate') {
                    fileTitle = 'o_slate';
                } else if (i === 'closing_slate') {
                    fileTitle = 'c_slate';
                } else if (i === 'watermark') {
                    fileTitle = 'watermark';
                    // if (this.props.agencyResources[i].agency_name.id == this.props.order_details.agency_id)
                    // 	fileTitle = this.props.agencyResources[i].agency_name.name;
                    // else
                    // 	fileTitle = 'watermark';
                } else if (i === 'pro_show_file') {
                    fileTitle = this.props.agencyResources[i].name;
                } else if (i === 'password_slate_img_processing') {
                    fileTitle = 'pw_slate';
                }
                if (fileTitle)
                    tempZip.push({'file_path': this.props.agencyResources[i].file_path, 'file_title': fileTitle});
            }
            //OLD CODE
            // this.setState(prevState => ({
            // 	...prevState, resourcesZip: {
            // 		...prevState.resourcesZip, AgencyResources: tempZip
            // 	}
            // }));

            //New Code
            this.setState(prevState => ({
                ...prevState, resourcesZip: {
                    ...prevState.resourcesZip, ...tempZip
                },
                flashResourcesZip: {
                    ...prevState.flashResourcesZip, ...tempZip
                }
            }));
        }

        if (this.props.orderResources.audio_video !== prevProps.orderResources.audio_video) {
            console.log('orderResources', this.props.orderResources.audio_video);
            var tempZip1 = [];
            for (var j in this.props.orderResources.audio_video) {
                tempZip1.push({
                    'file_path': this.props.orderResources.audio_video[j].file_path,
                    'file_title': this.props.orderResources.audio_video[j].file_title
                });
            }
            this.setState(prevState => ({
                ...prevState, resourcesZip: {
                    ...prevState.resourcesZip, AudioVideoUploaded: tempZip1
                },
                flashResourcesZip: {
                    ...prevState.flashResourcesZip,
                    AudioVideoUploaded: tempZip1
                }
            }), () => {
                console.log('resources zip', this.state.resourcesZip);
            });
        }

        if (this.props.orderResources.order_documents !== prevProps.orderResources.order_documents) {
            var tempZip2 = [];
            for (var k in this.props.orderResources.order_documents) {
                tempZip2.push({
                    'file_path': this.props.orderResources.order_documents[k].file_path,
                    'file_title': this.props.orderResources.order_documents[k].file_title
                });
            }
            this.setState(prevState => ({
                ...prevState, documentsZip: {
                    ...prevState.documentsZip, resources: tempZip2
                }
            }));
        }
    }


    saveVideos = (key, loaderTitle) => {
        this.props.showLoaderCall(false);
        const fd = new FormData();
        fd.append('file', this.state[key]);
        this.props.storePMOrderVideoCall(fd, this.props.orderId, 'projectManager', key, loaderTitle).then(
            response => {
                this.props.showLoaderCall(true);
                this.setState({
                    [key]: {},
                });
            }
        )
    }
    finalize = () => {
        const {orderId} = this.props;
        var settingData = {
            value: 1,
        };
        if (this.props.orderResources.highlight_video && this.state.is_highlight_video_check){
            this.props.onResourceDeleteCall(this.props.orderResources.highlight_video, this.props.orderResources.highlight_video.id)
        }
        this.props.updateSettingsDataCall(orderId, 'finalize_by_pm', settingData).then(response => {
            if (hasRole(['producer', 'Remote Producer','Remote Editor/Producer'])) {
                history.push(`dashboard`);
            } else if (hasRole('admin')) {
                history.push(`/Admin/dashboard/producer`);
            }

        });
    }
    assignQAToOrder = (orderId) => {
        this.props.assignUserToOrderCall(orderId, {userId: this.props.userAdmin.user.id, role: 'qa'}).then(response => {
            if (hasRole(['admin'])) {
                history.push(`/Admin/${orderId}/order-edit?orderDetailCode=production&imageCode=qa`);
                history.go();

            } else {
                history.push(`/Admin/${orderId}/order-edit?imageCode=qa`);
                history.go();

            }
        });
    }

    componentDidMount() {
        this.props.allOrderImagesByPhaseCall(this.props.orderId, 'working', this.props.tabName);
        this.props.allOrderImagesByPhaseCall(this.props.orderId, 'flash', this.props.tabName);
        this.props.getOrderResourcesByRoleCall(this.props.orderId);
        this.props.getResourcesByRoleCall(this.props.order_details.agency_id, this.props.order_details.id);
        orderService.getReassignedBy(this.props.order_details.id).then(res => {
            this.setState({
                remakeNoteData: res.data.note
            })
        })
    }

    componentWillUnmount() {
        this.props.showLoaderCall(true);
    }

    imageProcessingAndDownloadFunction = () => {
        this.props.showLoaderCall(false);
        const {order_details} = this.props;
        this.props.imageProcessingAndDownloadCall(1, {
            orderId: order_details.id,
            agency_id: order_details.agency_id,
            customer_slates_for_videos_id: order_details.customer_slates_for_videos_id
        }).then(response => {
            this.props.showLoaderCall(true);
        });
    }
    removeFileWI = (key, index) => {
        const workingImagesTempState = [...this.state.workingImagesTemp]
        workingImagesTempState.splice(index, 1);
        this.setState({
            workingImagesTemp: workingImagesTempState,
        });
    }
    clearAllTempImages = () => {
        this.setState({
            workingImagesTemp: [],
        });
    }
    saveImages = () => {
        this.props.showLoaderCall(false);

        const workingImagesTemp = [...this.state.workingImagesTemp];
        const fd = new FormData();
        fd.append('reUpload', true);
        workingImagesTemp.forEach((file) => {
            // const fd = new FormData();
            fd.append('images[]', file);
        });


        this.props.storeEditorOrderImagesCall(fd, this.props.orderId, 'working').then(
            response => {
                this.props.showLoaderCall(true);
                this.props.allOrderImagesByPhaseCall(this.props.orderId, 'working', this.props.tabName);
                this.props.allOrderImagesByPhaseCall(this.props.orderId, 'flash', this.props.tabName);
                this.ctrl_list.imagesPaginationReload();
            },
            error => {
                console.log('error');
            }
        )
    }
    getZipName = (zip, index) => {
        if (index === 1) {
            return `1 - ${this.state.chunkedImages[zip].length}`
        } else {
            return `${this.state.chunkedImages[`zip-${index - 1}`].length + 1} -  ${this.state.chunkedImages[`zip-${index - 1}`].length + this.state.chunkedImages[zip].length}`;
        }
    }
    is_highlight_video_check = () => {
        this.setState({is_highlight_video_check: !this.state.is_highlight_video_check})
      }

    showNoteModalHandler = () => {
        this.setState({
            showNoteModal: !this.state.showNoteModal
        })
    }

    onResourceDeleteHandler = (resource, id) => {
        this.props.onResourceDeleteCall(resource, id).then(response => {
            this.props.getOrderResourcesByRoleCall(this.props.orderId);
        });
    }

    confirmResourceDelete = (resource, id) => {
        confirmAlert({
            customUI: ({ onClose }) => <ConfirmAlertForDelete typeName='Resource' onClosePro={onClose} deleteType={this.onResourceDeleteHandler.bind(this, resource, id)} />
        })
    }


    render() {
        const {orderId, tabName, order_details, orderResources, userAdmin, production_details} = this.props;
        const {
            workingImages,
            workingImagesTemp,/*  flashImages, audioVideosZip, documentsZip,  */
            full_video,
            highlight_video,
            resourcesZip,
            mosaic_file,
            original_mosaic,
            allowedFormatsStringMime,
            allowedFormatsStringExt,
            flashResourcesZip,
            showNoteModal,
            remakeNoteData
        } = this.state;
        return (
            <div>
                <div className="col-md-6">
                    <div className="">
                        {
                            /*
                            <div className="photoAdminUserCredentials well p-none m-none flexElem mb-sm no_radius">
                                <div style={{padding: "9px"}}><strong>Photo Vision Username</strong> : { order_details.photo_admin_username }</div>
                                <div style={{padding: "9px"}} className="ml-md"><strong>Photo Vision Password</strong> : { order_details.photo_admin_password }</div>
                            </div>
                            */
                        }

                        {/*<button className="orderDetailsAction downloadIcon " onClick={this.imageProcessingAndDownloadFunction}  >Download Password Slate</button>*/}

                        {
                            /*workingImages.length > 0
                            &&
                            <DownloadFile
                                title="Edited Images"
                                multiple={true}
                                files={workingImages}
                                extraClass={"ml-sm mt-sm"}
                            />
                            */
                        }

                        {
                            /*
                            flashImages.length > 0
                            &&
                            <DownloadFile
                                title="Flash Images"
                                multiple={true}
                                files={flashImages}
                                extraClass={"ml-sm mt-sm"}
                            />
                            */
                        }

                        {
                            /*agencyResources &&

                            <React.Fragment>
                                {
                                    ( ( "use_secondary_slates_logos" in order_details.settings ) &&  ( parseInt(order_details.settings.use_secondary_slates_logos.value,10) === 1 ) )
                                    ?
                                        <React.Fragment>
                                        {

                                            'secondary_closing_slate' in agencyResources &&
                                            <DownloadFile
                                                title="Secondary closing Slate"
                                                multiple={false}
                                                files={{
                                                    'file_path':agencyResources.secondary_closing_slate.file_path,
                                                    'file_title' : 'Secondary Closing Slate'
                                                }}
                                                extraClass={"ml-sm mt-sm"}
                                            />
                                        }
                                        {

                                            'secondary_opening_slate' in agencyResources &&
                                            <DownloadFile
                                                title="Secondary opening Slate"
                                                multiple={false}
                                                files={{
                                                    'file_path':agencyResources.secondary_opening_slate.file_path,
                                                    'file_title' : 'Secondary opening Slate'
                                                }}
                                                extraClass={"ml-sm mt-sm"}
                                            />
                                        }
                                        </React.Fragment>
                                    :
                                        <React.Fragment>
                                        {

                                            'closing_slate' in agencyResources &&
                                            <DownloadFile
                                                title="Closing Slate"
                                                multiple={false}
                                                files={{
                                                    'file_path':agencyResources.closing_slate.file_path,
                                                    'file_title' : 'Closing Slate'
                                                }}
                                                extraClass={"ml-sm mt-sm"}
                                            />
                                        }
                                        {

                                            'opening_slate' in agencyResources &&
                                            <DownloadFile
                                                title="Opening Slate"
                                                multiple={false}
                                                files={{
                                                    'file_path':agencyResources.opening_slate.file_path,
                                                    'file_title' : 'Opening Slate'
                                                }}
                                                extraClass={"ml-sm mt-sm"}
                                            />
                                        }
                                        {
                                            'watermark' in agencyResources &&
                                            <DownloadFile
                                                title="Watermark"
                                                multiple={false}
                                                files={{
                                                    'file_path':agencyResources.watermark.file_path,
                                                    'file_title' : 'Watermark'
                                                }}
                                                extraClass={"ml-sm mt-sm"}
                                            />
                                        }
                                        </React.Fragment>
                                }
                            </React.Fragment>
                        */}
                        {
                            (
                                Object.keys(resourcesZip).length > 0
                            )
                            &&
                            <React.Fragment>
                                {
                                    // Object.keys(this.state.chunkedImages).length >= 1
                                    // &&
                                    // Object.keys(this.state.chunkedImages).map((zip, index) => {
                                    //     return (
                                    //         <DownloadFile
                                    //             title={`Download Images (${this.getZipName(zip, index + 1)})`}
                                    //             multiple={true}
                                    //             files={this.state.chunkedImages[zip]}
                                    //             extraClass={"ml-sm mt-sm"}
                                    //             downloadName={`${order_details.barcode} (${this.getZipName(zip, index + 1)})`}
                                    //         />
                                    //     )
                                    // })
                                }

                                {/* <DownloadFile
                                    title="Download Resources with Flash Images"
                                    multiple={true}
                                    files={flashResourcesZip}
                                    extraClass={"ml-sm mt-sm"}
                                    downloadName={order_details.barcode}
                                /> */}

                                <a href={`${baseUrl}downloadZip/${order_details.id}`} target="_blank" className="orderDetailsAction downloadIcon ml-sm mt-lg display_ib">Download All Resources</a>
                            </React.Fragment>

                        }
                        {
                            /*(
                                Object.keys(audioVideosZip).length > 0
                            )
                            &&
                            <DownloadFile
                                title="Download Audio Video Resources"
                                multiple={true}
                                files={audioVideosZip}
                                extraClass={"ml-sm mt-sm"}
                            />
                        */}
                        {
                            /*
                            (
                                Object.keys(documentsZip).length > 0
                            )
                            &&
                            <DownloadFile
                                title="Download Documents Resources"
                                multiple={true}
                                files={documentsZip}
                                extraClass={"ml-sm mt-sm"}
                            />
                        */}

                    </div>
                    <div className="table-responsive mt-xlg">
                        <table className="table  table-striped">
                            <tbody>
                                <tr>
                                    <td>Special Need Date</td>
                                    <td>{dateFormatMyReturnNone(order_details.order_dates.video_special_need_date.value)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="table-responsive mt-xlg">

                        <table className="table  table-striped">
                            <tbody>
                                <tr>
                                    <td> Total Edited Photos</td>
                                    <td>{order_details.workingImages}</td>
                                </tr>
                                <tr>
                                    <td>Edited By</td>
                                    <td> {'editor' in order_details && order_details.editor ? order_details.editor.name : 'None'} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="table-responsive mt-xlg">

                        <table className="table  table-striped">
                            <tbody>
                                <tr>
                                    <td>Departure Date</td>
                                    <td>{dateFormatMyReturnNone(order_details.order_dates.departure_date.value)}</td>
                                </tr>
                                <tr>
                                    <td>Return Date</td>
                                    <td> {dateFormatMyReturnNone(order_details.order_dates.return_date.value)} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="table-responsive mt-xlg">

                        <table className="table table-striped ">
                            <tbody>
                                <tr>
                                    <td>Music Type</td>
                                    <td>
                                        <span className={order_details.music_selection_criteria.includes("age_based") ? "yellowBackgroud" : ""}>
                                            {order_details.music_selection_criteria.replace('_', ' ').toUpperCase()}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Destination</td>
                                    <td>{order_details.destinationText}</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <div className="table-responsive mt-xlg">

                        <table className="table table-striped ">
                            <tbody>
                            <tr>
                                <td>Audio/Video Files Uploaded</td>
                                <td>{order_details.order_resources_audio_video_count}</td>
                            </tr>
                            {
                                'audio_video' in this.props.orderResources &&
                                <Card bsClass={["innerCard mt-sm"]} title="Audio And Video Files"
                                    collapseTrigger={this.state.collapseAudioAndVideoFile}
                                    collapsHandler={() => this.setState({collapseAudioAndVideoFile: !this.state.collapseAudioAndVideoFile})}
                                    collapsable={true}
                                    content={
                                        <div className="flexElem respFlex spaceBetween form_tile_row">
                                            <div className="col-md-12">
                                                <div className="table-responsive">
                                                    <table className="table_tile mt-md">
                                                        <thead>
                                                        <tr className="border-bottom">
                                                            <th width="35%">File Name</th>
                                                            <th width="25%">Uploaded Date</th>
                                                            <th width="20%">Action</th>
                                                        </tr>
                                                        </thead>

                                                        <tbody>
                                                        {
                                                            'audio_video' in this.props.orderResources &&
                                                            this.props.orderResources.audio_video.map((item, index) => (
                                                                <tr key={item.id}>
                                                                    <td><a style={{wordBreak: 'break-all'}}
                                                                            className='blue_link' target="_blank"
                                                                            href={item.file_path}
                                                                            rel="noopener noreferrer">{item.original_file_name}</a>
                                                                    </td>
                                                                    <td>{getFormattedDate(item.created_at)}</td>
                                                                    <td>
                                                                        <a className='ml-md' href={item.file_path}><i
                                                                            className='fa fa-download'></i></a>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                />
                            }
                            {/* {
                                order_details.orderResourcesAudioVideo.map((prop, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>File Name:</td>
                                            <td>{order_details.orderResourcesAudioVideo[key].file_title}</td>
                                        </tr>
                                    )
                                })
                            } */}
                            </tbody>
                        </table>

                    </div>


                    <OrderTitles hideDropDown submit orderId={order_details.orderId}
                                 titles={production_details.video_title_templates} order_details={order_details}/>
                    {/* {
                        'audio_video' in this.props.orderResources &&
                        <Card bsClass={["innerCard mt-sm"]} title="Audio And Video Files"
                              collapseTrigger={this.state.collapseAudioAndVideoFile}
                              collapsHandler={() => this.setState({collapseAudioAndVideoFile: !this.state.collapseAudioAndVideoFile})}
                              collapsable={true}
                              content={
                                  <div className="flexElem respFlex spaceBetween form_tile_row">
                                      <div className="col-md-12">
                                          <div className="table-responsive">
                                              <table className="table_tile mt-md">
                                                  <thead>
                                                  <tr className="border-bottom">
                                                      <th width="35%">File Name</th>
                                                      <th width="25%">Uploaded Date</th>
                                                      <th width="20%">Action</th>
                                                  </tr>
                                                  </thead>

                                                  <tbody>
                                                  {
                                                      'audio_video' in this.props.orderResources &&
                                                      this.props.orderResources.audio_video.map((item, index) => (
                                                          <tr key={item.id}>
                                                              <td><a style={{wordBreak: 'break-all'}}
                                                                     className='blue_link' target="_blank"
                                                                     href={item.file_path}
                                                                     rel="noopener noreferrer">{item.original_file_name}</a>
                                                              </td>
                                                              <td>{getFormattedDate(item.created_at)}</td>
                                                              <td>
                                                                  <a className='ml-md' href={item.file_path}><i
                                                                      className='fa fa-download'></i></a>
                                                              </td>
                                                          </tr>
                                                      ))
                                                  }
                                                  </tbody>
                                              </table>
                                          </div>
                                      </div>
                                  </div>
                              }
                        />
                    } */}
                </div>
                <div className="col-md-6">
                    <div className="">
                        {
                            (
                                ('highlight_video' in orderResources || this.state.is_highlight_video_check)
                                && 'full_video' in orderResources
                                && "finalize_by_pm" in order_details.settings
                                &&
                                (
                                    hasRole(['admin']) ||
                                    (
                                        hasRole(['producer', 'Remote Producer','Remote Editor/Producer']) && (order_details.producer_id === userAdmin.user.id)
                                    )
                                )
                                && 'original_mosaic' in orderResources
                            )
                            &&
                            <React.Fragment>
                                {
                                    parseInt(order_details.settings.finalize_by_pm.value, 10) === 0
                                        ?
                                        <button type="button" className="orderDetailsAction finalizeIcon ml-md mt-lg"
                                                onClick={this.finalize}>Video Production Complete</button>
                                        :
                                        <span>Finalized by producer</span>
                                }
                            </React.Fragment>
                        }
                        {
                            remakeNoteData.note  && parseInt(order_details.settings.finalize_by_editor.value, 10) === 1 && parseInt(order_details.settings.finalize_by_qa.value, 10) === 0 &&
                            <button type="button" style={{padding:"15px", background: "#f181ad"}} className="backButton pt-sm no_radius pb-sm primary mt-none btn btn btn-info ml-md  btn btn-default" onClick={this.showNoteModalHandler}>Remake Notes</button>
                        }
                        <span className={'ml-sm'}>
							<React.Fragment>
								{
                                    Number(order_details.settings.send_gl_fd_copy.value, 10) === 1 &&
                                    <span style={{'fontWeight': 'bold', 'color': 'red'}}>MAKE MASTER GL FD</span>
                                }

                                {
                                    /*Number(order_details.settings.no_gl_copy.value, 10) === 1 &&
                                    <span style={{ 'fontWeight': 'bold', 'color': 'red' }}>MAKE MASTER DVD</span>
                                */}
                                {
                                    Number(order_details.settings.no_gl_copy.value, 10) === 0 && Number(order_details.settings.send_gl_fd_copy.value, 10) === 0 &&
                                    <span style={{ 'fontWeight': 'bold', 'color': 'red' }}>MAKE MASTER DVD</span>
                                }
							</React.Fragment>
						</span>
                        <br/>
                        {
                            order_details.producer_id !== null &&
                            <span style={{'fontWeight': 'bold'}}>Producer : {order_details.producer.name}</span>
                        }
                        <br/>
                        {
                            (
                                "finalize_by_pm" in order_details.settings
                                && hasRole(['qa', 'admin'])
                                && order_details.qa_id === null
                            )
                            &&
                            (
                                parseInt(order_details.settings.finalize_by_pm.value, 10) === 1
                            )
                            &&
                            <OverlayTrigger placement="bottom" overlay={<Popover
                                id="tooltip">{'Order assign me for qa process'}</Popover>}>
                                <button className="orderDetailsAction assignIcon ml-md"
                                        onClick={() => this.assignQAToOrder(orderId)}>Assign Me For QA Process
                                </button>
                            </OverlayTrigger>
                        }
                    </div>

                    <OrderComments showSteps={false} hideTitle={true}
                                   showOnly={['Production', 'TO_GL', 'TO_GL_Production']}
                                   addExceptTab={['TO_GL', 'All', 'TO_GL_Production']} modelId={order_details.id}
                                   order_details={order_details}/>

                    <div className="videoUploaderSection flexElem flexResponsive">
                        <Card bsClass={["innerCard mt-sm"]} title="Upload Full Video"
                              collapseTrigger={this.state.collapseFullVidUploaderAndViewer}
                              collapsHandler={() => this.setState({collapseFullVidUploaderAndViewer: !this.state.collapseFullVidUploaderAndViewer})}
                              collapsable={true}
                              content={
                                  <div className="uploadVideoSection">
                                      <div className="clearfix mb-md mt-xs text-right">
                                          {
                                              <button type="button"
                                                      className={Object.keys(full_video).length > 0 ? "orderDetailsAction finalizeIcon" : "orderDetailsAction finalizeIcon visibilty_hidden"}
                                                      onClick={() => this.saveVideos('full_video', 'Full video')}>Save</button>
                                          }
                                      </div>
                                      {
                                          "finalize_by_pm" in order_details.settings && hasRole(['producer', 'admin', 'Remote Producer','Remote Editor/Producer'])
                                          &&
                                          parseInt(order_details.settings.finalize_by_pm.value, 10) === 0
                                              ?

                                              <React.Fragment>
                                                  <span className="customUploader"></span>
                                                  {/*<FileUpload
													setResourceFile={this.setResourceFile}
													multiple={false}
													allowedFormatsStringMimePro={'video/mp4'}
													allowedFormatsStringExtPro={'mp4'}
													title={'full video'}
													type={'full_video'}
												/>
												*/}
                                                  <FileUploaderNew
                                                      setResourceFile={this.setResourceFile}
                                                      multiple={false}
                                                      allowedFormatsStringMimePro={'video/mp4'}
                                                      allowedFormatsStringExtPro={'mp4'}
                                                      title={'full video'}
                                                      type={'full_video'}
                                                  />
                                              </React.Fragment>
                                              :

                                              TF.errorMsg("Can't upload now!", "danger", s3StaticPath('img/critical_icon.png'), " flex-center align-items-center")
                                      }
                                      <ImagePreview
                                          filesToPreview={
                                              Object.keys(full_video).length > 0
                                                  ?
                                                  full_video
                                                  :
                                                  'full_video' in orderResources
                                                  &&
                                                  {
                                                      preview: orderResources.full_video.file_path + '?rand=' + returnTimeStamp(orderResources.full_video.updated_at),
                                                      name: orderResources.full_video.file_title,
                                                      type: orderResources.full_video.mime_type,
                                                      id: orderResources.full_video.id
                                                  }
                                          }
                                          removeFile={Object.keys(full_video).length > 0 ? this.removeFile : null}
                                          type={'full_video'}
                                      />
                                  </div>
                              }
                        />
                        <Card bsClass={["innerCard mt-sm"]} title="Upload Highlight Video"
                              collapseTrigger={this.state.collapseHighlightVidUploaderAndViewer}
                              collapsHandler={() => this.setState({collapseHighlightVidUploaderAndViewer: !this.state.collapseHighlightVidUploaderAndViewer})}
                              collapsable={true}
                              content={
                                  <div className="uploadVideoSection">
                                        <div className="clearfix mb-md mt-xs text-right">
                                            {
                                                parseInt(order_details.settings.finalize_by_pm.value, 10) === 0 &&
                                                <label className="container-check text-right">NO HIGHLIGHT VIDEO
                                                    <input type="checkbox" className='checkbox' onChange={this.is_highlight_video_check}/>
                                                    <span className="checkmark"></span>
                                                </label>
                                            }
                                        </div>
                                      <div className="clearfix mb-md mt-xs text-right">
                                          {
                                              <button type="button"
                                                      className={orderResources.highlight_video && Object.keys(highlight_video).length === 0  && parseInt(order_details.settings.finalize_by_pm.value, 10) === 0 && !this.state.is_highlight_video_check ? "orderDetailsAction deleteIcon" : "orderDetailsAction deleteIcon visibilty_hidden"}
                                                      onClick={() => this.confirmResourceDelete(orderResources.highlight_video, orderResources.highlight_video.id)}>Delete Existing Video</button>
                                          }
                                          {
                                              <button type="button"
                                                      className={Object.keys(highlight_video).length > 0 ? "orderDetailsAction finalizeIcon" : "orderDetailsAction finalizeIcon visibilty_hidden"}
                                                      onClick={() => this.saveVideos('highlight_video', 'Highlight video')}>Save</button>
                                          }
                                      </div>
                                        {
                                          "finalize_by_pm" in order_details.settings && hasRole(['producer', 'admin', 'Remote Producer','Remote Editor/Producer'])
                                          &&
                                          parseInt(order_details.settings.finalize_by_pm.value, 10) === 0
                                              ?

                                              <React.Fragment>
                                                  <span className="customUploader"></span>
                                                  <FileUploaderNew
                                                      setResourceFile={this.setResourceFile}
                                                      multiple={false}
                                                      allowedFormatsStringMimePro={'video/mp4'}
                                                      allowedFormatsStringExtPro={'mp4'}
                                                      title={'highlight video'}
                                                      type={'highlight_video'}
                                                  />
                                              </React.Fragment>
                                              :
                                              TF.errorMsg("Can't upload now!", "danger", s3StaticPath('img/critical_icon.png'), " flex-center align-items-center")
                                        }
                                        {
                                            !this.state.is_highlight_video_check ?
                                                <ImagePreview
                                                    filesToPreview={
                                                        Object.keys(highlight_video).length > 0
                                                            ?
                                                            highlight_video
                                                            :
                                                            'highlight_video' in orderResources
                                                            &&
                                                            {
                                                                preview: orderResources.highlight_video.file_path + '?rand=' + returnTimeStamp(orderResources.highlight_video.updated_at),
                                                                name: orderResources.highlight_video.file_title,
                                                                type: orderResources.highlight_video.mime_type,
                                                                id: orderResources.highlight_video.id
                                                            }
                                                    }
                                                    removeFile={Object.keys(highlight_video).length > 0 ? this.removeFile : null}
                                                    type={'highlight_video'}
                                                    // resetImage={true}
                                                />
                                            :''
                                        }
                                  </div>
                              }
                        />
                        <Card bsClass={["innerCard mt-sm"]} title="Upload Mosaic File"
                            collapseTrigger={this.state.collapseMosaicFileUploaderAndViewer}
                            collapsHandler={() => this.setState({collapseMosaicFileUploaderAndViewer: !this.state.collapseMosaicFileUploaderAndViewer})}
                            collapsable={true}
                            content={
                                <div className="uploadVideoSection">
                                    <div className="clearfix mb-md mt-xs text-right">
                                        {
                                            <button type="button"
                                                    className={orderResources.original_mosaic && Object.keys(original_mosaic).length === 0  && parseInt(order_details.settings.finalize_by_pm.value, 10) === 0 ? "orderDetailsAction deleteIcon" : "orderDetailsAction deleteIcon visibilty_hidden"}
                                                    onClick={() => this.confirmResourceDelete(orderResources.original_mosaic, orderResources.original_mosaic.id)}>Delete Existing Mozaix</button>
                                        }
                                        {
                                            <button type="button"
                                                    className={Object.keys(original_mosaic).length > 0 ? "orderDetailsAction finalizeIcon" : "orderDetailsAction finalizeIcon visibilty_hidden"}
                                                    onClick={() => this.saveVideos('original_mosaic', 'Full video')}>Save</button>
                                        }
                                    </div>
                                    {
                                        "finalize_by_pm" in order_details.settings && hasRole(['producer', 'admin', 'Remote Producer','Remote Editor/Producer'])
                                        &&
                                        parseInt(order_details.settings.finalize_by_pm.value, 10) === 0
                                        ?

                                            <React.Fragment>
                                                <span className="customUploader"></span>
                                                <FileUpload
                                                    setResourceFile={this.setResourceFile}
                                                    multiple={false}
                                                    allowedFormatsStringMimePro={'image/jpeg'}
                                                    allowedFormatsStringExtPro={'jpg'}
                                                    title={'Mosaic File'}
                                                    type={'original_mosaic'}
                                                />
                                            </React.Fragment>
                                            :

                                            TF.errorMsg("Can't upload now!", "danger", s3StaticPath('img/critical_icon.png'), " flex-center align-items-center")
                                    }
                                    <div className="text-center" style={{height: 'auto'}}>
                                        <div className=" display_ib" style={{width: '500px', height: 'auto'}}>
                                            <ImagePreview
                                                filesToPreview={
                                                    Object.keys(original_mosaic).length > 0
                                                        ?
                                                        original_mosaic
                                                        :
                                                        'original_mosaic' in orderResources
                                                        &&
                                                        {
                                                            preview: orderResources.original_mosaic.file_path + '?rand=' + returnTimeStamp(orderResources.original_mosaic.updated_at),
                                                            name: orderResources.original_mosaic.file_title,
                                                            type: orderResources.original_mosaic.mime_type,
                                                            isMozaix: true,
                                                            id: orderResources.original_mosaic.id
                                                        }
                                                }
                                                removeFile={Object.keys(original_mosaic).length > 0 ? this.removeFile : null}
                                                type={'original_mosaic'}
                                                showName={'true'}
                                                // resetImage={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                        />
                        {
                            hasRole(['producer', 'admin', 'Remote Producer','Remote Editor/Producer'])
                            &&
                            parseInt(order_details.settings.finalize_by_pm.value, 10) === 0 &&
                            <Card bsClass={["innerCard mt-sm"]} title="Re-upload ALL Edited Photos"
                                  collapseTrigger={this.state.collapseImageUploaderAndViewer}
                                  collapsHandler={() => this.setState({collapseImageUploaderAndViewer: !this.state.collapseImageUploaderAndViewer})}
                                  collapsable={true}
                                  content={
                                      <div>
                                          <div className="clearfix mb-md mt-xs text-right">
                                              {
                                                  workingImagesTemp.length > 0 &&
                                                  <span
                                                      style={{'color': 'red'}}>Count of images to upload : {workingImagesTemp.length}</span>
                                              }

                                              {
                                                  workingImagesTemp.length > 0 &&
                                                  <button type="button"
                                                          className="orderDetailsAction finalizeIcon ml-md"
                                                          onClick={this.saveImages}>Save</button>
                                              }
                                              {
                                                  workingImagesTemp.length > 0 &&
                                                  <button type="button" className="orderDetailsAction clearIcon ml-md"
                                                          onClick={this.clearAllTempImages}>Clear ALL</button>
                                              }

                                          </div>

                                          <React.Fragment>
                                              <span className="customUploader"></span>
                                              <FileUpload
                                                  files={workingImagesTemp}
                                                  setResourceFile={this.setResourceFileWI}
                                                  updateResourceFile={this.updateResourceFileWI}
                                                  allowedFormatsStringMimePro={allowedFormatsStringMime}
                                                  allowedFormatsStringExtPro={allowedFormatsStringExt}
                                                  askToUpdate={true}
                                                  updateWithoutAsking={false}
                                                  allowDuplicate={false}
                                                  multiple={true}
                                              />
                                          </React.Fragment>


                                          <ImagePreview
                                              filesToPreview={workingImagesTemp}
                                              removeFile={this.removeFileWI}
                                          />
                                      </div>
                                  }
                            />
                        }


                    </div>

                </div>
                <ImagesList
                    provideCtrlListing={ctrlList => this.ctrl_list = ctrlList}
                    orderId={orderId}
                    versionType='working'
                    tabName={tabName}
                />
                 {/* Add Note Model */}
                 <Modal size="sm" backdrop={'static'} show={showNoteModal} id="" onHide={closeModal.bind(this, "showNoteModal")} aria-labelledby="ModalHeader" className="" >
                        <Modal.Header closeButton>
                            <Modal.Title id='ModalHeader' className="headerTitle">Remake Notes by: {remakeNoteData.reassigned_by}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="clearfix">
                                <textarea type="text" value={remakeNoteData.note} disabled className="form-control" />
                                <br />
                                {/* <button onClick={closeModal.bind(this, "showNoteModal")}  className="folderBtn mr-md "> Back</button> */}


                                {/* <button onClick={() => this.storeNotehandler()} style={{float: "right"}} className="backButton pt-sm no_radius pb-sm primary mt-none btn btn btn-info  btn btn-default">Submit</button> */}
                            </div>
                        </Modal.Body>
                    </Modal>
            </div>
        );
    };
}

function mapStateToProps(state) {
    const {loadingOrder, allImagesByPhase, agencyResources, orderResources, imageUpdates} = state.ordersReducer;
    // console.log('orderResources',)
    const {loading, userAdmin} = state.adminReducer;

    return {
        loadingOrder, allImagesByPhase, agencyResources, orderResources, imageUpdates,
        loading, userAdmin
    };
}

const mapDispatchToProps = dispatch => {
    return ({
        allOrderImagesByPhaseCall: (orderId, version_type, tabName) => {
            dispatch(allOrderImagesByPhase(orderId, version_type, tabName))
        },
        getResourcesByRoleCall: (agencyId, orderId) => {
            dispatch(getResourcesByRole(agencyId, orderId))
        },
        getOrderResourcesByRoleCall: (orderId) => {
            dispatch(getOrdersResourcesByRole(orderId))
        },
        storePMOrderVideoCall: (videoData, orderId, folder, key, loaderTitle) => {
            return dispatch(storePMOrderVideo(videoData, orderId, folder, key, loaderTitle))
        },
        updateSettingsDataCall: (model_id, key, settingData) => {
            return dispatch(updateSettingsData(model_id, key, settingData))
        },
        assignUserToOrderCall: (orderId, data) => {
            return dispatch(assignUserToOrder(orderId, data))
        },
        showLoaderCall: (status) => {
            dispatch(showLoader(status))
        },
        imageProcessingAndDownloadCall: (processCase, data) => {
            return dispatch(imageProcessingAndDownload(processCase, data))
        },
        storeEditorOrderImagesCall: (imagesData, orderId, folder) => {
            return dispatch(storeEditorOrderImages(imagesData, orderId, folder))
        },

        onResourceDeleteCall: (resources, id) => { return dispatch(orderResourceDelete(resources, id)) },
    });
};

const ProductionManager = connect(mapStateToProps, mapDispatchToProps)(ProductionManagerPage);
export default ProductionManager;
