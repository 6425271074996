import { SC } from '../helper/ServerCall';




export const settingsService = {
    getAgencyType,
    getCountries,
    getStatesPrimary,
    getStatesSecondary,
    getStates,
    getSalesRep,
    getAgencySalesRep,
    getProgramType,
    getProgramItem,
    getMainProgramType,
    getInvoicing,
    getSettingValue,
    getUserPreferences,
    storeOrUpdateUserPreferences,
    getSettingValueByModelAndId,
    storeSettingsData,
    updateSettingsData,
    getAllAddtionalItems,
    storeItem,
    getSingleAdditionalItem,
    updateAdditionalItem,
    deleteAdditionalService,
    getShippingSettings,
    getItemType,
    getItemCategory,
    getInvLocations,
    getDestinations,
    imageProcessingAndDownload,
    getRanges,
    getUiSettings,
    getOrderStatuses,
    getDefaultPreference,
    storeAdvanceSearch,
    getInvoicePeriod,
    getFilPath,
    getDestinationsAsync,
    changePermission,
    getFile,
    updateOrCreateSettingsData,
}
function getAgencyType() {
    return SC.getCall('getAgencyTypes');
}
function getCountries() {
    return SC.getCall('getAllCountries');
}
function getStatesPrimary(countryId) {
    return SC.getCall('getStatesForCountry/' + countryId);
}
function getStates(countryId, type) {
    return SC.getCall('getStatesForCountry/' + countryId + '/' + type);
}
function getStatesSecondary(countryId) {
    return SC.getCall('getStatesForCountry/' + countryId);
}
function getSalesRep() {
    return SC.getCall('getGtvSalesRepresentatives');
}

function getAgencySalesRep(id) {
    return SC.getCall('getAgencySalesRepresentatives/' + id);
}
function getProgramType() {
    return SC.getCall('getProgramTypes');
}
function getMainProgramType() {
    return SC.getCall('getMainProgramTypes');
}

function getProgramItem(programType) {
    return SC.getCall('getProgramItems/' + programType);
}
function getInvoicing() {
    return SC.getCall('getInvoiceStyles');
}

function getSettingValue(key, state_name) {
    if (state_name === undefined) {
        return SC.getCall('getSettingValue/' + key);
    } else {
        return SC.getCall('getSettingValue/' + key + '?state=' + state_name);
    }
}

function getUserPreferences() {
    return SC.getCall('getUserPreferences');
}

function storeOrUpdateUserPreferences(preferenceData, searchId) {
    if (searchId){
        return SC.putCall(`updateUserPreferences/${searchId}`, preferenceData);
    } else {
        return SC.putCall('updateUserPreferences', preferenceData);
    }
}

function getSettingValueByModelAndId(modelName, modelId, key) {
    return SC.getCall(`getSettingValueByModelAndId/${modelName}/${modelId}/${key}`);
}

function storeSettingsData(settingsData) {
    return SC.postCall(`storeSettingsData`, settingsData);
}

function updateSettingsData(model_id, key, settingsData) {
    return SC.putCall(`updateSettingsData/${model_id}/${key}`, settingsData);
}

function updateOrCreateSettingsData(model_id, key, settingsData) {
    return SC.putCall(`updateOrCreateSettingsData/${model_id}/${key}`, settingsData);
}

function getAllAddtionalItems(page, filter, sort, pageSize) {
    return SC.getCall(`additionalServices?page=${page}&filter=${filter}&sort=${sort}&pageSize=${pageSize}`);
}

function storeItem(data) {
    return SC.postCall(`additionalServices`, data);
}

function getSingleAdditionalItem(id) {
    return SC.getCall(`additionalServices/${id}`);
}

function updateAdditionalItem(data, id) {
    return SC.putCall(`additionalServices/${id}`, data);
}

function deleteAdditionalService(id) {
    return SC.deleteCall(`additionalServices/${id}`);
}

function getShippingSettings() {
    return SC.getCall(`getShippingSettings`);
}

function getItemType() {
    return SC.getCall('getItemType');
}

function getItemCategory() {
    return SC.getCall('getItemCategory');
}

function getInvLocations() {
    return SC.getCall('getInvLocations');
}
function getDestinations(data) {

    return SC.postCall(`getDestinations`, data);
}

function getDestinationsAsync(data) {

    return SC.postCall(`getDestinations`, data).then(response=>{
        return response.getDestinations;
    });
}

function getDestinationsFilter(filter) {
    return SC.getCall(`getDestinationsFilter/${filter}`);
}
function imageProcessingAndDownload(processCase, data) {
    return SC.postCall(`imageProcessing/${processCase}`, data);
}
function getRanges() {
    return SC.getCall('getRanges');
}

function getUiSettings() {
    return SC.getCall('UiSettings');
}

function getOrderStatuses() {
    return SC.getCall('getOrderStatuses')
}
function getDefaultPreference() {
    return SC.getCall('getDefaultPreference')
}
function storeAdvanceSearch(data) {
    return SC.postCall('storeAdvanceSearch', data)
}
function getInvoicePeriod() {
    return SC.getCall('getInvoicePeriods');
}
function getFilPath(orderId, type) {
    return SC.getCall(`getFilPath/${orderId}/${type}`)   
}
function changePermission(data) {
    return SC.postCall(`updatePermission`, data);   
}
function getFile(fileId) {
    console.log('service call')
    return SC.getCall(`getFile/${fileId}`)      
}