import React, { Component } from 'react'
import { connect } from 'react-redux'
// import OrderForm from './OrderForm/OrderForm';
// import { Link } from 'react-router-dom'
import { Dashboard } from 'layouts/Frontend/Dashboard/Dashboard';
import AgencyLogo from 'components/ThemeComponents/AgencyLogo';
// import { orderService } from 'services/order';
import { getOrder, updateTitlesMusic, updateOrderSettings } from 'redux/actions/order-actions';
// import { OverlayTrigger, Tooltip } from 'react-bootstrap';
// import DownloadFile from 'components/ThemeComponents/downloadFile';
import { s3StaticPath, returnKeyWithColumnMatch, toggleModal, inputChangeHandler, helperFunctions as HF } from 'helper/helperFunctions';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import User from 'helper/User';
import ShippingDetails from 'components/FrontEnd/Order/OrderForm/ShippingDetails';
import VideoTitles from 'components/FrontEnd/Order/OrderForm/VideoTitles';
import { getCountries } from 'redux/actions/settings-actions';
import Validator from 'simple-react-validator';
import { Roles } from '../../../constants/Roles';

var moment = require("moment");

class OrderSummaryPA extends Component {
     constructor(props) {
        super(props)

        this.state = {
            order_details: OC.ORDERDETAIL,
            production_details: this.props.production_details,
            shipping_details: OC.SHIPPINGDETAIL,
            favOption: 'desktop_app',
            favOption2: '',
            music_type: 'destination_based',
            photo_admin_approval: false,
            allow_upload: false,
            orderTitles: {},
            videoPaused: true,
            showErrorMessages: false,
            sampleMusicModal: false,
            submitComment: false,
            is_france : false,
            ptma_initial_country_France: false,
            vsa_initial_country_France: false,
        }
        this.props.getOrderCall(User.order('id'));
        this.Validator = new Validator();
        this.video = React.createRef();
        this.toggleModal = toggleModal.bind(this);
        this.inputChangeHandler = inputChangeHandler.bind(this);
    }

    addDefaultSrc = (ev) => {
        ev.target.src = HF.s3StaticPath('img/logo-placeholder.png');
    }
    getState = (state, container) => {
        this.setState(prevState => ({
            ...prevState,
            [container]: state
        }))
    }



    componentDidUpdate(prevProps, prevState) {
        if (this.props.order_details !== prevProps.order_details) {
            this.setState({
                order_details: this.props.order_details
            })
        }
        if (this.props.production_details !== prevProps.production_details) {
            this.setState({
                production_details: this.props.production_details
            })
        }
        if (this.props.shipping_details !== prevProps.shipping_details) {
            this.setState({
                shipping_details: this.props.shipping_details
            })
        }

        if (this.props.shipping_details !== prevProps.shipping_details){

            if(this.props.shipping_details && this.props.shipping_details.pre_trip_material_address && this.props.shipping_details.pre_trip_material_address.country && this.props.shipping_details.pre_trip_material_address.country.title && this.props.shipping_details.pre_trip_material_address.country.title === 'France'){
                this.setState({
                    ptma_initial_country_France : true
                })
            }
            if (prevProps.shipping_details !== this.props.shipping_details && this.props.shipping_details.video_shipping_address && this.props.shipping_details.video_shipping_address.country && this.props.shipping_details.video_shipping_address.country.title && this.props.shipping_details.video_shipping_address.country.title === 'France') {
                this.setState({
                    vsa_initial_country_France : true
                })
            }
        }
    }

    radioChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            if (this.state.favOption === 'not_sure') {
                this.setState({
                    favOption2: ''
                })
            }
        });
    }

    printOrderOptions = () => {
        if (this.Validator.allValid()) {
            const data = {
                order_details: this.state.order_details,
                shipping_details: this.state.shipping_details,
                production_details: this.state.production_details
            }
            this.setState({
                submitComment: true
            }, () => {
                this.setState({
                    submitComment: false
                })
            })
            this.props.updateOrderSettingsCall(User.order('id'), 0, 'online_options_page_submitted');
            this.props.updateTitlesMusic(data, User.order('id')).then(
                res => {
                    // if (res) {
                    //     localStorage.setItem('favOption2', this.state.favOption2);
                    //     localStorage.setItem('favOption', this.state.favOption);
                    //     window.open(`/print-order-options/${User.getProperty('order.id')}`)
                    // }
                },
                err => {

                }
            );
        } else {
            // console.log('validator', this.Validator.showMessages());
            this.Validator.showMessages();
            this.setState(prevState => ({
                ...prevState,
                showErrorMessages: this.Validator.messagesShown
            }))
        }
    }

    toggleVideo = () => {
        this.setState(prevState => ({
            videoPaused: !prevState.videoPaused
        }), () => {
            if (!this.state.videoPaused) {
                this.video.current.play()
            }
        })
    }

    setStateOfParentPtma = (newValue) => {
        this.setState({
            ptma_initial_country_France: newValue
        });
    }
    setStateOfParentVsa = (newValue) => {
        this.setState({
            vsa_initial_country_France: newValue
            });
    }
    render() {
        this.Validator.purgeFields();
        const { order_details, shipping_details, production_details, ptma_initial_country_France } = this.state;
        let domain_slug = order_details && order_details.agency_type && order_details.agency_type.title.toLowerCase() === 'itk' ? 'itk' : User.getProperty('brand.domain_slug');
        let appsLink = returnKeyWithColumnMatch('domain_slug', OC.APPS,  domain_slug);
        return (
            <div className='container'>
                <div className="row">
                    <div className="col-md-12">

                        <h2 className="panel_heading userLogin_heading">Order Summary</h2>
                        <br/>
                        <p style={{'textAlign':'center'}}>
                        Arrangements have been make for a fun keepsake video to be produced for your travelers to enjoy using the photos taken on your trip.<br/><strong>No additional payment is required!</strong>
                        </p>
                    </div>
                </div>

                <div className="form_tile">
                    <div className="form_tile_heading">
                        <img src={s3StaticPath('img/order_information.png')} alt="General Information" />
                        <span>Order Information</span>
                    </div>

                    <div className="form_tile_content orderInformationBox">
                        <div className="flexElem respFlex form_tile_row">
                            <div className="col-md-6">
                                <div className="flexElem alignCenter spaceBetween form_tile_row">
                                    <div className="col-md-4">
                                        Group Name:
                                    </div>
                                    <div className="col-md-8 text-primary">
                                        {order_details.group_name}
                                    </div>
                                </div>
                                <div className="flexElem alignCenter spaceBetween form_tile_row">
                                    <div className="col-md-4">
                                        Group Leader/Photo Admin:
                                    </div>
                                    <div className="col-md-8 text-primary">
                                        {order_details.group_leader_contact.name}
                                    </div>
                                </div>
                                <div className="flexElem alignCenter spaceBetween form_tile_row">
                                    <div className="col-md-4">
                                        Destination:
                                    </div>
                                    <div className="col-md-8 text-primary">
                                        {order_details.destinationText}
                                    </div>
                                </div>
                                <div className="flexElem alignCenter spaceBetween form_tile_row">
                                    <div className="col-md-4">
                                        Trip Dates:
                                    </div>
                                    <div className="col-md-8 text-primary">
                                        {new Date(order_details.order_dates.departure_date.value).toDateString() + ' - ' + new Date(order_details.order_dates.return_date.value).toDateString()}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div>
                                    <strong className="text_16">Photo Admin Login</strong>
                                </div>

                                <div className="text-primary text_14 mt-sm">Photo Admin Login is for GROUP LEADER ONLY!</div>

                                <div className="flexElem alignCenter spaceBetween mt-md">
                                    <div className="flexElem alignCenter">
                                        <div><img src={s3StaticPath('img/user_icon_round.png')} alt="Username Icon" /></div>
                                        <div className="ml-sm">Username:</div>
                                    </div>
                                    <div className="text-primary">
                                        {order_details.photo_admin_username}
                                    </div>
                                </div>
                                <div className="flexElem alignCenter spaceBetween mt-sm">
                                    <div className="flexElem alignCenter">
                                        <div><img src={s3StaticPath('img/password_icon_round.png')} alt="Username Icon" /></div>
                                        <div className="ml-sm">Password:</div>
                                    </div>

                                    <div className="text-primary">
                                        {order_details.photo_admin_password}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 trevlerLoginDetails">
                                <div>
                                    <strong className="text_16">Traveler Login</strong>
                                </div>
                                <div className="flexElem alignCenter spaceBetween mt-md">
                                    <div className="flexElem alignCenter">
                                        <div><img src={s3StaticPath('img/user_icon_round.png')} alt="Username Icon" /></div>
                                        <div className="ml-sm">Username:</div>
                                    </div>
                                    <div className="text-primary">
                                        {order_details.username}
                                    </div>
                                </div>
                                <div className="flexElem alignCenter spaceBetween mt-sm">
                                    <div className="flexElem alignCenter">
                                        <div><img src={s3StaticPath('img/password_icon_round.png')} alt="Username Icon" /></div>
                                        <div className="ml-sm">Password:</div>
                                    </div>

                                    <div className="text-primary">
                                        {order_details.password}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form_tile">
                    <div className="form_tile_heading">
                        <img src={s3StaticPath('img/order_information.png')} alt="General Information" />
                        <span>FREE { order_details && order_details.agency_type && order_details.agency_type.title.toLowerCase() === 'itk' ? 'Incentive TripKit' : User.getProperty('brand.title') } App!</span>
                    </div>

                    <div className="form_tile_content grpTravelApp">
                        <div className="flexElem respFlex form_tile_row pb-md" style={{'background':'white', marginLeft: '-20px', marginRight: '-20px'}}>
                            {
                                appsLink &&
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-6 mt-md mb-md">
                                            <React.Fragment>
                                            {
                                                User.hasRole([Roles.TRAVELER, Roles.PHOTO_ADMIN])
                                                ?
                                                    <React.Fragment>
                                                        <img src={User.customerSlatesLogo() ? User.customerSlatesLogo():User.agencyLogo() } onError={this.addDefaultSrc} className={this.props.className} style={this.props.style} alt="logo" />
                                                    </React.Fragment>
                                                :
                                                <AgencyLogo fixHeight="170px" />
                                            }
                                            </React.Fragment>
                                        </div>
                                        <div className="col-md-6 mt-sm">
                                            <h1 style={{'color':'#329FCF', 'fontSize':'20px', 'textAlign':'center'}}><strong>Download the App today!</strong></h1>

                                            <h4 style={{ 'fontSize':'15px', 'textAlign':'center'}}>
                                                <strong>Search</strong>: { order_details && order_details.agency_type && order_details.agency_type.title.toLowerCase() === 'itk' ? 'Incentive TripKit' : User.getProperty('brand.title') } App
                                            </h4>

                                            <div className="downloadBtns flexElem respFlex ml-xxxlg mt-sm">
                                                <a target="_blank" href="https://apps.apple.com/us/app/travel-tripkit/id1643125077" ><img src={s3StaticPath('img/appStore.png')} alt="Download from App Store"/></a>
                                                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.travel.tripkit.pro"><img src={s3StaticPath('img/playStore.png')} alt="Download from Play Store"/></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="flexElem respFlex form_tile_row">

                            <div className="col-md-6">
                                <div className="text_14 mt-md">
                                   Once you have the app, simply login with your Group's
                                   username and password. Both the Photo Admin and Traveler Login will work. However, we request
                                   that you do not share the Photo Admin Login except with those people you are designated to
                                   administer the photos. For instance, you, a parent or school administrator (for student groups), or family
                                   member at home could be the Photo Admin.
                                </div>

                                <div className="mt-md text_14 text-primary">
                                    Note: If video photo sequence is important to you, you can arrange
                                    the photos on this website's Uploaded Photos Gallery by logging as
                                    the Photo Admin.
                                </div>

                                <div className="mt-lg text_18">
                                    Family at home can follow the trip online!
                                </div>

                                <div className="text_14 mt-md">
                                    Those at home can login to this website or the { order_details && order_details.agency_type && order_details.agency_type.title.toLowerCase() === 'itk' ? 'Incentive TripKit' : User.getProperty('brand.title') } App with the Traveler Log In and follow along as the trip takes place.
                                </div>

                                <div className="mt-md">
                                    <img src={s3StaticPath('img/family_at_home.jpg')} alt="Family at home can follow the trip online!" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                {
                                    User.getProperty('brand.appDemoLink') &&
                                        <div id="video">
                                            <div className="video_text p-sm text_18 text-center purple_gradient text_white">
                                                <strong>Learn how the { order_details && order_details.agency_type && order_details.agency_type.title.toLowerCase() === 'itk' ? 'Incentive TripKit' : User.getProperty('brand.title') } App works!!</strong>
                                            </div>
                                            <div className="video_placeholder">
                                                {
                                                    !order_details || !order_details.agency_type || order_details.agency_type.title === ''
                                                    ?
                                                        <React.Fragment>
                                                        <video width="100%" height="240">&nbsp;</video>
                                                        <div className="video_play blue_gradient hover_dark"></div>
                                                        </React.Fragment>
                                                    :
                                                        order_details && order_details.agency_type && order_details.agency_type.title.toLowerCase() === 'itk'
                                                        ?
                                                            <React.Fragment>

                                                                <video onPlay={() => {
                                                                    this.setState({
                                                                        videoPaused: false
                                                                    })
                                                                }} onPause={this.toggleVideo} ref={this.video} width="100%" height="240" controls>
                                                                    <source src='https://gtv.grouptravelvideos.com/videos/ITK_App_Demo.mp4' type="video/mp4" />
                                                                </video>

                                                                {this.state.videoPaused &&
                                                                <div onClick={this.toggleVideo} className="video_play blue_gradient hover_dark"></div>}
                                                            </React.Fragment>
                                                        :
                                                        order_details && order_details.agency_type && order_details.agency_type.title !== '' && order_details.agency_type.title.toLowerCase() !== 'itk' &&
                                                            <React.Fragment>

                                                                <video onPlay={() => {
                                                                    this.setState({
                                                                        videoPaused: false
                                                                    })
                                                                }} onPause={this.toggleVideo} ref={this.video} width="100%" height="240" controls>
                                                                    <source src={User.getProperty('brand.appDemoLink').value} type="video/mp4" />
                                                                </video>
                                                                {this.state.videoPaused &&
                                                                <div onClick={this.toggleVideo} className="video_play blue_gradient hover_dark"></div>}
                                                            </React.Fragment>
                                                }
                                            </div>
                                        </div>
                                }

                                <div className="p-md approvalOptions text_white purple_blue_gradient">
                                    <h4 style={{'textAlign':'center'}}>Photo Approval</h4>
                                    <p>By default your group’s uploaded photos will be allowed
                                    to go online for the group’s viewing without additional
                                    Photo Admin Approval.</p>
                                    <br/>
                                    <p>
                                        If you would like the Photo Admin to have
                                        the option to Approve ALL photos before the Group can
                                        view them online, please contact us at.
                                    </p>

                                    <div className="text_14 mt-md">

                                    704-846-7870 x209 or at <br />
                                        <a href="mailto:Info@traveltripkit.com" className="text_white link_hover">Info@traveltripkit.com</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <ShippingDetails
                                contactInfo={true}
                                panelTitle="Please verify the below information"
                                order_details={order_details}
                                showErrorMessages={this.state.showErrorMessages}
                                validatorInstance={this.Validator}
                                returnState={this.getState}
                                agencyAddress={order_details.agency.primary_address}
                                shipping_details={shipping_details}
                                submitComment={this.state.submitComment}
                                notShipToAgency={true}
                                notVideoAddressAgencyPreTripButton={true}
                                notVideoShipAddressShow={parseInt(order_details.settings.no_gl_copy.value)?true:false}
                                ptma_initial_country_France={ptma_initial_country_France}
                                setStateOfParentPtma={this.setStateOfParentPtma}
                                setStateOfParentVsa={this.setStateOfParentVsa}

                            />
                        </div>

                        <div className="col-md-6">
                            <VideoTitles
                                panelTitle="Edit &amp; Enter Custom Videos Titles"
                                showErrorMessages={this.state.showErrorMessages} validatorInstance={this.Validator} returnState={this.getState} production_details={production_details} />

                            <div className="form_tile">
                                <div className="form_tile_heading">
                                    <img src={s3StaticPath('img/video_music.png')} alt="Video Music" />
                                    <span>Video Music Type</span>
                                </div>

                                <div className="form_tile_content">
                                    <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                        <div className="col-md-6">
                                            <div className="customChk">
                                                <input onChange={this.inputChangeHandler} checked={order_details.music_selection_criteria === 'destination_based'} name='order_details.music_selection_criteria' value='destination_based' type="radio" id="destinationBased" />
                                                <label htmlFor="destinationBased" className="text_16">Destination-based</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="customChk">
                                                <input checked={order_details.music_selection_criteria === 'age_based'} onChange={this.inputChangeHandler} type="radio" id="ageBased" name='order_details.music_selection_criteria' value='age_based' />
                                                <label htmlFor="ageBased" className="text_16">Age-based</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                        <div className="col-md-6 mt-md">
                                            <button onClick={() => { this.toggleModal('sampleMusicModal') }} className="gradient_btn block themeBtn_14 purple_gradient hover_dark">Sample Music Here</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-md-2 col-md-offset-5 mt-xlg mb-xlg">
                            <button className='themeBtn_14 block mb-xlg red_gradient' onClick={this.printOrderOptions} >Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    const { order_details, shipping_details, pricing_details, production_details } = state.ordersReducer;
    const { countries } = state.settingsReducer;
    return {
        order_details, shipping_details, pricing_details, production_details, countries
    }
}


const mapDispatchToProps = dispatch => {
    return {
        getOrderCall: (orderId) => { dispatch(getOrder(orderId)) },
        getCountriesCall: () => { dispatch(getCountries()) },
        updateTitlesMusic: (data, id) => { return dispatch(updateTitlesMusic(data, id)) },
        updateOrderSettingsCall: (orderId, value, key) => { dispatch(updateOrderSettings(orderId, value, key)) }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard(OrderSummaryPA))
