import { frontEndUserConstants } from '../constant/front-end-constant';
import { history } from '../../helper/history';
import { frontEndUserService } from 'services/frontEndUser';
import { alertActions } from './alert-actions';
import User from '../../helper/User';
import { returnDynamicURL } from '../../helper/helperFunctions';
import { Roles } from '../../constants/Roles';

const queryString = require('query-string');
const parsed = queryString.parse(window.location.search);
export function login(email, password, intended = null) {
    return dispatch => {
        dispatch(request({ email }));
        frontEndUserService.login(email, password)
            .then(
                frontEndUser => {
                    console.log('frontEndUser', frontEndUser)
                    const host = window.location.hostname.replace(/www./).split('.');

                    let match, result;
                    match = document.domain.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im);
                    if (match) {
                        result = match[1]
                        let domainSlugs = ['gtv', 'brt', 'mci', 'eti', 'itk', 'act', 'ttk'];
                        if (domainSlugs.includes(result.split('.')[0])) {
                            result = result.split('.').slice(1).join('.');
                        }
                    }
                    let link = returnDynamicURL(frontEndUser.user.brand.domain_slug, email, password)
                    if (process.env.NODE_ENV === 'production') {
                        console.log("production enviroment......");
                        //TO TEST ON LOCAL PLEASE CHOOSE AGENCY USERS OF GTV AGENCY BECAUSE IT IS DEFAULT AGENCY TO RUN ON LOCAL
                        if (host[0] !== frontEndUser.user.brand.domain_slug.toLowerCase()) {
                            if(parsed.days) {
                                link = `${link}/increase_ucd?days=${parsed.days}`;
                            } else if (parsed.photos_submitted_for_video_production) {
                                link = `${link}/increase_ucd?photos_submitted_for_video_production=${parsed.photos_submitted_for_video_production}`;
                            }
                            window.location = link
                        } else {
                            if (intended) {
                                window.location = `http://${frontEndUser.user.brand.domain_slug}.${result}${intended}`;
                            } else {
                                console.log("user////0", User);
                                if ((frontEndUser.user.role.name === 'Traveler' || frontEndUser.user.role.name === 'Photo Admin') && frontEndUser.user.status === 'Approved' && frontEndUser.user.first_email_sent) {
                                    var url = '';
                                    if(parsed.days) {
                                    url = `increase_ucd?days=${parsed.days}`;
                                    } else if (parsed.photos_submitted_for_video_production) {
                                        url = `increase_ucd?photos_submitted_for_video_production=${parsed.photos_submitted_for_video_production}`;
                                    }else {
                                        url = `photo-gallery`;
                                    }
                                    // const url = `photo-gallery`;
                                    window.location = `http://${frontEndUser.user.brand.domain_slug}.${result}/${url}`;

                                } else if (frontEndUser.user.role.name === 'Primary Contact' || frontEndUser.user.role.name === 'User') {
                                    window.location = `http://${frontEndUser.user.brand.domain_slug}.${result}/new-order`;
                                    
                                } else {
                                    window.location = `http://${frontEndUser.user.brand.domain_slug}.${result}/dashboard`;
                                }
                            }
                        }
                        localStorage.setItem('user', JSON.stringify(frontEndUser));
                        dispatch(success(frontEndUser));
                    } else {
                        console.log("local enviroment");
                        localStorage.setItem('user', JSON.stringify(frontEndUser));
                        dispatch(success(frontEndUser));
                        if ( (frontEndUser.user.role.name === 'Traveler' || frontEndUser.user.role.name === 'Photo Admin')  && frontEndUser.user.status === 'Approved' && frontEndUser.user.first_email_sent) {
                            var url = '';
                                if(parsed.days) {
                                url = `increase_ucd?days=${parsed.days}`;
                                } else if (parsed.photos_submitted_for_video_production) {
                                    url = `increase_ucd?photos_submitted_for_video_production=${parsed.photos_submitted_for_video_production}`;
                                }else {
                                    url = `photo-gallery`;
                                }
                            console.log("if");
                            // const url = `photo-gallery`;
                            window.location = `/${url}`;
                        
                        } else if (frontEndUser.user.role.name === 'Primary Contact') {
                            console.log("else if");
                            window.location = '/new-order';
                        } else {
                            console.log("else");
                            window.location = '/dashboard';
                        }
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(username) { return { type: frontEndUserConstants.LOGIN_FRONT_END_USER_REQUEST, payload: username } }
    function success(frontEndUser) { return { type: frontEndUserConstants.LOGIN_FRONT_END_USER_SUCCESS, payload: frontEndUser } }
    function failure(error) { return { type: frontEndUserConstants.LOGIN_FRONT_END_USER_FAILURE, payload: error } }
}


export function forgetUsername(email) {
    return dispatch => {
        dispatch(request({ email }));
        frontEndUserService.forgetUsername(email)
            .then(
                response => {
                    // console.log('frontEndUser data', frontEndUser);
                    dispatch(success(response.message));
                    dispatch(alertActions.success(response.message));
                    //history.push('/Dashboard');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(email) { return { type: frontEndUserConstants.FORGET_FRONT_END_USER_REQUEST, payload: email } }
    function success(response) { return { type: frontEndUserConstants.FORGET_FRONT_END_USER_SUCCESS, payload: response } }
    function failure(error) { return { type: frontEndUserConstants.FORGET_FRONT_END_USER_FAILURE, payload: error } }
}

export function logout() {
    frontEndUserService.logout();
    User.logout();
    window.location = '/';
    return { type: frontEndUserConstants.LOGOUT_FRONT_END_USER };
}


export function getAdudicators(frontEndUserData) {
    return dispatch => {
        dispatch(request());
        frontEndUserService.getAdudicators(frontEndUserData)
            .then(
                response => {
                    dispatch(success(response));
                    history.push('/Dashboard');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: frontEndUserConstants.GET_ADJUDICATORS_REQUEST } }
    function success(response) { return { type: frontEndUserConstants.GET_ADJUDICATORS_SUCCESS, payload: response } }
    function failure(error) { return { type: frontEndUserConstants.GET_ADJUDICATORS_FAILURE, payload: error } }
}

export function getAdudicatorsRecordings(adjudicatorObject, page) {
    return dispatch => {
        dispatch(request());
        frontEndUserService.getAdudicatorsRecordings(adjudicatorObject, page)
            .then(
                response => {
                    dispatch(success(response));
                    history.push('/Dashboard');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: frontEndUserConstants.GET_ADJUDICATORS_RECORDINGS_REQUEST } }
    function success(response) { return { type: frontEndUserConstants.GET_ADJUDICATORS_RECORDINGS_SUCCESS, payload: response } }
    function failure(error) { return { type: frontEndUserConstants.GET_ADJUDICATORS_RECORDINGS_FAILURE, payload: error } }
}



export function getMessage(data) {
    return dispatch => {
        // dispatch(request())
        return frontEndUserService.getMessage(data)
            .then(
                response => {
                    return response;
                    // dispatch(success(response));
                },
                error => {
                    return Promise.reject(error);
                    // dispatch(failure(error));
                    // dispatch(alertActions.error(error));
                }
            );
    };

    // function request() { return { type: adminConstants.PAGINATION_AGENCIES_REQUEST } }
    // function success(response) { return { type: adminConstants.PAGINATION_AGENCIES_SUCCESS, payload: response } }
    // function failure(error) { return { type: adminConstants.PAGINATION_AGENCIES_FAILURE, payload: error } }
}

export function sendPasswordResetLink(username) {
    return dispatch => {
        return frontEndUserService.sendPasswordResetLink(username).then(
            res => {
                dispatch(alertActions.success('Reset link sent to your email'))
                return res
            },
            err => {
                dispatch(alertActions.error(err))
            }
        )
    }
}
export function resetPassword(data) {
    return dispatch => {
        return frontEndUserService.resetPassword(data).then(
            res => {
                dispatch(alertActions.success(res.message))
                return res
            },
            err => {
                dispatch(alertActions.error(err))
                return Promise.reject(err)
            }
        )
    }
}
export function sendContactEmail(data) {
    return dispatch => {
        return frontEndUserService.sendContactEmail(data).then(
            res => {
                dispatch(alertActions.success(res))
                return res
            },
            err => {
                dispatch(alertActions.error(err))
                return Promise.reject(err)
            }
        )
    }
}
