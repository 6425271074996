import React from "react";
// import { Modal } from "react-bootstrap";
var Modal = require('react-bootstrap-modal');

class ThemeModal extends React.Component {
    render() {
        return (
            <Modal backdrop={'static'} className={this.props.modalClass} bsSize={this.props.size} show={this.props.show} onHide={this.props.hide} >
                <Modal.Header closeButton>
                    <Modal.Title className={this.props.headingStyle}>
                        {this.props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={this.props.bodypadding}>
                    {this.props.children}
                </Modal.Body>
            </Modal>
        );
    }
}
export default ThemeModal;