import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getOrder, getOrderItems } from 'redux/actions/order-actions';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import User from 'helper/User';
import { Link } from 'react-router-dom'
import moment from 'moment';
import { getOrdersResourcesByRole } from 'redux/actions/agency-resource-actions';
import DownloadFile from 'components/ThemeComponents/downloadFile';
import { returnDynamicURL, returnTimeStamp } from 'helper/helperFunctions';
import ImagePreview from 'components/Upload/ImagePreview';

class PhotoAndVideo extends Component {
    constructor(props) {
        super(props)
        if (!User.isAuthenticated()) {
            this.props.history.push('/');
        }
        // console.log('poarams', this.props.match.params);
        if (User.isAuthenticated()) {
            this.props.getOrderCall(this.props.order_details.id);
            this.props.getOrderVideos(this.props.order_details.id);
        }
        this.state = {
            order_details: OC.ORDERDETAIL
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.order_details !== prevProps.order_details) {
            this.setState({
                order_details: this.props.order_details
            });
        }
    }
    render() {

        const { orderResources } = this.props;
        const { order_details } = this.state;
        let photomozaixLink = order_details && order_details.orderResources && 'mosaic_file' in order_details.orderResources ? <a href={order_details.orderResources['20x30_mosaic_preview'].file_path} target='_blank' >PhotoMozaix</a> : 'PhotoMozaix';
        let link = returnDynamicURL(typeof order_details.agency.single_account === 'undefined' || !order_details.agency.single_account ? null : order_details.agency.single_account.account.domain_slug, order_details.photo_admin_username, order_details.photo_admin_password);
        return (
            <div className="form_tile">
               <div className="form_tile_heading">
                    <img src="https://gtvoriginalimages.s3.amazonaws.com/static/img/upload_image.png" height="27" alt="Photos" /><span>Photos</span>
               </div>
               <div className="form_tile_content">
                  <div className="reponsive-table form_tile_row">
                    <Link className="blue_link" to={`/image-gallery/${this.props.order_details.id}`}><i className="fa fa-image" /> Photo Gallery</Link>
                    <table style={{width: '100%'}} className="table_tile">
                        <tbody>
                            <tr>
                                <td>
                                    <div style={{ paddingTop: '15px' }}>
                                        <a className="blue_link" href={`microsoft-edge:${link}`}>{returnDynamicURL(typeof order_details.agency.single_account === 'undefined' || !order_details.agency.single_account ? null : order_details.agency.single_account.account.domain_slug, order_details.photo_admin_username, order_details.photo_admin_password)}</a> (Quick Login Link)
                                    </div>
                                    <p className="text_12 text-muted dark">
                                        Note: You can use this Quick Link to view this group's Photo Gallery and {photomozaixLink}.
                                </p>
                                </td>
                            </tr>
                            {/* {
                                'highlight_video' in orderResources &&
                                <tr>
                                    <td>
                                        <DownloadFile extraClass='blue_link' multiple={false} title={'Download Highlight Video (' + (orderResources.highlight_video.file_size / 1024 / 1024).toFixed(2) + ') MB'} files={{
                                            'file_title': orderResources.highlight_video.file_title,
                                            'file_path': orderResources.highlight_video.file_path
                                        }} />
                                        <p className="text_12 text-muted dark">
                                            Note: If download link does not work, try right-clicking and "Save Link As" (or "Save Target As")
                                </p>
                                    </td>
                                </tr>
                            }
                            {
                                'full_video' in orderResources &&
                                <tr>
                                    <td>
                                        <DownloadFile extraClass='blue_link' multiple={false} title={'Download Full Video (' + (orderResources.full_video.file_size / 1024 / 1024).toFixed(2) + ') MB'} files={{
                                            'file_title': orderResources.full_video.file_title,
                                            'file_path': orderResources.full_video.file_path
                                        }} />
                                        <p className="text_12 text-muted dark">
                                            Note: If download link does not work, try right-clicking and "Save Link As" (or "Save Target As")
                                </p>
                                    </td>
                                </tr>
                            } */}
                        </tbody>
                    </table>
                  </div>
               </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { 
        order_details,
        orderResources
    } = state.ordersReducer;
    const { orderItems } = state.ordersReducer;
    return {
        order_details,
        orderResources
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getOrderCall: (id) => { dispatch(getOrder(id)) },
        getOrderVideos: (orderId) => { dispatch(getOrdersResourcesByRole(orderId)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PhotoAndVideo);
