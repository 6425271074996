import React, { Component } from 'react';
import {
    OverlayTrigger,
    ButtonToolbar,
    Popover,
    Grid,
    Row,
    Col
} from "react-bootstrap";
import { SC } from 'helper/ServerCall';
import ThemeModal from '../../../ThemeComponents/ThemeModal';
import ToggleButton from 'react-toggle-button';
import { themeCSSConstants } from 'redux/constant/themeCSS';
// import MoreButtonForm from "../MoreButton/MoreButtonForm";
import { connect } from 'react-redux'
import { getAccountMoreButtonsPagination, deleteMoreButton, updateMoreButtonStatus } from '../../../../redux/actions/more-buttons-action';
import Confirmalertfordelete from 'components/ThemeComponents/confirmAlertForDelete';
import { createSortString, closeModal, dateFormatMy } from '../../../../helper/helperFunctions'

import 'react-table/react-table.css';
import { confirmAlert } from 'react-confirm-alert';
import BrandMoreButtonForm from './BrandMoreButtonForm';
import MoreButtonsPagination from '../../../ThemeComponents/MoreButtonsPagination';
import Card from '../../../Card/Card';
import BrandMoreButtonsWeTravel from './BrandMoreButtonsWeTravel';
class BrandMoreButtonsList extends Component {

    constructor(props) {
        super(props);
        const { accountId } = this.props.match.params;
        this.table = React.createRef();
        this.state = {
            pageSize: 10,
            filtered: [],
            moreButtonEditModal: false,
            moreButtonCreateModal: false,
            moreButtonEditData: '',
            accountId: parseInt(accountId, 10),
            is_active: '1'
        }
        this.showModal = this.showModal.bind(this);
    }

    showModal(data = '', e) {
        let newData = { ...data };
        newData.file_path = {
            preview: data.file_path,
            name: data.file_name,
            type: data.mime_type,
            id: data.id
        }

        this.setState({
            [e.target.name]: true,
            moreButtonEditData: newData,
        });

    }
    updateMoreButton = (id, data) => {
        return SC.postCall('updateMoreButton/' + id, data );
    }

    updateMoreButtonsSortOrders = (data) => {
        return SC.postCall('updateMoreButtonsSortOrders' , data );
    }
    // handleOptionAtOrderPlacement = (name, value, id,data) => {
    //     let tempValue = !value;
    //     tempValue = tempValue ? 1 : 0;

    //     if (name === 'show_on_all_orders' || name === 'option_at_order_placement') {
    //         var WtOrder = '';
    //         if (name === 'show_on_all_orders'){
    //             WtOrder = 'option_at_order_placement';
    //         }
    //         else{
    //             WtOrder = 'show_on_all_orders';
    //         }
    //         let newData = {
    //             ...data,
    //             [name] : tempValue,
    //             [WtOrder] : 0,
    //         }
    //         this.updateMoreButton(id, newData).then(res=>{
    //             this.pagination.dataCall();
    //         })
    //     }
	// }
    handleOptionAtOrderPlacement = (name, value, id,data) => {
        let tempValue = !value;
        tempValue = tempValue ? 1 : 0;

        if (name === 'show_on_all_orders' || name === 'option_at_order_placement') {
            let newData = {
                ...data,
                [name] : tempValue
            }
            this.updateMoreButton(id, newData).then(res=>{
                this.pagination.dataCall();
            })
        }
	}

    deleteConfirmMoreButton = (id) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <Confirmalertfordelete typeName="Button" description="Are you sure you want to delete this button" onClosePro={onClose} deleteType={() => this.deleteMoreButtonHandler(id)} />
                )
            }
        })
    }

    deleteMoreButtonHandler(id) {
        this.props.deleteMoreButtonCall(id).then(res => {
            this.pagination.dataCall();
        });
    }

    paginationCall = (data) => {
        let sorted = data.sort.split(',');
        let { sort, orderType } = createSortString(sorted);
        return this.props.getAccountMoreButtonsPaginationCall(data.page, data.filter, sort, data.pageSize, orderType, this.state.accountId);

    }
    handleInactiveStatusUpdate = (moreButtonId, e) => {
        this.props.updateMoreButtonStatusCall(moreButtonId, { is_active: e.target.value ? true : false }).then(
            response => {
                this.pagination.dataCall();
            }
        );
    }
    handleActiveStatusUpdate = (moreButtonId, e) => {
        this.props.updateMoreButtonStatusCall(moreButtonId, { is_active: e.target.value ? false : true }).then(
            response => {
                this.pagination.dataCall();
            }
        );
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.is_active !== prevState.is_active) {
            this.pagination.dataCall();
        }

        if (this.props.agency_id !== prevProps.agency_id && this.props.agency_id) {
            // console.log('this.props.agency_id',this.props.agency_id, prevProps.agency_id);
            this.pagination.dataCall();
        }
    }

    render() {
        const { moreButtons, is_active, accountId } = this.state;
        console.log('accountId', accountId);
        const columns = [
            {
                Header: 'Title',
                accessor: 'title',
                className: "action-center",
                width: 120,
                height: 100,

            },
            {
                Header: 'Description',
                accessor: 'description',
                className: "action-center",
            },
            // {
            //     Header: 'File Name',
            //     accessor: 'file_name',
            //     className: "action-center",
            //     // Cell: ({ original }) => {
            //     //     console.log(original, "data");
            //     //     return <span>{original.file_name}</span>
            //     // }
            // },
            {
                Header: 'Shows On Front End Of App',
                accessor: 'show_on_all_orders',
                className: "action-center",
                Cell: ({ original,row }) => {
                    return (

                        <div className="togglePadding">
                            <ToggleButton
                                inactiveLabel={''}
                                activeLabel={''}
                                colors={themeCSSConstants.THUMBICONSTYLE}
                                trackStyle={{ height: '10px', width: '30px' }}
                                thumbStyle={themeCSSConstants.THUMBSTYLE}
                                name={'moreButtonEditModal'}
                                thumbAnimateRange={[0, 15]}
                                value={original.show_on_all_orders}
                                disabled={false}
                                readOnly={true}
                                onToggle={() => this.handleOptionAtOrderPlacement("show_on_all_orders", original.show_on_all_orders, original.id,original)}
                            />
                        </div>
                    )
                },
            },
            {
                Header: 'Show On Back End Of App',
                accessor: 'option_at_order_placement',
                className: "action-center",
                Cell: ({ original, row }) => {
                    // console.log(value,'option cell value')
                    return (
                        <div className="togglePadding">
                            <ToggleButton
                                inactiveLabel={''}
                                activeLabel={''}
                                colors={themeCSSConstants.THUMBICONSTYLE}
                                trackStyle={{ height: '10px', width: '30px' }}
                                thumbStyle={themeCSSConstants.THUMBSTYLE}
                                name={'moreButtonEditModal'}
                                thumbAnimateRange={[0, 15]}
                                // value={moreButtons && moreButtons[row._index].option_at_order_placement}
                                value={original.option_at_order_placement}
                                disabled={false}
                                readOnly={true}
                                // onToggle={() => this.handleOptionAtOrderPlacement("option_at_order_placement", moreButtons && moreButtons[row._index].option_at_order_placement, original.id, original)}
                                onToggle={() => this.handleOptionAtOrderPlacement("option_at_order_placement", original.option_at_order_placement, original.id, original)}
                            />
                        </div>
                    )
                },
            },
            {
                Header: 'File Name/Link',

                accessor: 'id',
                className: "action-center",
                Cell: ({ original }) => {
                    //     console.log(original, "data");
                    return <span>{

                        original.file_name ? original.file_name : original.link

                        }</span>
                }
            },
            {
                Header: 'Expiration Date',
                accessor: 'expiration_date',
                className: "action-center",
                Cell: ({ value }) => {
                    return (
                        value ? dateFormatMy(value) : 'None'
                    )
                },
            },
            {
                Header: 'Status',
                accessor: 'is_active',
                className: "action-center",
                Cell: ({ original, value }) => {
                    return (
                        parseInt(value, 10) == 1 ? 'Active' : 'In-Active'
                    )
                },
            },
            {
                    Header: 'App',
                    accessor: 'id',
                    // className: "action-center",
                    Cell: ({ original }) => {
                        let domain_slug = original.account.domain_slug;
                        return <span>{original.account.label != '' ? original.account.label : (domain_slug != '' ? domain_slug.charAt(0).toUpperCase() + domain_slug.slice(1).toLowerCase() : '')}</span>
                    }
            },
            {
                Header: "Actions",
                accessor: "id",
                className: "action-center",
                Cell: ({ original, value }) => {
                    return (

                        <div>

                            <ButtonToolbar>
                                <OverlayTrigger trigger="focus" placement="bottom" overlay={
                                    <Popover id="tooltip">
                                        <table className="table text-left actions_table">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <button name='moreButtonEditModal' onClick={(e) => { this.showModal(original, e) }} type='button' className="orderDetailsAction editIcon" >Edit</button>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <button onClick={() => this.deleteConfirmMoreButton(value)} className="btn_link text-danger"><i className="fa fa-trash text-danger " /> Delete</button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <button onClick={(e) => this.handleActiveStatusUpdate(value, e)} className="btn_link"><i className="fa fa-check-square-o" /> Active</button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <button onClick={(e) => { this.handleInactiveStatusUpdate(value, e) }} className="btn_link text-danger" ><i className="fa fa-ban text-danger " />Inactive</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </Popover>
                                }>
                                    <span>
                                        <button type='button' className="action-button-size" ><i className="fa fa-bars" /></button>
                                    </span>
                                </OverlayTrigger>
                            </ButtonToolbar>

                        </div>
                    )
                },

                className: "action-center",
                sortable: false,
                excExp: false,
            }
        ]
        return (
                <div className="content">
                    <Grid fluid>
                        <Row>
                        <Col md={12}>
                        <Card
                            ctTableResponsive
                            content={
                            <div>
                                <Grid fluid>
                                    <Row>
                                        <Col md={12}>
                                            <div className="secHeading">
                                                More Button List
                                            </div>
                                        </Col>
                                        {/* <BrandMoreButtonsWeTravel
                                          account_id={accountId}
                                        /> */}
                                        <Col md={12}>
                                            <div className="flexElem flexResponsive" style={{ "alignItems": "flex-start", "justifyContent": "space-between" }}>
                                                <div className="flexElem">
                                                <button type="button" onClick={(e) => this.showModal('', e)} name='moreButtonCreateModal' className='backButton mr pt-sm no_radius pb-sm primary text-xs btn btn-lg btn-info btn btn-lg btn-info  btn btn-default' style={{marginBottom: '10%', marginLeft: '-5%'}} ><i className="fa fa-plus" />Create New ‘More’ Button</button>
                                                </div>

                                            </div>
                                        </Col>

                                        <MoreButtonsPagination
                                            noCols={true}
                                            // addNewBtn={
                                            //     <>
                                            //         <button type="button" onClick={(e) => this.showModal('', e)} name='moreButtonCreateModal' className='backButton mr pt-sm no_radius pb-sm primary text-xs btn btn-lg btn-info btn btn-lg btn-info  btn btn-default' ><i className="fa fa-plus" />Create New ‘More’ Button</button>
                                            //     </>
                                            // }
                                            ref={(p) => this.pagination = p}
                                            showPagination={false}
                                            columns={columns}
                                            pageSize={100}
                                            noDataText='No Item found'
                                            getDataCall={this.paginationCall}
                                            updateSort={this.updateMoreButtonsSortOrders}
                                            // filterView={true}
                                            // filterPlaceHolder={'Button'}
                                            getRowProps={this.getRowProps}
                                        />
                                    </Row>
                                </Grid>
                                {/*Add Brand Modal Start*/}
                                <ThemeModal size='lg' show={this.state.moreButtonEditModal} hide={closeModal.bind(this, 'moreButtonEditModal')} title='Edit More Button'>
                                    <BrandMoreButtonForm getPagination={this.pagination ? this.pagination.dataCall : null} formType='edit' hide={closeModal.bind(this, 'moreButtonEditModal')} data={this.state.moreButtonEditData} agency_id={this.props.agency_id} account_id={accountId}/>
                                </ThemeModal>
                                <ThemeModal size='lg' show={this.state.moreButtonCreateModal} hide={closeModal.bind(this, 'moreButtonCreateModal')} title='Create More Button'>
                                    <BrandMoreButtonForm getPagination={this.pagination ? this.pagination.dataCall : null} hide={closeModal.bind(this, 'moreButtonCreateModal')} formType='add' agency_id={this.props.agency_id} account_id={accountId}/>
                                </ThemeModal>
                                {/*Edit Brand Modal End*/}
                            </div>
                            } />
                        </Col>
                        </Row>
                    </Grid>
                </div>
        )
    }
}
const mapStateToProps = (state) => {
    const { accountMoreButtonsPagination } = state.moreButtonReducer;
    return { accountMoreButtonsPagination }
}

const mapDispatchToProps = dispatch => {
    return {
        getAccountMoreButtonsPaginationCall: (page, filter, sort, pageSize, orderType, id) => { return dispatch(getAccountMoreButtonsPagination(page, filter, sort, pageSize, orderType, id)) },
        updateMoreButtonStatusCall: (moreButtonId, data) => { return dispatch(updateMoreButtonStatus(moreButtonId, data)) },
        deleteMoreButtonCall: (id) => { return dispatch(deleteMoreButton(id)) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BrandMoreButtonsList)
