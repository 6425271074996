import React, { Component } from "react";
import VideoPreview from "../../Upload/VideoPreview";


class TtkAppDemoPage extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<VideoPreview
							src="https://gtvoriginalimages.s3.amazonaws.com/static/ttk-media/Travel-TripKit-App-Demo.mp4" 
							hideVideoControls={true}
							/>
					</div>
				</div>
			</div>
		)
	}
}

export default TtkAppDemoPage;