import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Row, Col,
    OverlayTrigger,
    Popover } from 'react-bootstrap'
import Card from '../../Card/Card'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { dateFormatMy, helperFunctions as HF } from 'helper/helperFunctions';
import { getAllMosaicStyles, updateOrderMosaic } from '../../../redux/actions/mosaicActions'
import { getOrder, getOrderMosaic, orderImages, changeMosaicImage, generateMosaicInstructions, getOrderMaterials, updateOrderSettings, assignUserToOrder, storePMOrderVideo } from '../../../redux/actions/order-actions'
import { updateOrderModerator } from 'redux/actions/user-actions';
import { ThemeFunctions as TF } from 'helper/ThemeFunctions';
import MyImage from 'components/Upload/MyImage';
import Validator from 'simple-react-validator'
import OrderComments from '../Order/OrderComments'
import { objectsConstants } from '../../../redux/constant/objects-constant'
import { baseUrl } from '../../../helper/ApiCall'
import { Status } from '../../../constants/Status'
import { SC } from '../../../helper/ServerCall';
import { getSettingValue, updateSettingsData } from '../../../redux/actions/settings-actions'
import $ from 'jquery';
import { orderService } from '../../../services/order'


class ManageMosaics extends Component {
    constructor(props) {
        super(props)

        this.state = {
            shipping_details: objectsConstants.SHIPPINGDETAIL,
            orderMosaic: {},
            selected_image: '',
            custom_image: '',
            printLetter: false,
            random: 0,
            mosaic_font: '',
            dashboardMozaix:{},
            unassign: {},
            print_btn: false,
            replace_image: 123456 + Math.random() * (99999999 - 123456),
        }
        this.validator = new Validator();
        this.selectChangeHandler = HF.selectChangeHandler.bind(this)
        this.inputChangeHandler = HF.inputChangeHandler.bind(this)
        this.clean = HF.clean.bind(this)
        this.props.getAllMosaicStyles({ selectable: true })
        this.props.getOrder(this.props.match.params.id)
        this.getOrderMozaixManager(this.props.match.params.id).then(
            response => {
                this.setState({
                   dashboardMozaix : response.data
                })
            }
        )
        this.props.getOrderMosaic(this.props.match.params.id)
        this.props.orderImagesCall(this.props.match.params.id, 'working', 'uploaded_images', 1, '', '')
        this.props.getOrderMaterials(this.props.match.params.id).then(
            response => {
                    this.setState({
                                replace_image: 123456 + Math.random() * (99999999 - 123456)
                            })
            }
        )
        this.props.getSettingValueCall('mosaic_font')
    }

    // componentDidMount() {
    //     const replace_image= this.state.replace_image
    //     this.setState({
    //                 replace_image: replace_image + 1
    //             })
    //         }

    componentDidUpdate(prevProps, prevState) {
        if ((this.props.order_details !== prevProps.order_details)) {
            this.setState({ order_details: this.props.order_details });
        }
        if (this.props.shipping_details !== prevProps.shipping_details) {
            this.setState({ shipping_details: this.props.shipping_details });
        }
        if (this.props.settings !== prevProps.settings) {
            this.setState({
                mosaic_font: this.props.settings.value
            })
        }
        if (this.props.pricing_details !== prevProps.pricing_details) {
            this.setState({ pricing_details: this.props.pricing_details });
        }
        if (this.props.production_details !== prevProps.production_details) {
            this.setState({ production_details: this.props.production_details });
        }
        if (this.props.orderMosaic !== prevProps.orderMosaic) {
            this.setState({
                orderMosaic: this.props.orderMosaic
            })
        }
        // if (this.state.dashboardMozaix !== prevState.dashboardMozaix) {
        //     this.setState({
        //         dashboardMozaix: this.state.dashboardMozaix
        //     })
        // }
        if (this.state.replace_image !== prevState.replace_image) {
            this.setState({
                replace_image: this.state.replace_image
            })
        }
        // else if (this.state.replace_image === prevState.replace_image){
        //     this.setState({
        //         replace_image: this.state.replace_image + 1
        //     })
        // }
    }


    updateOrderMosaicStyle = () => {
        if (this.validator.fieldValid('Mosaic Style')) {
            const data = {
                mosaic_style_id: this.state.mosaicStyle
            }
            this.props.updateOrderMosaic(this.props.match.params.id, data)
        } else {
            this.forceUpdate()
            this.validator.showMessageFor('Mosaic Style')
        }
    }

    updateOrderMosaicFont = () => {
        if (this.validator.fieldValid('mosaic_font')) {
            const data = {
                value: this.state.mosaic_font
            }
            this.props.updateSettingsDataCall(null, 'mosaic_font', data)
        } else {
            this.forceUpdate()
            this.validator.showMessageFor('mosaic_font')
        }
    }

    releaseMosaic = () => {
        const data = {
            mosaic_released_at: HF.getFormattedDate(new Date())
        }
        this.props.updateOrderMosaic(this.props.match.params.id, data).then(
            res => {
                this.props.getOrderMosaic(this.props.match.params.id)
            }
        )
    }

    holdMosaic = () => {
        // const data = {
        //     mosaic_hold_at: HF.getFormattedDate(new Date())
        // }
        this.props.updateOrderSettingsCall(this.props.match.params.id, 0, 'on_hold')
        // this.props.updateOrderMosaic(this.props.match.params.id, data).then(
        //     res => {
        //         this.props.getOrderMosaic(this.props.match.params.id)
        //     }
        // )
    }

    nextOrder = (id) => {
        this.props.getOrderMosaic(id)
    }

    changeMosaicImage = (e) => {
        const { name } = e.target;
        if (name === 'working_image') {
            console.log("working_image");
            if (this.validator.fieldValid('selected_image')) {
                const data = {
                    working_image_id: this.state.selected_image,
                    order_id: this.props.match.params.id
                }
                this.props.changeMosaicImage(data)
            } else {
                console.log("working_image force update");
                this.forceUpdate()
                this.validator.showMessageFor('selected_image')
            }
        } else if (name === 'custom_image') {
            console.log("custom_image");
            if (this.validator.fieldValid('custom_image')) {
                const data = new FormData();
                data.append('custom_image', this.state.custom_image)
                data.append('order_id', this.props.match.params.id)
                this.props.changeMosaicImage(data)
            } else {
                console.log("custom_image forceupdate");
                this.forceUpdate()
                this.validator.showMessageFor('custom_image')
            }
        }
    }
    saveVideos = () => {
        // this.props.showLoaderCall(false);
        if (this.validator.fieldValid('selected_image')) {
            const fd = new FormData();
            fd.append('file', this.state.custom_image);
            this.props.storePMOrderVideoCall(fd, this.props.match.params.id, 'projectManager', 'original_mosaic', 'Full video').then(
                response => {
                    // this.props.showLoaderCall(true);
                    this.setState({
                        custom_image: '',
                    },()=>{
                        const random = this.state.random;
                            this.setState({
                                random: random + 1
                            })
                    });
                    this.setState({
                        replace_image: 123456 + Math.random() * (99999999 - 123456)
                    })
                    console.log("custom_image valid");
                    this.props.getAllMosaicStyles({ selectable: true })
                    this.props.getOrder(this.props.match.params.id)
                    this.getOrderMozaixManager(this.props.match.params.id).then(
                        response => {
                            this.setState({
                               dashboardMozaix : response.data
                            })
                        }
                    )
                    this.handleReloadPage();
                    this.props.getOrderMosaic(this.props.match.params.id)
                    this.props.orderImagesCall(this.props.match.params.id, 'working', 'uploaded_images', 1, '', '')
                    this.props.getOrderMaterials(this.props.match.params.id)
                    this.props.getSettingValueCall('mosaic_font')
                    // this.setState({
                    //         custom_image: '',
                    //     });
                }
            )
        } else {
            console.log("custom_image forceupdate");
            this.forceUpdate()
            this.validator.showMessageFor('selected_image')
        }
    }

    fileHandler = (e) => {
        this.setState({
            custom_image: e.target.files[0]
        })
    }

    printLetter = () => {
        this.setState({
            printLetter: true
        })
    }

    generateMosaicInstructions = () => {
        const data = {
            order_id: this.props.match.params.id
        }
        this.props.generateMosaicInstructions(data);
    }

    getOrderMozaixManager(orderId) {
        return SC.getCall('getOrderMozaixManager/' + orderId);
    }

    assignMozaixToOrder = (orderId) => {
        this.props.assignUserToOrderCall(orderId, { userId: this.props.userAdmin.user.id, role: 'mozaix' }).then(response => {
        //   if (hasRole(['admin'])) {
        //     const url = `/Admin/${orderId}/order-edit?orderDetailCode=production&imageCode=mozaix`;
        //     window.open(url);
        //   } else {
        //     const url = `/Admin/${orderId}/order-edit?imageCode=mozaix`;
        //     window.open(url);
        //   }
        // this.props.getOrderMosaic(this.props.match.params.id)
        this.getOrderMozaixManager(this.props.match.params.id).then(
            response => {
                this.setState({
                   dashboardMozaix : response.data
                })
            }
        )
        });
      }

      handleChangeModerator = (type) => {
        const { unassign } = this.state;
        // var data = { [unassign.type === 'edit' ? 'editor_id' : 'producer_id']: -1 }
        var data = '';
            if(unassign.type === 'edit'){
              unassign.type = 'editor_id'
            }else if (unassign.type === 'producer'){
                unassign.type = 'producer_id'
            } else{
                unassign.type = 'mozaix_manager_id'
            }
            data = {[unassign.type] :-1};

        this.props.updateOrderModeratorCall(data, this.props.match.params.id).then(
            res => {
                // this.props.getOrderMosaic(this.props.match.params.id)
                this.getOrderMozaixManager(this.props.match.params.id).then(
                    response => {
                        this.setState({
                           dashboardMozaix : response.data
                        })
                    }
                )
            }
        )
      }

    updateOrderMozaixStatus(orderId, data) {
        return SC.putCall('updateOrderMozaix/' + orderId, data);
    }


    handleUpdateOrderMozaixStatus = orderId => {
        // return false;
        //   console.log(orderId, 'newww');
        // let newData = [...this.state.orderData.data]
        //   console.log(newData[orderId], 'newww');
        this.updateOrderMozaixStatus(orderId).then(
          response => {
            this.getOrderMozaixManager(this.props.match.params.id).then(
                response => {
                    this.setState({
                       dashboardMozaix : response.data
                    },() =>{
                            const url = `/Admin/dashboard/mozaix`;
                            window.open(url);

                    })
                }
            )
            // this.props.getUsersCall(this.props.current_page, '', '', this.state.pageSize, this.state.user_status);
            // this.props.usersPaginationData();
          }
        );
      }

      handleFinalizeBtn = () => {
        const url = `/Admin/dashboard/mozaix`;
          window.open(url);
      }
      handlePrintBtn = () => {
        this.setState({
            print_btn: true
        })
      }
      handleReloadPage = () => {
        const random = this.state.random;
        this.setState({
            random: random + 1
        }, () => {
            let tp = $('#iframe').offset().top + 50;
            $('body, html').animate({scrollTop: tp});
        })
      }
      handleReloadMozaixImg = (e) => {
        e.preventDefault();
        this.props.getOrderMaterials(this.props.match.params.id).then(
            response => {
                this.setState({
                            replace_image: 123456 + Math.random() * (99999999 - 123456)
                        })
        }
    )
        orderService.mozaixProcessJobRecCall(this.props.match.params.id).then((response) => {
            window.location.reload(false);
            let tp = $('#mozaix_img').offset().top + 50;
            $('body, html').animate({scrollTop: tp});
        })


      }



    render() {
        // console.log(this.props.userAdmin,'ordersdata');
        const { mosaicStyles, order_details, pricing_details, imagesData, orderMaterials, settings } = this.props;
        const { shipping_details, orderMosaic, dashboardMozaix, print_btn, replace_image } = this.state
        const orderImageOptions = imagesData.filter(image => image.image_format.extension === 'jpg').map(item => {
            return {
                label: item.image_versions.original_file_name,
                value: item.id
            }
        })
        // let is_disabled = false;
        // if (order_details && order_details.status.title === Status.COMPLETED){
        //     console.log("truueee");
        //     is_disabled = true;
        // } else {
        //     // console.log("falseee");c
        //     is_disabled = false;
        // }
        // console.log("is_disabled", is_disabled);
        return (
            <div className='content'>
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                content={
                                    <React.Fragment>
                                        <div className='secHeading'>Manage Mozaix</div>
                                        {
                                            order_details.settings.on_hold.value === '1' &&
                                            <h4 className='text-warning font-weight-bold'>Mozaix Hold</h4>
                                        }
                                        <div className='container-fluid'>
                                            <div className='row flexElem' style={{ alignItems: 'start' }}>
                                                <div className='col-md-3'>
                                                    <div className='orderContent'>
                                                        <div className='flexElem' style={{ "flexWrap": "wrap", "justifyContent": "space-between" }}>
                                                            <div className='orderDetailsHeader'>
                                                                <span>Order Information</span>
                                                            </div>
                                                            <div style={{ width: '100%', border: 'none', borderTop: '1px solid #ccc' }} className="orderDetailsBox">
                                                                <table className='table orderTable'>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Barcode</td>
                                                                            <td>
                                                                                <Link to={'/Admin/' + this.props.match.params.id + '/order-edit'} className="blue_link barcode" target="_blank" >
                                                                                    <strong>{order_details.barcode}</strong>
                                                                                </Link>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Status</td>
                                                                            <td>
                                                                                {
                                                                                    '20x30 PhotoMozaix' in pricing_details.order_items && pricing_details.order_items['20x30 PhotoMozaix'].quantity === 0 ?
                                                                                        <strong>No Mozaix Ordered</strong>
                                                                                        :
                                                                                        orderMosaic ?
                                                                                            <React.Fragment>
                                                                                                <strong>Mozaix Created </strong>
                                                                                                {
                                                                                                    dashboardMozaix && dashboardMozaix.is_mozaix_printed ?
                                                                                                        <React.Fragment>
                                                                                                            <strong>Mozaix Has Printed </strong>
                                                                                                            <span>on {dashboardMozaix.updated_at ? dateFormatMy(dashboardMozaix.updated_at) + ' ' : ''}
                                                                                                             by {dashboardMozaix.mozaix_manager ? dashboardMozaix.mozaix_manager.name : ''}</span>
                                                                                                        </React.Fragment>
                                                                                                        :
                                                                                                            <strong>Mozaix Needs Printed</strong>
                                                                                                    // orderMosaic.mosaic_released_at ?
                                                                                                    //     <strong> Mozaix Released</strong>
                                                                                                    //     :
                                                                                                    //     <strong> Mozaix Not Released</strong>
                                                                                                }
                                                                                            </React.Fragment>
                                                                                            :
                                                                                            <strong>No Mozaix Created</strong>
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                        {/* <tr>
                                                                            <td style={{ verticalAlign: 'top' }}>Mozaix Style</td>
                                                                            <td>
                                                                                <Select
                                                                                    onChange={this.selectChangeHandler}
                                                                                    name='orderMosaic.mosaic_style_id'
                                                                                    options={mosaicStyles}
                                                                                    value={mosaicStyles.filter(item => {
                                                                                        if (orderMosaic) {
                                                                                            if (item.value === orderMosaic.mosaic_style_id) {
                                                                                                return item
                                                                                            }
                                                                                        }
                                                                                        return null;
                                                                                    })}
                                                                                />
                                                                                <button onClick={this.updateOrderMosaicStyle} className='customBtn btn btn-primary btn btn-default mt-md mb-md'>Set</button>
                                                                                {this.validator.message('Mosaic Style', this.state.mosaicStyle, 'required')}
                                                                            </td>
                                                                        </tr> */}
                                                                        <tr>
                                                                            <td>20x30 Quantity</td>
                                                                            <td>
                                                                                {
                                                                                    '20x30 PhotoMozaix' in pricing_details.order_items &&
                                                                                    <strong>{pricing_details.order_items['20x30 PhotoMozaix'].quantity}</strong>
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Video Producer</td>
                                                                            {
                                                                                order_details.producer &&
                                                                                <td><strong>{order_details.producer.name}</strong></td>
                                                                            }
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Photo Count</td>
                                                                            <td><strong>{order_details.working_images_count}</strong></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='orderContent'>
                                                        <div className='flexElem' style={{ "flexWrap": "wrap", "justifyContent": "space-between" }}>
                                                            <div className='orderDetailsHeader'>
                                                                <span>Group Information</span>
                                                            </div>
                                                            <div style={{ width: '100%', border: 'none', borderTop: '1px solid #ccc' }} className="orderDetailsBox">
                                                                <table className='table orderTable'>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Group Name</td>
                                                                            <td>{order_details.group_name}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Destination</td>
                                                                            <td>{order_details.destinationText}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Travel Year</td>
                                                                            <td>{new Date(order_details.order_dates.departure_date.value).getFullYear()}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Tour Operator</td>
                                                                            <td>{order_details.agency.name}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className='orderContent'> */}
                                                        {/* <div className='flexElem' style={{ "flexWrap": "wrap", "justifyContent": "space-between" }}>
                                                            <div className='orderDetailsHeader'>
                                                                <span>Shipping Information</span>
                                                            </div>
                                                            <div style={{ width: '100%', border: 'none', borderTop: '1px solid #ccc' }} className="orderDetailsBox">
                                                                <table className='table orderTable'>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Contact Name</td>
                                                                            <td>{order_details.group_leader_contact.name}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Address</td>
                                                                            {
                                                                             (shipping_details.video_shipping_address.country.title == 'France')
                                                                             ?
                                                                                <>
                                                                                    <p>&nbsp;</p>
                                                                                    {/* <p>&nbsp;</p> */}
                                                                                    {/* <p>{shipping_details.video_shipping_address.street_address_1,

                                                                                            shipping_details.video_shipping_address.street_address_2
                                                                                            ?
                                                                                                shipping_details.video_shipping_address.street_address_2
                                                                                            : ''
                                                                                            }
                                                                                    </p>
                                                                                    <p>{shipping_details.video_shipping_address.zipcode ? shipping_details.video_shipping_address.zipcode : ''} {shipping_details.video_shipping_address.city}{shipping_details.video_shipping_address.country.title ? ', '+shipping_details.video_shipping_address.country.title : ''}</p> */}
                                                                                    {/* <p>{shipping_details.video_shipping_address.country.title}</p> */}
                                                                                    {/* <td>{shipping_details.video_shipping_address.street_address_1}</td>
                                                                                    <td>{shipping_details.video_shipping_address.zipcode} {shipping_details.video_shipping_address.city}</td>
                                                                                    <td>{shipping_details.video_shipping_address.country.title}</td> */}
                                                                                {/* </> */}
                                                                            {/* : */}
                                                                                {/* <td>{shipping_details.video_shipping_address.street_address_1 } {shipping_details.street_address_2 ? shipping_details.street_address_2 : ''}, {shipping_details.video_shipping_address.state.code} {shipping_details.video_shipping_address.zipcode ? shipping_details.video_shipping_address.zipcode : ''}</td> */}

                                                                            {/* } */}
                                                                        {/* </tr> */}
                                                                    {/* </tbody> */}
                                                                {/* </table> */}
                                                            {/* </div> */}
                                                        {/* </div> */}
                                                    {/* </div> */}
                                                    <div className='mb-md'>
                                                        {
                                                            (orderMosaic && orderMosaic.mosaic_released_at === null && order_details.status.title === Status.COMPLETED) &&
                                                            <button name='mosaic_released_at' onClick={this.releaseMosaic} className='text-uppercase mr-md customBtn btn btn-primary btn btn-default'>Release</button>
                                                        }
                                                        {
                                                            (orderMosaic && orderMosaic.next_order_id && order_details.status.title === Status.COMPLETED) &&
                                                            <button onClick={() => { this.nextOrder(orderMosaic.next_order_id) }} className='text-uppercase customBtn btn btn-primary btn btn-default'>Next</button>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    {
                                                        parseInt(order_details.settings.finalize_by_qa.value, 10) ?
                                                            <React.Fragment>
                                                                <div className='orderContent' style={{ border: 'none' }}>
                                                                    <div className='flexElem' style={{ "flexWrap": "wrap", "justifyContent": "space-between" }}>
                                                                        <div style={{ width: '100%', border: 'none' }} className="orderDetailsBox">
                                                                            <div className='mt-md'>
                                                                                <strong>20x30 Mozaix</strong>
                                                                                    <a href={orderMaterials.mosaic_image_path} onClick={this.handlePrintBtn} className='text-uppercase ml-md customBtn btn btn-primary btn btn-default text-white' target="_blank" >View Mozaix</a>
                                                                            </div>
                                                                            {/* <div className='mt-md'>
                                                                    <strong>Letter</strong>
                                                                    <form className='display_ib' method='post' target='_blank' action={`${baseUrl}generateMosaicLetter`}>
                                                                        <input name='order_id' type="hidden" value={this.props.match.params.id} />
                                                                    </form>
                                                                    <button onClick={this.printLetter} className='text-uppercase ml-md customBtn btn btn-primary btn btn-default'>print Letter</button>
                                                                </div> */}
                                                                            {
                                                                                orderMaterials.mosaic_image_path_preview &&
                                                                                <img id="mozaix_img" style={{ width: '100%' }} className='mt-md' src={orderMaterials.mosaic_image_path_preview.concat(`?v=${replace_image}`)} alt="mosaic image" />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='mt-md' id="iframe" >
                                                                    <iframe
                                                                        className='w-100'
                                                                        style={{ maxHeight: '500px', background: `url('${HF.s3StaticPath('img/main_loader.gif')}') no-repeat center` }}
                                                                        height='1000px'
                                                                        src={`${baseUrl}generateMosaicLetter?order_id=${this.props.match.params.id}`}
                                                                        frameborder="0"
                                                                        title="generate mosaic letter"
                                                                        key={this.state.random}
                                                                    ></iframe>
                                                                </div>
                                                            </React.Fragment>
                                                            : ''
                                                    }
                                                </div>

                                                <div className='col-md-3'>
                                                <div className='orderContent'>
                                                        <div className='flexElem' style={{ "flexWrap": "wrap", "justifyContent": "space-between" }}>
                                                            <div className='orderDetailsHeader'>
                                                                <span>Mozaix Manager</span>
                                                            </div>
                                                            <div style={{ width: '100%', border: 'none', borderTop: '1px solid #ccc' }} className="orderDetailsBox no-padding">
                                                                {/* <OrderComments hideTitle={true} showOnly={['Production']} modelId={this.props.match.params.id} order_details={order_details} withUuid={false} addExceptTab={[]} /> */}
                                                                <div className="flexElem" style={{ marginLeft: "4px"}}>
                                                                    {
                                                                        dashboardMozaix && dashboardMozaix.mozaix_manager_id
                                                                            ?
                                                                            dashboardMozaix && dashboardMozaix.mozaix_manager.image
                                                                                ?
                                                                                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{dashboardMozaix && dashboardMozaix.mozaix_manager.name}</Popover>}>
                                                                                    <button className="mt-md mb-md" ><MyImage alt={dashboardMozaix && dashboardMozaix.mozaix_manager.image.title} className="initialsLogo" src={dashboardMozaix && dashboardMozaix.mozaix_manager.image.preview} /></button>
                                                                                </OverlayTrigger>
                                                                                :
                                                                                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{dashboardMozaix && dashboardMozaix.mozaix_manager.name}</Popover>}>
                                                                                    <button className="mt-md mb-md" > {TF.initialsLogo(dashboardMozaix && dashboardMozaix.mozaix_manager.bgColor, dashboardMozaix && dashboardMozaix.mozaix_manager.name)}</button>
                                                                                </OverlayTrigger>
                                                                            :
                                                                            <div>
                                                                                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{'Order assign me for mosaics process'}</Popover>}>
                                                                                        <button className="orderDetailsAction assignIcon mt-md mb-md ml-md" onClick={() => this.assignMozaixToOrder(dashboardMozaix && dashboardMozaix.id)} >&nbsp;</button>
                                                                                </OverlayTrigger>
                                                                                <label className="assign-label">
                                                                                    Assign this to me
                                                                                </label>
                                                                           </div>
                                                                    }

                                                                        {
                                                                         dashboardMozaix && dashboardMozaix.mozaix_manager_id && dashboardMozaix.is_mozaix_printed == 0

                                                                            ?<>
                                                                                {/* <div>
                                                                                    <button onClick={() => this.handleChangeModerator('mozaix')} className="customBtn btn ml-sm btn-primary btn btn-default mt-md mb-md">Unassign</button>
                                                                                </div> */}
                                                                                    {/* <button onClick={() => this.handleUpdateOrderMozaixStatus(dashboardMozaix.id)} className="customBtn btn ml-sm btn-primary btn btn-default mt-md mb-md">Finalize</button> */}

                                                                                    {
                                                                                    print_btn
                                                                                    ?
                                                                                        <div>
                                                                                            {/* <button onClick={this.handlePrintBtn} className="customBtn btn ml-sm btn-primary btn btn-default mt-md mb-md">Finalize</button> */}
                                                                                            <button onClick={() => this.handleUpdateOrderMozaixStatus(dashboardMozaix.id)} className="customBtn btn ml-sm btn-primary btn btn-default mt-md mb-md">Finalize</button>
                                                                                        </div>
                                                                                    :
                                                                                        ''
                                                                                    }

                                                                                </>
                                                                            :
                                                                            dashboardMozaix && dashboardMozaix.is_mozaix_printed == 1

                                                                               ?
                                                                                <div>
                                                                                    {/* <button onClick={() => this.handleChangeModerator('mozaix')} className="customBtn btn ml-sm btn-primary btn btn-default mt-md mb-md">Unassign</button> */}
                                                                                    {/* <button disabled className="customBtn btn ml-sm btn-primary btn btn-default mt-md mb-md">Printed</button> */}
                                                                                    <table className='table orderTable'>
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    Printed:
                                                                                                </td>
                                                                                                <td>
                                                                                                    {dashboardMozaix.mozaix_manager ? dashboardMozaix.mozaix_manager.name : '' }
                                                                                                    <br/>
                                                                                                    {dashboardMozaix.updated_at ? dateFormatMy(dashboardMozaix.updated_at) : ''}
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                               :
                                                                               ''
                                                                        }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        order_details.agency.mosaic_letter_text &&
                                                        <div className='orderContent'>
                                                            <div className='flexElem' style={{ "flexWrap": "wrap", "justifyContent": "space-between" }}>
                                                                <div className='orderDetailsHeader'>
                                                                    <span>Custom Letter Text</span>
                                                                </div>
                                                                <div style={{ width: '100%', border: 'none', borderTop: '1px solid #ccc', padding: '5px' }} className="orderDetailsBox">
                                                                    <p className='mb-0'>{order_details.agency.mosaic_letter_text}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                    <div className='orderContent'>
                                                        <div className='flexElem' style={{ "flexWrap": "wrap", "justifyContent": "space-between" }}>
                                                            <div className='orderDetailsHeader'>
                                                                <span>Comments</span>
                                                            </div>
                                                            <div style={{ width: '100%', border: 'none', borderTop: '1px solid #ccc' }} className="orderDetailsBox no-padding">
                                                                <OrderComments hideTitle={true} showOnly={['Production', 'DVD_MozaixShip']} modelId={this.props.match.params.id} order_details={order_details} withUuid={false} addExceptTab={[]} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    {
                                                        // parseInt(order_details.settings.finalize_by_qa.value, 10)
                                                        true ?
                                                            <React.Fragment>
                                                                <div className='orderContent'>
                                                                    <div className='flexElem' style={{ "flexWrap": "wrap", "justifyContent": "space-between" }}>
                                                                        <div className='orderDetailsHeader'>
                                                                            <span>Order Materials</span>
                                                                        </div>
                                                                        <div style={{ width: '100%', border: 'none', borderTop: '1px solid #ccc' }} className="orderDetailsBox">
                                                                            <table className='table orderTable'>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>Mozaix Image</td>
                                                                                        {
                                                                                            orderMaterials.mosaic_image &&
                                                                                            <td><a target='_blank' href={orderMaterials.original_mosaic_image_path} className='text-uppercase mr-md customBtn btn btn-primary btn btn-default text-white' >View</a></td>
                                                                                        }
                                                                                        {/* <td><button to='/' className='text-uppercase mr-md customBtn btn btn-primary btn btn-default' >Remake</button></td> */}
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td colSpan={2}>
                                                                                            <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">PLEASE NOTE: FIle dimensions should be 5985x4005 Maximum file size for uploads is 40 MBs</Popover>}>
                                                                                                 <input key={this.state.random} style={{width: '100%', maxWidth: '300'}} accept={"image/jpeg"} onChange={(e) => {this.fileHandler(e)}} type="file" file={this.state.custom_image} name="selected_image" id=""  />
                                                                                            </OverlayTrigger>
                                                                                            {this.validator.message('selected_image', this.state.custom_image, 'required')}
                                                                                        </td>
                                                                                        <td colSpan={1}>
                                                                                            <button onClick={this.saveVideos} className='text-uppercase mr-md customBtn btn btn-primary btn btn-default' >Remake</button>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr><td colspan="3"><hr /></td></tr>
                                                                                    <tr>
                                                                                        <td>20x30 Mozaix</td>
                                                                                        {
                                                                                            orderMaterials.mosaic_image &&
                                                                                            <td><a href={orderMaterials.mosaic_image_path} className='text-uppercase mr-md customBtn btn btn-primary btn btn-default text-white' target='_blank'>View</a></td>
                                                                                        }
                                                                                        <td><button type='button' onClick={this.handleReloadMozaixImg} className='text-uppercase mr-md customBtn btn btn-primary btn btn-default' >Remake</button></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Letter</td>
                                                                                        <td><a target='_blank' href={`${baseUrl}generateMosaicLetter?order_id=${this.props.match.params.id}`} className='text-uppercase mr-md customBtn btn btn-primary btn btn-default text-white' >View</a></td>
                                                                                        <td>
                                                                                            <button type='button' onClick={(e) => {this.handleReloadPage(e)}} className='text-uppercase mr-md customBtn btn btn-primary btn btn-default' >remake</button>
                                                                                        </td>
                                                                                    </tr>
                                                                                    {/* <tr>
                                                                                        <td>Instructions</td>
                                                                                        {
                                                                                            orderMaterials.instructions_file &&
                                                                                            <td><Link target='_blank' to={orderMaterials.instructions_file_path} className='blue_link'>View</Link></td>
                                                                                        }
                                                                                        <td>
                                                                                            <button onClick={this.generateMosaicInstructions} className='text-uppercase mr-md customBtn btn btn-primary btn btn-default'>Remake</button>
                                                                                        </td>
                                                                                    </tr> */}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                            :
                                                            ''
                                                    }
                                                    {
                                                        order_details.settings.on_hold.value === '1' &&
                                                        <h4 className='text-warning font-weight-bold'>Mozaix Hold</h4>
                                                    }
                                                    {/* {
                                                        (shipping_details.mosaic_ship_status === null && parseInt(order_details.settings.on_hold.value, 10) === 0) &&
                                                        <div>
                                                            <button onClick={this.holdMosaic} className='text-uppercase mt-md customBtn btn btn-primary btn btn-default'>Hold</button>
                                                        </div>
                                                    } */}
                                                    {/* <div className='mt-md'>
                                                        <p><strong>Order Image Selection</strong></p>
                                                        <p>
                                                            Select an order image from the menu below, then click "Submit".
                                                            Once the Mosaic has been remade, click on the "remake" linkbeside the mosaic size you would like to recreate.
                                                        </p>
                                                        <Select
                                                            onChange={this.selectChangeHandler}
                                                            name='selected_image'
                                                            options={orderImageOptions}
                                                        />
                                                        {this.validator.message('selected_image', this.state.selected_image, 'required')}
                                                        <button onClick={this.changeMosaicImage} name='working_image' className='text-uppercase mt-md customBtn btn btn-primary btn btn-default'>submit</button>
                                                    </div> */}
                                                    {
                                                        orderMosaic &&
                                                        <div className='mt-md'>
                                                            <p>
                                                                Use this to change the font size on the mozaix. After you change it,
                                                                you will have to remake the 20x30 and the letter.
                                                                The higher the number the smaller the font will get. I recommend
                                                                changing it up or down 5 to start with.
                                                                The standard number is 90. Do not forget to change it back to 90!
                                                        </p>
                                                            {
                                                                settings &&
                                                                <>
                                                                <label className="assign-label"> Mozaix Font </label>
                                                                <input type="text" name="mosaic_font" className='form-control' value={this.state.mosaic_font} onChange={(e) => this.inputChangeHandler(e, /^\d{0,3}$/)} />
                                                                </>
                                                            }
                                                            <button onClick={this.updateOrderMosaicFont} className='text-uppercase mt-md mb-md customBtn btn btn-primary btn btn-default'>Change Mozaix Font</button>
                                                            {this.validator.message('mosaic_font', this.state.mosaic_font, 'required')}
                                                        </div>
                                                    }
                                                    {/* <div className='orderContent'>
                                                        <div className='flexElem' style={{ "flexWrap": "wrap", "justifyContent": "space-between" }}>
                                                            <div className='orderDetailsHeader'>
                                                                <span>Replace Mozaix</span>
                                                            </div>
                                                            <div style={{ width: '100%', border: 'none', borderTop: '1px solid #ccc' }} className="orderDetailsBox">
                                                                <table className='table orderTable'>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <input accept={"image/jpeg"} onChange={this.fileHandler} type="file" file={this.state.custom_image} name="selected_image" id="" />
                                                                                {this.validator.message('selected_image', this.state.custom_image, 'required')}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <button onClick={this.saveVideos} className='customBtn btn btn-primary btn btn-default'>Send File</button>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <p>PLEASE NOTE: FIle dimensions should be 5985x4005 Maximum file size for uploads is 40 MBs</p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { mosaicStyles } = state.mosaicReducer
    const { order_details, shipping_details, pricing_details, production_details, orderMosaic, imagesData, orderMaterials } = state.ordersReducer;
    const { settings } = state.settingsReducer;
    const { userAdmin } = state.adminReducer;
    return {
        mosaicStyles,
        order_details,
        shipping_details,
        pricing_details,
        production_details,
        orderMosaic,
        imagesData,
        orderMaterials,
        settings,
        userAdmin
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAllMosaicStyles: (data) => { dispatch(getAllMosaicStyles(data)) },
        getOrder: (id) => { return dispatch(getOrder(id)) },
        updateOrderMosaic: (id, data) => { return dispatch(updateOrderMosaic(id, data)) },
        getOrderMosaic: (id) => { return dispatch(getOrderMosaic(id)) },
        orderImagesCall: (orderId, version_type, tabName, page, filter, sort, pageSize, userRole) => { dispatch(orderImages(orderId, version_type, tabName, page, filter, sort, pageSize, userRole)) },
        changeMosaicImage: (data) => { dispatch(changeMosaicImage(data)) },
        generateMosaicInstructions: (data) => { dispatch(generateMosaicInstructions(data)) },
        getOrderMaterials: (id) => { return dispatch(getOrderMaterials(id)) },
        updateOrderSettingsCall: (orderId, value, key) => { dispatch(updateOrderSettings(orderId, value, key)) },
        getSettingValueCall: (key, stateName) => { dispatch(getSettingValue(key, stateName)) },
        updateSettingsDataCall: (model_id, key, settingData) => { return dispatch(updateSettingsData(model_id, key, settingData)) },
        assignUserToOrderCall: (orderId, data) => { return dispatch(assignUserToOrder(orderId, data)) },
        updateOrderModeratorCall: (orderUserData, orderId) => { return dispatch(updateOrderModerator(orderUserData, orderId)) },
        storePMOrderVideoCall: (videoData, orderId, folder, key, loaderTitle) => {
            return dispatch(storePMOrderVideo(videoData, orderId, folder, key, loaderTitle))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageMosaics)
