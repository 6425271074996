import React, {Component} from "react";
import {connect} from 'react-redux';
import {updateOrCreateSettingsData} from 'redux/actions/settings-actions';
import {orderService} from 'services/order';
import {SC} from "helper/ServerCall";
import {makeTrackingUrl} from "./../../../helper/helperFunctions";


const queryString = require('query-string');


const moment = window.moment;

class CSRAutomatedCheckInEmailPage extends Component {
    constructor(props) {
        super(props);
        const parsed = queryString.parse(window.location.search);
        this.state = {
            order_id: parsed.order_id,
            flag: parsed.flag,
            shippingData: {
                shipping_method: {
                    tracking_url: ''
                },
                address: {
                    country: {
                        code: ''
                    }
                },
                tracking_number: ''
            },
            group_name: ''
        };
        // console.log('state', this.state);
    }

    componentDidMount() {
        const {order_id, flag} = this.state;
        SC.getCall(`order/${order_id}`).then(res => {
            this.setState({
                group_name: res.order.order_details.group_name
            })
        });
        if (order_id && flag === 'grayed_out') {
            this.props.updateOrCreateSettingsDataCall(order_id, 'grayed_out', {'value': 1})
        }

        if (order_id && flag === 'showShippingAndTracking') {
            orderService.getOrderShippingDetail(order_id).then(response => {
                this.setState({shippingData: response.data});
            });
            // this.props.getOrderShippingDetail(order_id);
        }
    }

    render() {
        const {order_id, flag, shippingData, group_name} = this.state;
        let trackingUrl ='';
        if(shippingData.shipping_method.tracking_url){
            trackingUrl = makeTrackingUrl(shippingData.shipping_method.tracking_url, shippingData.tracking_number, shippingData.address.country.code)
        }
        return (
            <div>
                {
                    (order_id && flag === 'grayed_out') &&
                    <React.Fragment>
                        <section id="since" className="section bg_alter">
                            <div className="flexElem alignCenter justifyCenter">
                                <div className="flexElem alignCenter justifyCenter mt-xxxlg mb-xxxlg" style={{backgroundColor: '#eaeef6', minHeight: '525px', width: '100%',
                                    maxWidth: '800px'}}>
                                    <div>
                                        <div className="col-md-12">
                                            <h1 className="blue_color mb-lg pl-lg pr-lg font-weight-medium font40">
                                                We're so happy to hear you received your materials and
                                                are ready to start using the Travel TripKit App.
                                            </h1>
                                        </div>

                                        <div className="col-md-12">
                                            <p className="text_24 mt-md smaller pl-lg pr-lg">
                                            Be sure to email or print the traveler app card and ask your travelers to download and login to the app. Everyone needs to 'ALLOW" access to their gallery, notifications and location services to assure the app works properly. The app is private and the mapping only turns on the morning of your departure date and turns off at midnight the day of your return.
                                            Have a wonderful, memorable trip!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </React.Fragment>
                }

                {
                    (order_id && flag === 'showShippingAndTracking') &&
                    <React.Fragment>
                        <section id="since" className="section bg_alter">
                            <div className="container mt-xxxlg mb-xxxlg" style={{backgroundColor: '#eaeef6'}}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="p-md pb-xlg pt-xlg">
                                            <table className="infoPage table blank">
                                                <tbody>
                                                <tr>
                                                    <td><span
                                                        className="text_20 font-weight-medium">Group Name:</span>
                                                    </td>
                                                    <td><span
                                                        className="text_20 font-weight-medium blue_color">{group_name}</span>
                                                    </td>
                                                </tr>
                                                {
                                                    'process_date' in shippingData &&
                                                    <tr>
                                                        <td><span
                                                            className="text_20 font-weight-medium">Shipped Date:</span>
                                                        </td>
                                                        <td><span
                                                            className="text_20 font-weight-medium blue_color">{moment(shippingData.process_date).format('MMM D, Y')}</span>
                                                        </td>
                                                    </tr>
                                                }
                                                <tr>
                                                    <td><span
                                                        className="text_20 font-weight-medium">Tracking Number:</span>
                                                    </td>
                                                    <td><span
                                                        className="text_20 font-weight-medium blue_color"><a target="_blank"
                                                        href={trackingUrl}>{shippingData.tracking_number}</a></span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2"><span className="text_18 font-weight-medium green_color">Still have questions about your shipment or your order?</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><span
                                                        className="text_20 font-weight-medium">Please call us at:</span>
                                                    </td>
                                                    <td><span
                                                        className="text_20 font-weight-medium blue_color">1-888-533-7637 x213</span>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </React.Fragment>
                }

            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return ({
        updateOrCreateSettingsDataCall: (model_id, key, settingsData) => {
            dispatch(updateOrCreateSettingsData(model_id, key, settingsData))
        },//example function
    });
};

function mapStateToProps(state) {
    // const { exampleConstant } = state.exampleReducer;

    return {
        // exampleConstant
    };
}


const CSRAutomatedCheckInEmail = connect(mapStateToProps, mapDispatchToProps)(CSRAutomatedCheckInEmailPage);
export default CSRAutomatedCheckInEmail;
