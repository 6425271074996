import React, { Component } from 'react'
import { connect } from 'react-redux'
import User from 'helper/User';
import { getOrder, updateTitlesMusic, updateOrderSettings } from 'redux/actions/order-actions';
import { Dashboard } from 'layouts/Frontend/Dashboard/Dashboard';
import { objectsConstants } from 'redux/constant/objects-constant';
import { getCountries } from 'redux/actions/settings-actions';
import Validator from 'simple-react-validator'
import AgencyLogo from 'components/ThemeComponents/AgencyLogo';
import ShippingDetails from 'components/FrontEnd/Order/OrderForm/ShippingDetails';
import VideoTitles from 'components/FrontEnd/Order/OrderForm/VideoTitles';
import { toggleModal, inputChangeHandler, s3StaticPath } from 'helper/helperFunctions';
import ThemeModal from 'components/ThemeComponents/ThemeModal';
import SampleMusic from 'components/FrontEnd/Order/SampleMusic';

class OrderOptions extends Component {
    constructor(props) {
        super(props)

        this.state = {
            order_details: objectsConstants.ORDERDETAIL,
            production_details: this.props.production_details,
            shipping_details: objectsConstants.SHIPPINGDETAIL,
            favOption: 'desktop_app',
            favOption2: '',
            music_type: 'destination_based',
            photo_admin_approval: false,
            allow_upload: false,
            orderTitles: {},
            videoPaused: true,
            showErrorMessages: false,
            sampleMusicModal: false,
            submitComment: false
        }
        this.props.getOrderCall(User.order('id'));
        this.Validator = new Validator();
        this.video = React.createRef();
        this.toggleModal = toggleModal.bind(this);
        this.inputChangeHandler = inputChangeHandler.bind(this);
    }

    getState = (state, container) => {
        this.setState(prevState => ({
            ...prevState,
            [container]: state
        }))
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.order_details !== prevProps.order_details) {
            this.setState({
                order_details: this.props.order_details
            })
        }
        if (this.props.production_details !== prevProps.production_details) {
            this.setState({
                production_details: this.props.production_details
            })
        }
        if (this.props.shipping_details !== prevProps.shipping_details) {
            this.setState({
                shipping_details: this.props.shipping_details
            })
        }
    }

    radioChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            if (this.state.favOption === 'not_sure') {
                this.setState({
                    favOption2: ''
                })
            }
        });
    }

    printOrderOptions = () => {
        if (this.Validator.allValid()) {
            const data = {
                order_details: this.state.order_details,
                shipping_details: this.state.shipping_details,
                production_details: this.state.production_details
            }
            this.setState({
                submitComment: true
            }, () => {
                this.setState({
                    submitComment: false
                })
            })
            this.props.updateOrderSettingsCall(User.order('id'), 0, 'online_options_page_submitted');
            this.props.updateTitlesMusic(data, User.order('id')).then(
                res => {
                    if (res) {
                        localStorage.setItem('favOption2', this.state.favOption2);
                        localStorage.setItem('favOption', this.state.favOption);
                        window.open(`/print-order-options/${User.getProperty('order.id')}`)
                    }
                },
                err => {

                }
            );
        } else {
            this.Validator.showMessages();
            this.setState(prevState => ({
                ...prevState,
                showErrorMessages: this.Validator.messagesShown
            }))
        }
    }

    toggleVideo = () => {
        this.setState(prevState => ({
            videoPaused: !prevState.videoPaused
        }), () => {
            if (!this.state.videoPaused) {
                this.video.current.play()
            }
        })
    }

    render() {
        const { order_details, shipping_details, production_details } = this.state;
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="panel_heading userLogin_heading">Select Your Options!</h2>
                    </div>
                </div>

                <div className="row mb-xlg">
                    <div className="col-md-12">
                        <div className="flexElem respFlex alignCenter spaceBetween">
                            <div className="text_16" style={{ width: "100%", maxWidth: "660px" }}>
                                Arrangements have been make for a fun keepsake video to be produced for your travelers to enjoy using the photos taken on your trip.
                                <br /><strong>No additional payment is required!</strong>
                            </div>

                            <div style={{ maxWidth: '230px' }}>
                                <AgencyLogo />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="form_tile">
                            <div className="form_tile_heading">
                                <img src={s3StaticPath('img/order_information.png')} alt="General Information" />
                                <span>Order Information</span>
                            </div>

                            <div className="form_tile_content orderInformationBox">
                                <div className="flexElem respFlex form_tile_row">
                                    <div className="col-md-6">
                                        <div className="flexElem alignCenter spaceBetween form_tile_row">
                                            <div className="col-md-4">
                                                Group Name:
                                            </div>
                                            <div className="col-md-8 text-primary">
                                                {order_details.group_name}
                                            </div>
                                        </div>
                                        <div className="flexElem alignCenter spaceBetween form_tile_row">
                                            <div className="col-md-4">
                                                Group Leader/Photo Admin:
                                            </div>
                                            <div className="col-md-8 text-primary">
                                                {order_details.group_leader_contact.name}
                                            </div>
                                        </div>
                                        <div className="flexElem alignCenter spaceBetween form_tile_row">
                                            <div className="col-md-4">
                                                Destination:
                                            </div>
                                            <div className="col-md-8 text-primary">
                                                {order_details.destinationText}
                                            </div>
                                        </div>
                                        <div className="flexElem alignCenter spaceBetween form_tile_row">
                                            <div className="col-md-4">
                                                Trip Dates:
                                            </div>
                                            <div className="col-md-8 text-primary">
                                                {new Date(order_details.order_dates.departure_date.value).toDateString() + ' - ' + new Date(order_details.order_dates.return_date.value).toDateString()}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div>
                                            <strong className="text_16">Photo Admin Login</strong>
                                        </div>

                                        <div className="text-primary text_14 mt-sm">Photo Admin Login is for GROUP LEADER ONLY!</div>

                                        <div className="flexElem alignCenter spaceBetween mt-md">
                                            <div className="flexElem alignCenter">
                                                <div><img src={s3StaticPath('img/user_icon_round.png')} alt="Username Icon" /></div>
                                                <div className="ml-sm">Username:</div>
                                            </div>
                                            <div className="text-primary">
                                                {order_details.photo_admin_username}
                                            </div>
                                        </div>
                                        <div className="flexElem alignCenter spaceBetween mt-sm">
                                            <div className="flexElem alignCenter">
                                                <div><img src={s3StaticPath('img/password_icon_round.png')} alt="Username Icon" /></div>
                                                <div className="ml-sm">Password:</div>
                                            </div>

                                            <div className="text-primary">
                                                {order_details.photo_admin_password}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 trevlerLoginDetails">
                                        <div>
                                            <strong className="text_16">Traveler Login</strong>
                                        </div>
                                        <div className="flexElem alignCenter spaceBetween mt-md">
                                            <div className="flexElem alignCenter">
                                                <div><img src={s3StaticPath('img/user_icon_round.png')} alt="Username Icon" /></div>
                                                <div className="ml-sm">Username:</div>
                                            </div>
                                            <div className="text-primary">
                                                {order_details.username}
                                            </div>
                                        </div>
                                        <div className="flexElem alignCenter spaceBetween mt-sm">
                                            <div className="flexElem alignCenter">
                                                <div><img src={s3StaticPath('img/password_icon_round.png')} alt="Username Icon" /></div>
                                                <div className="ml-sm">Password:</div>
                                            </div>

                                            <div className="text-primary">
                                                {order_details.password}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form_tile">
                            <div className="form_tile_heading">
                                <img src={s3StaticPath('img/order_information.png')} alt="General Information" />
                                <span>Please Select Your Favorite Option Below</span>
                            </div>

                            <div className="form_tile_content favoriteOptions">
                                {/* <div className="text_14">
                                Mouse over images for more information.
                                </div> */}

                                <div className="favoriteOptionsGrid">
                                    <div>
                                        <div><label htmlFor="uploadPhotos"><img src={s3StaticPath('img/uploadPhotos.png')} alt="Upload Photos" /></label></div>
                                        <div>
                                            <div className="customChk">
                                                <input name='favOption' checked={this.state.favOption === 'desktop_app'} value='desktop_app' onChange={(e) => this.radioChangeHandler(e)} type="checkbox" id="uploadPhotos" /><label htmlFor="uploadPhotos">Upload Photos with<br />Website and/or App</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div><label htmlFor="digitalCamera"><img src={s3StaticPath('img/digitalCamera.png')} alt="Digital Camera" /></label></div>
                                        <div>
                                            <div className="customChk">
                                                <input name='favOption2' onChange={(e) => this.radioChangeHandler(e)} checked={this.state.favOption2 === 'camera'} value='camera' type="checkbox" id="digitalCamera" /><label htmlFor="digitalCamera">Digital Camera</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div><label htmlFor="memoryCard"><img src={s3StaticPath('img/memoryCard.png')} alt="Memory Card" /></label></div>
                                        <div>
                                            <div className="customChk">
                                                <input name='favOption2' checked={this.state.favOption2 === 'card'} value='card' onChange={(e) => this.radioChangeHandler(e)} type="checkbox" id="memoryCard" /><label htmlFor="memoryCard">Memory Card</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div><label htmlFor="flashDrive"><img src={s3StaticPath('img/flashDrive.png')} alt="Flash Drive" /></label></div>
                                        <div>
                                            <div className="customChk">
                                                <input name='favOption2' checked={this.state.favOption2 === 'drive'} value='drive' onChange={(e) => this.radioChangeHandler(e)} type="checkbox" id="flashDrive" /><label htmlFor="flashDrive">Flash Drive</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div><label htmlFor="contactMe"><img src={s3StaticPath('img/contactMe.png')} alt="Contact Me" /></label></div>
                                        <div>
                                            <div className="customChk">
                                                <input name='favOption' checked={this.state.favOption === 'not_sure'} value='not_sure' onChange={(e) => this.radioChangeHandler(e)} type="checkbox" id="contactMe" /><label htmlFor="contactMe"> I'm not sure,<br />contact me</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form_tile">
                            <div className="form_tile_heading">
                                <img src={s3StaticPath('img/order_information.png')} alt="General Information" />
                                <span>FREE Group Travel Videos&trade; App!</span>
                            </div>

                            <div className="form_tile_content grpTravelApp">
                                <div className="flexElem respFlex form_tile_row">
                                    <div className="col-md-6">
                                        <div className="text_14">
                                            Group Travel Videos&trade; App is available for any travelers with an Apple or Android mobile device (such as a phone or tablet). Just search the Apple or Android App store for "Group Travel Videos" and install the App. Once you have the app, simply login with your Group's username and password. Both the Photo Admin and Traveler Login will work. However, we request that you do not share the Photo Admin Login except with those people you are designated to administer the photos. For instance, you, a parent or school administrator (for student groups), or family member at home could be the Photo Admin.
                                        </div>

                                        <div className="mt-md text_14 text-primary">
                                            Note: If video photo sequence is important to you, you can arrange the photos on this website's Uploaded Photos Gallery by logging as the Photo Admin.
                                        </div>

                                        <div className="mt-lg text_18">
                                            Family at home can follow the trip online!
                                        </div>

                                        <div className="text_14 mt-md">
                                            Those at home can login to this website or the Group Travel Videos&trade; App with the Traveler Log In and follow along as the trip takes place.
                                        </div>

                                        <div className="mt-md">
                                            <img src={s3StaticPath('img/family_at_home.jpg')} alt="Family at home can follow the trip online!" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div id="video">
                                            <div className="video_text p-sm text_18 text-center purple_gradient text_white">
                                                <strong>Learn how the Group Travel Videos&trade; App works!!</strong>
                                            </div>
                                            <div className="video_placeholder">
                                                {/* <img src={videoPlaceholder} alt="Video placeholder" /> */}
                                                <video onPlay={() => {
                                                    this.setState({
                                                        videoPaused: false
                                                    })
                                                }} onPause={this.toggleVideo} ref={this.video} width="100%" height="240" controls>
                                                    <source src="https://file-examples.com/wp-content/uploads/2017/04/file_example_MP4_640_3MG.mp4" type="video/mp4" />
                                                </video>
                                                {
                                                    this.state.videoPaused &&
                                                    <div onClick={this.toggleVideo} className="video_play blue_gradient hover_dark"></div>
                                                }
                                            </div>
                                        </div>

                                        <div className="p-md approvalOptions text_white purple_blue_gradient">
                                            <div className="text_18">
                                                Please Select a Photo Approval Option
                                            </div>

                                            <div className="approvalChk mt-md">
                                                <div className="customChk">
                                                    <input type="checkbox" id="allowPhotos" value={!this.state.photo_admin_approval} checked={this.state.photo_admin_approval} onChange={this.inputChangeHandler} name='photo_admin_approval' />
                                                    <label htmlFor="allowPhotos">
                                                        Allow photos to go online for group's viewing without Photo Admin Approval.
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="approvalChk mt-md">
                                                <div className="customChk">
                                                    <input type="checkbox" checked={this.state.allow_upload} value={!this.state.allow_upload} onChange={this.inputChangeHandler} name='allow_upload' id="doNot" />
                                                    <label htmlFor="doNot">
                                                        I DO NOT want my group to be able to upload photos at all.
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="approvalChk mt-md">
                                                <div className="customChk">
                                                    <input readOnly checked={false} type="checkbox" id="doNotAllow" />
                                                    <label htmlFor="doNotAllow">
                                                        DO NOT allow photos to go online for group's viewing without Photo Admin Approval.
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="text_14 mt-md">
                                                If you would like to select this option please contact <br />
                                                us at 704-846-7870 x209 or at <br />
                                                <a href="mailto:Info@traveltripkit.com" className="text_white link_hover">Info@traveltripkit.com</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <ShippingDetails
                                    contactInfo={true}
                                    panelTitle="Please verify the below information"
                                    order_details={order_details}
                                    showErrorMessages={this.state.showErrorMessages}
                                    validatorInstance={this.Validator}
                                    returnState={this.getState}
                                    agencyAddress={order_details.agency.primary_address}
                                    shipping_details={shipping_details}
                                    submitComment={this.state.submitComment} />
                            </div>

                            <div className="col-md-6">
                                <VideoTitles
                                    panelTitle="Edit &amp; Enter Custom Videos Titles"
                                    showErrorMessages={this.state.showErrorMessages} validatorInstance={this.Validator} returnState={this.getState} production_details={production_details} />

                                <div className="form_tile">
                                    <div className="form_tile_heading">
                                        <img src={s3StaticPath('img/video_music.png')} alt="Video Music" />
                                        <span>Video Music Type</span>
                                    </div>

                                    <div className="form_tile_content">
                                        <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                            <div className="col-md-6">
                                                <div className="customChk">
                                                    <input onChange={this.inputChangeHandler} checked={order_details.music_selection_criteria === 'destination_based'} name='order_details.music_selection_criteria' value='destination_based' type="radio" id="destinationBased" />
                                                    <label htmlFor="destinationBased" className="text_16">Destination-based</label>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="customChk">
                                                    <input checked={order_details.music_selection_criteria === 'age_based'} onChange={this.inputChangeHandler} type="radio" id="ageBased" name='order_details.music_selection_criteria' value='age_based' />
                                                    <label htmlFor="ageBased" className="text_16">Age-based</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                            <div className="col-md-6 mt-md">
                                                <button onClick={() => { this.toggleModal('sampleMusicModal') }} className="gradient_btn block themeBtn_14 purple_gradient hover_dark">Sample Music Here</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="col-md-2 col-md-offset-5 mt-xlg mb-xlg">
                                <button className='themeBtn_14 block mb-xlg red_gradient' onClick={this.printOrderOptions} >Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ThemeModal size='lg' modalClass="frontEndModal" title='♫ Sample Music' show={this.state.sampleMusicModal} hide={() => { this.toggleModal('sampleMusicModal') }}>
                    <SampleMusic />
                </ThemeModal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { order_details, shipping_details, pricing_details, production_details } = state.ordersReducer;
    const { countries } = state.settingsReducer;
    return {
        order_details, shipping_details, pricing_details, production_details, countries
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getOrderCall: (orderId) => { dispatch(getOrder(orderId)) },
        getCountriesCall: () => { dispatch(getCountries()) },
        updateTitlesMusic: (data, id) => { return dispatch(updateTitlesMusic(data, id)) },
        updateOrderSettingsCall: (orderId, value, key) => { dispatch(updateOrderSettings(orderId, value, key)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard(OrderOptions))
