import React, {Component} from "react";
import {CSVLink} from "react-csv";
import _get from 'lodash.get'
import {returnDynamicURL} from "../../helper/helperFunctions";

class reactDataToCSV extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataToDownload: []
        }
        this.download = this.download.bind(this);
    }

    download(event) {
        //OLD CODE
        const {currentRecordsRef, columnsData} = this.props;
        let currentRecordsData = currentRecordsRef.resolvedData;

        //NEW CODE
        const DataToDownload = [];
        const columnsToDownload = {};

        columnsData.forEach(column => {
            if (column.excExp === true) {
                let accessor = column.accessor;
                const splitAccessor = accessor.split('.');
                const accessorLength = splitAccessor.length;
                if (accessorLength > 2) {
                    if (splitAccessor[1] in columnsToDownload) {
                        columnsToDownload[splitAccessor[1]].push(column);
                    } else {
                        columnsToDownload[splitAccessor[1]] = [column]
                    }
                } else if (accessorLength > 1) {
                    if (splitAccessor[0] in columnsToDownload) {
                        columnsToDownload[splitAccessor[0]].push(column);
                    } else {
                        columnsToDownload[splitAccessor[0]] = [column]
                    }
                } else {
                    columnsToDownload[splitAccessor[0]] = column;
                }
            }
        });
        let i = 0;
        let record = currentRecordsData[i];
        while (record !== undefined) {
            let currentRecord = {}
            Object.keys(columnsToDownload).forEach(column => {
                if (columnsToDownload[column] instanceof Array) {
                    columnsToDownload[column].forEach(nestedItem => {
                        this.makeColumn(nestedItem, currentRecord, record)
                    })
                } else {
                    this.makeColumn(columnsToDownload[column], currentRecord, record)
                }
            })
            DataToDownload.push(currentRecord);
            i += 1;
            record = currentRecordsData[i]
        }
        this.setState({dataToDownload: DataToDownload}, () => {
            this.csvLink.link.click()
        })
    }

    makeColumn(item, currentRecord, record) {
        if (item.csvMod) {
            if (item instanceof Array) {
                let arr = item;
                let output = '';
                arr.forEach(item => {
                    if (item.csv_value)
                        output += item.csv_value + '\n';
                })
                currentRecord[item.Header] = output;
            } else {
                if (item.Header === 'Traveler Quick Login') {
                    let match, result;
                    match = document.domain.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im);
                    if (match) {
                        result = match[1]
                        let domainSlugs = ['gtv', 'brt', 'mci', 'eti', 'itk', 'act', 'ttk'];
                        if (domainSlugs.includes(result.split('.')[0])) {
                            result = result.split('.').slice(1).join('.');
                        }
                    }
                    let link = returnDynamicURL(record.accounts[0].domain_slug, record.username, record.password)
                    currentRecord[item.Header] = link
                } else {
                    currentRecord[item.Header] = item.csv_value;
                }
            }
        } else {
            let value = _get(record, item.accessor);
            // if (item.accessor.match(/[\w\W]+\.[\w\W]+\.[\w\W]+/)) {
            //     let newAcc = item.accessor.split('.');
            //     if (record[newAcc[0]][newAcc[1]].value === '0') {
            //         value = ''
            //     }
            // } else {
            //     if (item.accessor === 'barcode') {
            //         // value = `=""${value}""`
            //         value = `=HYPERLINK(""https://www.grouptravelvideos.com/Admin/${record.id}/order-edit"",""${value}"")`
            //     }
            //     if (item.accessor === 'orderShippingCameraShippedDate') {
            //         value = record.orderShippingCameraFlag ? "**" + value + "**" : value
            //     }
            // }
            if (item.accessor === 'barcode') {
                // value = `=""${value}""`
                value = `=HYPERLINK(""https://www.grouptravelvideos.com/Admin/${record.id}/order-edit"",""${value}"")`
            }
            if (item.accessor === 'orderShippingCameraShippedDate') {
                value = record.orderShippingCameraFlag ? "**" + value + "**" : value
            }
            currentRecord[item.Header] = value;
        }
    }

    render() {
        const {fileName} = this.props;
        const {dataToDownload} = this.state;
        return (
            <React.Fragment>
                <button onClick={this.download}>
                    Download
                </button>
                <CSVLink
                    data={dataToDownload}
                    filename={fileName + ".csv"}
                    className="hidden"
                    ref={(r) => this.csvLink = r}
                    target="_blank"
                />
            </React.Fragment>
        );
    }
}

export default reactDataToCSV;
