import React from 'react';
import {
	Row,
	Col
} from "react-bootstrap";
import { connect } from 'react-redux';
import { allOrderImagesByPhase, getOrder, processShipStatus, updateSettingsInOrderDetail } from 'redux/actions/order-actions';
import DownloadFile from 'components/ThemeComponents/downloadFile';
import ImagesList from '../ImagesList';
import { getOrdersResourcesByRole, getAgencyResourcesPagination } from 'redux/actions/agency-resource-actions';
import ImagePreview from 'components/Upload/ImagePreview';
import { returnTimeStamp, s3StaticPath, hasRole, returnDynamicURL, closeModal } from 'helper/helperFunctions';
import { updateSettingsData } from 'redux/actions/settings-actions';
import { Card } from "components/Card/Card.jsx";
import { ThemeFunctions as TF } from 'helper/ThemeFunctions';
import { orderService } from '../../../../services/order';
import { alertActions } from '../../../../redux/actions/alert-actions';
import User from 'helper/User';
import { history } from 'helper/history';
import EditedPhotosGallery from '../EditedPhotosGallery';
import OrderComments from '../OrderComments';
import Lightbox from 'react-image-lightbox';
var Modal = require('react-bootstrap-modal');

class QualityAssurancePage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			workingImages: [],
			flashImages: [],
			full_video: {},
			highlight_video: {},
			collapseFullVidViewer: true,
			collapseHighlightVidViewer: true,
			collapseVidTemplateViewer: true,
			collapseMozaixFileViewer: true,
            collapseEditedPhotosGalViewer: false,
            collapseCommentsViewer: false,
            addNoteModal: false,
            note:'',
            noteSubmitted: false,
            watermark: false,
            isOpen: false,
		}


	};
	componentDidUpdate(prevProps, nextProps) {
		if (this.props.allImagesByPhase !== prevProps.allImagesByPhase) {
			if (this.props.allImagesByPhase.type === 'working') {
				this.setState({ workingImages: this.props.allImagesByPhase.images });
			} else if (this.props.allImagesByPhase.type === 'flash') {
				this.setState({ flashImages: this.props.allImagesByPhase.images });
			}
		}
        if (this.props.agencyResourcesData !== prevProps.agencyResourcesData){
            this.props.agencyResourcesData.resource_types.map((type) => {
                if (type.title === "Watermark") {
                   this.setState({
                    watermark: true
                   })
                }
              })
        }
	}
    showNoteModal = () => {
        this.setState({
            addNoteModal: !this.state.addNoteModal
        })
    }
    changeNotehandler = (e) => {
        this.setState({
            note: e.target.value
        })
    }
    storeNotehandler = () => {
        if(this.state.note !== "") {
            orderService.storeOrderNote(this.props.orderId, {note: this.state.note, reassigned_by: this.props.order_details.qa.name}).then(
                response => {
                    this.props.success(response.message);
                    this.setState({
                        addNoteModal: false,
                        noteSubmitted: true,
                        note: ''
                    });
                    const { orderId } = this.props;
                    var flag = 'finalize_by_pm'
                    var settingData = {
                        value: 0,
                    };
                    this.props.updateSettingsDataCall(orderId, flag, settingData).then(response => {
                        history.push(`/Admin/${orderId}/order-edit?orderDetailCode=production&imageCode=productionManager`);
                        history.go();
                    });
                }
            );
        }
    }
	finalize = (status, flag) => {
        if (status === 0 && flag === 'finalize_by_pm')
        {
            this.setState({
                addNoteModal: !this.state.addNoteModal
            })
        } else {
            const { orderId } = this.props;
            var settingData = {
                value: status,
            };
            this.props.updateSettingsDataCall(orderId, flag, settingData).then(response => {
                this.props.getOrderCall(this.props.orderId);
            });
        }
	}
	componentDidMount() {
		this.props.allOrderImagesByPhaseCall(this.props.orderId, 'working', this.props.tabName);
		this.props.allOrderImagesByPhaseCall(this.props.orderId, 'flash', this.props.tabName);
		this.props.getOrderResourcesByRoleCall(this.props.order_details.id);
        this.props.getAgencyResourcesCall(this.props.order_details.agency_id);

	}
    showLinkCode(title, username, password, link) {
		// console.log('this.props.order_details.agency.single_account', typeof this.props.order_details.agency.single_account === 'undefined' || !this.props.order_details.agency.single_account ?'no':this.props.order_details.agency.single_account)
		var domainSlug = typeof this.props.order_details.agency.single_account === 'undefined' || !this.props.order_details.agency.single_account ? null : this.props.order_details.agency.single_account.account.domain_slug;
		// const link = '';
        if (domainSlug !== null)
		return link = returnDynamicURL(domainSlug, username, password);
    }
    updateSettingsInOrderDetail = (e) => {
		const value = e.target.value;
		const key = e.target.name
		this.props.updateOrderSettingsCallNew(this.state.orderId, value, key).then(
			res => {
				// this.props.getOrderCall(this.state.orderId)
			}
		);
	}

    handleShowDialog = () => {
        this.setState({ isOpen: !this.state.isOpen });
        console.log("cliked");
    }

    closeMozaixLightBox = () => {
        this.setState({
            isOpen: false
        })
    }

    downloadImage = (orderResources) => {
        // const downloadUrl = file.image_versions.file_path.replace('SIZE', this.state.sizes[this.state.fullIndex]) + '?updated_at=' + file.updated_at;
        const downloadUrl = orderResources['20x30_mosaic_preview'].file_path+ '?updated_at=' + orderResources['20x30_mosaic_preview'].updated_at;
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', "download")
        link.target="_blank";
        document.body.appendChild(link);
        link.click();
        console.log("clicker");
    }

	render() {
		const { orderId, tabName, orderResources, order_details, userAdmin, production_details, agencyResourcesData } = this.props;
		const { workingImages, flashImages, full_video, highlight_video, addNoteModal, watermark, isOpen } = this.state;
        var path = '';
        path = this.showLinkCode('Photo Admin', order_details.photo_admin_username, order_details.photo_admin_password, window.location.host);
        console.log('Path', path);
		return (
			<div>
				<div className="videoUploaderSection flexElem flexResponsive section-frontEnd" >
					<Card bsClass={["innerCard"]} title="View Full Video" collapseTrigger={this.state.collapseFullVidViewer} collapsHandler={() => this.setState({ collapseFullVidViewer: !this.state.collapseFullVidViewer })} collapsable={true}
						content={
							<div className="uploadVideoSection">
                                <div className="displayInline pull-right">
                                    {
                                        Object.keys(workingImages).length > 0
                                        &&
                                        <DownloadFile
                                            title="Download Edited Images"
                                            multiple={true}
                                            files={workingImages}
                                            extraClass={"ml-sm mt-sm"}
                                        />
                                    }
                                    {
                                        'full_video' in orderResources
                                        &&
                                        <DownloadFile
                                            title="Download Full Video"
                                            multiple={true}
                                            files={orderResources.full_video.file_path}
                                            extraClass={"ml-sm mt-sm"}
                                        />
                                    }
                                    <a class="orderDetailsAction small_icon btn" style={{cursor: "pointer"}} href={`microsoft-edge:${path}`}>Quick Login</a>
                                </div>
								{
									'full_video' in orderResources
										?
										<ImagePreview
											filesToPreview={
												{
													preview: orderResources.full_video.file_path + '?rand=' + returnTimeStamp(orderResources.full_video.updated_at),
													name: orderResources.full_video.file_title,
													type: orderResources.full_video.mime_type,
													id: orderResources.full_video.id,
												}
											}
											removeFile={Object.keys(full_video).length > 0 ? this.removeFile : null}
											type={'full_video'}
										/>
										:
										TF.errorMsg("No Video has been uploaded yet!", "danger", s3StaticPath('img/critical_icon.png'), " flex-center align-items-center")
								}



							</div>
						}
					/>
                    <Card bsClass={["innerCard"]} title="View Mozaix Image"
                              collapseTrigger={this.state.collapseMozaixFileViewer}
                              collapsHandler={() => this.setState({collapseMozaixFileViewer: !this.state.collapseMozaixFileViewer})}
                              collapsable={true}
                              content={
                                  <div className="uploadVideoSection mozaixImageOnQA">
                                      {
                                            '20x30_mosaic_preview' in orderResources
                                             &&
                                            <img id="mozaix_img" onClick={this.handleShowDialog} style={{cursor: 'pointer'}} className='mt-md' src={orderResources['20x30_mosaic_preview'].file_path} alt="mosaic image" />

                                      }
                                      {/* <ImagePreview
                                          filesToPreview={

                                                  'original_mosaic' in orderResources
                                                  &&
                                                  {
                                                      preview: orderResources.original_mosaic.file_path + '?rand=' + returnTimeStamp(orderResources.original_mosaic.updated_at),
                                                      name: orderResources.original_mosaic.file_title,
                                                      type: orderResources.original_mosaic.mime_type,
                                                      id: orderResources.original_mosaic.id
                                                  }
                                          }
                                          type={'full_video'}
                                      /> */}
                                  </div>
                              }
                    />
                    <Card bsClass={["innerCard"]} title="Video Titles/Info" collapseTrigger={this.state.collapseVidTemplateViewer} collapsHandler={() => this.setState({ collapseVidTemplateViewer: !this.state.collapseVidTemplateViewer })} collapsable={true}
						content={
							<div>
								<table className="table orderTable last_three_rows">
									<tbody>
                                        {
                                            "music_selection_criteria" in order_details
                                            &&
                                            TF.showValueLabel("Music", order_details.music_selection_criteria.replace('_', ' ').toUpperCase())
                                        }
                                        {
                                            "destinationText" in order_details
                                            &&
                                            TF.showValueLabel("Destination ", order_details.destinationText)
                                        }
                                        {
                                            order_details
                                            &&
                                            TF.showValueLabel("# of Photos Original/Edited", order_details.originalImages + '/' + order_details.workingImages)
                                        }
                                        {
                                            order_details
                                            &&
                                            TF.showValueLabel("Audio/Video Files", order_details.order_resources_audio_video_count)
                                        }
                                        {
                                            TF.showValueLabel("Watermark", watermark ? "YES" : "NO")
                                        }
                                        {
                                            "ot1" in production_details.video_title_templates
                                            &&
                                            TF.showValueLabelForTitles(production_details.video_title_templates.ot1.title, production_details.video_title_templates.ot1.pivot.value, production_details.video_title_templates.ot1.pivot.is_changed)
                                        }
										{
											"ct1" in production_details.video_title_templates
											&&
											TF.showValueLabelForTitles(production_details.video_title_templates.ct1.title, production_details.video_title_templates.ct1.pivot.value, production_details.video_title_templates.ct1.pivot.is_changed)
										}
										{
											"ct2" in production_details.video_title_templates
											&&
											TF.showValueLabelForTitles(production_details.video_title_templates.ct2.title, production_details.video_title_templates.ct2.pivot.value, production_details.video_title_templates.ct2.pivot.is_changed)
										}
										{
											"ct3" in production_details.video_title_templates
											&&
											TF.showValueLabelForTitles(production_details.video_title_templates.ct3.title, production_details.video_title_templates.ct3.pivot.value, production_details.video_title_templates.ct3.pivot.is_changed)
										}
									</tbody>
								</table>
							</div>
						}
					/>
					<Card bsClass={["innerCard"]} title="View Highlight Video" collapseTrigger={this.state.collapseHighlightVidViewer} collapsHandler={() => this.setState({ collapseHighlightVidViewer: !this.state.collapseHighlightVidViewer })} collapsable={true}
						content={
							<div className="uploadVideoSection">
								{
									'highlight_video' in orderResources
										?
										<ImagePreview
											filesToPreview={
												{
													preview: orderResources.highlight_video.file_path + '?rand=' + returnTimeStamp(orderResources.highlight_video.updated_at),
													name: orderResources.highlight_video.file_title,
													type: orderResources.highlight_video.mime_type,
													id: orderResources.highlight_video.id,
												}
											}
											removeFile={Object.keys(highlight_video).length > 0 ? this.removeFile : null}
											type={'highlight_video'}
										/>
										:
										TF.errorMsg("No Video has been uploaded yet!", "danger", s3StaticPath('img/critical_icon.png'), " flex-center align-items-center")
								}

							</div>
						}
					/>

				</div>
                <Card
                    ctTableResponsive
                    content={
                        <div>
						    <div className="container-fluid p-none">
							    <Row>
                                    <Col md={8} sm={8} xs={12}>
                                        <div className="">
                                            {
                                                Object.keys(workingImages).length > 0
                                                &&
                                                <DownloadFile
                                                    title="Edited Images"
                                                    multiple={true}
                                                    files={workingImages}
                                                    extraClass={"ml-sm mt-sm"}
                                                />
                                            }
                                            {
                                                Object.keys(flashImages).length > 0
                                                &&
                                                <DownloadFile
                                                    title="Flash Images"
                                                    multiple={true}
                                                    files={flashImages}
                                                    extraClass={"ml-sm mt-sm"}
                                                />
                                            }
                                            {
                                                parseInt(order_details.settings.finalize_by_editor.value, 10) === 1 &&
                                                    <span>&nbsp;Edited by: <strong>{order_details.editor.name}</strong> &nbsp;&nbsp;</span>
                                            }
                                            {
                                                parseInt(order_details.settings.finalize_by_pm.value, 10) === 1 &&
                                                <span>Produced by: <strong>{order_details.producer.name}</strong>&nbsp;&nbsp;</span>
                                            }
                                            {
                                                /*("finalize_by_qa" in order_details.settings && ( ( hasRole(['qa']) && order_details.qa_id === userAdmin.user.id ) || hasRole(['admin'] ) ) ) */
                                                ("finalize_by_qa" in order_details.settings && hasRole(['qa', 'admin']) && order_details.qa_id === userAdmin.user.id &&
                                                parseInt(order_details.settings.finalize_by_editor.value, 10) === 1 && parseInt(order_details.settings.finalize_by_pm.value, 10) === 1)
                                                    ?
                                                    parseInt(order_details.settings.finalize_by_qa.value, 10) === 0
                                                        ?
                                                        <React.Fragment>
                                                            <button type="button" className="orderDetailsAction finalizeIcon ml-md" onClick={() => this.finalize(1, 'finalize_by_qa')} >Finalize</button>
                                                            <button type="button" className="orderDetailsAction closeIcon ml-md" onClick={() => this.finalize(0, 'finalize_by_pm')} >Re Assign to Producer</button>
                                                        </React.Fragment>
                                                        :
                                                        <span>Finalized by QA</span>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </Col>
                                </Row>
                                    <Card bsClass={["innerCard"]} title="Edited Photos Gallery" collapseTrigger={this.state.collapseEditedPhotosGalViewer} collapsHandler={() => this.setState({ collapseEditedPhotosGalViewer: !this.state.collapseEditedPhotosGalViewer })} collapsable={true}
                                        content={
                                            <div className="uploadVideoSection">
                                                <section id="companyImagesInfo">
                                                    <EditedPhotosGallery
                                                        orderId={this.props.order_details.id}
                                                        toggleUploader={true}
                                                    />
                                                </section>
                                            </div>
                                        }
                                    />
                            </div>
                        </div>
                    }
                />
                <Card bsClass={["innerCard"]} title="Important Comments" collapseTrigger={this.state.collapseCommentsViewer} collapsHandler={() => this.setState({ collapseCommentsViewer: !this.state.collapseCommentsViewer })} collapsable={true}
                    content={
                        <div className="">
                            <OrderComments
                                formType={'edit'}
                                // contactLog={this.props.location.state}
                                modelId={orderId}
                                order_details={order_details}
                                withUuid={false}
                                addExceptTab={['All']}
                                showOnly={['All', 'QA', 'Production', 'DVD_MozaixShip', 'TO_GL', 'ContactLog']}
                                showSteps={true}
                                updateOrderSettingsCall={this.updateSettingsInOrderDetail}
                            />
                        </div>
                    }
                />
				{/* <ImagesList
					provideCtrlListing={ctrlList => this.ctrl_list = ctrlList}
					orderId={orderId}
					versionType='working'
					tabName={tabName}
					extraHtml={
						<div className="">
							{
								Object.keys(workingImages).length > 0
								&&
								<DownloadFile
									title="Edited Images"
									multiple={true}
									files={workingImages}
									extraClass={"ml-sm mt-sm"}
								/>
							}
							{
								Object.keys(flashImages).length > 0
								&&
								<DownloadFile
									title="Flash Images"
									multiple={true}
									files={flashImages}
									extraClass={"ml-sm mt-sm"}
								/>
							}
							{
								parseInt(order_details.settings.finalize_by_editor.value, 10) === 1 &&
									<span>&nbsp;Edited by: <strong>{order_details.editor.name}</strong> &nbsp;&nbsp;</span>
							}
							{
								parseInt(order_details.settings.finalize_by_pm.value, 10) === 1 &&
								<span>Produced by: <strong>{order_details.producer.name}</strong>&nbsp;&nbsp;</span>
							}
							{
                                ("finalize_by_qa" in order_details.settings && hasRole(['qa', 'admin']) && order_details.qa_id === userAdmin.user.id &&
                                parseInt(order_details.settings.finalize_by_editor.value, 10) === 1 && parseInt(order_details.settings.finalize_by_pm.value, 10) === 1)
									?
									parseInt(order_details.settings.finalize_by_qa.value, 10) === 0
										?
										<React.Fragment>
											<button type="button" className="orderDetailsAction finalizeIcon ml-md" onClick={() => this.finalize(1, 'finalize_by_qa')} >Finalize</button>
											<button type="button" className="orderDetailsAction closeIcon ml-md" onClick={() => this.finalize(0, 'finalize_by_pm')} >Re Assign to Producer</button>
										</React.Fragment>
										:
										<span>Finalized by QA</span>
									:
									null
							}
						</div>
					}
				/> */}
                {/* Add Note Model */}
                    <Modal size="sm" backdrop={'static'} show={addNoteModal} id="" onHide={closeModal.bind(this, "addNoteModal")} aria-labelledby="ModalHeader" className="" >
                        <Modal.Header closeButton>
                            <Modal.Title id='ModalHeader' className="headerTitle">Enter Remake Notes</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="clearfix">
                                <textarea type="text" value={this.state.note} onChange={this.changeNotehandler} className="form-control" placeholder="Please enter the reason of Re Assigning the order"/>
                                <br />
                                <button onClick={() => this.storeNotehandler()} style={{float: "right"}} className="backButton pt-sm no_radius pb-sm primary mt-none btn btn btn-info  btn btn-default">Submit</button>
                                <button onClick={closeModal.bind(this, "addNoteModal")} style={{float: "right"}}  className="backButton pt-sm no_radius pb-sm primary mt-none btn btn btn-info  btn btn-default mr-md">Cancel</button>


                            </div>
                        </Modal.Body>
                    </Modal>
                    {
                        isOpen && (
                        <Lightbox
                            mainSrc={orderResources['20x30_mosaic_preview'].file_path}
                            onCloseRequest={this.closeMozaixLightBox}
                            toolbarButtons={
                                [
                                    <div className="fb-share-button"
                                            data-href={orderResources['20x30_mosaic_preview'].file_path}
                                            data-layout="button" data-size="large">
                                        <a onClick={(e) => {
                                            e.preventDefault();
                                            window.open(`https://www.facebook.com/sharer/sharer.php?u=${orderResources['20x30_mosaic_preview'].file_path}`, 'popup', 'height=600,width=600')
                                        }} style={{
                                            color: '#fff',
                                            background: '#3b5998',
                                            padding: '5px',
                                            borderRadius: '5px'
                                        }}
                                            href={`https://www.facebook.com/sharer/sharer.php?u=${orderResources['20x30_mosaic_preview'].file_path }`}
                                            className="fb-xfbml-parse-ignore"><i className='fa fa-facebook'></i> Share</a>
                                    </div>,

                                    <i onClick={() => { this.downloadImage(orderResources) }} className='fa fa-download mr-md ml-md lightBoxDownload'></i>,
                                ]
                            }

                        />
                    )
                }
			</div>
		);
	};
}

function mapStateToProps(state) {
	const { loadingOrder, allImagesByPhase, orderResources } = state.ordersReducer;
	const { loading, userAdmin } = state.adminReducer;
	const { agencyResourcesData } = state.agencyResourceReducer;
	return {
		loadingOrder, allImagesByPhase, orderResources, agencyResourcesData,
		loading, userAdmin
	};
}

const mapDispatchToProps = dispatch => {
	return ({
		allOrderImagesByPhaseCall: (orderId, version_type, tabName) => { dispatch(allOrderImagesByPhase(orderId, version_type, tabName)) },
		getOrderResourcesByRoleCall: (orderId) => { dispatch(getOrdersResourcesByRole(orderId)) },
		updateSettingsDataCall: (model_id, key, settingData) => { return dispatch(updateSettingsData(model_id, key, settingData)) },
        getAgencyResourcesCall: (agency_id) => { dispatch(getAgencyResourcesPagination(agency_id)) },
		getOrderCall: (orderId) => { dispatch(getOrder(orderId)) },
        changeShipStatusCall: (orderId, data) => { return dispatch(processShipStatus(orderId, data)) },
        success: (message) => { return dispatch(alertActions.success(message))},
		updateOrderSettingsCallNew: (orderId, value, key) => { return dispatch(updateSettingsInOrderDetail(orderId, value, key)) }
	});
};

const QualityAssurance = connect(mapStateToProps, mapDispatchToProps)(QualityAssurancePage);
export default QualityAssurance;
