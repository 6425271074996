import React, { Component } from 'react'
import { updateOrderCsrStep, getContent } from "redux/actions/comment-actions";
import { connect } from "react-redux";
import { returnColumnValueWithColumnMatch, closeModal } from "helper/helperFunctions";
import OpenModalButton from 'components/ThemeComponents/openModelButton';
import { Card } from "components/Card/Card.jsx";
import CKEditor from "react-ckeditor-component";
import { ThemeFunctions as TF } from 'helper/ThemeFunctions';
import CopyToClipboard from 'react-copy-html-to-clipboard';
import { getOrder } from 'redux/actions/order-actions';
import $ from 'jquery';
import {

	Tooltip,
	OverlayTrigger,
} from "react-bootstrap";
import Confirmalert from 'components/ThemeComponents/confirmAlert';
import { confirmAlert } from 'react-confirm-alert';
import { getFilPath, updateSettingsData } from "redux/actions/settings-actions";
import DownloadFile from 'components/ThemeComponents/downloadFile';
import copy from 'copy-to-clipboard';
var Modal = require('react-bootstrap-modal');

class CsrSteps extends Component {
	constructor(props) {
		super(props);
		this.state = {
			stepModal: false,
			stepModalContent: {
				currentStep: null,
				content: null,
				subject: null,
				emails: ['testEmail1', 'testEmail2'],
				title: null,
			}
		};
	}
	changeSteps = (e, modelId, id) => {
		const { name, checked } = e.target;
		// console.log('checked', checked );
		var pt = this.props.order_details.program_type.title.toLowerCase();
		if ((name === 'step1' || name === 'step6') && !pt.includes("upload") && checked) {
			this.confirmProgramType(pt, modelId, name, checked);
		} else {
			this.updateStepValue(modelId, name, checked);
		}
	}

	updateStepValue = (modelId, name, checked) => {
		this.props.updateOrderCsrStepCall(modelId, { code: name, value: checked, depatureDateDiff: this.props.order_details.depatureDateDiffCreate }).then(response => {
			this.props.updateComments();
		});
	}
	openModal = (code, title) => {
		if ((code === 'step7' || code === 'step7a') && this.props.order_details.depatureDateDiffCreate < 15) {
			code = 'step7a';
            title = 'Step #7a-Confirmation/Manual Process PTM Email';
		} else if ((code === 'step7' || code === 'step7a') && this.props.order_details.depatureDateDiffCreate >= 15) {
			code = 'step7';
			title = 'Step #7-Confirmation Email';
		}

		this.setState(prevState => ({
			...prevState, stepModalContent: {
				...prevState.stepModalContent, currentStep: code, title: title
			}
		}), () => {
			const { order_details } = this.props;
			this.props.getContentCall(order_details.agency.single_account.account_id, order_details.id, this.state.stepModalContent.currentStep).then(
				response => {
					this.setState({ stepModalContent: { ...this.state.stepModalContent, ...response.transmittalTemplate } }, () => {
						this.setState({ stepModal: true });
					});
				}
			).catch(function (err) {
				//store.dispatch(loginFail());
				//errorUtils.dispatchErrorWithTimeout(errorLogin);
				console.log(err);
			});

		});
	}
	updateSettings = (e) => {
		const { name, /*checked, */value } = e.target;
		const { order_details } = this.props;
		var settingData = {
			value: value,
			message: 'Approve For Shipping Immediately'
		};
		// console.log('settingData', settingData);
		// return false;
		this.props.updateSettingsDataCall(order_details.id, name, settingData).then(response => {
			this.props.getOrderCall(order_details.id);
		});
	}
	componentDidUpdate(prevProps, prevState) {

	}

	getFilPath = (orderId, type) => {
		this.props.getFilPathCall(orderId, type).then(response => {
			copy(response.data.file_path);
			// response.data.file_path.select();
			// document.execCommand('copy');
			// console.log('response', response);
		});
	}
	showContentForStep = (code, title, value, isEnable , currentStep) => {

        // console.log("code", code);
        // console.log("title", title);
        // console.log("isEnable", isEnable);
		if (code === 'step1') {
			return (
				<React.Fragment>
					{
						isEnable
							?
							<OpenModalButton
								openModal={() => this.openModal(code, title)}
								classButton={['btn-link p-none']}
								buttonName={((this.props.order_details.depatureDateDiffCreate <= 0) || (this.props.order_details.depatureDateDiffCreate >= 1 && this.props.order_details.depatureDateDiffCreate <= 4)) ? 'Step #1-Emailing PTM to GL/NO SHIP' : title}
								tooltipText={((this.props.order_details.depatureDateDiffCreate <= 0) || (this.props.order_details.depatureDateDiffCreate >= 1 && this.props.order_details.depatureDateDiffCreate <= 4)) ? 'Step #1-Emailing PTM to GL/NO SHIP' : title}
								classIcon={[]}
							/>
							:
							<span className='btn-link p-none' >{((this.props.order_details.depatureDateDiffCreate <= 0) || (this.props.order_details.depatureDateDiffCreate >= 1 && this.props.order_details.depatureDateDiffCreate <= 4)) ? 'Step #1-Emailing PTM to GL/NO SHIP' : title}</span>
					}

					{
						'expedite_ptm' in this.props.order_details.settings && 
						// ((this.props.order_details.depatureDateDiffCreate <= 0) || 
						// (this.props.order_details.depatureDateDiffCreate >= 1 && 
						// 	this.props.order_details.depatureDateDiffCreate <= 4)) &&
						<label className={`container-check mb-none ml-md ${returnColumnValueWithColumnMatch(this.props.csr_steps, 'value', ['step2'], 'code') === 1 ? 'disabled' : ''}`}>
							&nbsp;
							Expedite PTM Also
		                        <input
								type="checkbox"
								name={'expedite_ptm'}
								className="checkbox "
								value={Number(this.props.order_details.settings.expedite_ptm.value) ? 0 : 1}
								onChange={(e) => this.updateSettings(e)}
								checked={Number(this.props.order_details.settings.expedite_ptm.value) ? 1 : 0}
								disabled={returnColumnValueWithColumnMatch(this.props.csr_steps, 'value', ['step2'], 'code') === 1 ? true : false}

							/>
							<span className="checkmark"></span>
						</label>
					}


				</React.Fragment>
			);
		} else if (code === 'step2') {
			return (
				<React.Fragment>
					<span className={'btn-link p-none'}>{title}</span>
				</React.Fragment>
			);
		} else if (code === 'step3') {
			return (
				<React.Fragment>
					<span className={'btn-link p-none'}>{title} &nbsp; { <span>{ this.props.order_details.step3_projected_send_date }</span>}</span>
				</React.Fragment>
			);
		} else if (code === 'step4') {
			return (
				<React.Fragment>
					{
						isEnable
							?
							<OpenModalButton
								openModal={() => this.openModal(code, title)}
								classButton={['btn-link p-none']}
								buttonName={title}
								tooltipText={title}
								classIcon={[]}
							/>
							:
							<span className='btn-link p-none' >{title}</span>
					}
				</React.Fragment>
			);
		} else if (code === 'step5') {
			return (
				<React.Fragment>
					{
						isEnable
							?
							<OpenModalButton
								openModal={() => this.openModal(code, title)}
								classButton={['btn-link p-none']}
								buttonName={title}
								tooltipText={title}
								classIcon={[]}
							/>
							:
							<span className='btn-link p-none' >{title}</span>
					}
				</React.Fragment>
			);
		} else if (code === 'step6') {
			return (
				<React.Fragment>
					{
						isEnable
							?
							<OpenModalButton
								openModal={() => this.openModal(code, title)}
								classButton={['btn-link p-none']}
								buttonName={title}
								tooltipText={title}
								classIcon={[]}
							/>
							:
							<span className='btn-link p-none' >{title}</span>
					}
				</React.Fragment>
			);
		} else if (code === 'step7' || code === 'step7a') {
			return (
				<React.Fragment>
					{
						isEnable
							?
							<OpenModalButton
								openModal={() => this.openModal(code, title)}
								classButton={['btn-link p-none']}
								buttonName={(this.props.order_details.depatureDateDiffCreate >= 15) ? 'Step #7-Confirmation Email' : 'Step #7a-Confirmation/Manual Process PTM Email'}
								tooltipText={(this.props.order_details.depatureDateDiffCreate >= 15) ? 'Step #7-Confirmation Email' : 'Step #7a-Confirmation/Manual Process PTM Email'}
								classIcon={[]}
							/>
							:
							<span className='btn-link p-none' >{(this.props.order_details.depatureDateDiffCreate >= 15) ? 'Step #7-Confirmation Email' : 'Step #7a-Confirmation/Manual Process PTM Email'}</span>
					}
				</React.Fragment>
			);
		} else if (code === 'step8') {
			return (
				<React.Fragment>
                    {
						currentStep && currentStep.fiveDaysBeforeDeparture
							?
							<OpenModalButton
								openModal={() => this.openModal(code, title)}
								classButton={['btn-link p-none']}
								buttonName={title + ' '  + this.props.order_details.step8_projected_send_date}
								tooltipText={title}
								classIcon={[]}
							/>
							:
							<span className={'btn-link p-none'} >{title} &nbsp; { <span>{ this.props.order_details.step8_projected_send_date }</span>}</span>
					}
					{/* <span className={'btn-link p-none'} >{title} &nbsp; { <span>{ this.props.order_details.step8_projected_send_date }</span>}</span> */}
					<div className="text-center">
						{
							'app_card' in this.props.order_details.orderResources &&
							<DownloadFile multiple={false} title={'Download App Card'} files={{
								'file_title': this.props.order_details.orderResources.app_card.file_title,
								'file_path': this.props.order_details.orderResources.app_card.file_path
							}} />
						}


						{
							'title_sheet' in this.props.order_details.orderResources &&
							<DownloadFile multiple={false} title={'Download Paperwork'} extraClass="ml-sm" files={{
								'file_title': this.props.order_details.orderResources.title_sheet.file_title,
								'file_path': this.props.order_details.orderResources.title_sheet.file_path
							}} />
						}
						{
							/*
							<button className="customBtn mt-xs ml-sm btn-sm btn-primary btn" onClick={(orderId)=>this.getFilPath(this.props.order_details.id, 'app_card') } >Copy App Card</button>
							&nbsp;
							<button className="customBtn mt-xs ml-sm btn-sm btn-primary btn" onClick={(orderId)=>this.getFilPath(this.props.order_details.id, 'title_sheet') }>Copy Paperwork</button>
							*/
						}
					</div>
				</React.Fragment>
			);
		} else if (code === 'step9') {
			return (
				<React.Fragment>
					<span className={'btn-link p-none'} >{title}</span>
				</React.Fragment>
			);
		}

	}

	onChange = (key) => (evt) => {
		var newContent = evt.editor.getData();
		this.setState({ stepModalContent: { ...this.state.stepModalContent, [key]: newContent } })
	}


	confirmProgramType = (programtype, modelId, name, checked) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (

					<Confirmalert
						header={`warning: program type other than upload`}
						description={`program type is ${programtype}`}
						onClosePro={onClose}
						extraHtml={() => {
							return (<span></span>);
						}}
						onNo={
							() => {
								var element = '#programtype';
								var scrollPos = $(element).offset().top;
								$(window).scrollTop(scrollPos);
								$(element).addClass('highlighted');
								setTimeout(function () {
									$('.highlighted').removeClass('highlighted');
								}, 2000);
							}
						}
						onYes={() => { this.updateStepValue(modelId, name, checked) }}
						yesLabel='Proceed'
						noLabel='Correct Program Type'
					/>

				)
			}
		})
	}

	render() {
		const { order_details, csr_steps, step8_projected_send_date, step3_projected_send_date } = this.props;

		const { stepModal, stepModalContent } = this.state;
		// console.log('stepModalContent', stepModalContent);
        const tooltipFontSize = {
            fontSize: '13px'
        }
		return (
			<div className="commentSteps responsive-table" style={{ flexBasis: '100%' }}>
				<table className={'table'}>
					<tbody>
						{
							csr_steps.map((p, k) => {
								return (
									<tr key={k} className={p.colorEnable ? '' : 'disabledComment'}>
										{/*<tr key={k} className={!p.enable? p.code === 'step2' || p.code === 'step3' || p.code === 'step8' || p.code === 'step9' ?'csr':'disabledComment':''}>*/}
										<td style={{ 'width': '50px', 'verticalAlign': 'top' }}>
											<label className={`mb-none container-check ${p.colorEnable ? '' : 'disabled'}`}>
												&nbsp;
                                	<input
													type="checkbox"
													name={p.code}
													className={`checkbox`}
													value={p.value ? true : false}
													onChange={(e) => this.changeSteps(e, order_details.id)}
													checked={p.value ? true : false}
													disabled={!p.enable}
												/>
												<span className={`checkmark`}></span>
											</label>
										</td>
										{ /*<td>
                                p['title']
                                </td>*/ }
										<td>
											{

												this.showContentForStep(p.code, p.title, p.value, p.enable, p)
											}
										</td>
									</tr>
								)
							})
						}
					</tbody>
				</table>
				{
					stepModalContent.content &&
					<Modal backdrop={'static'} show={stepModal} onHide={closeModal.bind(this, "stepModal")} aria-labelledby="ModalHeader" id="csrDialogue" >
						<Modal.Header closeButton>
							{
                                                        // console.log("stepModalContent.currentStep", stepModalContent.currentStep),
								stepModalContent.currentStep === 'step1' || stepModalContent.currentStep === 'step6' || stepModalContent.currentStep === 'step7' || stepModalContent.currentStep === 'step7a' || stepModalContent.currentStep === 'step8'?
									<Modal.Title id='ModalHeader' className="headerTitle">
										<React.Fragment>
											<div className="flexElem8 alignCenter spaceBetween">
												<div>
													{`${stepModalContent.title}`}
												</div>

												<div className="copyEMails">
													<span>
														<OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip"><p style={tooltipFontSize}>Copy Group Leader Email</p></Tooltip>}>
															<CopyToClipboard text={ order_details.group_leader_contact.first_name + ' &lt;' + order_details.group_leader_contact.email + '&gt;'}
															options={{ asHtml: true }}
															>
																	<button className="orderDetailsAction copyColorAction" type="button">&nbsp;</button>
															</CopyToClipboard>
														</OverlayTrigger>
														{`Group Leader Email: ${order_details.group_leader_contact.email}`}
													</span>

													{
														(stepModalContent.currentStep === 'step7' || stepModalContent.currentStep === 'step7a')
														?
														<span style={{color:"gray"}}>
															<OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip" ><span style={tooltipFontSize}>Copy Agency Sales Rep Email</span></Tooltip>}>
																<CopyToClipboard text={order_details.agency_sales_rep.name + ' &lt;' + order_details.agency_sales_rep.email + '&gt;'}
																	options={{ asHtml: true }}>
																		<button style={{background:"none", paddingTop:"5"}} className="orderDetailsAction copyColorAction1" type="button"><svg xmlns="http://www.w3.org/2000/svg" stroke="#2F4576" fill="gray" stroke-width="0" viewBox="0 0 448 512" height="0.7em" width="0.7em" style={{color: "rgb(51, 51, 51)", fill: "gray"}}><path d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"></path></svg></button>
																</CopyToClipboard>
															</OverlayTrigger>
															{`Agency Sales Rep Email: ${ order_details.agency_sales_rep.email}`}
														</span>
														:
                                                        !(stepModalContent.currentStep === 'step8') ?
                                                            <span>
                                                                <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip" ><span style={tooltipFontSize}>Copy Agency Sales Rep Email</span></Tooltip>}>
                                                                    <CopyToClipboard text={order_details.agency_sales_rep.name + ' &lt;' + order_details.agency_sales_rep.email + '&gt;'}
                                                                        options={{ asHtml: true }}>
                                                                            <button className="orderDetailsAction copyColorAction" type="button">&nbsp;</button>
                                                                    </CopyToClipboard>
                                                                </OverlayTrigger>
                                                                {`Agency Sales Rep Email: ${ order_details.agency_sales_rep.email}`}
                                                            </span>
                                                            :''
													}

                                                    {
                                                        stepModalContent.currentStep === 'step1' ?
                                                            <span>
                                                                <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip"><span style={tooltipFontSize}>Copy to Clipboard</span></Tooltip>}>
                                                                    <CopyToClipboard text={
                                                                        `${order_details.agency.name}: ${order_details.destinationText} App Trip Materials Status`
                                                                        }
                                                                        options={{ asHtml: true }}>
                                                                            <button className="orderDetailsAction copyColorAction" type="button">&nbsp;</button>
                                                                    </CopyToClipboard>
                                                                </OverlayTrigger>
                                                                {`${order_details.agency.name}: ${order_details.destinationText} App Trip Materials Status`}
                                                            </span>
                                                            :
                                                            stepModalContent.currentStep === 'step7a' || stepModalContent.currentStep === 'step7' ?
                                                                <span>
                                                                    <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip"><span style={tooltipFontSize}>Copy to Clipboard</span></Tooltip>}>
                                                                        <CopyToClipboard text={
                                                                            `${order_details.agency.name}: App Confirmation & Program Summary for your upcoming trip`
                                                                            }
                                                                            options={{ asHtml: true }}>
                                                                                <button className="orderDetailsAction copyColorAction" type="button">&nbsp;</button>
                                                                        </CopyToClipboard>
                                                                    </OverlayTrigger>
                                                                    {`${order_details.agency.name}: App Confirmation & Program Summary for your upcoming trip`}
                                                                </span>
                                                            :
                                                            ''
                                                    }
                                                    {
                                                         stepModalContent.currentStep === 'step8' &&
                                                         <span>
                                                             <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip"><span style={tooltipFontSize}>Copy to Clipboard</span></Tooltip>}>
                                                                 <CopyToClipboard text={'Enjoy Your Trip and Upload Lots of Photos!'
                                                                     }
                                                                     options={{ asHtml: true }}>
                                                                         <button className="orderDetailsAction copyColorAction" type="button">&nbsp;</button>
                                                                 </CopyToClipboard>
                                                             </OverlayTrigger>
                                                             Enjoy Your Trip and Upload Lots of Photos!
                                                         </span>
                                                    }
												</div>

											</div>
										</React.Fragment>
									</Modal.Title>
									:
									<Modal.Title id='ModalHeader' className="headerTitle">
										<React.Fragment>
											{`${stepModalContent.title}`}

										</React.Fragment>
									</Modal.Title>
							}
						</Modal.Header>
						<Modal.Body>
							<div className="row">
								<div className="col-md-12">
									<Card bsClass={['innerCard mb-none']} content={
										<CKEditor
											activeClass="p10"
											content={stepModalContent.content}
											events={{
												"change": this.onChange('content')
											}}
										/>
									} />
									{
                                        stepModalContent.currentStep === 'step7a' || stepModalContent.currentStep === 'step7' || stepModalContent.currentStep === 'step1'

                                        ?


                                            TF.mailToEmailsSubject('Emails to Outlook', `${order_details.agency_sales_rep.name} <${order_details.agency_sales_rep.email}>, ${order_details.group_leader_contact.first_name} <${order_details.group_leader_contact.email}>`, stepModalContent.currentStep === 'step1' ? `${order_details.agency.name} : ${order_details.destinationText} App Trip Materials Status`: `${order_details.agency.name}: App Confirmation & Program Summary for your upcoming trip`)

                                        :
                                            stepModalContent.currentStep === 'step8' ?

                                            TF.mailToEmailsSubject('Emails to Outlook', `${order_details.group_leader_contact.first_name} <${order_details.group_leader_contact.email}>`,'Enjoy Your Trip and Upload Lots of Photos!')
                                            :

                                            TF.mailToEmailsSubject('Emails to Outlook', 'Sherri Taylor <sherri@createavideo.com>', stepModalContent.subject)
                                    }
                                    <span>&nbsp;</span>
									<CopyToClipboard text={
										stepModalContent.content
									}
										options={{ asHtml: true }}
									>
										<button className="orderDetailsAction copyColorAction" type="button">
											<OverlayTrigger placement="top" overlay={<Tooltip id="tooltip"><span style={tooltipFontSize}>Content Copy</span></Tooltip>}>
												<span className="question_mark">?</span>
											</OverlayTrigger>
										</button>
									</CopyToClipboard>
								</div>
							</div>
						</Modal.Body>
					</Modal>
				}
			</div>
		)
	}
}


const mapStateToProps = state => {
	const { csr_steps, order_details, step8_projected_send_date, step3_projected_send_date } = state.ordersReducer;
	return {
		csr_steps, order_details, step8_projected_send_date, step3_projected_send_date
	};
}

const mapDispatchToProps = dispatch => {
	return {
		updateOrderCsrStepCall: (model_id, data) => { return dispatch(updateOrderCsrStep(model_id, data)) },
		getContentCall: (account_id, order_id, code) => { return dispatch(getContent(account_id, order_id, code)) },
		updateSettingsDataCall: (model_id, key, settingData) => { return dispatch(updateSettingsData(model_id, key, settingData)) },
		getOrderCall: (orderId) => { dispatch(getOrder(orderId)) },
		getFilPathCall: (orderId, type) => { return dispatch(getFilPath(orderId, type)) },
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CsrSteps);
