
import React, { Component } from "react";
import Select, { createFilter } from 'react-select';
import { returnColumnValueWithColumnMatch, returnLabelWithValue } from 'helper/helperFunctions';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { connect } from 'react-redux';
import { getStates } from 'redux/actions/settings-actions';


class addressFormPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			adressObject: this.props.adressObject,
			states: [],
            agency_pa_is_france: false,
            agency_sa_is_france: false,
            is_france: false,
            vsa_is_france: false,
            ptma_is_france: false,
            show_tax_exempt: false,
		}

		this.handleChanegAddress = this.handleChanegAddress.bind(this);
		this.handleChangeAddressDropDown = this.handleChangeAddressDropDown.bind(this);
		this.returnAddressData = this.returnAddressData.bind(this);
		this.returnCountryName = this.returnCountryName.bind(this);
		if (this.props.adressObject.country_id)
			this.props.getStateCall(this.props.adressObject.country_id, this.props.type);
	}
	returnCountryName() {
		return returnColumnValueWithColumnMatch(this.props.options.countries, "label", [returnLabelWithValue(this.state.adressObject.country_id)], "label");
	}
	returnAddressData() {
		return { ...this.state.adressObject };
	}
	handleChanegAddress(e, parentObject) {
		const { name, value } = e.target;
		let updateAddress = { ...this.state.adressObject };
		updateAddress[name] = value;
		this.setState({ adressObject: updateAddress });
	}
	handleChangeAddressDropDown(selectedOptionType, info, parentObject) {
        console.log("selectedOptionType = " , selectedOptionType);
        console.log("info = " , info);
        console.log("parentObject = " , parentObject);

            //  -------For Agency France Check---------
            if (selectedOptionType.label === 'France' && info.name === 'country' && parentObject === 'primary_address') {
                console.log("condition true");
                this.setState({
                    is_france: true,
                }, ()=>{
                    if(this.props.agencyChildState)
                    {
                        this.props.agencyChildState(this.state.is_france)
                    }
                    if (this.props.agencySetStateOfParent){
                        this.props.agencySetStateOfParent(true)
                    }
                })
            }
            if (selectedOptionType.label !== 'France' && info.name === 'country' && parentObject === 'primary_address'){
                this.setState({
                    is_france: false
                }, ()=>{
                    if(this.props.agencyChildState)
                    {
                        this.props.agencyChildState(this.state.is_france)
                    }
                    if (this.props.agencySetStateOfParent){
                        this.props.agencySetStateOfParent(false)
                    }
                })

            }
            // ---------------------------------------------------------------------------------------
            // ---------------------------------------------------------------------------------------
            // ---------------------------------------------------------------------------------------
            if (selectedOptionType.label === 'France' && info.name === 'country' && parentObject === 'primary_address') {
                this.setState({
                    agency_pa_is_france: true
                }, ()=>{
                    if(this.props.agencyPaChildState)
                    {
                        this.props.agencyPaChildState(this.state.agency_pa_is_france)
                    }
                    if (this.props.agencySetStateOfParentPa){
                        this.props.agencySetStateOfParentPa(true)
                    }
                })
            }
            if (selectedOptionType.label !== 'France' && info.name === 'country' && parentObject === 'primary_address'){
                this.setState({
                    agency_pa_is_france: false
                }, ()=>{
                    if(this.props.agencyPaChildState)
                    {
                        this.props.agencyPaChildState(this.state.is_france)
                    }
                    if (this.props.agencySetStateOfParentPa){
                        this.props.agencySetStateOfParentPa(false)
                    }
                })

            }
            if (selectedOptionType.label === 'France' && info.name === 'country' && parentObject === 'secondary_address') {
                this.setState({
                    agency_sa_is_france: true
                }, ()=>{
                    if(this.props.agencySaChildState)
                    {
                        this.props.agencySaChildState(this.state.agency_sa_is_france)
                    }
                    if (this.props.agencySetStateOfParentSa){
                        this.props.agencySetStateOfParentSa(true)
                    }
                })
            }
            if (selectedOptionType.label !== 'France' && info.name === 'country' && parentObject === 'secondary_address'){
                this.setState({
                    agency_sa_is_france: false
                }, ()=>{
                    if(this.props.agencySaChildState)
                    {
                        this.props.agencySaChildState(this.state.agency_sa_is_france)
                    }
                    if (this.props.agencySetStateOfParentSa){
                        this.props.agencySetStateOfParentSa(false)
                    }
                })

            }
            // -------------Order Edit Pre Trip Material Address France Check
            if (selectedOptionType.label === 'France' && info.name === 'country' && parentObject === 'pre_trip_material_address') {
                console.log("condition true");
                this.setState({
                    ptma_is_france: true
                }, ()=>{
                    if(this.props.childrenStatePtma)
                    {
                        this.props.childrenStatePtma(this.state.ptma_is_france)
                    }
                    if (this.props.setStateOfParent){
                        this.props.setStateOfParent(true)
                    }
                })
            }
            if (selectedOptionType.label !== 'France' && info.name === 'country' && parentObject === 'pre_trip_material_address'){
                this.setState({
                    ptma_is_france: false
                }, ()=>{
                    if(this.props.childrenStatePtma)
                    {
                        this.props.childrenStatePtma(this.state.ptma_is_france)
                    }
                    if (this.props.setStateOfParent){
                        this.props.setStateOfParent(false)
                    }
                })

            }

            // -------------Order Edit video shipping address France Check
            if (selectedOptionType.label === 'France' && info.name === 'country' && parentObject === 'video_shipping_address') {
                console.log("condition true");
                this.setState({
                    vsa_is_france: true
                }, ()=>{
                    if(this.props.childrenStateVsa)
                    {
                        this.props.childrenStateVsa(this.state.vsa_is_france)
                    }
                    if (this.props.setStateOfParentVsa){
                        this.props.setStateOfParentVsa(true)
                    }
                })
            }

            if (selectedOptionType.label !== 'France' && info.name === 'country' && parentObject === 'video_shipping_address'){
                this.setState({
                    vsa_is_france: false
                }, ()=>{
                    if(this.props.childrenStateVsa)
                    {
                        this.props.childrenStateVsa(this.state.vsa_is_france)
                    }
                    if (this.props.setStateOfParentVsa){
                        this.props.setStateOfParentVsa(false)
                    }
                })
            }


		if (parentObject === 'pre_trip_material_address' && (info.name === 'state' || info.name === 'country')) {
			if (selectedOptionType === null) {
				if ('getCarolinaTax' in this.props) {
					this.props.getCarolinaTax('tax');
				}
			}
		}

        if (parentObject === 'primary_address' && info.name === 'state' && (selectedOptionType.label.toLowerCase() === 'north carolina')) {
            this.setState({
                show_tax_exempt: true
            }, ()=>{

                if (this.props.setStateOfParentNCS){
                    this.props.setStateOfParentNCS(this.state.show_tax_exempt)
                }
            })
        }
        if (parentObject === 'primary_address' && info.name === 'state' && (selectedOptionType.label.toLowerCase() !== 'north carolina')) {
            this.setState({
                show_tax_exempt: false
            }, ()=>{

                if (this.props.setStateOfParentNCS){
                    this.props.setStateOfParentNCS(this.state.show_tax_exempt)
                }
            })
        }

		//start chnage logic for north caroline will remove in future
		// if (info.name === 'state' && (selectedOptionType.label.toLowerCase() === 'north carolina')) {
		// 	if ('getCarolinaTax' in this.props) {
		// 		this.props.getCarolinaTax('tax', 'carolina');
		// 	}
		// }
		//end chnage logic for north caroline will remove in future

		if (info['action'] === 'select-option') {

			//start chnage logic for north caroline will remove in future
			// if (this.state.adressObject.state_name === 'north carolina') {
			// 	if ( this.props.updateNCTaxCheckBox ) {
			// 		this.props.updateNCTaxCheckBox(1, this.props.type);
			// 	}
			// 	if ('getCarolinaTax' in this.props) {
			// 		this.props.getCarolinaTax('tax');

			// 	}
			// }
			//end chnage logic for north caroline will remove in future

			let shippingDetail = { ...this.state.adressObject };
			shippingDetail[info['name'] + '_id'] = selectedOptionType['value'];
			shippingDetail['state_name'] = selectedOptionType.label.toLowerCase();
			if (info['name'] === 'country'){

                this.props.getStateCall(selectedOptionType['value'], this.props.type);
                shippingDetail['state_id'] = null;
			    shippingDetail['state_name'] = null;

            }

			this.setState({ adressObject: shippingDetail }, function () {
				if (this.props.updateZipReq) {
					if (this.state.adressObject.country_id) {
						var tempCountry = returnLabelWithValue(this.state.adressObject.country_id, this.props.options.countries);
						if (tempCountry) {
							if (OC.COUNTRIESNOTREQZIP.includes(tempCountry.label)) {
								this.props.updateZipReq(false, this.props.type);
							} else {
								this.props.updateZipReq(true, this.props.type);
							}
						}
					}
				}

				//start chnage logic for north caroline will remove in future
				// if ( this.props.updateNCTaxCheckBox && this.state.adressObject.state_name === 'north carolina') {
				// 	this.props.updateNCTaxCheckBox(1, this.props.type);
				// } else if (this.props.updateNCTaxCheckBox && this.state.adressObject.state_name !== 'north carolina') {
				// 	this.props.updateNCTaxCheckBox(0, this.props.type);
				// }
				//end chnage logic for north caroline will remove in future

			});
		}
		else if (info['action'] === 'clear') {
			let shippingDetailClear = { ...this.state.adressObject };
			shippingDetailClear['country_id'] = null;
			shippingDetailClear['state_id'] = null;
			this.setState({ adressObject: shippingDetailClear, states: [] });
			this.props.clearState(this.props.type);
		}
	}
	componentDidUpdate(prevProps) {
		if (prevProps.options.countries !== this.props.options.countries) {
			if (this.props.defaultCountry && !this.state.adressObject.country_id) {
				this.setState({
					adressObject: { ...this.props.adressObject, country_id: returnColumnValueWithColumnMatch(this.props.options.countries, 'value', [OC.DEFAULTCOUNTRY], 'label') }
				}, function () {
					if (this.state.adressObject.country_id)
						this.props.getStateCall(this.state.adressObject.country_id, this.props.type);
				});
			}
		}
		if (prevProps.states !== this.props.states && (this.props.type === this.props.statesType)) {
			this.setState({ states: this.props.states });
		}
		if (prevProps.adressObject !== this.props.adressObject) {
			this.setState({ adressObject: this.props.adressObject }, function () {
				if ((prevProps.adressObject.country_id !== this.props.adressObject.country_id) && this.props.adressObject.country_id) {
					this.props.getStateCall(this.props.adressObject.country_id, this.props.type);
				}
			})
		}
		var textReplace = document.querySelectorAll(".customMsg .srv-validation-message");
		if (textReplace.length) {
			for (let i = 0; i < textReplace.length; i++) {
				let str = textReplace[i].innerHTML;
				let newStr = str.replace(this.props.replaceText, "");
				textReplace[i].innerHTML = newStr;
			}
		}

		// if ( this.state.adressObject.state_name  )
	}
	componentDidMount() {
		this.props.provideCtrl({
			returnAddressData: () => this.returnAddressData()
		});
	}
	componentWillUnmount() {
		this.props.provideCtrl(null);
	}
	render() {
		const { options, type, readOnly, /*validatorInstance*/ } = this.props;
		// validatorInstance.purgeFields();
		const { adressObject, states, is_france } = this.state;
		const filterConfig = {
			ignoreCase: true,
			matchFrom: 'start',
		};
		// if (type === 'video_shipping_address')

		return (
			<div className='clearfix'>
				<span></span>
				<div className="col-md-6" id={this.props.htmlElement + "Country"}>
					<div className="form-group">
						<label htmlFor="country_id">Country
							{
								this.props.requiredField.country_id && this.props.requiredField.country_id.includes("required") &&
								<span className="requiredClass">*</span>
							}
						</label>

						{

							readOnly
								?
								<div>
									<label htmlFor="country_id">
										{
											adressObject.country.title
										}
									</label>
								</div>
								:
								<React.Fragment>
									<Select
										value={returnLabelWithValue(adressObject.country_id, options.countries)}
										onChange={(event, info) => this.handleChangeAddressDropDown(event, info, type)}
										options={options.countries}
										isSearchable={true}
										isMulti={false}
										name='country'
										isClearable={this.props.isClearableCountryPro}
										placeholder={'Select Country'}
										filterOption={createFilter(filterConfig)}
									/>
									{
										this.props.requiredField.country_id &&
										<span className="customMsg">
											{this.props.validatorInstance.message(this.props.htmlElement + 'Country', adressObject.country_id, this.props.requiredField.country_id)}
										</span>
									}
								</React.Fragment>
						}


					</div>
				</div>
				<div className="col-md-6" id={this.props.htmlElement + "Street"}>
					<div className='form-group'>
						<label htmlFor="proshow_file_company_name">Street
							{
								this.props.requiredField.street_address_1 && this.props.requiredField.street_address_1.includes("required") &&
								<span className="requiredClass">*</span>
							}
						</label>
						{

							readOnly
								?
								<div>
									<label htmlFor="country_id">
										{
											adressObject.street_address_1
										}
									</label>
								</div>
								:
								<React.Fragment>
									<input type="text" name="street_address_1" className="form-control" value={adressObject.street_address_1 ? adressObject.street_address_1 : ''} onChange={(event) => this.handleChanegAddress(event, type)} />
									{
										this.props.requiredField.street_address_1 &&
										<span className="customMsg">
											{this.props.validatorInstance.message(this.props.htmlElement + 'Street', adressObject.street_address_1, this.props.requiredField.street_address_1)}
										</span>
									}
								</React.Fragment>
						}
					</div>
				</div>
				{
					'orderForm' in this.props ?
						<div className="col-md-6" id={this.props.htmlElement + "Street 2"}>
							<div className='form-group'>
								<label htmlFor="proshow_file_company_name">Street 2</label>
								{

									readOnly
										?
										<div>
											<label htmlFor="street_address_2">
												{
													adressObject.street_address_2 || ''
												}
											</label>
										</div>
										:
										<React.Fragment>
											<input type="text" name="street_address_2" className="form-control" value={adressObject.street_address_2 || ''} onChange={(event) => this.handleChanegAddress(event, type)} />
										</React.Fragment>
								}
							</div>
						</div>
						: ''
				}
				<div className="col-md-12" id={this.props.htmlElement + "City"}>
					<div className='form-group'>

						<div className="row">

							<div className="col-md-4" ref={this.props.htmlElement + "City"}>
								<label htmlFor="proshow_file_company_name">City
						        	{
										this.props.requiredField.city && this.props.requiredField.city.includes("required") &&
										<span className="requiredClass">*</span>
									}
								</label>

								{

									readOnly
										?
										<div>
											<label htmlFor="country_id">
												{
													adressObject.city
												}
											</label>
										</div>
										:
										<React.Fragment>
											<input type="text" name="city" className="form-control" value={adressObject.city ? adressObject.city : ''} onChange={(event) => this.handleChanegAddress(event, type)} />
											{
												this.props.requiredField.city &&
												<span className="customMsg">
													{this.props.validatorInstance.message(this.props.htmlElement + 'City', adressObject.city, this.props.requiredField.city)}
												</span>
											}
										</React.Fragment>
								}
							</div>
							<div className="col-md-4" id={this.props.htmlElement + "State"}>
								<label htmlFor="proshow_file_company_name">State
						        	{
										this.props.requiredField.state_id && this.props.requiredField.state_id.includes("required") &&
										<span className="requiredClass">*</span>
									}
								</label>

								{

									readOnly
										?
										<div>
											<label htmlFor="country_id">
												{
													adressObject.state.title
												}
											</label>
										</div>
										:
										<React.Fragment>
											<Select
												value={returnLabelWithValue(adressObject.state_id, states)}
												onChange={(event, info) => this.handleChangeAddressDropDown(event, info, type)}
												options={states}
												filterOption={createFilter(filterConfig)}
												isSearchable={true}
												isMulti={false}
												name='state'
												placeholder={'Select State'}
											/>
											{
												this.props.requiredField.state_id &&
												<span className="customMsg">
													{this.props.validatorInstance.message(this.props.htmlElement + 'State', adressObject.state_id, this.props.requiredField.state_id)}
												</span>
											}
										</React.Fragment>
								}
							</div>
							<div className="col-md-4">
								<label htmlFor="proshow_file_company_name">Zip
						        	{
										((this.props.requiredField.zipcode && this.props.requiredField.zipcode.includes("required")) /*|| (returnColumnValueWithColumnMatch(this.props.options.countries, 'label', [adressObject.country_id], 'value') === OC.DEFAULTCOUNTRY)*/) &&
										<span className="requiredClass">*</span>
									}
								</label>
								{

									readOnly
										?
										<div>
											<label htmlFor="country_id">
												{
													adressObject.zipcode ? adressObject.zipcode : ''
												}
											</label>
										</div>
										:
										<React.Fragment>
											<input type="text" id={this.props.htmlElement + "Zip"} name="zipcode" className="form-control" value={adressObject.zipcode ? adressObject.zipcode : ''} onChange={(event) => this.handleChanegAddress(event, type)} />
											{
												this.props.requiredField.zipcode &&

												<span className="customMsg">
													{
														this.props.validatorInstance.message(this.props.htmlElement + 'Zip', adressObject.zipcode, this.props.requiredField.zipcode)
													}
												</span>
											}
										</React.Fragment>

								}
							</div>
							<span></span>
						</div>
					</div>
				</div>
			</div>
		);
	};
}

const mapDispatchToProps = dispatch => {
	return ({
		getStateCall: (countryId, type) => { dispatch(getStates(countryId, type)) },
	});
};
function mapStateToProps(state) {
	const { loadingSettings, states, statesType } = state.settingsReducer;
	return {
		loadingSettings, states, statesType
	};
}

const addressForm = connect(mapStateToProps, mapDispatchToProps)(addressFormPage);


export default addressForm;
