import React from 'react';
import {
	OverlayTrigger,
	Popover,
} from "react-bootstrap";
import Select from 'react-select';
import { connect } from 'react-redux';
import { Card } from 'components/Card/Card.jsx';
import { orderDetailEdit, updateOrderProshowFile, updateOrderDetail, deleteOrder, updateProgramType, updateTravelerPassword, updateOrderSettings, updateIsInvoiced, getIsInvoiced } from 'redux/actions/order-actions';
import { ThemeFunctions as TF } from 'helper/ThemeFunctions';
import { toggleModal, optionsHasRole, returnDynamicURL, focusInput, dateFormatMy, closeModal, openModal, returnColumnValueWithColumnMatch, s3StaticPath, returnLabelWithValueGroup, appendValuesWithMatchKeys, appendValues } from 'helper/helperFunctions';
import OpenModalButton from 'components/ThemeComponents/openModelButton';
import OrderDetailForm from 'components/Admin/Order/OrderDetailForm';
import SimpleReactValidator from 'simple-react-validator';
import { getAgency } from 'redux/actions/agency-actions';
import { updateOrderModerator } from 'redux/actions/user-actions';
import { hasPermission } from 'helper/hasPermission';
import { Link } from 'react-router-dom';
import { login } from 'redux/actions/front-end-action.jsx';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getProShowFilesPagination } from 'redux/actions/proShowFile-action';
import Confirmalertfordelete from 'components/ThemeComponents/confirmAlertForDelete';
import { confirmAlert } from 'react-confirm-alert';
import { convertMomentIntoDateObj } from 'helper/helperFunctions';
import MyImage from 'components/Upload/MyImage';
import { addComment, getCommentCategoryByTitle } from "redux/actions/comment-actions";
import { cloneDeep, filter, flatMap, map } from 'lodash'
import { Status } from 'constants/Status';
import ThemeModal from 'components/ThemeComponents/ThemeModal';
import chroma from 'chroma-js';
import InputMask from 'react-input-mask';
import { orderService } from 'services/order';
var Modal = require('react-bootstrap-modal');
var dateFormat = require('dateformat');
const moment = window.moment;

// const customStyles = {

//   singleValue: (provided, state) => {
//     console.log('customStyles', provided);
// 	console.log('state', state);
//     const display = "none";
//     return { ...provided, display };
//   }
// }

class OrderDetailShowAndModalPage extends React.Component {
	constructor(props) {
		super(props);
		this.validatorOrderDetail = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
		this.validatorComment = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
		this.state = {
			order_details: this.props.order_details,
			editOrderModal: false,
			commentHoldAdd: false,
			formType: 'edit',
			editEditor: false,
			editProducer: false,
			editQA: false,
			enableUsernamePassword: false,
			travelerPassword: '',
			travelerPasswordError: '',
			travelerUsername:'',
			Invoicedchecked : this.props.is_invoiced,
			commentOnHold: {
				comment: '',
				comment_category_id: '',
				slug: '',
				uuid: '',
			},
			cancelOrderModal: false,
			cancelComment: '',
		};
		// this.orderDetailUpdate = this.orderDetailUpdate.bind(this);
		this.handleOrderDetailSubmit = this.handleOrderDetailSubmit.bind(this);
		this.showLinkCode = this.showLinkCode.bind(this);
		this.handleChangeModerator = this.handleChangeModerator.bind(this);
		this.changeStatusModerator = this.changeStatusModerator.bind(this);
		this.showSelect = this.showSelect.bind(this);
		this.deleteConfirmOrder = this.deleteConfirmOrder.bind(this);


		// this.props.getProgramTypeCall();
		this.toggleModal = toggleModal.bind(this);
	}


	componentDidMount() {
		this.props.proShowFilesPaginationDataCall(0, '', '', 0, false);
        this.props.getIsInvoicedCall(this.props.orderId);
	}
	deleteConfirmOrder(orderId) {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<Confirmalertfordelete typeName="Order" description="Are you sure to delete this order?" onClosePro={onClose} deleteType={() => this.props.deleteOrderCall(orderId).then(
						res => {
							this.props.history.push('/Admin/orders');
						}
					)} />
				)
			}
		})
	}
	showSelect(options, accesor, accessor_id, name) {
		return (
			<div className="flexElem editAdmin" >
				<Select
					value={
						this.state.order_details[accesor] ? {
							id: this.state.order_details[accesor].id, name: this.state.order_details[accesor].name
						} : null
					}
					options={optionsHasRole(options, accesor)}
					onChange={(e) => this.handleChangeModerator(e, accessor_id, name)}
					getOptionLabel={(option) => option.name}
					getOptionValue={(option) => option.id}
					className="selectUser"
					placeholder="Select User"
					isMulti={false}
				/>

				<OverlayTrigger placement='top' overlay={<Popover id='copyLink' >Cancel</Popover>} >
					<a href="#/" onClick={() => this.changeStatusModerator(name)}><i className="fa fa-times"></i></a>
				</OverlayTrigger>
			</div>
		);
	}

	changeStatusModerator(name, callback) {
		this.setState({ ['edit' + name]: !this.state['edit' + name] }, callback);
	}
	handleChangeModerator(e, accessor_id, name) {
		var data = { [accessor_id]: e.id }
		this.changeStatusModerator(name, this.props.updateOrderModeratorCall(data, this.state.order_details.id));
	}
	changeUsernamePassword = (e) => {
		const { value, name } = e.target;

		this.setState({ [name]: value });

		// this.setState(prevState => ({
		// 	...prevState, order_details: {
		// 		...prevState.order_details, password: value
		// 	}
		// }), ()=>{
		// 	console.log('state password', this.state.order_details.password);
		// });

		// console.log('value', value);
	}
	checkedToggle = (e) => {
		if (e.target.checked=== true){
			this.setState({Invoicedchecked : 1})
		}
		else {
			this.setState({Invoicedchecked : 0})
		}
        this.props.updateIsInvoicedCall(this.props.orderId, {is_invoiced: e.target.checked=== true ? 1 : 0 });
		// orderService.updateIsInvoiced(this.props.order_details.id, {is_invoiced: e.target.checked=== true ? 1 : 0 }).then(response =>{
		// 	const orderDetailState = { ...this.state.order_details };
		// 	orderDetailState['is_invoiced'] = response.rec.is_invoiced;
		// 	this.setState({ order_details: orderDetailState });
		// })
	}
	submitchangeUsernamePassword = (e) => {
		e.preventDefault();
		const { order_details, travelerPassword, travelerUsername } = this.state;
		if ( travelerPassword.length === 7) {
			this.setState({travelerPasswordError:'Traveler password must not be 7 digits'}, ()=>{
				return false;
			})
		} else {
			this.props.updateTravelerPasswordCall(order_details.id, { password: travelerPassword, username:travelerUsername }).then(response => {
				this.setState({ enableUsernamePassword: false, travelerPasswordError:'' });
			});
		}
	}
	showLinkCode(title, username, password, link) {
		const { enableUsernamePassword, travelerPassword, travelerUsername, travelerPasswordError } = this.state;
		// console.log('this.state.order_details.agency.single_account', typeof this.state.order_details.agency.single_account === 'undefined' || !this.state.order_details.agency.single_account ?'no':this.state.order_details.agency.single_account)
		var domainSlug = typeof this.state.order_details.agency.single_account === 'undefined' || !this.state.order_details.agency.single_account ? null : this.state.order_details.agency.single_account.account.domain_slug;
		if (domainSlug !== null)
			link = returnDynamicURL(domainSlug, username, password);

		return (
			<tbody className={title === 'Traveler' ? 'highlight_green' : (title === 'Photo Admin' ? 'highlight_blue' : '')}>
				<tr>
					<td valign="top">{title} Code</td>
					{
							enableUsernamePassword && title === 'Traveler'
								?
								<td colSpan="2">
									<React.Fragment>
										<form onSubmit={this.submitchangeUsernamePassword} >
											<div className="flexElem alignCenter">
												<div className="flexElem alignCenter">
													<div className="blue_label mr-md">
														Username
													</div>
													<div>
														<input className="form-control" type="text" name="travelerUsername" value={travelerUsername} onChange={this.changeUsernamePassword} required />
													</div>
												</div>
												<div className="flexElem alignCenter ml-md">
													<div className="blue_label mr-md">
														Password
													</div>
													<div>
														<input className="form-control" type="text" name="travelerPassword" value={travelerPassword} onChange={this.changeUsernamePassword} minLength={4} maxLength={10} required />
													</div>
												</div>
											</div>
											<br/>
											{
												travelerPasswordError &&
													<span className="text-danger"> { travelerPasswordError }</span>
											}
											<button type="submit" className="customBtn btn ml-sm btn-primary btn btn-default" > Submit </button>
											<button className="customBtn btn ml-sm btn-primary btn btn-default" onClick={() => this.setState({ enableUsernamePassword: !enableUsernamePassword })} >Cancel</button>
										</form>
									</React.Fragment>
								</td>
								:
								<React.Fragment>
									<td><span className="blue_label" style={{ "textTransform": "uppercase" }}>Username:</span> {username}</td>
									<td><span className="blue_label" style={{ "textTransform": "uppercase" }}>Password:</span> {password}</td>
								</React.Fragment>

						}


				</tr>
				{
					title === 'Traveler' &&
					<tr>
						<td colSpan="3" className="text-right">

							{
								title === 'Traveler' && !enableUsernamePassword &&
								<button className="customBtn btn ml-sm btn-primary btn btn-default" onClick={() => this.setState({ enableUsernamePassword: !enableUsernamePassword })} >Change Traveler Credentials</button>
							}
						</td>
					</tr>
				}
				<tr>
					<td colSpan="3" className="blue_label">
						<div className="flexElem flexResponsive quickLink" style={{ "alignItems": "center" }}>
							<span className="mr-sm" style={{ "width": "77px" }}>
								<a href="#/" >Quick Link:</a>
							</span>
							<input readOnly id={title === 'Traveler Code' ? 'traverller_link' : 'admin_link'} type='text' value={link} />
							<OverlayTrigger placement='top' overlay={<Popover id='copyLink' >Copy to clipboard</Popover>} >
								<span className="ml-sm">
									<CopyToClipboard text={
										` ${title} USERNAME: ${username} \n ${title} PASSWORD: ${password} \n Website Link: ${link} `
									}
									>
										<button className="orderDetailsAction copyColorAction" type="button">
											<span className="">&nbsp;</span>
										</button>
									</CopyToClipboard>

									{/* <a className="orderDetailsAction copyAction small_icon" style={{ cursor: 'pointer' }} onClick={this.copyLink.bind(this, title === 'Traveler Code' ? 'traverller_link' : 'admin_link')} >Copy</a> */}
								</span>
							</OverlayTrigger>
							<OverlayTrigger placement='top' overlay={<Popover id='copyLink' >Login</Popover>} >
								<span className="ml-sm">

                                <a class="orderDetailsAction small_icon btn" style={{cursor: "pointer"}} href={`microsoft-edge:${link}`}>Login</a>
									{/* <button onClick={() => { this.props.loginCall(username, password) }} className="orderDetailsAction small_icon btn" style={{ cursor: 'pointer' }} >Login</button> */}
									{/* <button onClick={() => {this.openLoginLink(title)}} className="orderDetailsAction small_icon btn" style={{ cursor: 'pointer' }} >Login</button> */}
								</span>
							</OverlayTrigger>
						</div>
					</td>
				</tr>
			</tbody>
		);
	}

	copyLink = (link, e) => {
		e.preventDefault();
		var quickLink = document.getElementById(link);
		quickLink.select();
		document.execCommand('copy')
	}

	handleOrderDetailSubmit(e) {
		e.preventDefault();
        let data = { order_details: this.ctrl_ODF.returnOrderDetailData() }
		let data_clone = cloneDeep(data);
		if (this.validatorOrderDetail.allValid() && !data_clone.order_details.departure_date_is_greater) {
			this.setState({ submitted: true }, function () {

				const data = { order_details: this.ctrl_ODF.returnOrderDetailData() }
				let data_clone = cloneDeep(data);
				for (let key in data_clone.order_details.order_dates) {
					if (data_clone.order_details.order_dates[key].value) {
						data_clone.order_details.order_dates[key]['value'] = dateFormat(convertMomentIntoDateObj(data_clone.order_details.order_dates[key].value), "yyyy-mm-dd h:MM TT")
					}
				}
				this.props.updateOrderCall(data_clone, this.props.orderId).then(
					response => {
						this.setState({
							editOrderModal: false
						})
					}
				)
			});
		} else {
			this.setState({ submitted: false });
			focusInput(this.validatorOrderDetail.getErrorMessages());
			this.validatorOrderDetail.showMessages();
			this.forceUpdate();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.order_details !== prevProps.order_details) {
			this.setState({ order_details: this.props.order_details });
		}

		if (this.state.enableUsernamePassword !== prevState.enableUsernamePassword) {
			this.setState({ travelerPassword: this.props.order_details.password, travelerUsername:this.props.order_details.username, travelerPasswordError:'' });
		}

		if ('onHoldLastComment' in this.props.order_details) {
			if (this.props.order_details.onHoldLastComment !== prevProps.order_details.onHoldLastComment && this.props.order_details.onHoldLastComment !== null) {
				this.setState({ commentOnHold: this.props.order_details.onHoldLastComment });
			}
		}

		if (prevProps.commentCategories !== this.props.commentCategories) {
			this.setState((state) => {
				return {
					commentOnHold: {
						...state.comment,
						comment_category_id: returnColumnValueWithColumnMatch(this.props.commentCategories, 'id', "Hold", 'commentCategoryCode')
					}
				}
			});
		}

        if (prevProps.is_invoiced !== this.props.is_invoiced){
            this.setState({Invoicedchecked: this.props.is_invoiced})
        }
	}

	changeOrderStatus = (selected) => {
		if (selected.label === Status.CANCELLED && this.state.cancelOrderModal === false) {
			this.setState({
				cancelOrderModal: true
			})
		} else {
			let order_details = cloneDeep(this.state.order_details);
			if (this.state.cancelOrderModal) {
				const orderStatus = this.props.orderStatuses;
				const cancel = orderStatus.find(item => {
					return item.label === Status.CANCELLED
				})
				order_details.status_id = cancel.value;
				order_details.status.status = cancel.label;
				order_details.status.id = cancel.value;
			} else {
				order_details.status_id = selected.value;
				order_details.status.status = selected.label;
				order_details.status.id = selected.value;
			}
			this.setState(prevState => ({
				// order_details: {
				// 	...prevState.order_details,
				// 	status_id: selected.value,
				// 	status: {
				// 		status: selected.label,
				// 		id: selected.value
				// 	}
				// }
				order_details: order_details
			}), () => {
				// this.props.updateOrderCall({ order_details: this.state.order_details }, this.props.orderId)
				if (this.state.cancelComment) {

                    // console.log("before", this.state.cancelComment);
					this.props.getCommentCategoryByTitle(['Cancel']).then(
						res => {
							const data = {
								comment: this.state.cancelComment,
								comment_category_id: res.data.comment_category_id
							}
							this.props.addCommentCall(data, this.props.orderId).then(res => {

                                this.props.updateOrderCall({ order_details: this.state.order_details }, this.props.orderId)

                                this.setState({
                                    cancelOrderModal: false,
                                    cancelComment: ''
                                })
                                // console.log("after", this.state.cancelComment);
                            })
						}
					)
				} else {
					this.props.updateOrderCall({ order_details: this.state.order_details }, this.props.orderId);
					this.setState({
						cancelOrderModal: false
					})
				}
			})
		}
	}
	handleChangeProshowFile = (selectedOptionType, info) => {
		if (info['action'] === 'select-option') {
			this.props.updateOrderProshowFileCall(this.props.orderId, { 'pro_show_file_id': selectedOptionType['id'] });
		}
	}

	inputChangeHandler = (e) => {
		const { value, name } = e.target;
		this.setState({
			order_details: {
				...this.state.order_details,
				[name]: value
			}
		}, () => {
			this.props.updateOrderCall({ order_details: this.state.order_details }, this.props.orderId)
		})
	}

	disableOption = (option) => {
		if (option.price !== this.props.pricing_details.program_type.price)
			return true;
	}

	hideOption = (option) => {
		if (option.price !== this.props.pricing_details.program_type.price)
			return null;
	}

	handleChangeProgramType = (selectedOptionType, info) => {
		if (info['action'] === 'select-option') {
			this.props.updateProgramTypeCall(this.props.order_details.id, selectedOptionType.value).then(response => {
				this.props.getOrderCall(this.props.order_details.id);
			});
		}
	}

	updateOrderSettingsModal = () => {
		this.setState({ commentHoldAdd: true });
	}

	updateOrderSettings = (e) => {
		var value = e.target.value;
		var key = e.target.name;
		this.props.updateOrderSettingsCall(this.props.order_details.id, value, key).then(
			res => {
				this.props.getOrderCall(this.props.order_details.id)
			}
		);
	}

	commentHoldAddSubmitHandler = () => {

		if (this.validatorComment.allValid()) {
			const data = {
				...this.state.commentOnHold,
			}

			this.props.addCommentCall(data, this.props.order_details.id).then(
				response => {
					this.setState(state => {
						this.props.updateOrderSettingsCall(this.props.order_details.id, 0, 'on_hold').then(
							res => {
								this.props.getOrderCall(this.props.order_details.id)
							}
						);

						return {
							commentHoldAdd: false,
							commentOnHold: {
								...state.commentOnHold,
								comment: response.data.comment.comment,
							}
						}


					})
				}
			);

		} else {
			this.validatorComment.showMessages();
			this.forceUpdate();
		}
	}

	commentOnHoldChangeHandler = (e) => {
		const value = e.target.value;
		this.setState((state) => {
			return {
				commentOnHold: {
					...state.commentOnHold,
					comment: value
				}
			}
		})
	}

	returnOrderDetails = (order_details) => {
		this.setState(prevState => ({
			order_details: {
				...prevState.order_details,
				...order_details
			}
		}))
	}

    // emailTest = () => {
    //    return orderService.emailTest().then((email) => {
    //     console.log("emailed successfull");
    //    })
    // }

    render() {
		const { editOrderModal, formType, order_details, commentHoldAdd, commentOnHold } = this.state;
		const { proShowFileData, programType, pricing_details, is_france, initial_country_France,
            vsa_initial_country_France,
            ptma_initial_country_France,
            ptma_is_france,
            vsa_is_france, is_invoiced } = this.props;

        let is_order_france = false;
        is_order_france = vsa_initial_country_France || ptma_initial_country_France || ptma_is_france || vsa_is_france
		let orderStatuses = appendValues(cloneDeep(this.props.orderStatuses), [{ column: 'color', value: '#000000' }] );
		orderStatuses = appendValuesWithMatchKeys( orderStatuses , ['Cancelled'], 'label', 'color', 'red' );
		orderStatuses = appendValuesWithMatchKeys( orderStatuses , ['Approved'], 'label', 'color', 'green' );
		const dot = (color = '#ccc') => ({
		  alignItems: 'center',
		  display: 'flex',

		  ':before': {
		    backgroundColor: color,
		    borderRadius: 10,
		    content: '" "',
		    display: 'block',
		    marginRight: 8,
		    height: 10,
		    width: 10,
		  },
		});

		const colourStyles = {
		  control: styles => ({ ...styles, backgroundColor: 'white' }),
		  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		    const color = chroma(data.color);
		    return {
		      ...styles,
		      backgroundColor: isDisabled
		        ? null
		        : isSelected
		        ? data.color
		        : isFocused
		        ? color.alpha(0.1).css()
		        : null,
		      color: isDisabled
		        ? '#ccc'
		        : isSelected
		        ? chroma.contrast(color, 'white') > 2
		          ? 'white'
		          : 'black'
		        : data.color,
		      cursor: isDisabled ? 'not-allowed' : 'default',

		      ':active': {
		        ...styles[':active'],
		        backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
		      },
		    };
		  },
		  input: styles => ({ ...styles, ...dot() }),
		  placeholder: styles => ({ ...styles, ...dot() }),
		  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
		};


		const { group_leader_contacts } = order_details;
		let groupLeaderEmails = '';
		group_leader_contacts.map((item, index) => {
			if (item.email != null) {
				return groupLeaderEmails += item.email + (group_leader_contacts.length > index + 1 ? ';' : '')
			}
			return '';
		});
		let onlySamePriceProgramTypes = [];
		if (pricing_details.program_type) {
			let prorgramTypeTemp = cloneDeep(programType);
			onlySamePriceProgramTypes = map(prorgramTypeTemp, 'options');
			onlySamePriceProgramTypes = flatMap(onlySamePriceProgramTypes);
			onlySamePriceProgramTypes = filter(onlySamePriceProgramTypes, function (item) {
				return Number(item.price) === Number(pricing_details.program_type.price)
			})
			// onlySamePriceProgramTypes.forEach((program, index) => {
			// 	return program.filter(item => {
			// 		return Number(item.price) === Number(pricing_details.program_type.price)
			// 	})
			// })

			// onlySamePriceProgramTypes = cloneDeep(programType);
			// onlySamePriceProgramTypes.forEach(program => {
			// 	program.options = program.options.filter(item => {
			// 		return Number(item.price) === Number(pricing_details.program_type.price)
			// 	});
			// })
		}
		if (this.props.shipping_details && this.props.order_details && this.props.order_details.status && this.props.order_details.status.title !== 'Pending') {
			if (this.props.shipping_details.camera_ship_status !== null) {
				orderStatuses = orderStatuses.filter(status => {
					return status.label !== 'Pending'
				})
			}
		}
		return (
			<div className="orderContent">
				{
					order_details &&
					<div id="order_details">
						<div className="flexElem" style={{ "flexWrap": "wrap", "justifyContent": "space-between" }}>
							<div className="orderDetailsHeader">

								<span>Order Details</span>



								<span className="controls flexElem">
									<div className="mr-sm">
										{
											(this.state.order_details.status.title === 'Cancelled' && hasPermission('order-delete')) &&
											<button type='button' className="deleteIcon orderDetailsAction" onClick={() => this.deleteConfirmOrder(order_details.id)}>Delete</button>
										}
									</div>
									<OpenModalButton
										openModal={openModal.bind(this, "editOrderModal")}
										classButton={['orderDetailsAction editIcon']}
										buttonName="Edit Order"
										tooltipText="Edit Order Details"
										classIcon={[]}
									/>
								</span>

							</div>
							<div className="orderDetailsBox box_small">
								<table className="table orderTable last_three_rows">
									<tbody>
										{TF.showValueLabel('Barcode', order_details.barcode)}
										{TF.showValueLabel('Group Name', order_details.group_name)}
										<React.Fragment>
											{group_leader_contacts.map((item, index) => {
												return <>
													<tr class=""><td className="td_heading">Contact Info{
														group_leader_contacts.length > 1 ? '('+ (index + 1) +')' : ''
													}:</td><td><span>{item.name}</span></td></tr>
													<tr class=""><td>Phone:</td><td><span>
													{
														initial_country_France || is_france || is_order_france
														?
															<InputMask
																mask={"99-9-99-99-99-99"}
																maskChar={null}
																type="text"
																className="form-control notCard bg_none pl-none cursorDefault"
																placeholder="12-3-45-67-89-10"
																value={item.phone1}
																readOnly={true}
															/>
														:
															<InputMask
																mask={"999-999-9999-999999"}
																maskChar={null}
																type="text"
																className="form-control notCard bg_none pl-none cursorDefault"
																placeholder="123-456-7890-123456"
																value={item.phone1}
																readOnly={true}
															/>
													}
													</span></td></tr>
													<tr class=""><td>Cell:</td><td><span>
													{
														initial_country_France || is_france || is_order_france
														?
															<InputMask
																mask={"99-9-99-99-99-99"}
																maskChar={null}
																type="text"
																className="form-control notCard bg_none pl-none cursorDefault"
																placeholder="12-3-45-67-89-10"
																value={item.phone2}
																readOnly={true}
															/>
														:
															<InputMask
																mask={"999-999-9999-999999"}
																maskChar={null}
																type="text"
																className="form-control notCard bg_none pl-none cursorDefault"
																placeholder="123-456-7890-123456"
																value={item.phone2}
																readOnly={true}
															/>
													}
													</span></td></tr>
													<tr class=""><td>Email:</td><td><span>{item.email}</span></td></tr>
												</>
											})}
										</React.Fragment>
										{/* {TF.showValueLabel('Contact Name(s)', <React.Fragment>
											{group_leader_contacts.map((item, index) => {
												return <span key={item.id + 'i'}>{item.name}{group_leader_contacts.length > index + 1 ? ',' : ''} </span>
											})}
										</React.Fragment>)} */}

                                            {/* ---------------> Phone Format Check Start <-------------*/}
                                        {/* {
                                            initial_country_France || is_france
                                            ?
                                                <React.Fragment>
                                                    {
                                                        TF.showValueLabel(
                                                            'Phone(s)',

                                                            <React.Fragment>
                                                            {
                                                                group_leader_contacts.map((item, index) => {
                                                                    return <span key={item.id + 'j'}>
                                                                        <InputMask
                                                                            mask={"99-99-99-99-99-99-99"}
                                                                            maskChar={null}
                                                                            type="text"
                                                                            // name={name}
                                                                            className="form-control notCard bg_none pl-none cursorDefault"
                                                                            placeholder="12-34-56-78-90"
                                                                            value={item.phone1}
                                                                            readOnly={true}
                                                                        />
                                                                        {group_leader_contacts.length > index + 1 ? ',' : ''}
                                                                    </span>

                                                                })
                                                            }
                                                            </React.Fragment>
                                                        )
                                                    }
                                                    {
                                                        TF.showValueLabel('Cell(s)', <React.Fragment>
                                                            {group_leader_contacts.map((item, index) => {
                                                                return <span key={item.id + 'k'}>
                                                                    <InputMask
                                                                        mask={"99-99-99-99-99-99-99"}
                                                                        maskChar={null}
                                                                        type="text"
                                                                        // name={name}
                                                                        className="form-control notCard bg_none pl-none cursorDefault"
                                                                        placeholder="12-34-56-78-90"
                                                                        value={item.phone2}
                                                                        readOnly={true}
                                                                    />
                                                                    {group_leader_contacts.length > index + 1 ? ',' : ''}
                                                                </span>
                                                            })}
                                                        </React.Fragment>)
                                                    }
                                                </React.Fragment>

                                            :
                                                <React.Fragment>
                                                    {
                                                        TF.showValueLabel(
                                                            'Phone(s)',

                                                            <React.Fragment>
                                                            {
                                                                group_leader_contacts.map((item, index) => {
                                                                    return <span key={item.id + 'j'}>
                                                                        <InputMask
                                                                            mask={"999-999-9999-999999"}
                                                                            maskChar={null}
                                                                            type="text"
                                                                            // name={name}
                                                                            className="form-control notCard bg_none pl-none cursorDefault"
                                                                            placeholder="123-456-7890-123456"
                                                                            value={item.phone1}
                                                                            readOnly={true}
                                                                        />
                                                                        {group_leader_contacts.length > index + 1 ? ',' : ''}
                                                                    </span>

                                                                })
                                                            }
                                                            </React.Fragment>
                                                        )
                                                    }
                                                    {
                                                        TF.showValueLabel('Cell(s)', <React.Fragment>
                                                            {group_leader_contacts.map((item, index) => {
                                                                return <span key={item.id + 'k'}>
                                                                    <InputMask
                                                                        mask={"999-999-9999-999999"}
                                                                        maskChar={null}
                                                                        type="text"
                                                                        // name={name}
                                                                        className="form-control notCard bg_none pl-none cursorDefault"
                                                                        placeholder="123-456-7890-123456"
                                                                        value={item.phone2}
                                                                        readOnly={true}
                                                                    />
                                                                    {group_leader_contacts.length > index + 1 ? ',' : ''}
                                                                </span>
                                                            })}
                                                        </React.Fragment>)
                                                    }
                                                </React.Fragment>

                                        } */}
                                               {/* ---------------> Phone Format Check End <-------------*/}

										{/* {TF.showValueLabelForEmailLink('Email(s)', <React.Fragment>
											{group_leader_contacts.map((item, index) => {
												return <span key={item.id}>{item.email}{group_leader_contacts.length > index + 1 ? ',' : ''} </span>
											})}
										</React.Fragment>)} */}
										{/* <tr className='form-group'>
											<td>Email(s)</td>
											<td>
												{
													groupLeaderEmails !== '' &&
													<a rel="noopener noreferrer" href={`mailto:${groupLeaderEmails}?subject=your title&body=TThe message`} target="_blank" style={{ backgroundColor: '#ffffa0' }} >{groupLeaderEmails}</a>
												}
											</td>
										</tr> */}
										{/* {TF.showValueLabel('Music Selection', order_details.music_selection_criteria)} */}
										{
											<tr>
												<td className='td_heading'>Music selection</td>
												<td>
													<label className="container-radio mr-md">
														Destination
                                                                        <input onChange={this.inputChangeHandler} value='destination_based' checked={this.state.order_details.music_selection_criteria === 'destination_based'} type='radio' name='music_selection_criteria' className='checkbox' />
														<span className="checkmark"></span>
													</label>
													<label className="container-radio mr-md">
														Age Based
                                                                        <input onChange={this.inputChangeHandler} value='age_based' checked={this.state.order_details.music_selection_criteria === 'age_based'} type='radio' name='music_selection_criteria' className='checkbox' />
														<span className="checkmark"></span>
													</label>
													<label className="container-radio">
														Custom
                                                                        <input onChange={this.inputChangeHandler} value='custom_music_based' checked={this.state.order_details.music_selection_criteria === 'custom_music_based'} type='radio' name='music_selection_criteria' className='checkbox' />
														<span className="checkmark"></span>
													</label>
												</td>
											</tr>
										}
										{TF.showValueLabel('Destination', order_details.destinationText)}
										{order_details.agency_type != null && TF.showValueLabel('Group Type', order_details.agency_type.title)}
										{TF.showValueLabel('Tour Code', order_details.client_tour_code)}
										{
											pricing_details.program_type &&
											<tr id="programtype">
												<td>Select Program Type:</td>
												<td>

													<Select
														value={returnLabelWithValueGroup(pricing_details.program_type_id, programType)}
														onChange={this.handleChangeProgramType}
														// options={programType}
														options={onlySamePriceProgramTypes}
														isSearchable={true}
														isMulti={false}
														name='program_type_id'
														placeholder={'Select Program'}
													// isOptionDisabled={(option) => this.disableOption(option)}
													/>

												</td>
											</tr>
										}

										{/* {
											(hasPermission("agency-edit") && order_details.customer_slates_for_videos_id) &&
											TF.showValueLabel('Secondary Agency', <Link target="_blank" className="blue_link target_blank" to={'/Admin/' + order_details.customer_slates_for_videos_id + '/agency-edit'} >
												{
													order_details.customer_slates_for_videos.name
												}
											</Link>
											)
										} */}
										<tr>
											<td>Randomization</td>
											<td>{order_details.sortOrder === 'Randomization' ? order_details.sortOrderCount : 'No images were randomized'}</td>
										</tr>
										<tr>
											<td>Positioning</td>
											<td>{order_details.sortOrder === 'Positioning' ? order_details.sortOrderCount : 'No images were positioned'}</td>
										</tr>
										{
											<tr>
												<td className='td_heading'>Invoiced</td>
												<td>
													<label className="container-checkbox mr-md">

                                                        <input  value={is_invoiced} type='checkbox' onChange={(e)=> {this.checkedToggle(e)}} name='Invoiced' checked={(this.state.Invoicedchecked || is_invoiced) ? 1 : 0} className='checkbox' />
														{/* <span className="checkmark" ></span> */}
													</label>

												</td>
											</tr>
										}

									</tbody>
								</table>
							</div>
							<div className="orderDetailsBox box_large">
								<div className="flexElem">
									<div className="block">
										<table className="table orderTable">
											<tbody>
												{/* {TF.showValueLabel('Order Status', order_details.status.title)} */}
												<tr >
													<td style={{ width: '35%' }}>Approval Status:</td>
													<td style={{ width: '65%' }}>
														<div className="flexElem alignCenter">
															<div style={{ width: "100%", maxWidth: '320px' }}>
																<Select
																	value={orderStatuses && orderStatuses.filter(item => {
																		if (item.value === this.state.order_details.status.id) {
																			return item
																		}
																		return null;
																	})}
																	onChange={this.changeOrderStatus}
																	options={orderStatuses}
																	name='order_details.status.id'
																	placeholder="Change Order Status"
																	isMulti={false}
																	styles={colourStyles}
																	// className={'Cancelled' === this.state.order_details.status.title?'redColor':null}
																	// style={'Cancelled' === this.state.order_details.status.title?{'color':'red !important'}:null}

																/>
															</div>
														</div>
													</td>
												</tr>
												<tr>
													<td>Hold</td>
													<td>
														{
															'on_hold' in order_details.settings &&
															<React.Fragment>
																{
																	order_details.settings.on_hold.value === '1'
																		?
																		<button value={order_details.settings.on_hold.value} name='on_hold' onClick={this.updateOrderSettings} className='customBtn btn btn-primary btn-default'>Release From Hold</button>
																		:
																		<button value={order_details.settings.on_hold.value} name='on_hold' onClick={this.updateOrderSettingsModal} className='customBtn btn btn-primary btn-default'>Place on Hold</button>
																}
															</React.Fragment>

														}
													</td>

													<td>
														{
															'un_resolved' in order_details.settings &&
															<React.Fragment>
																{
																	order_details.settings.un_resolved.value === '1'
																		?
																		<button value={order_details.settings.un_resolved.value} name='un_resolved' onClick={this.updateOrderSettings} className='customBtn btn btn-danger'>Resolved</button>
																		:
																		<button value={order_details.settings.un_resolved.value} name='un_resolved' onClick={this.updateOrderSettings} className='customBtn btn btn-primary btn-default'>Un Resolved</button>
                                                                }
															</React.Fragment>

														}
													</td>
												</tr>

												{

													hasPermission("agency-edit") &&
													<React.Fragment>
														<React.Fragment>

															{
																TF.showValueLabel('Agency',
																	<React.Fragment>
																		<Link target="_blank" className="blue_link target_blank" to={{
																			pathname: '/Admin/' + order_details.agency_id + '/agency-edit',
																			hash: this.props.orderId
																		}} > {order_details.agency.name}</Link>
																		<div className="display_ib ml-sm">
																			<Link target="_blank" to={'/Admin/' + order_details.agency_id + '/agency-resource'} className="iconOutlineBtn"><i className="fa fa-file" />
																				<span>Agency Resources</span>
																			</Link>
																		</div>
																	</React.Fragment>
																)
															}
														</React.Fragment>
													</React.Fragment>
												}
												{
													!hasPermission("agency-edit") &&
													TF.showValueLabel('Agency', order_details.agency.name)
												}

												{

													(hasPermission("agency-edit") && order_details.agency_id !== order_details.customer_slates_for_videos_id) &&
													<React.Fragment>
														<React.Fragment >

															{
																TF.showValueLabel('Secondary Agency',
																	<React.Fragment>
																		<Link target="_blank" className="blue_link target_blank" to={'/Admin/' + order_details.customer_slates_for_videos_id + '/agency-edit'} > {order_details.customer_slates_for_videos.name}</Link>
																		<div className="display_ib ml-sm">
																			<Link target="_blank" to={'/Admin/' + order_details.customer_slates_for_videos_id + '/agency-resource'} className="iconOutlineBtn"><i className="fa fa-file" />
																				<span>Agency Resources</span>
																			</Link>
																		</div>
																	</React.Fragment>
																)
															}
														</React.Fragment>
													</React.Fragment>
												}
												{
													(!hasPermission("agency-edit") && order_details.agency_id !== order_details.customer_slates_for_videos_id) &&
													TF.showValueLabel('Secondary Agency', order_details.customer_slates_for_videos.name)
												}
											</tbody>
										</table>
									</div>
									<div className="agencyLogoContainer">
										{
											order_details.agency.agency_logo !== undefined && order_details.agency.agency_logo !== null
												?
												<MyImage src={order_details.agency.agency_logo.file_path} alt="primary logo" className="agencyLogo" />
												:
												<MyImage src={s3StaticPath('img/placeholder.png')} className="agencyLogo" alt="primary agency logo placeholder" />
										}

										{
											order_details.agency_id !== order_details.customer_slates_for_videos_id
												?
												order_details.customer_slates_for_videos.agency_logo !== undefined && order_details.customer_slates_for_videos.agency_logo !== null
													?
													<MyImage src={order_details.customer_slates_for_videos.agency_logo.file_path} alt="secondary logo" className="agencyLogo secondaryLogo" />
													:
													<MyImage src={s3StaticPath('img/placeholder.png')} className="agencyLogo secondaryLogo" alt="secondary agency logo placeholder" />
												:
												null
										}
									</div>
								</div>

								<table className="table customStrips orderTable">
									<tbody>
										{TF.showValueLabel('Departure Date', dateFormatMy(order_details.order_dates.departure_date.value), true, { td1: '35%', td2: '65%' })}
										{TF.showValueLabel('Return Date', dateFormatMy(order_details.order_dates.return_date.value), true)}
										{TF.showValueLabel('Last Day Of School', dateFormatMy(order_details.order_dates.last_day_of_school.value), true)}
										{TF.showValueLabel('Pre Trip Materials Need Date', dateFormatMy(order_details.order_dates.pre_trip_package_need_date.value), true)}
										{TF.showValueLabel('Video Special Need Date', dateFormatMy(order_details.order_dates.video_special_need_date.value), true)}
										{
											order_details.agency_sales_rep !== null &&
											TF.showValueLabel('Agency Sales Representative', order_details.agency_sales_rep.name + ', ' + order_details.agency_sales_rep.email)
										}
										{
											order_details.gtv_sales_rep !== null &&
											TF.showValueLabel('GTV Sales Representative', order_details.gtv_sales_rep.name + ', ' + order_details.gtv_sales_rep.email)
										}
										<tr >
											<td>Pro Show File:</td>
											<td>

												<Select
													value={
														order_details.pro_show_file_id ? {
															id: returnColumnValueWithColumnMatch(proShowFileData, 'id', [order_details.pro_show_file_id], 'id'),
															title: returnColumnValueWithColumnMatch(proShowFileData, 'title', [order_details.pro_show_file_id], 'id')
														} : null
													}
													options={proShowFileData}
													onChange={this.handleChangeProshowFile}
													getOptionLabel={(option) => option.title}
													getOptionValue={(option) => option.id}
													className="w-50"
													placeholder="Select Pro Show File"
													isMulti={false}
												/>

											</td>
										</tr>

									</tbody>
								</table>
								<table className="table orderTable">
									{this.showLinkCode('Traveler', order_details.username, order_details.password, window.location.host)}
									{this.showLinkCode('Photo Admin', order_details.photo_admin_username, order_details.photo_admin_password, window.location.host)}
								</table>

								{/* <div className="orderStatusContainer flexElem flexResponsive">
									<div className="processCamera">Process cameras to ship</div>
									<div className="receiveOrder">Receive order</div>
									<div className="videoShipment">T/o video shipment</div>
								</div> */}
								{/* <div className="orderStatusContainer flexElem flexResponsive">
									<div className="awarenessFlyer"><GeneratePreAwarenessFlyer className='text-black' orders={this.props.orderId}/></div>
									<div className="returnEquipment">Return Equipment Postcard</div>
									<div className="printOrder"><PrintOrderLabel className='text-black' text='Print Order Label' order_page={true} orders={this.props.orderId} /></div>
								</div> */}
							</div>
						</div>
					</div>
				}

				<Modal backdrop={'static'} show={commentHoldAdd} onHide={closeModal.bind(this, 'commentHoldAdd')} aria-labelledby="ModalHeader" >
					<Modal.Header closeButton>
						<Modal.Title id='ModalHeader' className="headerTitle">Add Hold Comment</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Card bsClass={["innerCard"]} content={
							<div className='clearfix' >
								<div className="text-center">

									<textarea name='comment' onChange={this.commentOnHoldChangeHandler} className='form-control' value={commentOnHold.comment} />
									{
										this.validatorComment.message('comment', commentOnHold.comment, 'required')
									}
									{
										this.validatorComment.message('commentCategory', commentOnHold.comment_category_id, 'required')
									}

									<button name='add' type="button" className='backButton pt-sm no_radius pb-sm primary btn btn-md btn-info ml-sm mt-sm' onClick={this.commentHoldAddSubmitHandler} >save</button>
								</div>
							</div>
						} />
					</Modal.Body>
				</Modal>

				<Modal backdrop={'static'} show={editOrderModal} onHide={closeModal.bind(this, "editOrderModal")} aria-labelledby="ModalHeader" className="editOrderDetail" >
					<Modal.Header closeButton>
						<Modal.Title id='ModalHeader' className="headerTitle">Edit Order Information</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="clearfix">
							<Card bsClass={['innerCard']} content={
								<React.Fragment>
									<OrderDetailForm
										provideCtrlOPF={ctrlOPF => this.ctrl_ODF = ctrlOPF}
										htmlElement="OrderDetail"
										getAgencyCall={this.props.getAgencyCall}
										order_details={this.state.order_details}
										validatorInstance={this.validatorOrderDetail}
										requiredField={{}}
										formType={formType}
										returnOrderDetails={this.returnOrderDetails}
                                        is_france={is_france}
                                        initial_country_France={initial_country_France}
                                        vsa_initial_country_France={vsa_initial_country_France}
                                        ptma_initial_country_France={ptma_initial_country_France}
                                        ptma_is_france={ptma_is_france}
                                        vsa_is_france={vsa_is_france}
									/>
								</React.Fragment>
							} />

							<div className="text-right">
								<button className="backButton pt-sm no_radius pb-sm primary btn btn-lg btn-info" onClick={this.handleOrderDetailSubmit} >Save</button>
							</div>
						</div>
					</Modal.Body>
				</Modal>
				<ThemeModal title='Cancel Order' hide={() => this.toggleModal('cancelOrderModal')} show={this.state.cancelOrderModal}>
					<div className='form-group'>
						<textarea onChange={(e) => {
							this.setState({
								cancelComment: e.target.value
							})
						}} rows='5' placeholder='Kindly specify reason to cancel order' name="" className='form-control' id=""></textarea>
					</div>
					<div className='form-group text-right'>
						<button className="backButton pt-sm no_radius pb-sm primary btn btn-lg btn-info" onClick={this.changeOrderStatus} >Save</button>
					</div>
				</ThemeModal>
			</div>
		);
	};
}

function mapStateToProps(state) {
	const { loadingAgency, agenciesOption } = state.agenciesReducer;
	const { loadingSettings, agency_type_id, salesRep, agencySalesRep, orderStatuses, /*programType*/ } = state.settingsReducer;
	const { loadingOrder, shipping_details, is_invoiced } = state.ordersReducer;
	const { loading, orderUsers } = state.userReducer;
	const { loadingProShowFile, proShowFileData } = state.proShowFileReducer;
	const { commentCategories } = state.commentReducer;


	return {
		loadingAgency, agenciesOption,
		loadingSettings, agency_type_id, salesRep, agencySalesRep, /*programType,*/
		loadingOrder,
		loading,
		orderUsers,
		orderStatuses,
		loadingProShowFile, proShowFileData,
		commentCategories,
		shipping_details,
        is_invoiced
	};
}

const mapDispatchToProps = dispatch => {
	return ({
		editOrderDetailCall: (orderDetailData, id) => { dispatch(orderDetailEdit(orderDetailData, id)) },
		updateOrderProshowFileCall: (orderId, data) => { dispatch(updateOrderProshowFile(orderId, data)) },
		updateIsInvoicedCall: (orderId, value) => { dispatch(updateIsInvoiced(orderId, value)) },
		updateOrderModeratorCall: (orderUserData, orderId) => { dispatch(updateOrderModerator(orderUserData, orderId)) },
		getAgencyCall: (id) => { dispatch(getAgency(id)) },
		updateOrderCall: (data, id) => { return dispatch(updateOrderDetail(data, id)) },
		deleteOrderCall: (id) => dispatch(deleteOrder(id)),
		loginCall: (email, password) => { dispatch(login(email, password)) },
		proShowFilesPaginationDataCall: (page, filter, sort, pageSize, pagination) => { dispatch(getProShowFilesPagination(page, filter, sort, pageSize, pagination)) },
		// getProgramTypeCall: () => { dispatch(getProgramType()) },
		updateProgramTypeCall: (orderId, program_type_id) => { return dispatch(updateProgramType(orderId, program_type_id)) },
		updateTravelerPasswordCall: (orderId, data) => { return dispatch(updateTravelerPassword(orderId, data)) },
		addCommentCall: (data, modelId) => { return dispatch(addComment(data, modelId)) },
		updateOrderSettingsCall: (orderId, value, key) => { return dispatch(updateOrderSettings(orderId, value, key)) },
		getCommentCategoryByTitle: (titles) => dispatch(getCommentCategoryByTitle(titles)),
		getIsInvoicedCall: (id) => {return dispatch(getIsInvoiced(id)) },
	});
}

const OrderDetailShowAndModal = connect(mapStateToProps, mapDispatchToProps)(OrderDetailShowAndModalPage);
export default OrderDetailShowAndModal;
