import React, { Component } from "react";
import $ from "jquery";
import { s3StaticPath, localDirectory } from "../../helper/helperFunctions";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import { Parallax } from "react-parallax";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ScrollAnimation from "react-animate-on-scroll";
import "./../../assets/css/animate.min.css";
import logo from "../../assets/media/splash-logo.png";
import feature1 from "../../assets/media/feature-icon-1.png";
import feature2 from "../../assets/media/feature-icon-2.png";
import feature3 from "../../assets/media/feature-icon-3.png";
import footerLogo from "../../assets/media/footerLogo.png";
import document from "../../assets/media/Documents.png";
import Gallery from "../../assets/media/Gallery.png";
import Locator from "../../assets/media/Locator.png";
import Messaging from "../../assets/media/Messaging.png";
import Traveler from "../../assets/media/Traveler-list.png";
import teacher from "../../assets/media/teacher.png";
import students from "../../assets/media/students.png";
import family from "../../assets/media/family.png";

class GtvHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
    };
    this.handleHover = this.handleHover.bind(this);
  }
  handleHover() {
    // this.setState(prevState => ({
    //     isHovered: !prevState.isHovered
    // }));

    $("#clients div.client").hover(
      function () {
        $("#clients div.client").removeClass("pink_gradient");
        $(this).addClass("pink_gradient");
      },
      function () {
        $("#clients div.client").removeClass("pink_gradient");
      }
    );
  }
  render() {
    // const hoverClass = this.state.isHovered ? "client pink_gradient" : "client";
    const SlickButtonFix = ({
      currentSlide,
      slideCount,
      children,
      ...props
    }) => <div {...props}>{children}</div>;

    var sliderSettings = {
      dots: true,
      autoplay: true,
      infinite: true,
      speed: 1000,
      autoplaySpeed: 7000,
      slidesToShow: 4,
      slidesToScroll: 1,
      accessibility: false,
      responsive: [
        {
          breakpoint: 1080,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
      prevArrow: (
        <SlickButtonFix>
          <FaChevronLeft />
        </SlickButtonFix>
      ),
      nextArrow: (
        <SlickButtonFix>
          <FaChevronRight />
        </SlickButtonFix>
      ),
    };
    return (
      <main id="gtv_home">
        <section className="container mxAuto py25 fullScreen flexElem flexResponsive alignCenter">
          <div className="grid1x2 gridCenter">
            <div className="gridRow2">
              <img
                className="mainLogo"
                // src={localDirectory("logo.png")}
                src={logo}
                alt="Trip Kit Travel"
              />
            </div>
            <div className="text-center">
              <h1 className="primary font-weight-bold">Group Travel</h1>
              <i className="lightBlue fontBase mt5 h4">
                Everything you need
                <br />
                in one app.
              </i>
            </div>
            <div className="text-center mt10">
              <h3 className="primary">Totally Private</h3>
              <p className="h6 lightBlue mt5">
                No emails, phone numbers,
                <br />
                account setup or advertising.
              </p>
            </div>
          </div>
        </section>

        <section className="securityPoints fullScreen flexElem flexResponsive alignCenter borderTop py40">
          <div className="container">
            <Row className="mb50">
              <Col md={8} className="mt10">
                <h2 className="h1 primary text-left font-weight-bold">
                  The App Features
                </h2>
              </Col>
              <Col md={4} className="mt10">
                <img
                  src={footerLogo}
                  alt="logo"
                  className="mlAuto featureLogo"
                />
              </Col>
            </Row>
            <Row className="flexElem flexResponsive alignCenter mb25 mdCenter">
              <Col md={4} className="mt10 text-center">
                <img src={feature1} alt="Documents, Messaging, Map" />
                <h5 className="secondary font-weight-bold lineH">
                  Documents
                  <br />
                  Messaging & Map
                </h5>
                <span className="secondary textCenter">
                  Easily share trip documents with your traveling group and
                  communicate with built-in messaging and mapping helps to keep
                  people safe and in touch.
                </span>
              </Col>
              <Col md={4} className="mt10 text-center">
                <img src={feature2} alt="Group Photo Sharing" />
                <h5 className="secondary font-weight-bold lineH">
                  Group
                  <br />
                  Photo Sharing
                </h5>
                <span className="secondary textCenter px1-25">
                  Travelers can share photos privately with the group and those
                  following from home. No email or phone number required.
                </span>
              </Col>
              <Col md={4} className="mt10 text-center">
                <img src={feature3} alt="Lasting Memories" />
                <h5 className="secondary font-weight-bold lineH">
                  &nbsp;
                  <br />
                  Lasting Memories
                </h5>
                <span className="secondary textCenter px1-25">
                  Your travels are captured by all the shared photos that tell a
                  story to last a lifetime. Relive the fun and educational
                  experience.
                </span>
              </Col>
            </Row>
          </div>
        </section>

        <section className="featuresWhile hasDivider fullScreen flexElem flexResponsive alignCenter py40">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="featuresSlider">
                  <Slider {...sliderSettings}>
                    <div className="primaryBG py25 heightFull">
                      <h6 className="text-white mb25">Documents</h6>
                      <img
                        src={document}
                        alt="Feature Gallery"
                        className="mb25"
                      />
                      <p className="text-white">
                        Share documents with your computer or the app. Update in
                        real time.
                      </p>
                    </div>
                    <div className="primaryBG py25 heightFull">
                      <h6 className="text-white mb25">Private Gallery</h6>
                      <img src={Gallery} alt="Feature map" className="mb25" />
                      <p className="text-white">
                        Only your group and family at home can access photos. No
                        commenting, no bullies.
                      </p>
                    </div>
                    <div className="primaryBG py25 heightFull">
                      <h6 className="text-white mb25">Locator</h6>
                      <img src={Locator} alt="Feature group" className="mb25" />
                      <p className="text-white">
                        Turns on at 5am day of departure. Turns off at midnight
                        upon return.
                      </p>
                    </div>
                    <div className="primaryBG py25 heightFull">
                      <h6 className="text-white mb25">Messaging</h6>
                      <img
                        src={Messaging}
                        alt="Feature Docs"
                        className="mb25"
                      />
                      <p className="text-white">
                        Group leader can message all or individuals and create
                        subgroups.
                      </p>
                    </div>
                    <div className="primaryBG py25 heightFull">
                      <h6 className="text-white mb25">Traveler List</h6>
                      <img src={Traveler} alt="Feature Docs" className="mb25" />
                      <p className="text-white">
                        Easy to locate and message individuals.
                      </p>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Parallax
          bgImage={null}
          bgImageAlt="Personal Privacy"
          strength={500}
          className="securityParallax"
        >
          <section className="securityPoints fullScreen flexElem flexResponsive alignCenter">
            <div className="container text-center">
              <h2 className="mb50 h1 secondary font-weight-bold">
                Everyone Benefits
              </h2>
              <div className="row">
                <div className="col-md-4">
                  <div>
                    <div className="mb25">
                      <img src={teacher} alt="Teacher" className="mw100Force" />
                    </div>
                    <div>
                      <h5 className="secondary font-weight-bold">Teacher</h5>
                      <span className="secondary fontBase mwAuto textCenter font-weight-bold">
                        Teachers can show the previous year's photos to generate
                        excitement & participation. It reinforces the
                        educational experience for your students. Seeing history
                        in real life and having shared photos as keepsakes will
                        make the trip experience live on for years.
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div>
                    <div className="mb25">
                      <img
                        src={students}
                        alt="students"
                        className="mw100Force"
                      />
                    </div>
                    <div>
                      <h5 className="secondary font-weight-bold">Students</h5>
                      <span className="secondary fontBase mwAuto textCenter font-weight-bold">
                        Students are about to learn and see things they may have only read about. 
                        Experiencing it in real life is something they will want to remember 
                        and relive for years to come. Taking lots of pictures and privately 
                        sharing with others will help tell the story of their trip and recall 
                        what they learned.
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div>
                    <div className="mb25">
                      <img src={family} alt="family" className="mw100Force" />
                    </div>
                    <div>
                      <h5 className="secondary font-weight-bold">
                        Family at Home
                      </h5>
                      <span className="secondary fontBase mwAuto textCenter font-weight-bold">
                        Even from home family can enjoy the journey with the traveler’s 
                        username and password on any smartphone, tablet or computer. 
                        Enjoy seeing pictures of their loved ones learning and knowing 
                        they're safe while creating great memories. Download their 
                        favorite photos of their loved one.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Parallax>
        <div className="container mt25 mb25">
          <hr />
        </div>
        <section className="ourTeams fullScreen flexElem flexResponsive alignCenter">
          <div className="container">
            <div className="gridAreas">
              <div className="a">
                <div className="">
                  <strong className="">1. Place your order</strong>
                  <div className="mb25">
                    <strong className="">
                      Call (888) 533-7637 x209 to talk to our
                      <br />
                      team to get started today, even if your
                      <br />
                      trip is just days away.
                    </strong>
                  </div>
                </div>
                <div className="">
                  <strong className="">2. Download the App</strong>
                  <div className="mb25">
                    <strong className="">
                      The group is given a unique ID to login.
                      <br />
                      No email, phone number or personal info required.
                    </strong>
                  </div>
                </div>
                <div className="">
                  <strong className="">3. Share the Fun</strong>
                  <div className="mb25">
                    <strong className="">
                      While on your trip travelers privately share photos,
                      <br />
                      itineraries, passenger lists and more with the entire
                      group.
                      <br />
                      And built-in messaging and mapping tools help your team
                      stay safe and connected.
                    </strong>
                  </div>
                </div>
              </div>
              <div className="b text-center">
                <img src={logo} alt="Team" className="stepsLogo" />
              </div>
              <div className="c">
                <div className="info">
                  <strong className="">4. Relive the Memories</strong>
                  <div className="mb25">
                    <strong className="">
                      The shared photos are available up to three years after
                      your trip. Download and keep your favorites of your trip
                      of a lifetime.
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default GtvHome;
