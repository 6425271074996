import React, { Component } from "react";
// import footer_logo from "assets/img/footer_logo.png";
import Logo from "components/FrontEnd/Brand/Logo";
// import MyImage from 'components/Upload/MyImage';
import { Link } from "react-router-dom";
import { hasRole } from "helper/helperFunctions";
import logoFooter from "../../../assets/media/footerLogo.png";
import Phone from "../../../assets/media/phone-icon.png";
import { copy } from "copy-to-clipboard";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
    };
    this.fbLike = this.fbLike.bind(this);
  }

  fbLike() {
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.0";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }

  componentDidMount() {
    this.fbLike();
  }

  render() {
    return (
      <footer id="footer">
        <div className="container py25">
          <div className="flexElem flexCol">
            <div
              className="dflex aligncenter justifycenter mb-1 text-white darkBlue"
              style={{ fontSize: "1.4rem" }}
            >
              <img src={Phone} alt="logo" width={22} className="mr5" />
              {/* Contact us:&nbsp; &nbsp;  */}
              Call: 704-846-7870 x209
            </div>
            {/* <div className="mlAuto socialIcons">
                <a href="#!" className="link_hover">
                  <i className="fa fa-twitter"></i>
                </a>
                <a href="#!" className="link_hover">
                  <i className="fa fa-linkedin"></i>
                </a>
                <a href="#!" className="link_hover">
                  <i className="fa fa-youtube"></i>
                </a>
              </div> */}
            <hr />
            <div className="flexElem">
              <div className="text-white">
                &copy; 2022 Travel TripKit<sup>TM</sup>
                {/* Made with
                Charlotte, NC */}
              </div>
              <div className="flexElem wAuto mlAuto">
                <ul className="footer_menus">
                  <li className="link_hover">
                    <Link to="/" className="">
                      Home
                    </Link>
                  </li>
                  <li className="link_hover">
                    <Link to="/Terms_and_Conditions" className="">
                      Terms & Conditions
                    </Link>
                  </li>
                  <li className="link_hover">
                    <Link to="/Privacy_Policy" className="">
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
export default Footer;
