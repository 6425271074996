import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Roles } from "constants/Roles";
import User from "helper/User";
import { Dashboard } from 'layouts/Frontend/Dashboard/Dashboard';
import { s3StaticPath } from 'helper/helperFunctions';
import { Link } from "react-router-dom";
import footerLogo from "../../../assets/media/footerLogo.png";

// import MyImage from 'components/Upload/MyImage';

class UserHome extends Component {


    render() {
        const {orderUploadInfo} = this.props;
        console.log("order",orderUploadInfo);
        let order_approved = false;
        order_approved = orderUploadInfo && orderUploadInfo.status.title == "Approved" ? true : false;
        return (
            <div className="container">
                {
                    User.hasRole([Roles.AGENCY_USER, Roles.AGENCY_USER_SC, Roles.AGENCY_USER_U]) &&
                    <div className="">
                        <Link className="themeBtn_14 mt-xlg pt-sm pb-sm purple_gradient hover_dark" to={'/new-order'} ><i style={{ verticalAlign: 'bottom' }} className="noLmargin flaticon-shopping-cart"></i>&nbsp; New Order</Link>
                    </div>
                }
                <div className="col-md-12 text-center">
                        {/* <h5 className="panel_heading userLogin_heading twenty22">Your group is not approved yet, Once your group will approved you can access the app. </h5> */}
                        <div className="">
                            {/* <h2 className="panel_heading userLogin_heading" style={{color: '#f25e5e'}}>Welcome To Group Travel</h2>
                            <h2 className="panel_heading" style={{marginTop: '20px'}}>Everything you need in one app.</h2> */}
                            <div className="featureBox" style={{margin:'4em auto', maxWidth: '400px'}}>
                                <img
                                    src={footerLogo}
                                    alt="logo"
                                    className="mlAuto featureLogo"
                                />
                            </div>
                        </div>
                </div>            
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { orderUploadInfo } = state.frontEndUserReducer;

    return { orderUploadInfo}

};

const mapDispatchToProps = {

}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard(UserHome));
