import { s3StaticPath } from 'helper/helperFunctions';
import Dashboard from "components/Admin/Dashboard";
import AdminUsers from "components/Admin/Users/AdminUsers";
import AgencyUsers from "components/Admin/AgencyUsers/AgencyUsers";
import AgencyResources from "components/Admin/AgencyResource/AgencyResources";
import AgencyList from "components/Admin/Agency/AgencyList";
import OrderList from "components/Admin/Order/OrderList";
import OrderEdit from "components/Admin/Order/OrderEdit";
import AddAgency from "components/Admin/Agency/AddAgency";
import AddOrder from "components/Admin/Order/AddOrder";
import EditAgency from "components/Admin/Agency/EditAgency";
import Roles from "components/Admin/Roles/RolesList";
import Permissions from "components/Admin/Permissions/PermissionsList";
import ModulePermissions from "components/Admin/Module/ModulePermissions";
import OrderDocument from "components/Admin/Order/OrderDocument";
import ProcessOrderList from "components/Admin/Order/ProcessOrderList";
import RecentOrderProcessList from "components/Admin/Order/RecentOrderProcessList";
import AgencyTypeList from "components/Admin/AgencyType/AgencyTypeList";
import ProgramTypeList from 'components/Admin/ProgramType/ProgramTypeList'
import AdditionalServicesList from "components/Admin/Order/AdditionalServicesList";
import InventoryList from "components/Admin/Inventory/InventoryList";
import InventoryLocationStatsList from "components/Admin/Inventory/InventoryLocationStatsList";
import ItemList from "components/Admin/Item/ItemList";
import LabProduction from "components/Admin/Reports/LabProduction";
import OrderFinished from "components/Admin/Reports/OrderFinished";
import ReportTesting from "components/Admin/Reports/ReportTesting";
import Destinations from "components/Admin/Destination/DestinationList";
import ReorderList from "components/Admin/Reorder/ReorderList";
import ExampleForm from "components/Admin/ExampleForm";
import FlaggedOrders from "components/Admin/Order/FlaggedOrders";
import MultipleShippedOrders from "components/Admin/Order/MultipleShippedOrders";
import ProShowList from "components/Admin/ProShowFiles/ProShowList";
import Receive from "components/Admin/Order/Receive";
// import ContactList from "components/Admin/Order/ContactList";
import ContactListEQ from "components/Admin/Order/ContactListEQ";
import ContactListCS from "components/Admin/Order/ContactListCS";
import PreAwarenessFlyerGenerator from "components/Admin/Order/PreAwarenessFlyerGenerator";
import PreAwarenessFlyersHistory from "components/Admin/Order/PreAwarenessFlyersHistory";
import ManageMosaics from "components/Admin/Mosaics/ManageMosaics";
import MosaicsList from "components/Admin/Mosaics/MosaicsList";
import Brands from "components/Admin/Brand/BrandList";
import Faqs from "components/Admin/Brand/Faq/FaqList";
import TransmittalTemplates from "components/Admin/Brand/TransmittalTemplate/TransmittalTemplateList";
import AppSetting from "components/Admin/Brand/AppSetting";
import VideoShipment from "components/Admin/Reports/VideoShipment";
import TodaysShipment from "components/Admin/Reports/TodaysShipment";
import WebLog from "components/Admin/Reports/WebLog";
import OrderLog from "components/Admin/Reports/OrderLog";
import ContactLog from "components/Admin/Reports/ContactLog";
import AccessLog from "components/Admin/Reports/AccessLog";
import OrderBooking from "components/Admin/Reports/OrderBooking";
import ForeCast from "components/Admin/Reports/ForeCast";
import UCDReached from "components/Admin/Reports/UCDReached";
import InvoicingOrders from "components/Admin/Invoicing/InvoicingOrders";
import RecentlyInvoicedOrders from "components/Admin/Invoicing/RecentlyInvoicedOrders";
import InvoicingSettings from "components/Admin/Invoicing/InvoicingSettings";
import InvoicingImport from "components/Admin/Invoicing/InvoicingImport";
import PrintInvoices from "components/Admin/Invoicing/PrintInvoices";
import Test from "components/Admin/Test";
import ReceiveReports from 'components/Admin/Reports/ReceiveReports';
import EditingReports from 'components/Admin/Reports/EditingReports';
import OrdersTOMissingResource from 'components/Admin/Reports/OrdersTOMissingResource';
import ScanBarcode from "components/Admin/Reports/ScanBarcode";
import PendingOrders from "components/Admin/Order/pendingOrders";
import CreateLabel from "components/Admin/Reports/CreateLabel";
import OrderListingForModerator from 'components/Admin/Dashboard/OrderListingForModerator';
import PreShipmentEmailList from "../components/Admin/Reports/PreShipmentEmailList";
import preDeparturePostShippingList from "../components/Admin/Reports/preDeparturePostShippingList";
import OrderCheckInList from "../components/Admin/Reports/OrderCheckInList";
import ReOrderEntry from "../components/Admin/Order/ReOrderEntry";
import OrdersContactListSearchBy from "components/Admin/Order/OrdersContactListSearchBy";
import BrandMoreButtonsList from '../components/Admin/Brand/MoreButtons/BrandMoreButtonsList';
import AssignOrderDestination from '../components/Admin/Destination/AssignOrderDestination';
import EditedOrderByUser from '../components/Admin/Reports/EditedOrderByUser';
import ProductionReports from '../components/Admin/Reports/ProductionReports';
import ProducedOrderByUser from '../components/Admin/Reports/ProducedOrderByUser';
import RecentlyReceiveOrders from '../components/Admin/Reports/RecentlyReceiveOrders';
import OnHoldList from '../components/Admin/Reports/OnHoldList';


var dashboardRoutes = [
  {
    path: "/Admin/dashboard/:roleParam",
    name: "Dashboard",
    icon: "pe-7s-graph",
    component: OrderListingForModerator,
    iconImagePath: s3StaticPath('img/dashboard.svg'),
    permission: ["admin-dashboard-editor", "admin-dashboard-producer", "admin-dashboard-qa", "admin-dashboard-mozaix", "admin-remote-dashboard-qa", "admin-remote-dashboard-producer", "admin-remote-dashboard-editor", "admin-remote-dashboard-mozaix"]
    // permission: ["admin-remote-dashboard-qa", "admin-remote-dashboard-producer", "admin-remote-dashboard-editor", "admin-remote-dashboard-mozaix"]
  },
  {
    path: "/Admin/dashboard",
    name: "Dashboard",
    icon: "pe-7s-graph",
    component: Dashboard,
    iconImagePath: s3StaticPath('img/dashboard.svg'),
    permission: ["admin-dashboard", "editor-dashboard", "producer-dashboard", "qa-dashboard"]
  },
  {
    path: "/Admin/users",
    name: "Users",
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: AdminUsers,
    permission: "user-list"
  },
  {
    path: "/Admin/:id/agency-users",
    name: "Agency Users",
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: AgencyUsers,
    permission: "agency-user-list"
  },
  {
    path: "/Admin/roles",
    name: "Roles",
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: Roles,
    permission: "role-list"
  },
  {
    path: "/Admin/modules/:id?",
    name: "Modules",
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: ModulePermissions,
    permission: "role-list"
  },
  {
    path: "/Admin/permissions",
    name: "Permissions",
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: Permissions,
    permission: "permission-list"
  },
  {
    path: "/Admin/agencies",
    name: "Agencies",
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: AgencyList,
    permission: "agency-list"
  },
  {
    path: "/Admin/agency-create",
    name: "Create Agency",
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: AddAgency,
    permission: "agency-create"
  },
  {
    path: "/Admin/:id/agency-edit",
    name: "Edit Agency",
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: EditAgency,
    permission: "agency-edit"
  },
  {
    path: "/Admin/:id/agency-resource",
    name: "Resource Agency",
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: AgencyResources,
    permission: "agency-resource-list"
  },
  {
    path: "/Admin/orders",
    name: "Orders",
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: OrderList,
    permission: "order-list"
  },
  {
    path: "/Admin/:id/order-edit",
    name: "Edit Order",
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: OrderEdit,
    permission: "order-edit"
  },
  {
    path: "/Admin/order-create",
    name: "Create Order",
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: AddOrder,
    permission: "order-create"
  },
  {
    path: "/Admin/order-process-list",
    name: "Order Process List",
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: ProcessOrderList,
    permission: "order-process-list"
  },
  {
    path: "/Admin/recently-processed-orders",
    name: "Recently Processed Order List",
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: RecentOrderProcessList,
    permission: "recent-order-process-list"
  },
  {
    path: '/Admin/order-documents',
    name: 'Upload Order Documents',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: OrderDocument,
    permission: "upload-order-document"
  },
  {
    path: '/Admin/agency-types',
    name: 'Agency Types',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: AgencyTypeList,
    permission: "agency-types"
  },
  {
    path: '/Admin/program-types',
    name: 'Program Types',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: ProgramTypeList,
    permission: "program-types"
  },
  {
    path: '/Admin/additional-services',
    name: 'Additional Services',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: AdditionalServicesList,
    permission: "additional-services-list"
  },
  {
    path: '/Admin/inventories',
    name: 'Iventories',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: InventoryList,
    permission: "inventory-list"
  },
  {
    path: '/Admin/items',
    name: 'Items',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: ItemList,
    permission: "item-list"
  },
  {
    path: '/Admin/:id/lab-production-report',
    name: 'Lab Production Report',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: LabProduction,
  },
  {
    path: '/Admin/inventory-location-stats',
    name: 'Inventory Location Stats',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: InventoryLocationStatsList,
    permission: "inventory-locations-stats-list"
  },
  {

    path: '/Admin/destinations',
    name: 'Destinations',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: Destinations,
    permission: "destination-list"
  },
  {
    path: '/Admin/reorders-list',
    name: 'Reorder List',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: ReorderList,
    permission: "reorders-list"
  },
  {
    path: '/Admin/orderFinished',
    name: 'Order Finished',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: OrderFinished,
    permission: "order-finished"
  },
  {
    path: '/Admin/reportTesting',
    name: 'Report Testing',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: ReportTesting,
    permission: "report-test"
  },
  {
    path: '/Admin/test-from',
    name: 'Form Test',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: ExampleForm,
    permission: "example-form"
  },
  {
    path: '/Admin/Flagged-Orders',
    name: 'Flagged Orders',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: FlaggedOrders,
    permission: "show-flagged-orders"
  },
  {
    path: '/Admin/Multiple-Shipped-Orders',
    name: 'Multiple Shipped Orders',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: MultipleShippedOrders,
    permission: "show-multiple-shipped-orders"
  },
  {

    path: '/Admin/Pro-show-file-lists',
    name: 'Pro Show Files',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: ProShowList,
    permission: "pro-show-file-lists"
  },
  {
    path: '/Admin/receive-order',
    name: 'Receive Order',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: Receive,
    permission: "receive-order"
  },
//   {
//     path: '/Admin/contact-list',
//     name: 'Contact List',
//     icon: "pe-7s-users",
//     iconImagePath: s3StaticPath('img/user.svg'),
//     component: ContactList,
//     permission: "contact-list"
//   },
  {
    path: '/Admin/contact-list-eq',
    name: 'Contact List',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: ContactListEQ,
    permission: "contact-list-eq"
  },
  {
    path: '/Admin/contact-list-cs',
    name: 'Contact List',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: ContactListCS,
    permission: "contact-list-cs"
  },
  {
    path: '/Admin/PreAwareness-Flyer-Generator',
    name: 'PreAwareness Flyer Generator',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: PreAwarenessFlyerGenerator,
    permission: "show-preawareness-flyer-generator"
  },
  {
    path: '/Admin/PreAwareness-Flyers-History',
    name: 'PreAwareness Flyers History',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: PreAwarenessFlyersHistory,
    permission: "show-preawareness-flyers-history"
  },
  {
    path: '/Admin/:id/Manage-Mosaics',
    name: 'Manage Mosaics',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: ManageMosaics,
  },
  {
    path: '/Admin/Mosaics-List',
    name: 'Mosaics List',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: MosaicsList,
  },
  {

    path: '/Admin/brands/:accountId/faqs',
    name: 'Brands',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: Faqs,
    permission: "faq-list"
  },
  {

    path: '/Admin/brands/:accountId/moreButtons',
    name: 'Brands',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: BrandMoreButtonsList,
    // permission: "faq-list"
  },
  {

    path: '/Admin/brands/:accountId/transmittal-templates',
    name: 'Brands',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: TransmittalTemplates,
    permission: "transmittal-template-list"
  },
  {

    path: '/Admin/brands/:accountId/app-setting',
    name: 'Brands',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: AppSetting,
    permission: "app-setting-list"
  },
  {

    path: '/Admin/brands',
    name: 'Brands',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: Brands,
    permission: "brand-list"
  },
  {
    path: '/Admin/videoShipment',
    name: 'Video Shipment Report',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: VideoShipment,
    permission: "report-video-shipment"
  },

  {
    path: '/Admin/todaysShipment',
    name: 'Today Shipment Report',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: TodaysShipment,
    permission: "report-today-shipment"
  },

  {
    path: '/Admin/webLog/:id?',
    name: 'Web Log Report',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: WebLog,
    permission: "web-log-report"
  },

  {
    path: '/Admin/contactLog',
    name: 'Contact Log Report',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: ContactLog,
    permission: "contact-log-report"
  },

  {
    path: '/Admin/accessLog',
    name: 'Access Log Report',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: AccessLog,
    permission: "access-log-report"
  },

  {
    path: '/Admin/ordersBookingReport',
    name: 'Order Booking Report',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: OrderBooking,
    permission: "order-booking-report"
  },
  {
    path: '/Admin/salesForeCast',
    name: 'Sales Forecast',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: ForeCast,
    permission: "order-fore-cast"
  },
  {
    path: '/Admin/ucdReached',
    name: 'UCD Reached',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: UCDReached,
    permission: "ucd-reached-report"
  },
  {
    path: '/Admin/orderInvoicing',
    name: 'Order to Invoice',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: InvoicingOrders,
    permission: "order-to-invoice"
  },
  {
    path: '/Admin/recentlyInvoicedOrders',
    name: 'Recently Invoiced Orders',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: RecentlyInvoicedOrders,
    permission: "order-to-invoice"
  },
  {
    path: '/Admin/invoiceSettings',
    name: 'Invoice Settings',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: InvoicingSettings,
    permission: "invoice-settings"
  },
  {
    path: '/Admin/printInvoices',
    name: 'Print Invoices',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: PrintInvoices,
    permission: "print-invoice"
  },
  {
    path: '/Admin/testComponents',
    name: 'Component',
    icon: "pe-7s-users",
    component: Test,
    permission: "test-component"
  },
  {
    path: '/Admin/orderPhotoSubmitted',
    name: 'Photo Submitted for Video Production',
    icon: "pe-7s-users",
    component: ReceiveReports,
    permission: "report-order-photo-submitted"
  },
  {
    path: '/Admin/on_hold_list',
    name: 'On Hold List',
    icon: "pe-7s-users",
    component: OnHoldList,
    permission: "report-on-hold-list"
  },
  {
    path: '/Admin/recentlyReceiveOrders',
    name: 'Recently Receive Orders',
    icon: "pe-7s-users",
    component: RecentlyReceiveOrders,
    permission: "report-order-photo-submitted"
  },
  {
    path: '/Admin/editing-report',
    name: 'Editing Reports',
    icon: "pe-7s-users",
    component: EditingReports,
    permission: "remote-editing-report"
  },
  {
    path: '/Admin/production-report',
    name: 'Production Reports',
    icon: "pe-7s-users",
    component: ProductionReports,
    permission: "remote-production-report"
  },
  {
    path: '/Admin/:id/edited-order-by-user',
    name: 'EditedOrderByUser',
    icon: "pe-7s-users",
    component: EditedOrderByUser,
    permission: "remote-editing-report"
  },
  {
    path: '/Admin/:id/produced-order-by-user',
    name: 'ProducedOrderByUser',
    icon: "pe-7s-users",
    component: ProducedOrderByUser,
    permission: "remote-editing-report"
  },
  {
    path: '/Admin/OrdersTOMissingResource',
    name: 'Orders Missing Resource',
    icon: "pe-7s-users",
    component: OrdersTOMissingResource,
    permission: "report-order-missing-resource"
  },
  {
    path: '/Admin/receive',
    name: 'ScanBarcode',
    // icon: "pe-7s-users",
    component: ScanBarcode,
    permission: "report-receive"
  },
  {
    path: '/Admin/to_labels',
    name: 'CreateLabel',
    // icon: "pe-7s-users",
    component: CreateLabel,
    permission: "print-order-label"
  },
  {
    path: '/Admin/web-orders',
    name: 'Web Orders',
    // icon: "pe-7s-users",
    component: PendingOrders,
    permission: "web-orders"
  },
  {
    path: '/Admin/invoicing-import',
    name: 'Import Orders',
    // icon: "pe-7s-users",
    component: InvoicingImport,
    permission: "import-orders"
  },
  {
    path: '/Admin/OrderLog/:id?',
    name: 'Order Log Report',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: OrderLog,
    permission: "order-log-report"
  },
  {
    path: '/Admin/Pre-shipment-email-list',
    name: 'Pre Shipment Email List',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: PreShipmentEmailList,
    permission: "pre-shipment-email-list"
  },
  {
    path: '/Admin/Pre-departure-post-shipping-list',
    name: 'Pre Departure Post Shipping List',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: preDeparturePostShippingList,
    permission: "pre-departure-post-shipping-list"
  },
  {
    path: '/Admin/order-3-day-checkin-list',
    name: 'Order 3 Day Checkin List',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: OrderCheckInList,
    permission: "order-checkin-list"
  },
  {
    path: '/Admin/:id/gtv-reorder-entry',
    name: 'GTV ReOrder Entry',
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: ReOrderEntry,
    permission: "order-checkin-list"
  },
  {
    path: '/Admin/getOrdersSearchByContactList',
    name: 'Get Orders Search By Contact List',
    // icon: "pe-7s-users",
    // iconImagePath: s3StaticPath('img/user.svg'),
    component: OrdersContactListSearchBy,
    permission: "orders-contact-list-search-by"
  },
  {
    path: "/Admin/:id/assign-order-destinations",
    name: "Assign Destinations",
    icon: "pe-7s-users",
    iconImagePath: s3StaticPath('img/user.svg'),
    component: AssignOrderDestination,
    permission: "assign-order-destinations"
  },
  { redirect: true, path: "/Admin", to: "/Admin/dashboard", name: "Dashboard" }
];

export default dashboardRoutes;
