import React, { Component } from 'react';
import {connect} from 'react-redux';
import { Dashboard } from 'layouts/Frontend/Dashboard/Dashboard';
import { updateOrderUploadCutOffDateByEmail, getOrderUploadInfo, updateOrderSettings } from 'redux/actions/order-actions';
import User from 'helper/User';
import { alertActions } from '../../../redux/actions/alert-actions';
const queryString = require('query-string');

class IncreaseUploadCutOffDate extends Component {

    constructor(props) {
        super(props);
        const parsed = queryString.parse(window.location.search);
        this.state = {
          days: parsed.days ? parsed.days : null,
          photos_submitted_for_video_production: parsed.photos_submitted_for_video_production ? parsed.photos_submitted_for_video_production : null,
        };
        console.log("parsed", parsed);
    }
    componentDidMount() {
        //
        this.props.getOrderUploadInfoCall(User.getProperty('order.id')).then(
            res => {
                console.log("res", res);
                if (this.state.days) {
                    if ( Number(res.data.settings.photos_submitted_for_video_production.value) === 0 && res.data.traveler_upload_date_passed) {
                        this.props.updateOrderUploadCutOffDateByEmailCall(User.getProperty('order.id'), {
                            value: this.props.orderUploadInfo.upload_cut_off_date,
                            days: this.state.days
                        }).then(
                            response => {
                                this.props.getOrderUploadInfoCall(User.getProperty('order.id'));
                                if ( response ) {
                                    const url = `/uploader`;
                                    this.props.history.push(url);
                                }
                            }
                        )
                    } else {
                        this.props.error('You are not allowed to perform this action!')
                    }
                }
                if (this.state.photos_submitted_for_video_production) {
                    if (Number(this.props.orderUploadInfo.settings.photos_submitted_for_video_production.value) === 0) {
                        this.props.updateOrderSettingsCall(User.getProperty('order.id'), this.props.orderUploadInfo.photos_submitted_for_video_production.value, 'photos_submitted_for_video_production').then(
                            response => {
                                this.props.getOrderUploadInfoCall(User.getProperty('order.id'));
                                if ( response ) {
                                    const url = `/uploader`;
                                    this.props.history.push(url);
                                }
                            }
                        );
                    } else {
                        this.props.error('You are not allowed to perform this action!')
                    }
                }
            }
        )

    }
    // http://localhost:3000/increase_ucd?days=2
    // http://localhost:3000/login/23qkegj/piagweq
    // http://localhost:3000/login/23qkegj/piagweq?days=2
    // http://localhost:3000/login/23qkegj/piagweq?photos_submitted_for_video_production=true
    render() {
    const { barcode } = this.state;
        return (
            <div className="container pb-xlg text-center">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="panel_heading userLogin_heading">Increasing Your Upload Cut Off Date...</h2>
                        {/* <h2 className="panel_heading userLogin_heading text-muted mb-md">your order confirmation # is {barcode}</h2>
                        <p>We appreciate your business and look forward to producing your traveler's Keepsake Videos. <strong>You will receive a confirmation email containing all your order information.</strong> If you do not see the email, please check your spam or junk folder as sometimes emails are mistakelny placed there.</p>
                        <p>If you have any questions or concerns, pleaes <Link target='_blank' className='blue_link' to='/Contact_Us'>Contact Us.</Link></p> */}
                    </div>
                </div>
            </div>
        )
    }

}
const mapStateToProps = state => {
    const {orderUploadInfo} = state.frontEndUserReducer;
    return {
        orderUploadInfo
    }
}
const mapDispatchToProps = dispatch => {
    return {
        updateOrderUploadCutOffDateByEmailCall: (orderId, value) => dispatch(updateOrderUploadCutOffDateByEmail(orderId, value)),
        getOrderUploadInfoCall: (orderId) => {return dispatch(getOrderUploadInfo(orderId))},
        updateOrderSettingsCall: (orderId, value, key) => dispatch(updateOrderSettings(orderId, value, key)),
        error: (message) => { return dispatch(alertActions.error(message))},
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard(IncreaseUploadCutOffDate))
