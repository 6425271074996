import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getOrder, storeKeepSakeOrder } from 'redux/actions/order-actions';
import { s3StaticPath, clean, returnLabelWithValue } from 'helper/helperFunctions';
import { Grid, Row, Col } from 'react-bootstrap';
import Card from 'components/Card/Card.jsx'
import { objectsConstants } from 'redux/constant/objects-constant';
import { cloneDeep, set, transform } from 'lodash'
import { getReorderEntryItems } from 'redux/actions/item-actions';
import Validator from 'simple-react-validator'
import Select from 'react-select'
import { getCountries, getStates } from 'redux/actions/settings-actions';
import parseHtml from 'html-react-parser'
import { addComment, getCommentCategoryByTitle } from 'redux/actions/comment-actions';
import CustomPhoneNumber from '../../ThemeComponents/CustomPhoneNumber';
const queryString = require('query-string');

class ReOrderEntry extends Component {

    constructor(props) {
        super(props);
        this.state = {
            totals: {
                shipping: 0,
                total: 0,
                moazaixDiscount: 0
            },
            order_details: { ...objectsConstants.ORDERDETAIL, reorderCustomerName:'' },
            shipping_details: objectsConstants.SHIPPINGDETAIL,
            pricing_details: objectsConstants.PRICINGDETAIL,
            reorderItems: [],
            mozaixRanges: {
                '2-5': 35.10,
                '6-10': 33.15,
                '11-20': 31.20,
                '21-30': 29.25,
                '31-50': 27.30,
                '51-75': 25.35,
                '76+': 23.40
            },
            totalItems: 0,
            comment: {
                comment: '',
                comment_category_id: 0
            },
            initial_country_France: false,
            is_france : false,
            // comp: false
        }
        this.Validator = new Validator();
    }
    componentDidMount() {
        this.props.getOrderCall(this.props.match.params.id).then(
            res => {
                const pricing_details = cloneDeep(this.state.pricing_details);
                pricing_details.program_type_id = res.order.pricing_details.program_type_id;
                this.setState({
                    order_details: res.order.order_details,
                    shipping_details: res.order.shipping_details,
                    pricing_details: pricing_details
                })
                if(res.order.shipping_details.video_shipping_address.country.title === 'France'){
                    this.setState({
                        initial_country_France: true
                    })
                }
                let event = new MouseEvent('click', {
                    'bubbles': true,
                    'cancelable': false
                })
                var node1 = document.getElementById('france_1');
                node1.dispatchEvent(event);
            }
        );
        this.props.getReorderEntryItems().then(
            res => {
                const pricing_details = cloneDeep(this.state.pricing_details);
                res.reOrderItems.forEach(item => {
                    pricing_details.order_items[item.title] = {
                        item_id: item.id,
                        price: Number(item.price),
                        quantity: 0,
                        title: item.title,
                        total: 0
                    }
                })
                this.setState({
                    reorderItems: res.reOrderItems,
                    pricing_details: pricing_details
                })
            }
        );
        this.props.getCountriesCall();
        this.props.getCommentCategoryByTitle(['Admin']);
        const parsed = queryString.parse(window.location.search);
        if ('comp' in parsed) {
            console.log(parsed)
            if (Boolean(parsed.comp) === true) {
                this.setState({
                    comp: Boolean(parsed.comp)
                })
            }
        }
    }

    quantityChangeHandler = (e) => {
        let { name, value } = e.target;
        if (value.match(/^\d*$/) === null || value > 100) {
            return false;
        }
        value = Number(value);
        let container = name.split('.')[0];
        const field = cloneDeep(this.state.pricing_details);
        set(field, name.replace(container + ".", ''), value);
        this.setState({
            [container]: field
        }, () => {
            const pricing_details = cloneDeep(this.state.pricing_details);
            const mozaixRanges = this.state.mozaixRanges;
            const mozaixRangesArr = Object.keys(this.state.mozaixRanges);
            const mozaixQuantity = pricing_details.order_items['PhotoMozaix™'].quantity;
            let mozaixDiscount = 0, shipping = 0;
            if (mozaixQuantity >= 76) {
                mozaixDiscount = (pricing_details.order_items['PhotoMozaix™'].quantity * pricing_details.order_items['PhotoMozaix™'].price) - (mozaixRanges['76+'] * mozaixQuantity);
            } else {
                for (let index = 0; index < mozaixRangesArr.length; index++) {
                    let range = mozaixRangesArr[index].split('-');
                    let lower = Number(range[0]);
                    let upper = Number(range[1]);
                    if (mozaixQuantity >= lower && mozaixQuantity <= upper) {
                        mozaixDiscount = (pricing_details.order_items['PhotoMozaix™'].quantity * pricing_details.order_items['PhotoMozaix™'].price) - (mozaixRanges[mozaixRangesArr[index]] * mozaixQuantity);
                    }
                }
            }
            let totalAmount = 0;
            let totalItems = 0;
            if (mozaixQuantity === 1) {
                shipping = 7;
            }
            transform(pricing_details.order_items, function (result, item, key) {
                item['total'] = item.price * item.quantity
                totalAmount += (item.price * item.quantity)
                totalItems += item.quantity
            }, 0);
            let comp = this.state.comp;
            // comp = comp.replace('?', '');
            // comp = Boolean(comp.split('=')[1]);
            // const comp = this.props
            let subtotal = totalAmount;
            totalAmount -= mozaixDiscount;
            totalAmount += shipping
            if (comp) {
                totalAmount = 0;
                // mozaixDiscount = 0;
                shipping = 0;
                subtotal = 0;
            }
            pricing_details.order_totals['grand_total'] = {
                code: 'grand_total',
                value: totalAmount
            }
            pricing_details.order_totals['discount'] = {
                code: 'discount',
                value: mozaixDiscount
            }
            pricing_details.order_totals['shipping'] = {
                code: 'shipping',
                value: shipping
            }
            pricing_details.order_totals['sub_total'] = {
                code: 'sub_total',
                value: subtotal
            }
            this.setState({
                pricing_details: pricing_details,
                totalItems: totalItems
            })
        })
    }

    inputChangeHandler = (e) => {
        let { name, value } = e.target;
        let container = name.split('.')[0];
        let field = cloneDeep(this.state[container]);
        set(field, name.replace(container + ".", ''), value);
        this.setState({
            [container]: field
        });
    }

    submitOrder = () => {
        if (this.Validator.allValid()) {
            const order_details = cloneDeep(this.state.order_details);
            order_details.comp = this.state.comp;
            let data = {
                order_details: order_details,
                pricing_details: this.state.pricing_details,
                shipping_details: {
                    pre_trip_material_address: this.state.shipping_details.pre_trip_material_address,
                    video_shipping_address: this.state.shipping_details.video_shipping_address
                }
            };
            this.props.storeKeepSakeOrder({ ...data, order_id: this.props.match.params.id }).then(
                res => {
                    const order_id = res.data.order_id;
                    const comment = cloneDeep(this.state.comment);
                    if (comment.comment !== '') {
                        this.props.addCommentCall(comment, order_id);
                    }
                    this.props.history.push(`/Admin/${this.props.match.params.id}/order-edit?orderDetailCode=reOrders`)
                }
            )
        } else {
            this.Validator.showMessages();
            this.forceUpdate();
        }
    }

    selectChangeHandler = (selectedOption, action) => {
        if (selectedOption.label === 'France' && action.name === 'shipping_details.video_shipping_address.country_id') {
            this.setState({
                is_france: true
            },() => {
                this.setState({
                    initial_country_France: true
                })
            })
        }
        if (selectedOption.label !== 'France' && action.name === 'shipping_details.video_shipping_address.country_id'){
            this.setState({
                is_france: false
            },() => {
                this.setState({
                    initial_country_France: false
                })
            })

        }
        console.log(selectedOption)
        console.log(action)
        if (action.action === 'select-option') {
            let container = action.name.split('.')[0];
            let field = cloneDeep(this.state[container]);
            set(field, action.name.replace(container + ".", ''), selectedOption.value);
            this.setState({
                [container]: field
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.shipping_details.video_shipping_address.country_id !== prevState.shipping_details.video_shipping_address.country_id) {
            this.props.getStateCall(this.state.shipping_details.video_shipping_address.country_id, 'video_shipping_address');
        }
        if (this.props.singleCommentCategory !== prevProps.singleCommentCategory) {
            const comment = cloneDeep(this.state.comment);
            comment.comment_category_id = this.props.singleCommentCategory.comment_category_id;
            this.setState({
                comment: comment
            })
        }
        if ((prevState.is_france !== this.state.is_france) || (prevState.initial_country_France !== this.state.initial_country_France)) {

            let event = new MouseEvent('click', {
                    'bubbles': true,
                    'cancelable': false
                })
                var node1 = document.getElementById('france_1');
                node1.dispatchEvent(event);
        }
    }


    render() {
        const imgs = [s3StaticPath('img/gtv_products_cd.png'), s3StaticPath('img/gtv_products_photomozaix.jpg'), s3StaticPath('img/gtv_products_video.png')]
        const { order_details, shipping_details, reorderItems, pricing_details, is_france, initial_country_France } = this.state;
        const { countries, states } = this.props;
        const { group_leader_contact } = order_details;
        const { video_shipping_address } = shipping_details;
        // console.log("video_shipping_address = ", order_details);
        console.log("is_france  = ", is_france);
        console.log("initial_country_France  = ", initial_country_France);
        return (
            <div className='content' >
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card content={
                                <React.Fragment>
                                    {/* <div className="secHeading">GTV ReOrder Entry</div> */}
                                    <table className='table customTable table-bordered'>
                                        <thead>
                                            <tr>
                                                <th>KeepSakes</th>
                                                <th>Description</th>
                                                <th>Quantity</th>
                                                <th>Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                reorderItems.map((item, index) => {
                                                    return (
                                                        <tr>
                                                            <td className='text-center'><img src={imgs[index]} alt={item.title} /></td>
                                                            <td>{parseHtml(item.description)}</td>
                                                            <td>
                                                                <input onChange={this.quantityChangeHandler} className='form-control' value={pricing_details.order_items[item.title].quantity} type="text" name={`pricing_details.order_items.${item.title}.quantity`} id="" />
                                                            </td>
                                                            <td style={{ width: '20%' }}>
                                                                ${item.price}
                                                                {
                                                                    item.title.match(/PhotoMozaix/) &&
                                                                    <div>
                                                                        <div><span style={{ width: '30%', display: 'inline-block' }}>2-5</span><span style={{ width: '80%' }}>$35.10 each</span></div>
                                                                        <div><span style={{ width: '30%', display: 'inline-block' }}>6-10</span><span style={{ width: '80%' }}>$33.15 each</span></div>
                                                                        <div><span style={{ width: '30%', display: 'inline-block' }}>11-20</span><span style={{ width: '80%' }}>$31.20 each</span></div>
                                                                        <div><span style={{ width: '30%', display: 'inline-block' }}>21-30</span><span style={{ width: '80%' }}>$29.25 each</span></div>
                                                                        <div><span style={{ width: '30%', display: 'inline-block' }}>31-50</span><span style={{ width: '80%' }}>$27.30 each</span></div>
                                                                        <div><span style={{ width: '30%', display: 'inline-block' }}>51-75</span><span style={{ width: '80%' }}>$25.35 each</span></div>
                                                                        <div><span style={{ width: '30%', display: 'inline-block' }}>76+</span><span style={{ width: '80%' }}>$23.40 each</span></div>
                                                                    </div>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            <tr>
                                                <td colSpan='2'>&nbsp;</td>
                                                <td colSpan='2' className='text-right'>
                                                    <div className='flexElem' style={{ justifyContent: 'flex-end' }}>
                                                        <div><strong>Mozaix Discount: </strong></div>
                                                        <div style={{ marginLeft: '10px' }}>${pricing_details.order_totals.discount ? pricing_details.order_totals.discount.value.toFixed(2) : 0}</div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan='2'>&nbsp;</td>
                                                <td colSpan='2'>
                                                    <div className='flexElem' style={{ justifyContent: 'flex-end' }}>
                                                        <div><strong>Shipping: </strong></div>
                                                        <div style={{ marginLeft: '10px' }}>${pricing_details.order_totals.shipping ? pricing_details.order_totals.shipping.value : 0}</div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan='2'>&nbsp;</td>
                                                <td colSpan='2' className='text-right'>
                                                    <div className='flexElem' style={{ justifyContent: 'flex-end' }}>
                                                        <div><strong>Total: </strong></div>
                                                        <div style={{ marginLeft: '10px' }}>${pricing_details.order_totals.grand_total ? pricing_details.order_totals.grand_total.value.toFixed(2) : 0}</div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <table className="table blank">
                                        <tbody>
                                            {this.Validator.message('items', this.state.totalItems, 'min:1,num')}
                                            <tr>
                                                <td>
                                                    <div className="mb-sm text-xs text-muted mt-md">Barcode:</div>
                                                    <div><input className='form-control' type="text" value={order_details.barcode} name="order_details.barcode" onChange={this.inputChangeHandler} id="" /></div>

                                                    {this.Validator.message('barcode', order_details.barcode, 'required')}
                                                </td>
                                                <td>
                                                    <div className="mb-sm text-xs text-muted mt-md">Name:</div>
                                                    <div>
                                                        <input className='form-control' value={clean(order_details.reorderCustomerName)} onChange={this.inputChangeHandler} type="text" name="order_details.reorderCustomerName" id="" />
                                                    </div>
                                                    {this.Validator.message('reorderCustomerName', order_details.reorderCustomerName, 'required')}
                                                </td>
                                                <td>
                                                    <div className="mb-sm text-xs text-muted mt-md">Address:</div>
                                                    <div>
                                                        <input className='form-control' type="text" name="shipping_details.video_shipping_address.street_address_1" value={video_shipping_address.street_address_1} onChange={this.inputChangeHandler} id="" />
                                                    </div>

                                                    {this.Validator.message('Address', video_shipping_address.street_address_1, 'required')}
                                                </td>
                                                <td style={{ verticalAlign: 'bottom' }}>
                                                    <div className="mb-sm text-xs text-muted mt-md">Country:</div>
                                                    <div>
                                                        <Select
                                                            value={returnLabelWithValue(video_shipping_address.country_id, countries)}
                                                            options={countries}
                                                            onChange={this.selectChangeHandler}
                                                            isSearchable={true}
                                                            isMulti={false}
                                                            name='shipping_details.video_shipping_address.country_id'
                                                            placeholder={'Select Country'}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <div className="mb-sm text-xs text-muted mt-md">City:</div>
                                                    <div>
                                                        <input className='form-control' value={video_shipping_address.city} onChange={this.inputChangeHandler} type="text" name="shipping_details.video_shipping_address.city" id="" />
                                                    </div>

                                                    {this.Validator.message('City', video_shipping_address.city, 'required')}
                                                </td>
                                                <td>
                                                    <div className="mb-sm text-xs text-muted mt-md">State:</div>
                                                    <div>
                                                        <Select
                                                            value={returnLabelWithValue(video_shipping_address.state_id, states)}
                                                            options={states}
                                                            onChange={this.selectChangeHandler}
                                                            isSearchable={true}
                                                            isMulti={false}
                                                            name='shipping_details.video_shipping_address.state_id'
                                                            placeholder={'Select State'}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="mb-sm text-xs text-muted mt-md">Zip:</div>
                                                    <div>
                                                        <input className='form-control' value={video_shipping_address.zipcode} onChange={this.inputChangeHandler} type="text" name="shipping_details.video_shipping_address.zipcode" id="" />
                                                    </div>
                                                    {this.Validator.message('zipcode', video_shipping_address.zipcode, 'required')}
                                                </td>
                                                <td>
                                                    <div className="mb-sm text-xs text-muted mt-md">Phone:</div>
                                                    <div>

                                                    <CustomPhoneNumber
                                                        value={clean(group_leader_contact.phone1)}
                                                        handleParent={this.inputChangeHandler}
                                                        validationName={'group_leader_contact'}
                                                        validator={this.Validator}
                                                        required={true}
                                                        name={'order_details.group_leader_contact.phone1'}
                                                        elemId={'france_1'}
                                                        is_france={is_france}
                                                        initial_country_France={initial_country_France}
                                                    />
                                                        {/* <input className='form-control' onChange={this.inputChangeHandler} value={clean(group_leader_contact.phone1)} type="text" name="order_details.group_leader_contact.phone1" id="" /> */}
                                                        {/* <PhoneNumber
                                                            value={order_details.group_leader_contact.phone1}
                                                            onChange={this.inputChangeHandler}
                                                            // validationName={'GroupLeaderPhone_' + getSum(key, 1)}
                                                            validator={this.Validator}
                                                            required={true}
                                                            name={'"order_details.group_leader_contact.phone1'}
                                                        /> */}
                                                    </div>
                                                    {this.Validator.message('Phone', group_leader_contact.phone1, 'required')}
                                                </td>
                                                <td>
                                                    <div className="mb-sm text-xs text-muted mt-md">Email:</div>
                                                    <div>
                                                        <input className='form-control' value={clean(group_leader_contact.email)} onChange={this.inputChangeHandler} type="email" name="order_details.group_leader_contact.email" id="" />
                                                    </div>

                                                    {this.Validator.message('Email', group_leader_contact.email, 'required')}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='form-group'>
                                                <label htmlFor="">Reorder Comment</label>
                                                <textarea placeholder='ReOrder Comment...' name="comment.comment" onChange={this.inputChangeHandler} value={this.state.comment.comment} id="" className='form-control' cols="30" rows="5"></textarea>
                                                {this.Validator.message('comment', this.state.comment.comment, 'required')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-right'>
                                        <button onClick={this.submitOrder} className='backButton pt-sm no_radius pb-sm primary btn btn-md btn-info ml-sm mt-sm'>Save</button>
                                    </div>
                                </React.Fragment>
                            } />
                        </Col>
                    </Row>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { countries, states } = state.settingsReducer;
    const { singleCommentCategory } = state.commentReducer;
    return {
        countries,
        states,
        singleCommentCategory
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getOrderCall: (orderId) => dispatch(getOrder(orderId)),
        getReorderEntryItems: () => dispatch(getReorderEntryItems()),
        storeKeepSakeOrder: (data) => dispatch(storeKeepSakeOrder(data)),
        getCountriesCall: () => { dispatch(getCountries()) },
        getStateCall: (countryId, type) => { dispatch(getStates(countryId, type)) },
        addCommentCall: (data, modelId) => dispatch(addComment(data, modelId)),
        getCommentCategoryByTitle: (titles) => { dispatch(getCommentCategoryByTitle(titles)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReOrderEntry)
