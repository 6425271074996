import React, { Component } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PanelGroup, Panel } from 'react-bootstrap';

import { s3StaticPath, scrollLogin } from 'helper/helperFunctions';
import Validator from 'simple-react-validator'
import { sendContactEmail } from '../../redux/actions/front-end-action';
import info_email from '../../assets/info_email.svg';
import dmartinson_email from '../../assets/dmartinson_email.svg';


class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
      full_name: '',
      phone: '',
      email: '',
      message: '',
      company: ''
    };
    this.handleHover = this.handleHover.bind(this);
    this.openChat = this.openChat.bind(this);
    this.contactValidator = new Validator();
  }

  componentDidMount(){
    this.openChat()
  }

  handleHover() {
    // this.setState(prevState => ({
    //     isHovered: !prevState.isHovered
    // }));

    $("#clients div.client").hover(function () {
      $("#clients div.client").removeClass("pink_gradient")
      $(this).addClass("pink_gradient")
    }, function () {
      $("#clients div.client").removeClass("pink_gradient")
    })
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://www.mylivechat.com/chatbutton.aspx?hccid=96602185";
    script.async = true;
    document.body.appendChild(script);
  }

  openChat = () => {
    new function(){
      if(!(window.location.href.indexOf("Admin") > -1)) {
        var url='https://a7.mylivechat.com/livechat/livechat.aspx?hccid=96602185&apimode=chatbutton';
        var s=document.getElementsByTagName('script')[0];
        var ns=document.createElement('script');
        ns.setAttribute('type','text/javascript');
        ns.setAttribute('src',url);
        s.parentNode.insertBefore(ns,s);
        // console.log(ns);
      }
    }
  }

  inputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  submitContactForm = () => {
    if (this.contactValidator.allValid()) {
      const data = {
        full_name: this.state.full_name,
        phone: this.state.phone,
        email: this.state.email,
        message: this.state.message,
        company: this.state.company
      }
      this.props.sendContactEmail(data).then(
        res => {
          const reset = {
            full_name: '',
            phone: '',
            email: '',
            message: '',
            company: ''
          }
          this.setState(prevState => ({
            ...prevState,
            ...reset
          }), () => {
            this.contactValidator.hideMessages();
            this.contactValidator.purgeFields();
            this.forceUpdate();
          })
        },
        err => {

        }
      );
    } else {
      this.contactValidator.showMessages();
      this.forceUpdate();
    }
  }
  render() {
    // const hoverClass = this.state.isHovered ? "client pink_gradient" : "client";
    // let sliderSettings = {
    //   dots: true,
    //   autoplay: true,
    //   infinite: true,
    //   speed: 500,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   accessibility: false,
    //   className: 'benefits',
    //   prevArrow: <div><MdArrowBack /></div>,
    //   nextArrow: <div><MdArrowForward /></div>,
    // };

    return (
      <React.Fragment>

        <section id="contactUs" className="section">
          <h2 className="panel_heading">Contact Us</h2>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <p className="text_22 text-center mt-md">Do you have a question or need assistance with a Travel TripKit Product or Service?</p>
              </div>

              <div className="col-md-6 mt-xlg">
                <div className="liveChatBanner">
                  <div>
                    <p>Live <span className="text-white">Chat</span></p>
                    {/* <p className="text_18 text-black">Call or Email</p> */}
                    <div id="MyLiveChatContainer"></div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 mt-xlg">
                    <div className="supportBox text-center">
                      <img src={s3StaticPath('img/traveler_support.png')} alt="" />

                      <p className="text_16 mt-xlg"><strong className="semibold">Traveler Support</strong></p>
                      <p className="text_14 mt-xs" style={{ letterSpacing: '0.5px' }}><img src={info_email} alt="email" /></p>
                      <p className="text_18 mt-xs">704-846-7870 x209</p>
                      <p className="text-primary mt-xs">(EST Hours: 9:00AM - 5:00PM)</p>
                    </div>
                  </div>
                  <div className="col-md-6 mt-xlg">
                    <div className="supportBox text-center">
                      <img src={s3StaticPath('img/tour_operator_support.png')} alt="" />

                      <p className="text_16 mt-xlg"><strong className="semibold">Tour Operator Support</strong></p>
                      <p style={{ letterSpacing: '0.5px' }} className="text_14 mt-xs"><img src={dmartinson_email} alt="email" /></p>
                      <p className="text_18 mt-xs">1.888.533.7637 x205</p>
                      <p className="text-primary mt-xs">(EST Hours: 9:00AM - 5:00PM)</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-xlg contactFormContainer">
                <div className="flexElem respFlex">
                  <div className="hasFieldIcon">
                    <span className="fieldIcon userIcon">&nbsp;</span>
                    <input onChange={this.inputChange} value={this.state.full_name} type="text" name='full_name' className="form-control" placeholder="Full Name" />
                    {this.contactValidator.message('full_name', this.state.full_name, 'required')}
                  </div>
                  <div className="hasFieldIcon">
                    <span className="fieldIcon companyIcon">&nbsp;</span>
                    <input onChange={this.inputChange} value={this.state.company} name='company' type="text" className="form-control" placeholder="Company" />
                    {this.contactValidator.message('company', this.state.company, 'required')}
                  </div>
                </div>

                <div className="flexElem respFlex">
                  <div className="hasFieldIcon">
                    <span className="fieldIcon emailIcon">&nbsp;</span>
                    <input onChange={this.inputChange} value={this.state.email} name='email' type="email" className="form-control" placeholder="Email" />
                    {this.contactValidator.message('email', this.state.email, 'required|email')}
                  </div>
                  <div className="hasFieldIcon">
                    <span className="fieldIcon userIcon">&nbsp;</span>
                    <input onChange={this.inputChange} value={this.state.phone} name='phone' type="text" className="form-control" placeholder="Phone" />
                    {this.contactValidator.message('phone', this.state.phone, 'required|phone')}
                  </div>
                </div>

                <div className="flexElem block respFlex">
                  <div><textarea name='message' value={this.state.message} onChange={this.inputChange} className="form-control" placeholder="Message"></textarea></div>
                </div>
                {this.contactValidator.message('message', this.state.message, 'required')}

                <div className="flexElem block respFlex">
                  <div className="text-center">
                    <button onClick={this.submitContactForm} type="button" className="themeBtn_14 no_radius hover_dark red_gradient">Submit Contact Form</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    sendContactEmail: (data) => dispatch(sendContactEmail(data))
  }
}
export default connect(null, mapDispatchToProps)(ContactUs);
