import React, { Component } from 'react';
import { connect } from 'react-redux';
import MessageCom from "layouts/Dashboard/Message";
import { login, logout } from 'redux/actions/front-end-action';
// import MyImage from 'components/Upload/MyImage';

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            submitted: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        if (Object.keys(this.props.match.params).length > 1) {
            const params = { ...this.props.match.params };
            if (params.username !== undefined && params.password !== undefined) {
                this.props.loginCall(params.username, params.password)
            }
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ submitted: true });
        const { email, password } = this.state;
        if (email && password) {
            this.props.loginCall(email, password);
        }
    }
    render() {
        const { email, password, submitted } = this.state;
        const { loggingIn } = this.props;
        return (
            <div className="main_login">
                <div className="login_form_container">
                    <div className="login_form">
                        <h1 className="text-center">User Login</h1>
                        <form name="form" onSubmit={this.handleSubmit}>
                            <div className={'form-group' + (submitted && !email ? ' has-error' : '')}>
                                <input type="text" placeholder="Email or Username" className="form-control email_ctrl" name="email" value={email} onChange={this.handleChange} />
                                {submitted && !email &&
                                    <div style={{ marginTop: '0' }} className="help-block">Email is required.</div>
                                }
                            </div>
                            <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                                <input type="password" placeholder="Password" className="form-control password_ctrl" name="password" value={password} onChange={this.handleChange} />
                                {submitted && !password &&
                                    <div style={{ marginTop: '0' }} className="help-block">Password is required.</div>
                                }
                            </div>
                            <div className="form-group">
                                <button style={{ width: '100%' }} className="btn btn-default btn_login">LOGIN</button>
                                {
                                    loggingIn &&
                                    <img alt="imageLogin" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                }
                            </div>
                        </form>
                    </div>
                    <MessageCom />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { loading, frontEndUser, loggedIn } = state.frontEndUserReducer;
    return {
        loading, frontEndUser, loggedIn
    };
}

const mapDispatchToProps = dispatch => {
    return {
        loginCall: (email, password) => { dispatch(login(email, password)) },
        logoutCall: () => { dispatch(logout()) }
    }
}

const Login = connect(mapStateToProps, mapDispatchToProps)(LoginPage);
export default Login;