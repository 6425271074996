import React, { Component } from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getOrder, orderCheckout, storeKeepSakeOrder } from 'redux/actions/order-actions';
import User from 'helper/User';
import DatePicker from 'react-date-picker';
import SimpleReactValidator from 'simple-react-validator';
// import logoBig from 'assets/img/company_logo_big.png';
import { s3StaticPath, usDateFormat } from 'helper/helperFunctions';
import {
	Button
} from "react-bootstrap";
var cloneDeep = require('lodash.clonedeep');
var moment = require("moment");

class Checkout extends Component {
	constructor(props) {
		super(props);
		this.validatorCheckout = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
		const data = this.props.location ? this.props.location.state.data : '' ;
		this.state = {
			keepSake: {
				order_details: data.order_details,
				pricing_details: data.pricing_details,
				shipping_details: data.shipping_details,
			},
			checkout: {
				firstName: '',
				lastName: '',
				address: '',
				city: '',
				country: '',
				zipcode: '',
				state: '',
				cvv: '',
				cardNumber: '',
				amount: data.pricing_details.order_totals.grand_total.value,
				expirationYearMonth: ''
			},
			checkedvideo_shipping_address: false,
            online_payment: true
		}
		this.props.getOrderCall(User.getProperty('order.id'));
	}

	onChangsAdvanceSearchDate = (value, field, dateType) => {
		const advanceSearchState = cloneDeep(this.state.checkout);
		advanceSearchState['expirationYearMonth'] = value;
		// advanceSearchState[field]['type'] = dateType;
		this.setState(prevState => ({
			...prevState, checkout: advanceSearchState
		}));
	}

	dateElement = (name, value, rules, type, dateType) => {
		var selectedDate = this.state.checkout.expirationYearMonth !== '' ? new Date(this.state.checkout.expirationYearMonth) : '';
		if (!dateType)
			dateType = "date";
		var propTemp = {};

		propTemp = { maxDetail: "year", monthPlaceholder: "M", yearPlaceholder: "Y" };

		if (!type)
			type = "text";
		return (

			<DatePicker
				className="form-control"
				value={selectedDate}
				name={name}
				onChange={(event) => this.onChangsAdvanceSearchDate(event, name, dateType)}
				clearIcon={null}
				calendarIcon={null}
				{...propTemp}

			/>
		);
	}

	handleUserSubmitForm = (e) => {
		e.preventDefault();
            const order_details = cloneDeep(this.state.keepSake.order_details);
             order_details.online_payment = this.state.online_payment;
             let data = {
                 order_details: order_details,
                 pricing_details: this.state.keepSake.pricing_details,
                 shipping_details: this.state.keepSake.shipping_details
             }
		if (this.validatorCheckout.allValid()) {
			this.props.handleCheckoutSubmit({ ...this.state.checkout, order_id: User.getProperty('order.id') }).then(response => {
				// console.log('keepSake state data two', ...this.state.keepSake);
				this.props.storeKeepSakeOrder({ ...data, order_id: User.getProperty('order.id') }).then(
					res => {
						this.props.history.push('/uploader');
					},
					err => {

					}
				);
			});
		} else {
			this.setState({ submitted: false });
			this.validatorCheckout.showMessages();
			this.forceUpdate();
		}
	}

	handleUser = (e) => {
		const { name, value, /*type*/ } = e.target;
		const userState = { ...this.state.checkout };
		userState[name] = value;
		this.setState({ checkout: userState });
	}

	copyShippingAddress = (e) => {
		let isChecked = e.target.checked;
		const { order_details,/* pricing_details,*/ shipping_details } = this.state.keepSake;
		// console.log('order_details', order_details);
		if (isChecked) {
			var parseName = order_details.group_leader_contact.name.split(' ');
			this.setState({
				checkout: {
					...this.state.checkout,
					firstName: parseName[0],
					lastName: parseName.length > 1 ? parseName[1] : '',
					address: shipping_details.video_shipping_address.street_address_1,
					city: shipping_details.video_shipping_address.city,
					country: shipping_details.video_shipping_address.country.title,
					state: shipping_details.video_shipping_address.state.title,
					zipcode: shipping_details.video_shipping_address.zipcode,
				}
			}, () => {
				this.setState({ checkedvideo_shipping_address: true });
			});
		} else {
			this.setState({ checkedvideo_shipping_address: false });
		}
	}
	render() {
		const { keepSake, checkout, checkedvideo_shipping_address } = this.state;
		const { pricing_details, /*shipping_details,*/ order_details } = this.props;
        // console.log("keepSake =", keepSake);
		return (
			<React.Fragment>
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<h2 className="panel_heading userLogin_heading">Reorder Checkout</h2>
						</div>

						<div className="col-md-12 mt-xlg">
							<div className="flexElem respFlex alignCenter spaceBetween">

								<div className="text_16" style={{ width: "100%", maxWidth: "660px" }}>
									{/*<div>You are placing a Keepsake order for: <span className="text-primary">{order_details.agency.name}</span></div>*/}
									<div className="mt-xs">Destination: <span className="text-primary">{order_details.destinationText}</span></div>
									{/* <div className="mt-xs">Trip Dates: <span className="text-primary">{order_details.order_dates.departure_date.value} - { order_details.order_dates.return_date.value}</span></div> */}
									<div className="mt-xs">Trip Dates: <span className="text-primary">{usDateFormat(order_details.order_dates.departure_date.value)} - { usDateFormat(order_details.order_dates.return_date.value)}</span></div>
									<div className="mt-xs">Original Order #: <span className="text-primary">{order_details.barcode}</span></div>
								</div>
								{
									('agency_logo' in order_details.agency && order_details.agency.agency_logo) &&
									<div>
										<img src={order_details.agency.agency_logo.file_path} alt="Logo name" style={{ maxHeight: "127px" }} />
									</div>
								}
							</div>
						</div>

						<div className="col-md-12">
							<div className="form_tile">
								<div className="form_tile_heading spaceBetween">
									<span>Please Review Your Keepsakes Quantities Before Checkout</span>
									{
										/*
										<a href="/#" className="actionBtn primaryBtn inverse p-xs" style={{fontSize: 'smaller'}}>
											<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path></svg>
											Edit Order
										</a>
										*/
									}
								</div>

								<div className="form_tile_content">
									{
										'order_items' in pricing_details &&
										<React.Fragment>
											{
												Object.keys(keepSake.pricing_details.order_items).map((keyName, i) => (
													<div key={i} className="flexElem respFlex alignCenter spaceBetween form_tile_row">
														<div className="col-md-4">{keepSake.pricing_details.order_items[keyName].title}:</div>
														<div className="col-md-8">
															<strong>{keepSake.pricing_details.order_items[keyName].quantity}</strong>
														</div>
													</div>
												)
												)
											}
											<hr />
											<div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
												<div className="col-md-4">Discount:</div>
												<div className="col-md-8">
													<strong>{`$${Number(keepSake.pricing_details.order_totals.discount.value).toFixed(2)} `}</strong>
												</div>
											</div>

											<div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
												<div className="col-md-4">Shipping:</div>
												<div className="col-md-8">
													<strong>${keepSake.pricing_details.order_totals.shipping.value}</strong>
												</div>
											</div>

											<div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
												<div className="col-md-4"><strong>Total:</strong></div>
												<div className="col-md-8">
													<strong>{`$${Number(keepSake.pricing_details.order_totals.grand_total.value).toFixed(2)} `}</strong>
												</div>
											</div>
										</React.Fragment>
									}
								</div>
							</div>

							<div className="form_tile">
								<div className="form_tile_heading spaceBetween">
									<span>Your Shipping &amp; Contact Information Before Checkout</span>
								</div>

								<div className="form_tile_content">
									<div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
										<div className="col-md-4">{keepSake.order_details.group_leader_contact.name}</div>
										<div className="col-md-8">
											{keepSake.order_details.group_leader_contact.phone1}
										</div>
									</div>

									<div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
										<div className="col-md-4">{keepSake.shipping_details.video_shipping_address.city}</div>
										<div className="col-md-8">
											{keepSake.order_details.group_leader_contact.email}
										</div>
									</div>

									<div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
										<div className="col-md-12">
											{
												`${keepSake.shipping_details.video_shipping_address.city}, ${keepSake.shipping_details.video_shipping_address.state.title} ${keepSake.shipping_details.video_shipping_address.zipcode} ${keepSake.shipping_details.video_shipping_address.country.title}`
											}
										</div>
									</div>
								</div>
							</div>

							<div className="form_tile">
								<div className="form_tile_heading spaceBetween">
									<span>Payment Information</span>
								</div>

								<div className="form_tile_content">
									<div className="flexElem respFlex spaceBetween">
										<div className="col-md-7">
											<div className="flexElem respFlex alignCenter form_tile_row">
												<div className="text_18">Accepted Payment Types</div>
												<div className="ml-xs"><img src={s3StaticPath('img/credit-cards.png')} alt="Accepted Payment Types" /></div>
											</div>
											<div className="flexElem respFlex alignCenter form_tile_row mt-xs">
												<div className="text_14">
													PhotoVision accepts online payments from Discover, MasterCard &amp; Visa.
											</div>
											</div>

											<div className="flexElem respFlex alignCenter form_tile_row">
												<div className="text_18">Need Help?</div>
											</div>
											<div className="flexElem respFlex alignCenter form_tile_row mt-xs">
												<div className="text_14">If you need help, please <Link className="text-primary" to="/Contact_Us">click here</Link> to contact us.</div>
											</div>

											<div className="flexElem respFlex alignCenter form_tile_row">
												<div className="text_18">Security</div>
											</div>
											<div className="flexElem respFlex form_tile_row mt-xs">
												<div className="text_14">
													PhotoVision takes your security to heart. We have been verified by Authorized.Net and secured by GoDaddy.com.
											</div>
											</div>
											<div className="flexElem respFlex form_tile_row">
												<div>
													<div className="AuthorizeNetSeal">
														<script type="text/javascript" language="javascript">var ANS_customer_id="1a5778aa-80c3-4f85-8542-9977b5fe22f1";</script> <script type="text/javascript" language="javascript" src="//verify.authorize.net/anetseal/seal.js" ></script> <a rel="noopener noreferrer" href="http://www.authorize.net/" id="AuthorizeNetText" target="_blank">Secure Online Payments</a>
													</div>
													<br />
													<span id="siteseal"><script type="text/javascript" src="https://seal.godaddy.com/getSeal?sealID=RtvUZzPygzLRHbN5ZztJc170UAwuEYKbxdcb4xsoY6lAp2dHJHx4CpPurgjG"></script></span>
												</div>
											</div>
										</div>

										<div className="col-md-5">
											<div className="flexElem respFlex alignCenter form_tile_row">
												<div className="col-md-12">
													<div className="text_18">
														Total : ${Number(keepSake.pricing_details.order_totals.grand_total.value).toFixed(2)}
												</div>
												</div>
											</div>

											{
												keepSake.shipping_details.video_shipping_address &&
												<div className="flexElem respFlex alignCenter form_tile_row">
													<div className="col-md-12">
														<div className="customChk">
															<input
																type="checkbox"
																name="priTrip"
																className="checkbox"
																value={checkedvideo_shipping_address}
																onChange={(e) => this.copyShippingAddress(e)}
																checked={checkedvideo_shipping_address}
																id="shipAddress"
															/>
															<label htmlFor="shipAddress" className="text_16">Same As Shipping Address</label>
														</div>
													</div>
												</div>
											}

											<form onSubmit={this.handleUserSubmitForm}>
												<div className="flexElem respFlex form_tile_row mt-lg">
													<div className="col-md-6">
														<div>
															<label className="mb-xs" htmlFor="name">Billing First Name<span className="requiredClass">*</span></label>
															<input type="text" name="firstName" className="form-control" value={checkout.firstName} onChange={this.handleUser} />
															{this.validatorCheckout.message('firstName', checkout.firstName, 'required')}
														</div>
													</div>
													<div className="col-md-6">
														<div>
															<label className="mb-xs" htmlFor="name">Billing Last Name<span className="requiredClass">*</span></label>
															<input type="text" name="lastName" className="form-control" value={checkout.lastName} onChange={this.handleUser} />
															{this.validatorCheckout.message('lastName', checkout.lastName, 'required')}
														</div>
													</div>
												</div>

												<div className="flexElem respFlex form_tile_row">
													<div className="col-md-6">
														<div>
															<label className="mb-xs" htmlFor="name">Billing Addres<span className="requiredClass">*</span></label>
															<input type="text" name="address" className="form-control" value={checkout.address} onChange={this.handleUser} />
															{this.validatorCheckout.message('address', checkout.address, 'required')}
														</div>
													</div>
													<div className="col-md-6">
														<div>
															<label className="mb-xs" htmlFor="name">City<span className="requiredClass">*</span></label>
															<input type="text" name="city" className="form-control" value={checkout.city} onChange={this.handleUser} />
															{this.validatorCheckout.message('city', checkout.city, 'required')}
														</div>
													</div>
												</div>

												<div className="flexElem respFlex form_tile_row">
													<div className="col-md-6">
														<div>
															<label className="mb-xs" htmlFor="name">State<span className="requiredClass">*</span></label>
															<input type="text" name="state" className="form-control" value={checkout.state} onChange={this.handleUser} />
															{this.validatorCheckout.message('state', checkout.state, 'required')}
														</div>
													</div>
													<div className="col-md-6">
														<div>
															<label className="mb-xs" htmlFor="name">Zipcode<span className="requiredClass">*</span></label>
															<input type="text" name="zipcode" className="form-control" value={checkout.zipcode} onChange={this.handleUser} />
															{this.validatorCheckout.message('zipcode', checkout.zipcode, 'required')}
														</div>
													</div>
												</div>

												<div className="flexElem respFlex form_tile_row">
													<div className="col-md-6">
														<div>
															<label className="mb-xs" htmlFor="name">Country<span className="requiredClass">*</span></label>
															<input type="text" name="country" className="form-control" value={checkout.country} onChange={this.handleUser} />
															{this.validatorCheckout.message('country', checkout.country, 'required')}
														</div>
													</div>
													<div className="col-md-6">
														<div>
															<label className="mb-xs" htmlFor="name">Credit Card Number<span className="requiredClass">*</span></label>
															<input type="text" name="cardNumber" className="form-control" value={checkout.cardNumber} onChange={this.handleUser} />
															{this.validatorCheckout.message('cardNumber', checkout.cardNumber, 'required')}
														</div>
													</div>
												</div>

												<div className="flexElem respFlex form_tile_row">
													<div className="col-md-6">
														<div>
															<label className="mb-xs" htmlFor="name">CCV<span className="requiredClass">*</span></label>
															<input type="text" name="cvv" className="form-control" value={checkout.cvv} onChange={this.handleUser} />
															{this.validatorCheckout.message('cvv', checkout.cvv, 'required')}
														</div>
													</div>
													<div className='col-md-6'>
														<div>
															<label className="mb-xs">Exp (XX/XX) <span className="requiredClass">*</span></label>
															{
																this.dateElement('expirationYearMonth', (checkout.expirationYearMonth !== '' || checkout.expirationYearMonth !== null) && moment(checkout.expirationYearMonth, 'YYYY-MM'), [], 'date', 'monthYear')
															}
															{this.validatorCheckout.message('expirationYearMonth', checkout.expirationYearMonth, 'required')}
														</div>
													</div>
												</div>


												<div className="flexElem respFlex form_tile_row">
													<div className="col-md-12">
														<div className='text-right'>
															<Button type='submit' className="actionBtn primaryBtn">Checkout</Button>
														</div>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return ({
		getOrderCall: (orderId) => { dispatch(getOrder(orderId)) },
		handleCheckoutSubmit: (data) => { return dispatch(orderCheckout(data)) },
		storeKeepSakeOrder: (data) => dispatch(storeKeepSakeOrder(data)),
		// exampleFunctionCall: () => { dispatch(exampleAction.exampleFunction()) },//example function
	});
};

function mapStateToProps(state) {
	// const { exampleConstant } = state.exampleReducer;
	const { order_details, shipping_details, pricing_details } = state.ordersReducer;
	return {
		order_details, shipping_details, pricing_details
	};
}


export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
