export const photoGalleryErrors = (orderUploadInfo, Status, photoGalleryError, User, React, Roles, showGallery, Link, history) => {
    if (orderUploadInfo.status.title === Status.CANCELLED) {
        photoGalleryError = <div className='alert alert-danger' role='alert'>
            <h4>Order Cancelled</h4>
            <p>The order for {orderUploadInfo.group_name} tour to {orderUploadInfo.destinations}</p>
        </div>
    } 
    // else if (orderUploadInfo.cameraShipStatus === null && !Number(orderUploadInfo.finalize_by_qa.value) && User.hasRole(Roles.PHOTO_ADMIN) && orderUploadInfo.preShippingContact) {
    //     history.push('order-options');
    // }
    // else if (orderUploadInfo.cameraShipStatus === null && !Number(orderUploadInfo.finalize_by_qa.value) && User.hasRole(Roles.PHOTO_ADMIN) && !orderUploadInfo.preShippingContact) {
    //     photoGalleryError = <div className='alert alert-danger' role='alert'>
    //         <h4>Options already selected - Awaiting Order Processing</h4>
    //         <p>You will be able to access your order after your information packet and/or equipment has been processed ad shipped.</p>
    //         <br />
    //         <p>Your projected processing date is {orderUploadInfo.projected_camera_ship_date}. If you have any questions regarding the options you have selected, please call 704-846-7870 x209</p>
    //     </div>
    // }
    else if (!orderUploadInfo.cameraShipStatus && !Number(orderUploadInfo.finalize_by_qa.value) && User.hasRole(Roles.TRAVELER)) {
        showGallery = true;
        photoGalleryError = <div className='alert alert-success' role='alert'>
            <h4>Congratulations</h4>
            <p>{orderUploadInfo.agency.name} your upcoming trip to {orderUploadInfo.destinations}</p>
            <p>Since you have not traveled. We don't have any photos to share with you. This where you can help us to share and preserve your memories. Please check back on the day of departure to start uploading photos of your travel experience.!</p>
            <p>In the meantime, check out the mobile app demo and get familiar with the process. Thank you for allowing us to help you share your memories for today and preserve them for tomorrow.</p>
        </div>
        //Equipment Still Assigned TODO
    }
    //  else if (orderUploadInfo.upload_date_passed && !Number(orderUploadInfo.finalize_by_qa.value) && orderUploadInfo.images_count === 0) {
    //     showGallery = true;
    //     photoGalleryError = <div className='alert alert-danger' role='alert'>
    //         <h4>Waiting for Your Photos</h4>
    //         <p>The photos for <strong>{orderUploadInfo.group_name}</strong> tour to <strong>{orderUploadInfo.destinations}</strong> are currently unavailable.
    //         Production will begin on your videos when the equipment we provided your group has been returned.
    //     Once production is complete, we will post all of your edited photos and your  to the gallery for you to enjoy your memories for today while we prepare and ship your Keepsake DVD memories for tomorrow.</p>
    //         <p>For questions or concerns, please <Link to="/Contact_Us">Contact Us</Link>. For a faster response, please reference your order #: <strong>{orderUploadInfo.barcode}</strong> when contacting us.</p>
    //     </div>
    // }
    // else if (!Number(orderUploadInfo.finalize_by_qa.value) && orderUploadInfo.upload_date_passed && orderUploadInfo.images_count === 0 && (orderUploadInfo.camType !== 'Uploading' || orderUploadInfo.camType !== 'SD Card Gift')) {
    //     showGallery = true;
    //     photoGalleryError = <div className='alert alert-danger' role='alert'>
    //         <h4>Waiting for Your Photos</h4>
    //         <p>The photos for <strong>{orderUploadInfo.group_name}</strong> tour to <strong>{orderUploadInfo.destinations}</strong> are currently unavailable.
    //         We need to begin production for your videos. However, it is very important that we speak with you before we begin! Please call 704-846-7870 x209 so we can complete your order.
    // </p>
    //         <p>To receive an uploading time extension please <Link to="/Contact_Us">contact us</Link>. For a faster response please reference your order # <strong>{orderUploadInfo.barcode}</strong> when contacting us.</p>
    //         <p>To receive an uploading time extension, please Contact Us. Please reference your order #: {orderUploadInfo.order_number} when contacting us.</p>
    //     </div>
    // }
    else {
        showGallery = true;
    }
    return { showGallery, photoGalleryError }
}