import React from 'react';
import Select from 'react-select';
import { Button } from "react-bootstrap";
import { connect } from 'react-redux';
import { updateProgramType, updateProjectCameraShipDate } from 'redux/actions/order-actions';
import { ThemeFunctions as TF } from 'helper/ThemeFunctions';
import { helperFunctions as HF } from 'helper/helperFunctions';
import { getProgramType } from 'redux/actions/settings-actions';
import DatePicker from "react-datepicker";
import { orderService } from 'services/order';
// import MyImage from 'components/Upload/MyImage';
import { Link } from 'react-router-dom';
import { filter, flatMap, map } from 'lodash';
import InputMask from 'react-input-mask';
// var _ = require('lodash');
var cloneDeep = require('lodash.clonedeep');

class CustomerServiceDetailPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			order_details: this.props.order_details,
			pricing_details: this.props.pricing_details,
			shipping_details: this.props.shipping_details,
			csr_steps: this.props.csr_steps,
			stars: {},
            ptma_initial_country_France: false,
            vsa_initial_country_France: false,
		};

		this.props.getProgramTypeCall();
	}

    componentDidMount() {
        if (this.props.shipping_details && this.props.shipping_details.pre_trip_material_address && this.props.shipping_details.pre_trip_material_address.country && this.props.shipping_details.pre_trip_material_address.country.title && this.props.shipping_details.pre_trip_material_address.country.title === 'France'){

            this.setState({
                    ptma_initial_country_France: true
            });
        }
        if (this.props.shipping_details && this.props.shipping_details.video_shipping_address && this.props.shipping_details.video_shipping_address.country && this.props.shipping_details.video_shipping_address.country.title && this.props.shipping_details.video_shipping_address.country.title === 'France'){

            this.setState({
                    vsa_initial_country_France: true
            });
        }
    }

	componentDidUpdate(prevProps, prevState) {
		if (this.props.order_details !== prevProps.order_details) {
			this.setState({ order_details: this.props.order_details });
		}

		if (this.props.shipping_details !== prevProps.shipping_details) {
			this.setState(
				{
					shipping_details: this.props.shipping_details
				}
			);
		}
		if (this.props.pricing_details !== prevProps.pricing_details) {
			this.setState(
				{
					pricing_details: this.props.pricing_details,
				});
		}
		if (this.props.csr_steps !== prevProps.csr_steps) {
			this.setState(
				{
					csr_steps: this.props.csr_steps,
				});
		}


	}

	disableOption = (option) => {
		if (option.price !== this.props.pricing_details.program_type.price)
			return true;
	}

	handleChangeProgramType = (selectedOptionType, info) => {
		if (info['action'] === 'select-option') {
			this.props.updateProgramTypeCall(this.props.order_details.id, selectedOptionType.value).then(response => {
				this.props.getOrderCall(this.props.order_details.id);
			});
		}
	}

	projectCameraShipDateChangeHandler = (value) => {
		const shippingDetails = cloneDeep(this.state.shipping_details);
		shippingDetails['order_dates']['projected_camera_ship_date']['value'] = value;
		this.setState({ shipping_details: shippingDetails });
	}

	projectCameraShipDateFormHandler = (e) => {
		e.preventDefault();
		const data = {
			slug: this.state.shipping_details.order_dates.projected_camera_ship_date.slug,
			value: this.state.shipping_details.order_dates.projected_camera_ship_date.value,
		}
		this.props.updateProjectCameraShipDateCall(this.props.orderId, data);
	}

	showStars = (value, flag) => {
		var data = {
			value: value,
			flag: flag
		};
		orderService.getOrderStars(data).then(response => {
			this.setState({ stars: { ...response.data } }, () => {
				console.log('stars', this.state.stars);
			})
		});
	}
	render() {
		const { order_details, pricing_details, shipping_details, stars, ptma_initial_country_France, vsa_initial_country_France } = this.state;
		const { programType, csr_steps } = this.props;


		let onlySamePriceProgramTypes = [];
		if (pricing_details.program_type) {
			let prorgramTypeTemp = cloneDeep(programType);
			onlySamePriceProgramTypes = map(prorgramTypeTemp, 'options');
			onlySamePriceProgramTypes = flatMap(onlySamePriceProgramTypes);
			onlySamePriceProgramTypes = filter(onlySamePriceProgramTypes, function (item) {
				return Number(item.price) === Number(pricing_details.program_type.price)
			})
			// onlySamePriceProgramTypes.forEach((program, index) => {
			// 	return program.filter(item => {
			// 		return Number(item.price) === Number(pricing_details.program_type.price)
			// 	})
			// })

			// onlySamePriceProgramTypes = cloneDeep(programType);
			// onlySamePriceProgramTypes.forEach(program => {
			// 	program.options = program.options.filter(item => {
			// 		return Number(item.price) === Number(pricing_details.program_type.price)
			// 	});
			// })
		}


		return (
			<div className="orderContent">
				{
					(shipping_details && order_details) &&
					<div id="order_details">
						<div className="flexElem" style={{ "flexWrap": "wrap", "justifyContent": "space-between" }}>
							{
								/*
									<div className="orderDetailsHeader">
										<span>Order Details</span>
									</div>
								*/
							}
							<div className="orderDetailsBox box_small">
								<table className="table orderTable last_three_rows">
									<tbody>
										{/*
										{TF.showValueLabel('Barcode', order_details.barcode)}
										{TF.showValueLabel('Group Name', order_details.groupNameStars ? <Link target="_blank" to={`/Admin/getOrdersSearchByContactList?code=groupName&value=${order_details.group_name}`} className="blue_link">{  order_details.group_name+' '+ order_details.groupNameStars  }</Link> : order_details.group_name)}
										{TF.showValueLabel('Group Leader Name', order_details.group_leader_contact.glNameStars ? <Link target="_blank" to={`/Admin/getOrdersSearchByContactList?code=groupLeaderName&value=${order_details.group_leader_contact.name}`} className="blue_link">{  order_details.group_leader_contact.name+' '+ order_details.group_leader_contact.glNameStars  }</Link> : order_details.group_leader_contact.name)}
										{TF.showValueLabelForEmailLink('Group Leader Email', order_details.group_leader_contact.email, false, null, order_details.group_leader_contact.glEmailStars ? <Link target="_blank" to={`/Admin/getOrdersSearchByContactList?code=groupLeaderEmail&value=${order_details.group_leader_contact.email}`} className="blue_link">{  order_details.group_leader_contact.glEmailStars  }</Link> : null)}
										{TF.showValueLabel('Group Leader Phone', order_details.group_leader_contact.phone1 ? order_details.group_leader_contact.phone1.replace(/-+$/,'') : order_details.group_leader_contact.phone1)}
										{TF.showValueLabel('Departure Date', HF.dateFormatMy(order_details.order_dates.departure_date.value), true, { td1: '35%', td2: '65%' })}
										{TF.showValueLabel('Return Date', HF.dateFormatMy(order_details.order_dates.return_date.value), true)}
										{TF.showValueLabel('Destination', order_details.destinationText)}
										{TF.showValueLabel('Traveler Logged In', order_details.travelLoggedIn)}
										*/}

										{TF.showValueLabel('Barcode', order_details.barcode)}

										{TF.showValueLabel('Group Name', <span>{order_details.group_name} <a className='starsLink' onClick={() => this.showStars({ group_name: order_details.group_name }, 'group_name')} >Show Stars Group Name</a> </span>)}
										{
											Object.keys(stars).length && stars.flag === 'group_name'
												?
												TF.showValueLabel('Link', <Link target="_blank" to={`/Admin/getOrdersSearchByContactList?code=groupName&value=${order_details.group_name}`} className="blue_link">{order_details.group_name + ' ' + stars.stars}</Link>)
												:
												null
										}









										{TF.showValueLabel('Group Leader Name', <span>{order_details.group_leader_contact.name} <a className='starsLink' onClick={() => this.showStars({ group_leader_first_name: order_details.group_leader_contact.first_name, group_leader_last_name: order_details.group_leader_contact.last_name }, 'group_leader_name')} >Show Stars Group leader Name</a></span>)}
										{
											Object.keys(stars).length && stars.flag === 'group_leader_name'
												?
												TF.showValueLabel('Link', <Link target="_blank" to={`/Admin/getOrdersSearchByContactList?code=groupLeaderName&value=${order_details.group_leader_contact.name}`} className="blue_link">{order_details.group_leader_contact.name + ' ' + stars.stars}</Link>)
												:
												null
										}


										{TF.showValueLabelForEmailLink('Group Leader Email', order_details.group_leader_contact.email, null, null, <a className='starsLink' onClick={() => this.showStars({ group_leader_email: order_details.group_leader_contact.email }, 'group_leader_email')} >Show Stars Group leaer Email</a>)}
										{
											Object.keys(stars).length && stars.flag === 'group_leader_email'
												?
												TF.showValueLabel('Link', <Link target="_blank" to={`/Admin/getOrdersSearchByContactList?code=groupLeaderEmail&value=${order_details.group_leader_contact.email}`} className="blue_link">{order_details.group_leader_contact.email + ' ' + stars.stars}</Link>)
												:
												null
										}
										{
                                            ptma_initial_country_France || vsa_initial_country_France ?

                                                TF.showValueLabel(
                                                    'Group Leader Phone'
                                                    ,<InputMask
                                                        mask={"99-9-99-99-99-99"}
                                                        maskChar={null}
                                                        type="text"
                                                        // name={name}
                                                        className="form-control notCard bg_none pl-none cursorDefault"
                                                        placeholder="12-3-45-67-89-10"
                                                        value={order_details.group_leader_contact.phone1 ? order_details.group_leader_contact.phone1.replace(/-+$/, '') : order_details.group_leader_contact.phone1}
                                                        readOnly={true}
                                                    />

                                                )
                                            :
                                                TF.showValueLabel(
                                                    'Group Leader Phone'
                                                    ,<InputMask
                                                        mask={"999-999-9999-999999"}
                                                        maskChar={null}
                                                        type="text"
                                                        // name={name}
                                                        className="form-control notCard bg_none pl-none cursorDefault"
                                                        placeholder="123-456-7890-123456"
                                                        value={order_details.group_leader_contact.phone1 ? order_details.group_leader_contact.phone1.replace(/-+$/, '') : order_details.group_leader_contact.phone1}
                                                        readOnly={true}
                                                    />

                                                )
                                        }
                                        {
                                            (!order_details.group_leader_contact || order_details.group_leader_contact.phone2 == null || order_details.group_leader_contact.phone2 == undefined || order_details.group_leader_contact.phone2 === "") ?
                                            ''
                                            :
                                            ptma_initial_country_France || vsa_initial_country_France ?

                                                TF.showValueLabel(
                                                    'Group Leader Cell'
                                                    ,<InputMask
                                                        mask={"99-9-99-99-99-99"}
                                                        maskChar={null}
                                                        type="text"
                                                        // name={name}
                                                        className="form-control notCard bg_none pl-none cursorDefault"
                                                        placeholder="12-3-45-67-89-10"
                                                        value={order_details.group_leader_contact.phone2 ? order_details.group_leader_contact.phone2.replace(/-+$/, '') : order_details.group_leader_contact.phone2}
                                                        readOnly={true}
                                                    />

                                                )
                                            :
                                                TF.showValueLabel(
                                                    'Group Leader Cell'
                                                    ,<InputMask
                                                        mask={"999-999-9999-999999"}
                                                        maskChar={null}
                                                        type="text"
                                                        // name={name}
                                                        className="form-control notCard bg_none pl-none cursorDefault"
                                                        placeholder="123-456-7890-123456"
                                                        value={order_details.group_leader_contact.phone2 ? order_details.group_leader_contact.phone2.replace(/-+$/, '') : order_details.group_leader_contact.phone2}
                                                        readOnly={true}
                                                    />

                                                )
                                        }
										{TF.showValueLabel('Departure Date', HF.dateFormatMy(order_details.order_dates.departure_date.value), true, { td1: '35%', td2: '65%' })}
										{TF.showValueLabel('Return Date', HF.dateFormatMy(order_details.order_dates.return_date.value), true)}
										{TF.showValueLabel('Destination', order_details.destinationText)}


									</tbody>
								</table>
							</div>
							<div className="orderDetailsBox box_large">
								{
									/*
									<div className="flexElem">
										<div className="block">
											<table className="table orderTable">
												<tbody>
													<React.Fragment>
														<React.Fragment>
															{
																order_details.agency_id === order_details.customer_slates_for_videos_id &&
																	TF.showValueLabel('Agency Name', order_details.agency.name)
															}

															{
																order_details.agency_id !== order_details.customer_slates_for_videos_id &&
																	TF.showValueLabel('Secondary Agency Name', order_details.customer_slates_for_videos.name)
															}
														</React.Fragment>
													</React.Fragment>
												</tbody>
											</table>
										</div>

												<div className="agencyLogoContainer">


													{
														order_details.agency_id === order_details.customer_slates_for_videos_id
														?
															order_details.agency.agency_logo !== undefined && order_details.agency.agency_logo !== null
																?
																	<MyImage src={order_details.agency.agency_logo.file_path} alt="primary logo" className="agencyLogo" />
																:
																	<MyImage src={placeholder} className="agencyLogo" alt="primary agency logo placeholder" />
														:
															null
													}


													{
														order_details.agency_id !== order_details.customer_slates_for_videos_id
															?
															order_details.customer_slates_for_videos.agency_logo !== undefined && order_details.customer_slates_for_videos.agency_logo !== null
																?
																<MyImage src={order_details.customer_slates_for_videos.agency_logo.file_path} alt="secondary logo" className="agencyLogo secondaryLogo" />
																:
																<MyImage src={placeholder} className="agencyLogo secondaryLogo" alt="secondary agency logo placeholder" />
															:
															null
													}
												</div>
									</div>
									*/
								}
								<table className="table customStrips orderTable">
									<tbody>

										{
											order_details.agency_id === order_details.customer_slates_for_videos_id &&
											TF.showValueLabel('Agency Name', order_details.agency.name)
										}

										{
											order_details.agency_id !== order_details.customer_slates_for_videos_id &&
											TF.showValueLabel('Secondary Agency Name', order_details.customer_slates_for_videos.name)
										}

										{
											order_details.agency_sales_rep !== null &&
											TF.showValueLabel('Agency Sales Representative', order_details.agency_sales_rep.name + ', ' + order_details.agency_sales_rep.email)
										}
										{
											<tr id="programtype">
												<td>Select Program Type:</td>
												<td >
													<Select
														value={HF.returnLabelWithValueGroup(pricing_details.program_type_id, programType)}
														onChange={this.handleChangeProgramType}
														options={onlySamePriceProgramTypes}
														isSearchable={true}
														isMulti={false}
														name='program_type_id'
														placeholder={'Select Program'}
														isOptionDisabled={(option) => this.disableOption(option)}
													/>
												</td>
											</tr>
										}

										{
											'order_dates' in shipping_details && HF.returnColumnValueWithColumnMatch(csr_steps, 'value', ['step2'], 'code') !== 1
											&&
											<React.Fragment>
												<tr>
													<td >Projected PTM Ship Date</td>

													<td >
														<form className="flexElem orderSettingText flexResponsive" style={{ "alignItems": "stretch" }} onSubmit={this.projectCameraShipDateFormHandler} >
															<DatePicker
																className="form-control calender_icon mt-md"
																onChange={this.projectCameraShipDateChangeHandler}
																selected={shipping_details.order_dates.projected_camera_ship_date.value ? new Date(shipping_details.order_dates.projected_camera_ship_date.value) : new Date()}
																minDate={Date.now()}
															/>
															<Button type='submit' className="customBtn btn ml-sm btn-primary" style={{ "marginTop": "15px" }}>Change</Button>
														</form>
													</td>
												</tr>
											</React.Fragment>
										}
										{
											shipping_details.camera_ship_status != null &&
											<React.Fragment>
												<tr>
													<td >PTM Ship Date </td>

													<td>
														{
															HF.dateFormatMy(shipping_details.camera_ship_status.shipped_date)
														}
													</td>
												</tr>
											</React.Fragment>
										}
                                        {
                                            shipping_details.pre_trip_material_address.state && shipping_details.pre_trip_material_address.state.title &&
                                            TF.showValueLabel('Pre-Trip Material State', shipping_details.pre_trip_material_address.state.title)
                                        }
                                        {
											TF.showValueLabel('Logged In Users', order_details.going_on_trip_count + order_details.following_from_home_count)
										}
										{
											/*
											shipping_details.camera_ship_status != null &&
											<tr>
												<td>
													{
														!_.isEmpty(shipping_details.camera_ship_status.shipping_method) ?
															<Button className="customBtn btn btn-primary mt-sm" onClick={this.props.processShipStatusCall.bind(this, this.props.orderId, {status:0, slug:'camera'} )} >Unprocess Pre-Trip Materials Shipment</Button>
														: null
													}
												</td>
											</tr>
											*/
										}
										{
											('best_time_to_call' in order_details.settings && order_details.settings.best_time_to_call.value != null) &&
											<React.Fragment>
												<tr>
													<td >Best time to call </td>

													<td>
														{
															order_details.settings.best_time_to_call.value
														}
													</td>
												</tr>
											</React.Fragment>
										}
									</tbody>
								</table>

							</div>
						</div>
					</div>
				}

			</div>
		);
	};
}

function mapStateToProps(state) {
	const { programType } = state.settingsReducer;

	return {
		programType,
	};
}

const mapDispatchToProps = dispatch => {
	return ({
		getProgramTypeCall: () => { dispatch(getProgramType()) },
		updateProgramTypeCall: (orderId, program_type_id) => { return dispatch(updateProgramType(orderId, program_type_id)) },
		updateProjectCameraShipDateCall: (orderId, Data) => { dispatch(updateProjectCameraShipDate(orderId, Data)) },
	});
}

const CustomerServiceDetail = connect(mapStateToProps, mapDispatchToProps)(CustomerServiceDetailPage);
export default CustomerServiceDetail;
