import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Row,
  Col,
  // Tooltip,
  OverlayTrigger,
  ButtonToolbar,
  Popover
} from "react-bootstrap";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import { hasPermission } from 'helper/hasPermission';
import {
  getAgenciesPagination,
  changeAgencyStatus,
  deleteAgency
} from 'redux/actions/agency-actions';
import {
  getAgencyType,
  getMainProgramType
} from 'redux/actions/settings-actions';
import { Link } from 'react-router-dom';
// import ToggleButton from 'react-toggle-button';
import Select from 'react-select';
import Confirmalertfordelete from 'components/ThemeComponents/confirmAlertForDelete';
import { themeCSSConstants } from 'redux/constant/themeCSS';
import { adminLabels } from 'redux/constant/admin-label-constant';
import Pagination from 'components/ThemeComponents/Pagination';
import { agenciesService } from 'services/agency';
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { selectChangeHandler } from 'helper/helperFunctions';
// import { CSVLink } from "react-csv";
//var moment = require("moment");

class AgencyListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionsCompanyType: [],
      optionsProgramType: [],
      selectedOptions_agency_type_id: null,
      selectedOptions_programType: null,
      is_active: '1',
      thumbStyle: themeCSSConstants.THUMBSTYLE,
      thumbIconStyle: themeCSSConstants.THUMBICONSTYLE,
      disableColor: themeCSSConstants.DISABLECOLOR,
      collapseLog: this.props.collapseCard !== undefined ? this.props.collapseCard : true,
      collapsable: this.props.collapseCard !== undefined ? true : false,
      filtered: '',
    };




    this.toggleSettings = this.toggleSettings.bind(this);
    this.handleChangeCompanyType = this.handleChangeCompanyType.bind(this);
    this.handleChangeProgramType = this.handleChangeProgramType.bind(this);
    this.deleteConfirmAgency = this.deleteConfirmAgency.bind(this);
    this.deleteAgency = this.deleteAgency.bind(this);
    this.selectChangeHandler = selectChangeHandler.bind(this);
    this.props.getAgencyTypeCall();
    this.props.getMainProgramTypeCall();
  };


  toggleSettings(id, currentValue, key) {
    this.props.changeAgencySettingStatusCall(id, { 'current_status': currentValue, 'key': key });
  }
  handleChangeCompanyType(selectedOptionType, info) {
    if (info['action'] === 'select-option') {
      this.setState({
        ...this.state,
        selectedOptions_agency_type_id: selectedOptionType
      }, function () {
        this.pagination.dataCall();
      });
    }
    if (info['action'] === 'clear') {
      this.setState({
        ...this.state,
        selectedOptions_agency_type_id: null
      }, function () {
        this.pagination.dataCall();
      });
    }
  }
  handleChangeProgramType(selectedOptionType, info) {
    if (info['action'] === 'select-option') {
      this.setState({
        ...this.state,
        selectedOptions_programType: selectedOptionType
      }, function () {
        this.pagination.dataCall();
      });
    }
    if (info['action'] === 'clear') {
      this.setState({
        ...this.state,
        selectedOptions_programType: null
      }, function () {
        this.pagination.dataCall();
      });
    }
  }
  deleteConfirmAgency(agencyId) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete typeName="Agency" description="Are you sure you want to Inactivate the agency" onClosePro={onClose} deleteType={() => this.deleteAgency(agencyId)} />
        )
      }
    })
  }
  deleteAgency(id) {
    this.props.deleteAgencyCall(id).then(Response => {
      this.pagination.dataCall();
    });
  }
  getAgencyDataWithStateValue = (data) => {
    return agenciesService.getAgenciesPagination(
      data.page,
      this.state.filtered,
      data.sort,
      data.pageSize,
      this.state.selectedOptions_agency_type_id ? this.state.selectedOptions_agency_type_id.value : null,
      this.state.selectedOptions_programType ? this.state.selectedOptions_programType.value : null,
      this.state.is_active
    );
    // return this.props.getAgenciesPaginationCall(data.page, data.filter, data.sort, data.pageSize, this.state.selectedOptions_agency_type_id ? this.state.selectedOptions_agency_type_id.value : null, this.state.selectedOptions_programType ? this.state.selectedOptions_programType.value : null);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.agency_type_id !== this.props.agency_type_id) {
      this.setState({ optionsCompanyType: this.props.agency_type_id });
    }

    if (prevProps.mainProgramType !== this.props.mainProgramType) {
      this.setState({ optionsProgramType: this.props.mainProgramType });
    }
    if (prevProps.changeAgencyStatus !== this.props.changeAgencyStatus) {
      this.pagination.dataCall();
    }
    // if (prevProps.deleteAgency !== this.props.deleteAgency) {
    //   this.pagination.dataCall();
    // }

    if (this.state.is_active !== prevState.is_active) {
      this.pagination.dataCall();
      // this.table.state.onFetchData(this.table.state, this.table.instance)
    }
  }

  collapseHandle(field) {
    this.setState({
      collapseLog: !this.state.collapseLog
    })
  }

  filterSearchHandle = (e) => {
    e.persist();
    // console.log('e.key', e.keyCode);
    this.setState({
      'filtered': e.target.value
    }, () => {
      if (e.keyCode === 13) {

        // console.log('e.key one', e.keyCode);
        this.pagination.dataCall();
      }
    });
  }

  render() {
    const { selectedOptions_agency_type_id, optionsCompanyType, selectedOptions_programType, optionsProgramType, collapseLog, collapsable } = this.state;
    const columns = [
      {
        Header: "Agencies Listing",
        headerClassName: 'headerPagination',
        columns: [
          {
            Header: "Agency Name",
            accessor: "name",
            className: "action-center",
            Cell: ({ value, original }) => (
              <div>
                {hasPermission('agency-edit') ? <Link target="_blank" to={'/Admin/' + original.id + '/agency-edit'} className="blue_link">{original.name}</Link> : original.name}
              </div>
            ),
            excExp: true,
          },
          {
            Header: "Agency Type",
            accessor: "agency_type.title",
            className: "action-center",
            Cell: ({ value, original }) => (
              <div>
                {value}
              </div>
            ),
            excExp: true,
          },
          {
            Header: "Primary Contact",
            accessor: "primary_contacts.name",
            Cell: ({ value, original }) => (
              <div>
                <OverlayTrigger trigger='click' placement="bottom" overlay={
                  <Popover id="tooltip">
                    {
                      original.primary_contacts &&
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <span>{'Name: ' + original.primary_contacts.name}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span>{'Phone: ' + original.primary_contacts.phone1}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span>{'Email: ' + original.primary_contacts.email}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    }

                    {
                      !original.primary_contacts &&
                      <span>N/A</span>
                    }
                  </Popover>
                }>
                  <span style={{ cursor: 'pointer' }}>
                    {
                      original.primary_contacts &&
                      original.primary_contacts.name
                    }

                    {
                      !original.primary_contacts &&
                      <span>N/A</span>
                    }
                  </span>
                </OverlayTrigger>

              </div>
            ),
            className: "action-center",
            excExp: true,
          },
          {
            Header: "Primary Contact Email",
            accessor: "primary_contacts.email",
            className: "action-center",
            excExp: true,
          },
          {
            // id: 'program_type',
            Header: "Program Type",
            accessor: "program_type.programTypeWithPrice",
            // accessor: d => d.program_type.title+'-'+d.program_type.price,
            className: "action-center",
            excExp: true,
          },
          {
            Header: "Accounting Contact",
            accessor: "accounting_contacts.name",
            Cell: ({ value, original }) => (
              <div>
                <OverlayTrigger trigger='click' placement="bottom" overlay={
                  <Popover id="tooltip">
                    {
                      original.accounting_contacts &&
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <span>{'Name: ' + original.accounting_contacts.name}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span>{'Phone: ' + original.accounting_contacts.phone1}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span>{'Email: ' + original.accounting_contacts.email}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    }

                    {
                      !original.accounting_contacts &&
                      <span>N/A</span>
                    }

                  </Popover>
                }>
                  <span style={{ cursor: 'pointer' }}>
                    {
                      original.accounting_contacts &&
                      original.accounting_contacts.name
                    }

                    {
                      !original.accounting_contacts &&
                      <span>N/A</span>
                    }
                  </span>
                </OverlayTrigger>

              </div>
            ),
            className: "action-center",
            excExp: true,
          },
          {
            Header: "Created Date",
            accessor: "created_at",
            className: "action-center",
            Cell: ({ value }) => (
              <div>
                {value}
              </div>
            ),
            excExp: true,
          },
          {
            Header: "Actions",
            accessor: "id",
            Cell: ({ value, original }) => (
              <div>
                <ButtonToolbar>
                  <OverlayTrigger trigger="focus" placement="bottom" overlay={
                    <Popover id="tooltip">
                      <table className="table text-left actions_table">
                        <tbody>
                          <tr>
                            <td>
                              {
                                hasPermission('agency-edit') &&
                                <Link to={'/Admin/' + value + '/agency-edit'} className=""><i className="fa fa-pencil" /> Edit</Link>

                              }
                              {
                                !hasPermission('agency-edit') &&
                                <Link to={'/Admin/' + value + '/agency-edit'} className="btn_link " ><i className="fa fa-eye" /> View</Link>
                              }
                            </td>
                          </tr>

                          <tr>
                            <td>
                              {
                                hasPermission('agency-delete') &&
                                <button onClick={() => this.deleteConfirmAgency(value)} className="btn_link text-danger"><i className="fa fa-trash text-danger " /> Inactivate</button>

                              }
                              {
                                !hasPermission('agency-delete') &&
                                <button type='button' className="btn_link button_disabled" ><i className="fa fa-trash text-danger" /> Inactivate</button>
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {
                                hasPermission('agency-user-list') &&
                                <Link to={'/Admin/' + value + '/agency-users'} className=""><i className="fa fa-users" /> Agency Users</Link>

                              }
                              {
                                !hasPermission('agency-user-list') &&
                                <button type='button' className="btn_link button_disabled" ><i className="fa fa-users" /> Agency Users</button>

                              }
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {
                                hasPermission('agency-resource-list') &&
                                <Link to={'/Admin/' + value + '/agency-resource'} className=""><i className="fa fa-file" /> Agency Resources</Link>

                              }
                              {
                                !hasPermission('agency-resource-list') &&
                                <button type='button' className="btn_link button_disabled" ><i className="fa fa-file" /> Agency Resources</button>

                              }
                            </td>
                          </tr>


                        </tbody>
                      </table>

                    </Popover>
                  }>
                    <span>
                      <button type='button' className="action-button-size" ><i className="fa fa-bars" /></button>
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>

              </div>
            ),
            className: "action-center",
            sortable: false,
            excExp: false,
          }
        ]
      }
    ];

    const options = [
      {
        value: '',
        label: 'All'
      },
      {
        value: '1',
        label: 'Active'
      },
      {
        value: '0',
        label: 'In-Active'
      }
    ]
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                ctTableResponsive
                bsClass={["innerCard relativeCard"]}
                collapsHandler={() => this.collapseHandle('collapseLog')}
                collapsable={collapsable}
                title="Agencies List"
                collapseTrigger={collapseLog}
                content={
                  <div>
                    <Grid fluid>
                      <Row>
                        {/* <Col md={12}>
                          <div className="secHeading">Agencies List</div>
                        </Col> */}
                        <Col md={12} className="mt-md">
                          <Pagination
                            ref={(p) => this.pagination = p}
                            columns={columns}
                            getDataCall={this.getAgencyDataWithStateValue}
                            filterView={false}
                            showAllToggle={true}
                            pageSize={50}
                            upperContent={
                              <React.Fragment>
                                <Col md={2} sm={6} xs={12}>
                                  <div className="form-group" style={{ "marginBottom": "9px" }}>
                                    {
                                      hasPermission('agency-create') &&
                                      <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Add Agency</Popover>} >
                                        <Link to="/Admin/agency-create" className="backButton btn-block pt-sm text-xs no_radius pb-sm primary btn btn-lg btn-info"><i className="fa fa-plus" />Agency</Link>
                                      </OverlayTrigger>
                                    }
                                    {
                                      !hasPermission('agency-create') &&
                                      <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{adminLabels.DONT_ALLOW}</Popover>} >
                                        <button to="/Admin/agency-create" className="button_disabled backButton pt-sm no_radius pb-sm primary btn btn-lg btn-block btn-info"><i className="fa fa-plus" />Agency</button>
                                      </OverlayTrigger>
                                    }
                                  </div>
                                </Col>
                                <Col md={3} sm={6} xs={12} >
                                  <div className="form-group select_height_adjust">
                                    <Select
                                      value={selectedOptions_agency_type_id}
                                      onChange={this.handleChangeCompanyType}
                                      options={optionsCompanyType}
                                      isSearchable={true}
                                      isMulti={false}
                                      name='agency_type_id'
                                      placeholder={'Select Agency Type'}
                                      isClearable={true}
                                      className=""
                                    />

                                    <Select
                                      isSearchable={false}
                                      isMulti={false}
                                      styles={
                                        {
                                          container: (provided) => {
                                          return {
                                            ...provided,
                                            width: '100%',
                                            minWidth: '200px'
                                          }
                                          },
                                          menu: (provided) => {
                                            return {
                                              ...provided,
                                              width: '200px'
                                            }
                                          }
                                        }
                                      }
                                      value={
                                        options.map(item => {
                                          if (item.value === this.state.is_active) {
                                            return item
                                          }
                                        })
                                      }
                                      className='mt-md'
                                      options={options}
                                      onChange={this.selectChangeHandler}
                                      name='is_active'
                                    />
                                  </div>
                                </Col>
                                <Col md={3} sm={6} xs={12} >
                                  <div className="form-group select_height_adjust">
                                    <Select
                                      value={selectedOptions_programType}
                                      onChange={this.handleChangeProgramType}
                                      options={optionsProgramType}
                                      isSearchable={true}
                                      isMulti={false}
                                      name='programType'
                                      placeholder={'Select Program Type'}
                                      isClearable={true}
                                      className=""
                                    />
                                  </div>
                                </Col>

                                <Col className='pull-right' md={4} sm={6} xs={12} >
                                  <FormInputs
                                    ncols={["col-md-12"]}
                                    // onChange={this.filterSearchHandle}
                                    // onKeyDown={this.filterSearchHandle}
                                    proprieties={[
                                      {
                                        type: "text",
                                        bsClass: "form-control",
                                        placeholder: "Search Agencies",
                                        // onChange: this.filterSearchHandle,
                                        onKeyDown: this.filterSearchHandle,
                                        name: "filter"
                                      }
                                    ]}
                                  />
                                </Col>
                              </React.Fragment>
                            }
                            downloadFileName={'Agency'}
                            downloadData={true}
                          // lowerContent = {null}
                          // filterPlaceHolder={'Agency'}
                          //getRowProps = { this.getRowProps }
                          />
                        </Col>
                      </Row>
                    </Grid>
                  </div>
                } />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  };
}

const mapDispatchToProps = dispatch => {
  return ({
    getAgenciesPaginationCall: (page, filter, sort, pageSize, filterCompanyType, filterProgramType) => { return dispatch(getAgenciesPagination(page, filter, sort, pageSize, filterCompanyType, filterProgramType)) },
    getAgencyTypeCall: () => { dispatch(getAgencyType()) },
    // getProgramTypeCall: () => { dispatch(getProgramType()) },
    getMainProgramTypeCall: () => { dispatch(getMainProgramType()) },
    changeAgencySettingStatusCall: (agencyId, settingData) => { dispatch(changeAgencyStatus(agencyId, settingData)) },
    deleteAgencyCall: (id) => { return dispatch(deleteAgency(id)) },
  });
};

function mapStateToProps(state) {
  const { loadingSettings, agency_type_id, mainProgramType } = state.settingsReducer;
  const { loadingAgency, changeAgencyStatus, deleteAgency } = state.agenciesReducer;
  return {
    loadingAgency, loadingSettings, agency_type_id, mainProgramType, changeAgencyStatus, deleteAgency
  };
};

const AgencyList = connect(mapStateToProps, mapDispatchToProps)(AgencyListPage);
export default AgencyList;
