import React from 'react';
import {
	Button
} from "react-bootstrap";
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { storeTransmittalTemplate, editTransmittalTemplate, getTransmittalTemplatesTypes, getTransmittalTemplatesCategories } from 'redux/actions/brand-actions';
import TemplateVariables from '../TemplateVariables';
import { toggleModal, returnColumnValueWithColumnMatch, openModal } from 'helper/helperFunctions';
import Select from 'react-select';
import CKEditor from "react-ckeditor-component";
import ThemeModal from 'components/ThemeComponents/ThemeModal';
import OpenModalButton from 'components/ThemeComponents/openModelButton';
import { getBrands } from "redux/actions/account-action";

class TransmittalTemplateFormPage extends React.Component {
	constructor(props) {
		super(props);
		this.validatorTransmittalTemplate = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
		this.state = {
			transmittalTemplate: {
				...this.props.transmittalTemplatePro
			},
			submitted: false,
			formType: this.props.formTypePro || '',
			content: '',
			showTemplateVariables: false,
			brands: [],
		};

		this.handleTransmittalTemplate = this.handleTransmittalTemplate.bind(this);
		this.handleTransmittalTemplateSubmit = this.handleTransmittalTemplateSubmit.bind(this);
		this.toggleModal = toggleModal.bind(this);

	}

	handleTransmittalTemplate(e) {
		const { name, value } = e.target;
		const transmittalTemplateState = { ...this.state.transmittalTemplate };
		transmittalTemplateState[name] = value;
		this.setState({ transmittalTemplate: transmittalTemplateState });
	}
	handleTransmittalTemplateSubmit(e) {
		e.preventDefault();
		if (this.validatorTransmittalTemplate.allValid()) {
			this.setState({ submitted: true }, function () {
				const { formType, transmittalTemplate } = this.state;
				if (formType === 'add') {
					this.props.storeTransmittalTemplateCall(transmittalTemplate.account_id, transmittalTemplate).then(
						response => {
							this.props.closeModel();
						}
					);
				}
				else if (formType === 'edit') {
					this.props.editTransmittalTemplateCall(transmittalTemplate.account_id, transmittalTemplate, transmittalTemplate.id).then(
						response => {
							this.props.closeModel();
						}
					);
				}
			});
		} else {
			this.setState({ submitted: false });
			this.validatorTransmittalTemplate.showMessages();
			this.forceUpdate();
		}
	}
	handleChangeLocation = (selectedOptionType, info) => {
		if (info['action'] === 'select-option') {
			this.setState({ transmittalTemplate: { ...this.state.transmittalTemplate, [info['name']]: selectedOptionType['id'] } }, () => {
				if (info['name'] === 'transmittal_type_id') {
					this.setState({
						transmittalTemplate: { ...this.state.transmittalTemplate, 'transmittal_category_id': '' }
					}, () => {
						this.props.getTransmittalTemplatesCategoriesCall(selectedOptionType['id']);
					});
				}
			});
		}
	}

	onChange = (key) => (evt) => {
		//console.log("1: ", key);
		//console.log("onChange fired with event info: ", evt);
		var newContent = evt.editor.getData();
		this.setState({ transmittalTemplate: { ...this.state.transmittalTemplate, [key]: newContent } })
		// this.setState({
		//  	content: newContent
		// })
	}

	onBlur = (evt) => {
		//console.log("onBlur event called with event info: ", evt);
	}

	afterPaste = (evt) => {
		//console.log("afterPaste event called with event info: ", evt);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.transmittalTemplate.transmittal_type_id !== prevState.transmittalTemplate.transmittal_type_id) {
			if (returnColumnValueWithColumnMatch(this.props.transmittalTemplatesTypes, 'title', [this.state.transmittalTemplate.transmittal_type_id], 'id') === 'email') {
				this.setState({
					transmittalTemplate: { ...this.state.transmittalTemplate, 'description': '' }
				}, () => {
					console.log('transmittalTemplate', this.state.transmittalTemplate);
				});
			}
		}
	}

	componentDidMount() {
		this.props.getTransmittalTemplatesTypesCall();
		if (this.state.transmittalTemplate.transmittal_type_id) {
			this.props.getTransmittalTemplatesCategoriesCall(this.state.transmittalTemplate.transmittal_type_id);
		}

		this.props.getBrandsCall().then(response => {
			this.setState({ brands: response.data });
		});
	}

	render() {
		const { transmittalTemplate } = this.state;
		const { transmittalTemplatesTypes, transmittalTemplatesCategories } = this.props;


		//console.log('transmittalTemplatesCategories', transmittalTemplatesCategories)
		//console.log('transmittalTemplate', transmittalTemplate)
		return (
			<form>
				<div className="row">
					<div className='col-md-12'>
						<div className='form-group text-right'>
							<OpenModalButton
								openModal={openModal.bind(this, "showTemplateVariables")}
								classButton={['backButton pt-sm no_radius pb-sm primary mt-none btn-info btn']}
								buttonName="Show Variables"
								tooltipText={'Show Variables'}
								classIcon={[]}
							/>
						</div>
					</div>
					<div className="col-md-12">
						<div className='form-group'>
							<label htmlFor="name">Subject<span className="requiredClass">*</span></label>
							<input type="text" name="subject" className="form-control" value={transmittalTemplate.subject} onChange={this.handleTransmittalTemplate} />
							{this.validatorTransmittalTemplate.message('subject', transmittalTemplate.subject, 'required')}
						</div>
					</div>
					<div className="col-md-6">
						<div className='form-group'>
							<label htmlFor="name">Select Type<span className="requiredClass">*</span></label>
							<Select
								value={
									(transmittalTemplate.transmittal_type_id && transmittalTemplatesTypes.length > 0) ? {
										id: returnColumnValueWithColumnMatch(transmittalTemplatesTypes, 'id', [transmittalTemplate.transmittal_type_id], 'id'),
										title: returnColumnValueWithColumnMatch(transmittalTemplatesTypes, 'title', [transmittalTemplate.transmittal_type_id], 'id')
									} : null
								}
								options={transmittalTemplatesTypes}
								onChange={this.handleChangeLocation}
								getOptionLabel={(option) => option.title}
								getOptionValue={(option) => option.id}
								className="selectUser"
								placeholder="Select Template Type"
								name="transmittal_type_id"
								isMulti={false}
							/>
							{this.validatorTransmittalTemplate.message('transmittalTemplateType', transmittalTemplate.transmittal_type_id, 'required')}
						</div>
					</div>
					<div className="col-md-6">
						<div className='form-group'>
							<label htmlFor="name">Select Category<span className="requiredClass">*</span></label>
							<Select
								value={
									(transmittalTemplate.transmittal_category_id && transmittalTemplatesCategories.length > 0) ? {
										id: returnColumnValueWithColumnMatch(transmittalTemplatesCategories, 'id', [transmittalTemplate.transmittal_category_id], 'id'),
										label: returnColumnValueWithColumnMatch(transmittalTemplatesCategories, 'label', [transmittalTemplate.transmittal_category_id], 'id')
									} : null
								}
								options={transmittalTemplatesCategories}
								onChange={this.handleChangeLocation}
								getOptionLabel={(option) => option.label}
								getOptionValue={(option) => option.id}
								className="selectUser"
								placeholder="Select Template Category"
								name="transmittal_category_id"
								isMulti={false}
							/>
							{this.validatorTransmittalTemplate.message('transmittalTemplateCategory', transmittalTemplate.transmittal_category_id, 'required')}
						</div>
					</div>
					<div className="col-md-12">
						<div className='form-group'>
							<label htmlFor="name">Content<span className="requiredClass">*</span></label>
							<CKEditor
								activeClass="p10"
								content={transmittalTemplate.content}

								events={{
									// "blur": this.onBlur,
									// "afterPaste": this.afterPaste,
									"change": this.onChange('content')
								}}
							/>

							{this.validatorTransmittalTemplate.message('content', transmittalTemplate.content, 'required')}
						</div>
					</div>

					{
						(returnColumnValueWithColumnMatch(transmittalTemplatesTypes, 'title', [transmittalTemplate.transmittal_type_id], 'id') !== 'email') &&
						<div className="col-md-12">
							<div className='form-group'>
								<label htmlFor="name">Description</label>
								<textarea name="description" onChange={this.handleTransmittalTemplate} className="form-control" value={transmittalTemplate.description} />

							</div>
						</div>
					}
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className='text-center'>
							<Button onClick={this.handleTransmittalTemplateSubmit} bsSize="large" bsStyle="info" className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm btn-default  btn btn-lg btn-info" >Save</Button>
							<Button bsSize="large" bsStyle="info" onClick={() => this.props.closeModel()} className="backButton pt-sm no_radius pb-sm primary btn btn-lg ml-sm mt-sm btn-info " >Cancel</Button>
						</div>
					</div>
				</div>
				<ThemeModal modalClass='' title='Variables' show={this.state.showTemplateVariables} hide={() => {
					this.toggleModal('showTemplateVariables')
				}}>
					<TemplateVariables />
				</ThemeModal>
			</form>
		);
	}
}


const mapDispatchToProps = dispatch => {
	return ({
		storeTransmittalTemplateCall: (accountId, transmittalTemplateData) => { return dispatch(storeTransmittalTemplate(accountId, transmittalTemplateData)) },
		editTransmittalTemplateCall: (accountId, transmittalTemplateData, id) => { return dispatch(editTransmittalTemplate(accountId, transmittalTemplateData, id)) },
		getTransmittalTemplatesTypesCall: () => { dispatch(getTransmittalTemplatesTypes()) },
		getTransmittalTemplatesCategoriesCall: (typeId) => { dispatch(getTransmittalTemplatesCategories(typeId)) },
		getBrandsCall: () => { return dispatch(getBrands()) },
	});
};

function mapStateToProps(state) {
	const { loadingTransmittalTemplate, transmittalTemplatesTypes, transmittalTemplatesCategories, } = state.brandsReducer;
	return {
		loadingTransmittalTemplate, transmittalTemplatesTypes, transmittalTemplatesCategories,
	};
};

const TransmittalTemplateForm = connect(mapStateToProps, mapDispatchToProps)(TransmittalTemplateFormPage);

export default TransmittalTemplateForm;