import React, { Component } from "react";
import { connect } from 'react-redux';
import { getContactLog } from "redux/actions/report-actions";
import ReactValidator from "simple-react-validator";
import DatePicker from "react-datepicker";
import { Row, Col, Grid } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import 'react-table/react-table.css';
import { Link } from 'react-router-dom';
import Pagination from "../../ThemeComponents/Pagination";
import { reportService } from 'services/report';
var moment = require("moment");

class ContactLogPage extends Component {
	constructor(props) {
		super(props);
		console.log('object', this.props.collapseCard)
		this.dateValidator = new ReactValidator();
		this.state = {
			fromDate: '',
			toDate: '',
			sorted: '',
			collapseLog: this.props.collapseCard !== undefined ? this.props.collapseCard : true,
			collapsable: this.props.collapseCard !== undefined ? true : false,
			pageSize: 1000,
			page: 0,
			REPORTStotal_orders: 0
		}
		this.table = React.createRef();
	}
	DateChangeHandler = (value, field) => {
		this.setState({ [field]: value === null ? '' : moment(value).format("YYYY-MM-DD") }, () => {
			if (this.dateValidator.allValid()) {
				this.pagination.dataCall();;
			} else {
				this.dateValidator.showMessages();
				this.forceUpdate();
			}
		})
	}
	dateElement(label, name, value, rules) {
		var selectedDate = this.state[name] !== '' ? new Date(this.state[name]) : '';
		const { fromDate, toDate } = this.state;
		return (
			<div className='form-group fullCalendar' id={name}>
				<label>{label}</label>
				<DatePicker className="form-control calender_icon" selected={selectedDate} name={name} onChange={(event) => this.DateChangeHandler(event, name)} />
				{
					(fromDate !== '' && toDate !== '') &&
					this.dateValidator.message(name, value, rules)
				}
			</div>
		);
	}
	reportsPaginationData = (page = this.state.page, sorted = this.state.sorted, pageSize = this.state.pageSize) => {
		const { fromDate, toDate } = this.state;
		this.props.getReportsCall(fromDate, toDate, page, pageSize, sorted);
	}


	collapseHandle(field) {
		this.setState({
			collapseLog: !this.state.collapseLog
		})
	}

	paginationCall = (data) => {
		const { fromDate, toDate } = this.state;
		return reportService.getContactLog(fromDate, toDate, data.page, data.pageSize, data.sort).then(
			res => {
                res &&
				this.setState({
					REPORTStotal_orders: res.data.pagination.total
				})
				return res
			}
		);
		// return this.props.getReportsCall(fromDate, toDate, data.page, data.pageSize, data.sort).then(
		// 	res => {
		// 		this.setState({
		// 			REPORTStotal_orders: res.data.pagination.total
		// 		})
		// 		return res
		// 	}
		// );
	}

	render() {
		const { /*toDate, fromDate,*/ collapseLog, collapsable, REPORTStotal_orders } = this.state;
		// const { contactlogData, REPORTSpages, loadingOrder } = this.props;
		const columns = [
			{
				Header: "Report Testing",
				headerClassName: 'headerPagination',
				columns: [
					{
						Header: "Barcode",
						id: "model.barcode",
						accessor: "model.barcode",
						Cell: ({ value, original }) => {
							return <Link target="_blank" to={'/Admin/' + (original.model.reorder ? original.model.reorder : original.model.id) + '/order-edit'} className="blue_link">{original.model.barcode}</Link>
						},
						className: "action-center",
						sortable: false,
						excExp: false,
					},
					{
						Header: "Barcode",
						accessor: "barcode",
                        width: 200,
						className: "action-center",
						sortable: false,
						excExp: true,
						show: false,
					},
					{
						Header: "Comment",
						accessor: "comment",
						className: "justifyStart",
						sortable: false,
						excExp: true
					},
					{
						Header: "Entry Date",
						accessor: "created_at",
						Cell: ({ value }) => {
							return value
						},
						className: "action-center",
						sortable: true,
						excExp: true
					},
					{
						Header: "Name",
						accessor: "created_by",
						className: "action-center",
						Cell: ({ value }) => {
							if (value) {
								if (value.name.toLowerCase() === 'admin') {
									return 'GTV System'
								} else {
									return value.name
								}

							} else {
								return '--';
							}
						},
						sortable: true,
						excExp: true
					},

				]
			}
		];
		return (
			<div className="content">
				<Grid fluid>
					<Row>
						<Col md={12}>
							<Card
								ctTableResponsive
								bsClass={["innerCard relativeCard"]}
								collapsHandler={() => this.collapseHandle('collapseLog')}
								collapsable={collapsable}
								title="Contact Log Entries"
								collapseTrigger={collapseLog}
								content={
									<div>
										<Grid fluid>
											<div className="row">
												{/* <Col md={4} >
													<div className='row flexElem flexResponsive align-items-center' >
														<div className="col-md-6" >
															{
																this.dateElement('Date From', 'fromDate', fromDate !== '' && moment(fromDate, 'YYYY-MM-DD'), [{ before_or_equal: moment(this.state.toDate) }])
															}
														</div>
													</div>
												</Col>
												<Col md={4} >
													<div className='row flexElem flexResponsive align-items-center' >
														<div className="col-md-6" >
															{
																this.dateElement('Date To', 'toDate', toDate !== '' && moment(toDate, 'YYYY-MM-DD'), [{ after_or_equal: moment(this.state.fromDate) }])
															}
														</div>
													</div>
												</Col> */}
												<Col md={6}>
													<div>Total Records: {REPORTStotal_orders ? REPORTStotal_orders : 0}</div>
												</Col>
												<Col md={2} className="downloadCsv text-right pull-right">
													{/* <ReactDataToCSV
														className="downloadbtn"
														fileName={'Contact'}
														currentRecordsRef={this.table.current}
														contactLog
														columnsData={columns[0].columns}
													/> */}
												</Col>
												{/* <Col md={12} className="mt-md">
													<ReactTable
														ref={this.table}
														noDataText='No contact log report found'
														data={contactlogData}
														pages={REPORTSpages}
														columns={columns}
														defaultPageSize={50}
														loading={loadingOrder}
														pageSizeOptions={[50, 100, 250, 500, 1000]}
														className="-striped listing"
														loadingText={'Loading...'}
														pageData={this.reportsPaginationData}
														manual
														onFetchData={(state, instance) => {

															var sort = state.sorted.length === 0 ? '' : state.sorted[0].id + ',desc:' + state.sorted[0].desc;
															state.pageData(state.page + 1, sort, state.pageSize);
														}}
													/>
												</Col> */}
												<Pagination
													ref={(p) => this.pagination = p}
													showPagination={false}
													columns={columns}
													noDataText='No contact log report found'
													pageSize={50}
													getDataCall={this.paginationCall}
													filterView={false}
													pageSizeOptions={[50, 100, 250, 500, 1000]}
													downloadData={true}
													downloadFileName={'Contact Log'}
													// lowerContent = {null}
													// filterPlaceHolder = {'Orders'}
													getRowProps={this.getRowProps}
												// showAllToggle={true}
												/>
											</div>
										</Grid>
									</div>
								} />
						</Col>
					</Row>
				</Grid>
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return ({
		getReportsCall: (fromDate, toDate, page, pageSize, sorted) => { return dispatch(getContactLog(fromDate, toDate, page, pageSize, sorted)) },//example function
	});
};

function mapStateToProps(state) {
	const { contactlogData, REPORTSpages, REPORTScurrent_page, REPORTStotal_orders, loadingOrder } = state.reportReducer;
	return {
		contactlogData, REPORTSpages, REPORTScurrent_page, REPORTStotal_orders, loadingOrder
	};
}


const ContactLog = connect(mapStateToProps, mapDispatchToProps)(ContactLogPage);
export default ContactLog;
