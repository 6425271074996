import React, { Component } from "react";
import { adminLabels } from '../../redux/constant/admin-label-constant';

class ConfirmEditTracking extends Component {

	render(){
		return(
			<div className='custom-ui'>
	            <div className="react-confirm-alert-body text-center">
	              	<div><i className="pe-7s-attention" style={{"fontSize":"40px", "color":"red"}}></i></div>
	              	<h3 style={{"color":"#4f7bea", "fontSize":"20px"}}>{ this.props.typeName }</h3>
	              	<p className="marginFromTopPopUp" >{ this.props.description + this.props.name }?</p>
	              	<div className="react-confirm-alert-button-group alignFlexButtons">
	                	<button onClick={this.props.onClosePro} className="btn btn-danger"><i className="pe-7s-close-circle"></i> No</button>
	                	<button className="btn btn-success" onClick={() => {
	                    	this.props.editModel()
	                    	this.props.onClosePro()
	                	}}>
	                	<i className="pe-7s-check"></i> Yes</button>
	              </div>
	            </div>
	        </div>
		);
	};
}



export default ConfirmEditTracking;
