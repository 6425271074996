import { SC } from 'helper/ServerCall';

export const agenciesService = {
    getAgenciesPagination,
    getAllAgencies,
    storeAgency,
    getAgency,
    editAgency,
    deleteAgency,
    changeAgencyStatus,
    getAgencyOrders,
    getAgencyInventoryPagination,
    storeAgencyInventory,
    editAgencyInventory,
    updateAgencyInventory,
    deleteAgencyInventory,
    getAgencyLogo,
    getInventoryLocationStats,
    updateInventoryLocation,
    flyersAllowedAgencies,
    toggleAllowStatus,
    flyersNotAllowedAgencies
}

function getAgenciesPagination(page, filter, sort, pageSize, filterCompanyType, filterProgramType, is_active) {
    filterCompanyType = (typeof filterCompanyType !== 'undefined') && (filterCompanyType !== null) ? filterCompanyType : '';
    filterProgramType = (typeof filterProgramType !== 'undefined') && (filterProgramType !== null) ? filterProgramType : '';
    return SC.getCall('agency?page=' + page + '&filter=' + filter + '&sort=' + sort + '&pageSize=' + pageSize + '&agency_type_id=' + filterCompanyType + '&program_type_id=' + filterProgramType + '&is_active='+is_active);
}

function getAllAgencies() {
    return SC.getCall('getAllAgencies');
}

function storeAgency(agencyData) {
    return SC.postCall('agency', agencyData);
}

function getAgency(agencyId) {
    return SC.getCall('agency/' + agencyId);
}

function editAgency(agencyData, agencyId) {
    return SC.putCall('agency/' + agencyId, agencyData);
}

function deleteAgency(agencyId) {
    return SC.deleteCall('agency/' + agencyId);
}

function changeAgencyStatus(agencyId, settingData) {
    return SC.putCall('editAgencySetting/' + agencyId, settingData);
}

function getAgencyOrders(page, filter, sort, pageSize, agencyId, advancedFilters) {
    return SC.postCall(`getAgencyOrders/${agencyId}?page=${page}&filter=${filter}&sort=${sort}&pageSize=${pageSize} `, {advancedFilters:advancedFilters});
}

function getAgencyInventoryPagination(page, filter, sort, pageSize, where) {
    return SC.postCall('getAgencyInventoryList?page=' + page + '&filter=' + filter + '&sort=' + sort + '&pageSize=' + pageSize, where);
}

function storeAgencyInventory(InvData) {
    return SC.postCall('storeAgencyInventory', InvData);
}

function editAgencyInventory(invId) {
    return SC.getCall(`getAgencyInventory/${invId}`);
}

function updateAgencyInventory(data, id, type) {
    return SC.putCall(`updateAgencyInventory/${id}/${type}`, data);
}
function deleteAgencyInventory(InvId) {
    return SC.deleteCall('deleteAgencyInventory/' + InvId);
}
function getAgencyLogo(agencyId) {
    return SC.getCall(`getAgencyLogo/${agencyId}`);
}
function getInventoryLocationStats() {
    return SC.getCall('getInventoryLocationStats');
}
function updateInventoryLocation(id, data, keyName, orderShippingId, type) {
    if ( orderShippingId === null && type === null) {
        return SC.putCall(`updateInventoryLocation/${id}/${keyName}`, data);
    }
    else {
        return SC.putCall(`updateInventoryLocation/${id}/${keyName}/${orderShippingId}/${type}`, data);
    }
}

function flyersAllowedAgencies() {
    return SC.getCall('flyersAllowedAgencies');
}
function flyersNotAllowedAgencies() {
    return SC.getCall('flyersNotAllowedAgencies');
}

function toggleAllowStatus(data) {
    return SC.postCall('toggleAllowStatus', data);
}