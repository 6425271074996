import React, { Component } from 'react'
import { connect } from 'react-redux'
import OrderForm from './OrderForm/OrderForm'
import User from 'helper/User'
import { getBrandingInfoById, getBrandingInfoBySlug } from '../../../redux/actions/account-action';
export class KeepSake extends Component {

    constructor(props) {
        super(props)
        this.props.getBrandingInfoBySlug('ttk');
      }
    componentDidMount(){
    }
    componentWillUnmount() {
        if (User.getProperty('brand.id')) {
            this.props.getBrandingInfoBySlug(User.getProperty('brand.domain_slug'));
        } 
    }
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="panel_heading userLogin_heading">Reorder Keepsakes</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <OrderForm reorder={true} formType='add' agency_id={User.agency('id')} id={User.order('id')} />
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    const { brandingInfo } = state.accountReducer;
    return {
        brandingInfo
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getBrandingInfoById: (id) => { dispatch(getBrandingInfoById(id)) },
        getBrandingInfoBySlug: (slug) => { dispatch(getBrandingInfoBySlug('ttk')) }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(KeepSake)
