import {
	adminConstants
} from '../constant/admin-constant';

const initialState = {
	loadingOrder: false,
    ordersData: [],
    orderWithStatusData: [],
	reportsData: [],
	printData:[],
	settingsData:{},
	invData:{},
	total_order:0,
	total_amount:0,
	total_invoices:0,
};

export function invoicingReducer(state = initialState, action) {

	switch (action.type) {


		case adminConstants.GET_INVOICING_ORDERS_REQUEST:
			return {
				...state,
				loadingOrder: true
			}
			
		case adminConstants.GET_INVOICING_ORDERS_SUCCESS:
			return {
				...state,
				reportsData: action.payload.data.pagination.data,
				total_orders: action.payload.data.pagination.total_order,
				total_amount: action.payload.data.pagination.total_amount,
				total_invoices: action.payload.data.pagination.total_invoices,
				REPORTSpages: 1,
				loadingOrder: false,
				REPORTScurrent_page: 1,
				REPORTStotal_orders: 1,
			};
		case adminConstants.GET_INVOICING_ORDERS_FAILURE:
			return {
				...state,
				loadingOrder: false,
				error: action.payload.error
		}
		case adminConstants.STORE_INVOICING_PRINT_REQUEST:
			return {
				...state,
				loadingOrder: true
			}
			
		case adminConstants.STORE_INVOICING_PRINT_SUCCESS:
			return {
				...state,
				printData: action.payload.reports.data,
				REPORTSpages: 1,
				loadingOrder: false,
				REPORTScurrent_page: 1,
				REPORTStotal_orders: 1,
			};
		case adminConstants.STORE_INVOICING_PRINT_FAILURE:
			return {
				...state,
				loadingOrder: false,
				error: action.payload.error
		}
		case adminConstants.STORE_INVOICING_ORDERS_REQUEST:
			return {
				...state,
				loadingOrder: true
			}
			
		case adminConstants.STORE_INVOICING_ORDERS_SUCCESS:
			return {
				...state,
				reportsData: action.payload.data,
				REPORTSpages: 1,
				loadingOrder: false,
				REPORTScurrent_page: 1,
				REPORTStotal_orders: 1,
			};
		case adminConstants.STORE_INVOICING_ORDERS_FAILURE:
			return {
				...state,
				loadingOrder: false,
				error: action.payload.error
			}	
			
		
		case adminConstants.GET_INVOICING_SETTING_REQUEST:
			return {
				...state,
				loadingOrder: true
			}
			
		case adminConstants.GET_INVOICING_SETTING_SUCCESS:
			return {
				...state,
				settingsData: action.payload.data,
			};
		case adminConstants.GET_INVOICING_SETTING_FAILURE:
			return {
				...state,
				loadingOrder: false,
				error: action.payload.error
		}

		case adminConstants.STORE_INVOICING_SETTING_REQUEST:
			return {
				...state,
				loadingOrder: true
			}
			
		case adminConstants.STORE_INVOICING_SETTING_SUCCESS:
			return {
				...state,
				settingsData: action.payload.data,
			};
		case adminConstants.STORE_INVOICING_SETTING_FAILURE:
			return {
				...state,
				loadingOrder: false,
				error: action.payload.error
		}


		case adminConstants.GET_INVOICING_IMPORT_REQUEST:
			return {
				...state,
				loadingOrder: true
			}
			
		case adminConstants.GET_INVOICING_IMPORT_SUCCESS:
			return {
				...state,
				settingsData: action.payload.data,
			};
		case adminConstants.GET_INVOICING_IMPORT_FAILURE:
			return {
				...state,
				loadingOrder: false,
				error: action.payload.error
		}

		case adminConstants.STORE_INVOICING_IMPORT_REQUEST:
			return {
				...state,
				loadingOrder: true
			}
			
		case adminConstants.STORE_INVOICING_IMPORT_SUCCESS:
			return {
				...state,
				invData: action.payload.data,
			};
		case adminConstants.STORE_INVOICING_IMPORT_FAILURE:
			return {
				...state,
				loadingOrder: false,
				error: action.payload.error
		}


		default:
			return {
				...state
			};
	}
}