import React, { Component } from 'react'
import { connect } from 'react-redux'
import SideNav from 'react-simple-sidenav';
import { IoMdClose } from 'react-icons/io';
import FrontEndRoutes from 'routes/FrontEndRoutes';
import User from 'helper/User';
import { Link } from 'react-router-dom'
import { getOrder, getOrderUploadInfo } from 'redux/actions/order-actions';
import { Roles } from '../../../constants/Roles';
class SideNavDashboard extends Component {

    componentDidMount() {
        //fetchUploaderinfo prop added in Sidenav mounted in dashboard to reduce multiple calls
        if (!('value' in this.props.orderUploadInfo.finalize_by_qa) && User.hasRole([Roles.PHOTO_ADMIN, Roles.TRAVELER]) && !this.props.fetchUploaderInfo) {
            this.props.getOrderUploadInfoCall(User.getProperty('order.id'));
        }
    }


    render() {
        const { orderUploadInfo } = this.props
        const navLinks = [];
        // console.log("frontendroutes", FrontEndRoutes);
        FrontEndRoutes.forEach((item, index) => {
            if ('role' in item && !item.showMenu && item.showInSidebar !== false) {
                if (User.hasRole([Roles.PHOTO_ADMIN, Roles.TRAVELER])) {
                    if (item.name === 'Logout') {
                        navLinks.push(<a href='/logout' onClick={this.props.logout} key={index} >{item.navIcon ? <i className={item.navIcon}></i> : ""}{item.name} {User.hasRole([Roles.PHOTO_ADMIN, Roles.TRAVELER]) && `#${User.getProperty('order.order_number')}`}</a>);
                    }
                    if ('render' in item && User.hasRole(Roles.PHOTO_ADMIN)) {
                        if (item.name === 'Your Password App Card') {
                        navLinks.push(<item.render key={index}  {...item.props}>
                            {item.navIcon ? <i className={item.navIcon}></i> : "cxz"}
                        </item.render>);
                        }
                    }
                    if (User.hasRole(item.role)) {
                        if ('needTravelerUcdPassed' in item && item.needTravelerUcdPassed === true ) {
                            if ( orderUploadInfo.traveler_upload_date_passed && Number(orderUploadInfo.photos_submitted_for_video_production.value) === 0) {
                                navLinks.push(<Link onClick={this.props.handleNav} key={index} to={item.disable ? '#' : item.path.replace(/(\/:[\w]*\??)+/, '')}>
                                    {item.navIcon ? <i className={item.navIcon}></i> : ""}{item.name}
                                </Link>);
                            }
                        } 
                    }
                }
                else if(User.hasRole(item.role)) {
                    // if (item.name === 'Logout') {
                    //     navLinks.push(<a href='/logout' onClick={this.props.logout} key={index} >{item.navIcon ? <i className={item.navIcon}></i> : ""}{item.name} {User.hasRole([Roles.PHOTO_ADMIN, Roles.TRAVELER]) && `#${User.getProperty('order.order_number')}`}</a>);
                    // }
                    if ('render' in item) {
                        if (User.hasRole(Roles.PHOTO_ADMIN) && ('needCameraShipment' in item || Number(orderUploadInfo.finalize_by_qa.value) === 1)) {
                            if(((User.getProperty('cameraShipped') && item.needCameraShipment) || ((!User.getProperty('cameraShipped') && !item.needCameraShipment) && ('needUploadApproved' in item && item.needUploadApproved === true && User.getProperty('upload_approved')))) || Number(orderUploadInfo.finalize_by_qa.value) === 1) {
                                navLinks.push(<item.render key={index}  {...item.props}>
                                    {item.navIcon ? <i className={item.navIcon}></i> : ""}
                                </item.render>);
                            }
                        } else {
                            navLinks.push(<item.render key={index}  {...item.props}>
                                {item.navIcon ? <i className={item.navIcon}></i> : ""}
                            </item.render>);
                        }
                    } else {
                        if (item.name === 'Logout') {
                            navLinks.push(<a href='/logout' onClick={this.props.logout} key={index} >{item.navIcon ? <i className={item.navIcon}></i> : ""}{item.name} {User.hasRole([Roles.PHOTO_ADMIN, Roles.TRAVELER]) && `#${User.getProperty('order.order_number')}`}</a>);
                        } else {
                            if ((User.hasRole([Roles.PHOTO_ADMIN, Roles.TRAVELER]) && ('needCameraShipment' in item || Number(orderUploadInfo.finalize_by_qa.value) === 1)) && (('needUploadApproved' in item && item.needUploadApproved && User.getProperty('upload_approved')) )) {

                                if ((User.getProperty('cameraShipped') && item.needCameraShipment) || (!User.getProperty('cameraShipped') && !item.needCameraShipment) || Number(orderUploadInfo.finalize_by_qa.value) === 1) {

                                    navLinks.push(<Link onClick={this.props.handleNav} key={index} to={(item.name === 'Uploader' && Number(orderUploadInfo.finalize_by_qa.value) === 1) ? `/photo-gallery`.replace(/(\/:[\w]*\??)+/, '') : item.path.replace(/(\/:[\w]*\??)+/, '')}>
                                        {item.navIcon ? <i className={item.navIcon}></i> : ""}{(item.name === 'Uploader' && Number(orderUploadInfo.finalize_by_qa.value) === 1) ? 'Video Gallery' : item.name}
                                    </Link>);
                                }
                            } else {
                                if ('needUploadApproved' in item && item.needUploadApproved === true && User.getProperty('upload_approved')) {
                                    navLinks.push(<Link onClick={this.props.handleNav} key={index} to={item.path.replace(/(\/:[\w]*\??)+/, '')}>
                                        {item.navIcon ? <i className={item.navIcon}></i> : ""}{item.name}
                                    </Link>);
                                } else {
                                    navLinks.push(<Link onClick={this.props.handleNav} key={index} to={item.path.replace(/(\/:[\w]*\??)+/, '')}>
                                        {item.navIcon ? <i className={item.navIcon}></i> : ""}{item.name}
                                    </Link>);
                                }
                            }
                        }
                    }
                }
            }
            
        })
        return (
            <SideNav
                openFromRight={true}
                className="sideNav"
                showNav={this.props.showNav}
                onHideNav={this.props.handleNav}
                title={
                    <button className="sideNavBtn dark" onClick={this.props.handleNav}><IoMdClose /></button>
                }
                titleStyle={{ backgroundColor: '#2196F3' }}
                items={
                    navLinks
                }
            />
        )
    }
}

const mapStateToProps = (state) => {
    const { orderUploadInfo } = state.frontEndUserReducer;
    return {
        orderUploadInfo
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getOrderCall: (orderId) => { dispatch(getOrder(orderId)) },
        getOrderUploadInfoCall: (orderId) => { dispatch(getOrderUploadInfo(orderId)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideNavDashboard)
