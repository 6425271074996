import React, { Component } from "react";
import { connect } from 'react-redux';
import { getInvoicingSettings, submitInvoicingSettings } from "redux/actions/invoicing-actions";
// import ReactValidator from "simple-react-validator";
// import DatePicker from "react-datepicker";
import {
    Grid,
    Row,
    Col,
    OverlayTrigger,
    Popover,
    Button
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
// import 'react-table/react-table.css';
// import { Link } from 'react-router-dom';
// import { helperFunctions as HF } from 'helper/helperFunctions';

// const moment = window.moment;

class InvoicingSettingsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numDays: 0,
        };
        this.props.getReportsCall();
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.settingsData !== this.props.settingsData) {
            if (this.props.settingsData) {
                const days = this.props.settingsData.value;
                this.setState({
                    numDays: days
                })
            }
        }
    }

    handleChange(e) {
        // console.log(e.target.value);
        this.setState({
            numDays: e.target.value
        })
    }

    sendOrdersTOInvoice = () => {
        const numDays = this.state.numDays;
        if (!numDays || numDays < 0) {
            alert('Please enter the valid number of days');
            return

        }
        this.props.submitInvoicingSettingsCall(numDays).then(
            response => {
                this.props.getReportsCall();
            },
            error => {

            }
        );

    }

    render() {
        const { numDays } = this.state;
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                ctTableResponsive
                                content={
                                    <div>
                                        <Grid fluid>
                                            <div className="row">
                                                <Col md={12}>
                                                    <div className="secHeading">Edit Global Invoice Buffer</div>
                                                </Col>


                                                <Col md={8}>

                                                    <div className="col-md-12 pl-none pr-none">
                                                        <div className='form-group' id="proshowFileCompanyName">
                                                            <div className="clearfix">
                                                                <div className="col-md-2 lbl_container">
                                                                    <label htmlFor="name">Days Prior to Departure</label>
                                                                </div>

                                                                <div className="col-md-4">
                                                                    <input type="number" min="0" name="proshow_file_company_name" className="form-control" value={numDays} onChange={this.handleChange} />
                                                                </div>

                                                                <div className="col-md-4">
                                                                    <OverlayTrigger placement="bottom" trigger={['hover']} overlay={<Popover id="tooltip">Submit</Popover>}>
                                                                        <Button type='button' className="backButton pt-sm no_radius btn-block pb-sm primary mt-none btn btn btn-info " onClick={this.sendOrdersTOInvoice} ><i className="fa fa-plus" />Submit</Button>
                                                                    </OverlayTrigger>

                                                                </div>

                                                                <span></span>
                                                            </div>



                                                        </div>
                                                    </div>
                                                </Col>



                                            </div>
                                        </Grid>
                                    </div>
                                } />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return ({
        getReportsCall: () => { dispatch(getInvoicingSettings()) },
        submitInvoicingSettingsCall: (ordersData) => { return dispatch(submitInvoicingSettings(ordersData)) },

    });
};

function mapStateToProps(state) {
    const { settingsData } = state.invoicingReducer;
    console.log('settingsData yyy', settingsData)
    return {
        settingsData
    };
}


const InvoicingSettings = connect(mapStateToProps, mapDispatchToProps)(InvoicingSettingsPage);
export default InvoicingSettings;