import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Row,
  Col,
  OverlayTrigger,
  Popover
} from "react-bootstrap";
import Select from 'react-select'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import { getFaqsPagination, getFaq, deleteFaq, updateFaqStatus } from 'redux/actions/brand-actions';
// import { permissionActions } from 'redux/actions/permission-action';
import 'react-table/react-table.css';
import FaqForm from "./FaqForm";
// import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { hasPermission } from 'helper/hasPermission';
import Confirmalertfordelete from 'components/ThemeComponents/confirmAlertForDelete';
import { adminLabels } from 'redux/constant/admin-label-constant';
import OpenModalButton from 'components/ThemeComponents/openModelButton';
import { toggleModal, selectChangeHandler, openModal, closeModalAfterApi } from 'helper/helperFunctions';
// import { Link } from 'react-router-dom';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import ThemeModal from 'components/ThemeComponents/ThemeModal';
// var Modal = require('react-bootstrap-modal');
// import DownloadFile from 'components/ThemeComponents/downloadFile';
import parse from 'html-react-parser';
import Pagination from 'components/ThemeComponents/Pagination';

class FaqsListPage extends Component {
  constructor(props) {
    super(props);
    const { accountId } = this.props.match.params;

    this.state = {
      addModalFaq: this.props.addModalFaq,
      editModalFaq: this.props.editModalFaq,
      onCloseAfterApi: false,
      sorted: '',
      filtered: '',
      pageSize: 10,
      page: 0,
      accountId: parseInt(accountId, 10),
      is_web: '1',
      brand_name: ''
    };

    this.toggleModal = toggleModal.bind(this);
    // this.selectChangeHandler = inputChangeHandler.bind(this);
    this.selectChangeHandler = selectChangeHandler.bind(this);
  };
  filterSearchHandle = (e) => {
    this.setState({
      'filtered': e.target.value
    });
  }
  deleteFaq = (faqId) => {
    const { accountId } = this.state;

    this.props.deleteFaqCall(accountId, faqId).then(response => {
      this.pagination.dataCall();
    });
  }
  deleteConfirmFaq = (faqId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete typeName="Faq" description="Are you sure to delete the faq?" onClosePro={onClose} deleteType={() => this.deleteFaq(faqId)} />
        )
      }
    })
  }
  faqsPaginationData = (page = this.state.page, filtered = this.state.filtered, sorted = this.state.sorted, pageSize = this.state.pageSize, is_web = this.state.is_web) => {
    // const { accountId } = this.state;
    this.pagination.dataCall();
  }

  handleFaqStatus = faqId => (e) => {
    this.props.handleFaqStatusCall(faqId, { is_active: e.target.checked ? true : false }).then(
      response => {
        this.pagination.dataCall();
      }
    );
  }
  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.editFaq !== this.props.editFaq) && this.props.editFaq) {
      this.setState({ editModalFaq: true });
    }
    if (prevProps.faqsCurrent_page !== this.props.faqsCurrent_page) {
      this.setState({ page: this.props.faqsCurrent_page }, () => {
      })
    }
    if ((!prevState.addModalFaq && this.state.addModalFaq) || (!prevState.editModalFaq && this.state.editModalFaq)) {
      this.setState({ onCloseAfterApi: false })
    }
    if ((prevState.addModalFaq && !this.state.addModalFaq && this.state.onCloseAfterApi) || (prevState.editModalFaq && !this.state.editModalFaq && this.state.onCloseAfterApi)) {
      this.pagination.dataCall();
    }
    if (this.state.is_web !== prevState.is_web) {
      this.pagination.dataCall();
    }
  }

  paginationCall = (data) => {
    const { accountId, is_web } = this.state;
    return this.props.getFaqsPaginationCall(accountId, data.page, data.filter, data.sort, data.pageSize, is_web).then(
      res => {
        this.setState({
          brand_name: res.data.brand.title
        })
        return res;
      }
    );
  }

  render() {
    const { /*filtered, */accountId, brand_name } = this.state;
    const { editFaq, /*faqsData, faqsPages, loadingBrand*/ } = this.props;
    const columns = [
      {
        Header: "Faqs Listing",
        headerClassName: 'headerPagination',
        columns: [
          {
            Header: "Faq Qustion",
            accessor: "question",
            className: "action-center"
          },
          {
            Header: "Answer",
            accessor: "answer",
            className: "action-center",
            Cell: ({ value }) => (
              <div>
                {parse(value)}
              </div>
            )
          },
          {
            Header: "Slug",
            accessor: "slug",
            className: "action-center"
          },
          /*
          {
            Header: "Category",
            accessor: "category.name",
            className: "action-center"
          },
          */
          {
            Header: "Status",
            accessor: "is_active",
            Cell: ({ row }) => (
              <label className="container-check">
                &nbsp;
                <input
                  type="checkbox" name="is_active" className="checkbox" value={row.is_active ? 1 : 0} onChange={this.handleFaqStatus(row.id)} checked={row.is_active ? 1 : 0}
                /><span className="checkmark"></span>
              </label>
            ),
            className: "action-center",
            sortable: false
          },
          {
            Header: "Actions",
            accessor: "id",
            Cell: ({ value }) => (
              <div>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{hasPermission('faq-edit') ? 'Edit Faq' : adminLabels.DONT_ALLOW}</Popover>}>
                  <span>
                    {
                      hasPermission('faq-edit') &&
                      <button type='button' className="editIcon orderDetailsAction" onClick={() => this.props.getFaqCall(accountId, value)}>&nbsp;</button>
                    }
                    {
                      !hasPermission('faq-edit') &&
                      <button type='button' className="editIcon orderDetailsAction button_disabled" >&nbsp;</button>
                    }
                  </span>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{hasPermission('faq-delete') ? 'Delete Faq' : adminLabels.DONT_ALLOW}</Popover>}>
                  <span>
                    {
                      hasPermission('faq-delete') &&
                      <button type='button' className="deleteIcon ml-xs orderDetailsAction" onClick={() => this.deleteConfirmFaq(value)}>&nbsp;</button>
                    }
                    {
                      !hasPermission('faq-delete') &&
                      <button type='button' className="deleteIcon ml-xs orderDetailsAction button_disabled" >&nbsp;</button>
                    }
                  </span>
                </OverlayTrigger>
              </div>
            ),
            className: "action-center",
            sortable: false
          }
        ]
      }
    ];
    const options = [
      {
        value: '0',
        label: 'Mobile'
      },
      {
        value: '1',
        label: 'Web'
      }
    ]
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                ctTableResponsive
                content={
                  <div>
                    <Grid fluid>
                      <Row>
                        <Col md={12}>
                          <div className="secHeading">
                            {brand_name} Faqs List
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="flexElem flexResponsive" style={{ "alignItems": "flex-start", "justifyContent": "space-between" }}>
                            <div className="flexElem">
                              <OpenModalButton
                                openModal={hasPermission('faq-create') ? openModal.bind(this, "addModalFaq") : null}
                                classButton={['backButton pt-sm no_radius pb-sm primary mt-none btn-block btn-info btn mr-md', !hasPermission('faq-create') ? 'button_disabled' : '']}
                                buttonName="Add Faq"
                                tooltipText={hasPermission('faq-create') ? 'Add Faq' : adminLabels.DONT_ALLOW}
                                classIcon={['fa', 'fa-plus']}
                              />
                              <Select
                                isSearchable={false}
                                isMulti={false}
                                value={options.map(item => {
                                  if (item.value === this.state.is_web) {
                                    return item;
                                  }
                                })}
                                styles={{
                                  container: (provided) => {
                                    return {
                                      ...provided,
                                      width: '100%',
                                      minWidth: '200px'
                                    }
                                  },
                                  menu: (provided) => {
                                    return {
                                      ...provided,
                                      width: '200px'
                                    }
                                  }
                                }}
                                options={options}
                                onChange={this.selectChangeHandler}
                                name='is_web'
                              />
                              {/* <select className='form-control' value={this.state.is_web} onChange={this.selectChangeHandler} name="is_web" id="">
                                <option value="1">Web</option>
                                <option value="0">Mobile</option>
                              </select> */}
                            </div>
                            {/* <div className="custom-search-input">
                              <FormInputs
                                ncols={["col-md-12"]}
                                onChange={this.filterSearchHandle}
                                proprieties={[
                                  {
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "Search Faqs",
                                    onChange: this.filterSearchHandle,
                                    name: "filter"
                                  }
                                ]}
                              />
                            </div> */}
                          </div>
                        </Col>
                        {/* <Col md={12} className="mt-md">
                          <ReactTable
                            noDataText='No faq found'
                            data={faqsData}
                            pages={faqsPages}
                            columns={columns}
                            filtered={filtered}
                            defaultPageSize={10}
                            loading={loadingBrand}
                            className="-striped listing"
                            loadingText={'Loading...'}
                            pageData={this.faqsPaginationData}
                            manual
                            onFetchData={(state, instance) => {
                              var sort = state.sorted.length === 0 ? '' : state.sorted[0].id + ',desc:' + state.sorted[0].desc;
                              state.pageData(state.page + 1, state.filtered, sort, state.pageSize);
                            }}
                          />
                        </Col> */}
                        <Pagination
                          ref={(p) => this.pagination = p}
                          showPagination={false}
                          columns={columns}
                          noDataText='No faq found'
                          pageSize={50}
                          getDataCall={this.paginationCall}
                          filterView={true}
                          // downloadData={true}
                          // downloadFileName={'Orders'}
                          // lowerContent = {null}
                          filterPlaceHolder={'FAQs'}
                          getRowProps={this.getRowProps}
                        // showAllToggle={true}
                        />
                      </Row>
                    </Grid>

                    {/*Add Faq Modal Start*/}
                    <ThemeModal modalClass="" title='Add Faq' show={this.state.addModalFaq} hide={() => { this.toggleModal('addModalFaq') }} >
                      <FaqForm
                        formTypePro="add"
                        faqPro={{ ...OC.FAQ, account_id: accountId }}
                        closeModel={closeModalAfterApi.bind(this, "addModalFaq", "onCloseAfterApi")}
                      />
                    </ThemeModal>
                    {/*Add Faq Modal End*/}

                    {/*Edit Faq Modal start*/}
                    {
                      editFaq &&
                      <ThemeModal modalClass="" title='Edit Faq' show={this.state.editModalFaq} hide={() => { this.toggleModal('editModalFaq') }} >
                        <FaqForm
                          formTypePro="edit"
                          faqPro={editFaq}
                          closeModel={closeModalAfterApi.bind(this, "editModalFaq", "onCloseAfterApi")}
                        />
                      </ThemeModal>
                    }
                    {/*Edit Faq Modal End*/}
                  </div>
                } />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  };
};

const mapDispatchToProps = dispatch => {
  return ({
    getFaqsPaginationCall: (accountId, page, filter, sort, pageSize, is_web) => { return dispatch(getFaqsPagination(accountId, page, filter, sort, pageSize, is_web)) },
    getFaqCall: (accountId, id) => { dispatch(getFaq(accountId, id)) },
    deleteFaqCall: (accountId, id) => { return dispatch(deleteFaq(accountId, id)) },
    handleFaqStatusCall: (faqId, data) => { return dispatch(updateFaqStatus(faqId, data)) },
  });
};

function mapStateToProps(state) {
  const { editFaq, loadingBrand, faqsData, faqsPages, faqsCurrent_page } = state.brandsReducer;
  return {
    editFaq, loadingBrand, faqsData, faqsPages, faqsCurrent_page
  };
};

const Faqs = connect(mapStateToProps, mapDispatchToProps)(FaqsListPage);
export default Faqs;