import React, { Component } from "react";
import { connect } from 'react-redux';
import { getUCDReached } from "redux/actions/report-actions";
import ReactValidator from "simple-react-validator";
import { Row, Col, Grid } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import 'react-table/react-table.css';
import { dateFormatMy, returnLabelWithValue } from 'helper/helperFunctions';
import { Link } from 'react-router-dom';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import Select from 'react-select';
import Pagination from "../../ThemeComponents/Pagination";

class UCDReachedPage extends Component {
	constructor(props) {
		super(props);
		this.dateValidator = new ReactValidator();

		this.state = {
			fromDate: '',
			toDate: '',
			pageSize: 100,
			page: 0,
			sorted: '',
			numOfDays: 90,
		}
		this.table = React.createRef();
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.page !== this.props.page) {
			this.setState({ page: this.props.page })
		}

		if (prevState.numOfDays !== this.state.numOfDays) {
			var numOfDays = this.state.numOfDays
			this.props.getUCDCall(numOfDays, '', '', '', '', '');
		}
	}
	handleChangeContactLogDays = (selectedOptionType, info) => {
		if (info['action'] === 'select-option') {
			this.setState({ numOfDays: selectedOptionType['value'] });
		}
	}

	getRowProps = (rowInfo) => {
		let data = {}
		if (rowInfo !== undefined) {
			const rowData = rowInfo
			data = {
				color: 'fontColor' in rowData ? rowData.fontColor : null,
			}
		}
		return data;
	}

	ucdPaginationData = (page = this.state.page, sorted = this.state.sorted, pageSize = this.state.pageSize) => {
		const { fromDate, toDate, numOfDays } = this.state;
		this.props.getUCDCall(numOfDays, fromDate, toDate, page, pageSize, sorted);
	}
	paginationCall = (data) => {
		const { fromDate, toDate, numOfDays } = this.state;
		return this.props.getUCDCall(numOfDays, fromDate, toDate, data.page, data.pageSize, data.sort);
	}
	render() {
		const { numOfDays } = this.state;
		const { /*ucdData, ucdpages, loadingOrder,*/ ucdtotal_orders } = this.props;
		const columns = [
			{
				Header: "UCD Reached",
				headerClassName: 'headerPagination',
				columns: [
					{
						Header: "Order",
						accessor: "barcode",
                        width: 200,
						className: "action-center",
						Cell: ({ value, original }) => {
							return <Link target="_blank" to={'/Admin/' + (original.reorder ? original.reorder : original.id) + '/order-edit'} className="blue_link">{value}</Link>
						},
						sortable: false,
					},
					{
						Header: "Group",
						accessor: "group_name",
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Destination",
						accessor: "destinations",
						Cell: ({ value }) => (
							<div>
								{value[0].title}
							</div>
						),
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Logged in Travelers/Travelers",
						accessor: "numberOfVideos",
						className: "action-center",
						sortable: false,
						width: 185,
						Cell: ({ original }) => (
							<div>
								<span>{original.order_app_users_count + "/" + original.numberOfVideos}</span>
							</div>
						),
					},
					{
						Header: "Departure",
						accessor: "order_dates",
						Cell: ({ value }) => (
							<div>
								{dateFormatMy(value['departure_date'].value)}
							</div>
						),
						className: "action-center",
						sortable: false,
					},

					{
						Header: "Return",
						accessor: "order_dates",
						Cell: ({ value }) => (
							<div>
								{dateFormatMy(value['return_date'].value)}
							</div>
						),
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Upload Cut Off Date",
						accessor: "order_dates",
						Cell: ({ value }) => (
							<div>
								{dateFormatMy(value['upload_cut_off_date'].value)}
							</div>
						),
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Photo Count",
						accessor: "images_count",
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Status",
						accessor: "hdStatus",
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Last Contact Log Entry",
						accessor: "contactLogLastComment",
						Cell: ({ value }) => (
							<div>{dateFormatMy(value)}</div>
						),
						className: "action-center",
						sortable: false,
					},

				]
			}
		];
		return (
			<div className="content">
				<Grid fluid>
					<Row>
						<Col md={12}>
							<Card
								ctTableResponsive
								content={
									<div>
										<Grid fluid>
											<div className="row">
												<Col md={12}>
													<div className="secHeading">UCD Reached</div>
												</Col>
												<Col md={4}>
													<Col md={12}>
														<Select
															value={returnLabelWithValue(numOfDays, OC.UCDREACHEDDAYS)}
															onChange={this.handleChangeContactLogDays}
															options={OC.UCDREACHEDDAYS}
															isSearchable={true}
															isMulti={false}
															name='numOfDays'
															placeholder={'Select Days'}
														/>
													</Col>

													<Col md={12}>
														<div className="small">
															<span style={{ color: '#00cc00', padding: '5px', fontWeight: 'bold' }}>EDITED</span> |
															<span style={{ color: '#ff0033', padding: '5px', fontWeight: 'bold' }}>NOT YET RECEIVED</span> |
															<span style={{ color: '#3366ff', padding: '5px', fontWeight: 'bold' }}>RECEIVED</span> |
															<span style={{ color: '#9933CC', padding: '5px', fontWeight: 'bold' }}>Not Auto Post</span>
														</div>
													</Col>

													<Col md={12}>
														<div className="small">Total Records: {ucdtotal_orders ? ucdtotal_orders : 0}</div>
													</Col>
												</Col>
												<Col md={8} xs={12}>
													<Col md={12}>
														<div>Criteria of the report:</div>
													</Col>
													<Col md={6} xs={6} style={{ padding: '0' }}>
														<ul>
															<li className="small">ProgramType=Uploading other than Demo</li>
															<li className="small">Photos Not Submitted</li>
														</ul>
													</Col>
													<Col md={6} xs={6} style={{ padding: '0' }}>
														<ul>
														<li className="small">Order Has NOT Been Produced</li>
															<li className="small">UCD Has been Reached</li>
														</ul>
													</Col>
												</Col>

												{/* <Col md={12} className="mt-md">
													<ReactTable
														ref={this.table}
														noDataText='No ucd data found'
														data={ucdData}
														pages={ucdpages}
														columns={columns}
														defaultPageSize={10}
														loading={loadingOrder}
														className="-striped listing"
														loadingText={'Loading...'}
														pageData={this.ucdPaginationData}
														manual
														onFetchData={(state, instance) => {

															var sort = state.sorted.length === 0 ? '' : state.sorted[0].id + ',desc:' + state.sorted[0].desc;
															console.log('state page size', state.pageSize);
															state.pageData(state.page + 1, sort, state.pageSize);
														}}
														getTrProps={(state, rowInfo) => {
															let data = {}
															if (rowInfo !== undefined) {
																const rowData = rowInfo.original
																data = {
																	color: 'fontColor' in rowData ? rowData.fontColor : null,
																}
															}
															return {
																style: data
															};
														}}
													/>
												</Col> */}
												<Pagination
													ref={(p) => this.pagination = p}
													showPagination={false}
													columns={columns}
													pageSize={100}
													noDataText='No ucd data found'
													getDataCall={this.paginationCall}
													filterView={false}
													// downloadData={true}
													// downloadFileName={'Orders'}
													// lowerContent = {null}
													// filterPlaceHolder = {'Orders'}
													getRowProps={this.getRowProps}
												// showAllToggle={true}
												/>
											</div>
										</Grid>
									</div>
								} />
						</Col>
					</Row>
				</Grid>
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return ({
		getUCDCall: (numOfDays, fromDate, toDate, page, pageSize, sorted) => { return dispatch(getUCDReached(numOfDays, fromDate, toDate, page, pageSize, sorted)) },//example function
		success: (response) => { return dispatch({ type: 'GET_ALL_UCD_REACHED_SUCCESS', payload: response }) }
	});
};

function mapStateToProps(state) {
	const { ucdData, ucdpages, ucdcurrent_page, ucdtotal_orders, loadingOrder } = state.reportReducer;
	return {
		ucdData, ucdpages, ucdcurrent_page, ucdtotal_orders, loadingOrder
	};
}


const UCDReached = connect(mapStateToProps, mapDispatchToProps)(UCDReachedPage);
export default UCDReached;
