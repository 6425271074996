import React, { Component } from 'react'
import { connect } from 'react-redux'

import User from 'helper/User';
import { getAgencyUsersPagination, storeAgencyUser, editAgencyUser, getAgencyUser, deleteAgencyUser } from 'redux/actions/agency-user-actions';
import { getAgencyRoles } from 'redux/actions/role-actions';
import ConfirmAlertForDelete from 'components/ThemeComponents/confirmAlertForDelete';
import { confirmAlert } from 'react-confirm-alert';
import ThemeModal from 'components/ThemeComponents/ThemeModal';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import AgencyUserForm from 'components/Admin/AgencyUsers/AgencyUserForm';
import { helperFunctions as HF } from 'helper/helperFunctions';

// import ReactTable from 'react-table'
import {
    OverlayTrigger, Popover
} from "react-bootstrap";
import Pagination from '../../../ThemeComponents/Pagination';
// import MyImage from 'components/Upload/MyImage';


class UsersList extends Component {
    constructor(props) {
        super(props)

        this.state = {
            createUserModal: false,
            editUserModal: false,
            pageSize: 10,
            filtered: '',
            showMore: false,
            firstLoaded: false,
            agencyUsersData: {}
        }
        this.toggleModal = HF.toggleModal.bind(this);
    }

    componentDidMount() {
        this.props.getAgencyUsersCall(User.getProperty('agency.id'), 1, '', '', this.state.pageSize).then(res => {
            this.setState({
                agencyUsersData: res.data.pagination
            })
            return res;
        })
        this.props.getAllRolesCall();
    }


    componentDidUpdate(prevProps) {
        if (prevProps.editAgencyUser !== this.props.editAgencyUser && !this.props.loadingAgencyUser) {
            this.setState({
                user: {
                    name: this.props.editAgencyUser.name,
                    username: this.props.editAgencyUser.username,
                    fax: this.props.editAgencyUser.contact !== null ? this.props.editAgencyUser.contact.fax === null ? '' : this.props.editAgencyUser.contact.fax : '',
                    phone1: this.props.editAgencyUser.contact ? this.props.editAgencyUser.contact.phone1 : '',
                    phone2: this.props.editAgencyUser.contact ? this.props.editAgencyUser.contact.phone2 === null ? '' : this.props.editAgencyUser.contact.phone2 : '',
                    email: this.props.editAgencyUser.email,
                    password: '',
                    confirm_password: '',
                    agencyRoles: this.props.editAgencyUser.agencyRoles,
                    is_active: this.props.editAgencyUser.is_active
                }
            }, function () {
                this.setState({
                    editUserModal: true
                })
            })
        }
        if ((prevProps.updatedAgencyUser !== this.props.updatedAgencyUser) && !this.props.loadingAgencyUser) {
            this.pagination.dataCall();
        }
        if ((prevProps.deleteAgencyUser !== this.props.deleteAgencyUser) && !this.props.loadingAgencyUser) {
            this.pagination.dataCall();
        }
    }

    userFormSubmitHandler = (user, formType) => {
        const data = {
            page: 1,
            filter: '',
            sort: '',
            pageSize: 10
        }
        if (formType === 'edit') {
            this.props.editAgencyUserCall(user, this.props.editAgencyUser.id, User.agency('id')).then(
                response => {
                    if (!this.pagination) {
                        this.paginationCall(data);
                    } else {
                        this.pagination.dataCall();
                    }
                    this.setState({
                        editUserModal: false
                    })
                }
            );
        } else if (formType === 'add') {
            this.props.storeAgencyUserCall(User.agency('id'), user).then(
                response => {
                    this.setState({
                        createUserModal: false
                    }, () => {
                        if (!this.pagination) {
                            this.paginationCall(data)
                        }
                    })
                    return response
                },
                err => {
                    return err
                }
            );
        }
    }

    closeEditModal = () => {
        this.setState({
            editUserModal: false,
            // user: {}
        })
    }

    deleteConfirmAgencyUser = (userId) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <ConfirmAlertForDelete typeName="Agency User" description="Are you sure to delete the admin agency user?" onClosePro={onClose} deleteType={() => this.props.deleteAgencyUserCall(1, userId)} />
                )
            }
        })
    }

    paginationCall = (data) => {
        return this.props.getAgencyUsersCall(User.getProperty('agency.id'), data.page, data.filter, data.sort, data.pageSize).then(
            res => {
                this.setState({
                    agencyUsersData: res.data.pagination
                })
                return res;
            }
        );;
    }

    render() {
        const { agencyRoles } = this.props;
        const { agencyUsersData } = this.state;
        const columns = [
            {
                Header: "Agency Users Listing",
                headerClassName: 'headerPagination',
                columns: [
                    {
                        Header: "Agency User Name",
                        accessor: "name",
                        className: "action-center"
                    },
                    {
                        Header: "Email",
                        accessor: "email",
                        className: "action-center"
                    },
                    {
                        Header: "Roles",
                        accessor: "roles",
                        Cell: ({ value }) => (
                            <div>
                                {
                                    value.map((prop, key) => {
                                        return (<span className="badge badge-success" key={key}>{prop.label}</span>)
                                    })
                                }
                            </div>
                        ),
                        className: "action-center",
                        sortable: false
                    },
                    {
                        Header: "Actions",
                        accessor: "id",
                        Cell: ({ value }) => (
                            <div>
                                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Edit Agency User</Popover>}>
                                    <span>
                                        {
                                            <button type='button' className="action-button-size" onClick={() => { this.props.getAgencyUserCall(User.getProperty('agency.id'), value) }}>Edit</button>
                                        }
                                    </span>
                                </OverlayTrigger>
                                {/* <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Delete Agency User</Popover>}>
                                    <span>
                                        {
                                            <button type='button' className="action-button-size" onClick={() => this.deleteConfirmAgencyUser(value)}><i className="fa fa-trash text-danger " /></button>
                                        }
                                    </span>

                                </OverlayTrigger> */}
                            </div>
                        ),
                        className: "action-center",
                        sortable: false
                    }
                ]
            }
        ];
        return (
            <div className="form_tile">
                <div className="form_tile_heading">
                    <img src={HF.s3StaticPath('img/account_users.png')} alt="Account Users" />
                    <span>Account Users</span>
                </div>

                <div className="form_tile_content">
                    <div className="accountUsersContainer form_tile_row">
                        <table className="table_tile">
                            <thead>
                                <tr className="border-bottom">
                                    <th>Contact Name</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    'data' in agencyUsersData &&
                                    agencyUsersData.data.map((user, index) => {
                                        return (
                                            index <= 9 ?
                                                <tr key={user.id}>
                                                    <td className="text_14">{user.name}</td>
                                                    <td>
                                                        <a onClick={() => { this.props.getAgencyUserCall(User.getProperty('agency.id'), user.id) }} href="#!" className="text_14 linkColor">Edit</a>
                                                    </td>
                                                </tr>
                                                : null
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className="flexElem respFlex spaceBetween form_tile_row">
                        <div className="col-md-12 text-center">
                            <button onClick={() => { this.toggleModal('createUserModal') }} className="pl-xlg pr-xlg themeBtn_12 purple_gradient hover_dark">Create New User</button>
                        </div>
                        {
                            agencyUsersData.total > 2 &&
                            <div className="col-md-6">
                                <button onClick={() => { this.toggleModal('showMore') }} className="pl-xlg pr-xlg themeBtn_12 purple_gradient hover_dark">Show More</button>
                            </div>
                        }
                    </div>
                </div>
                <ThemeModal modalClass="frontEndModal" title='Add User' show={this.state.createUserModal} hide={() => { this.toggleModal('createUserModal') }} >
                    <AgencyUserForm
                        closeModel={() => { this.toggleModal('createUserModal') }}
                        formTypePro="add"
                        userPro={OC.AGENCYUSER}
                        handleAgencyUserSubmit={this.userFormSubmitHandler}
                        componentPro="adminAgencyUser"
                        agencyRoles={agencyRoles || []}
                        frontEnd={true}
                    />
                </ThemeModal>
                <ThemeModal modalClass="frontEndModal" title='Edit User' show={this.state.editUserModal} hide={this.closeEditModal} >
                    <AgencyUserForm
                        closeModel={this.closeEditModal}
                        formTypePro="edit"
                        userPro={this.state.user}
                        handleAgencyUserSubmit={this.userFormSubmitHandler}
                        componentPro="adminAgencyUser"
                        agencyRoles={agencyRoles || []}
                        frontEnd={true}
                    />
                </ThemeModal>
                <ThemeModal size='lg' modalClass="frontEndModal" title='Agency Users' show={this.state.showMore} hide={() => { this.toggleModal('showMore') }} >
                    {/* <ReactTable
                        columns={columns}
                        noDataText='No agency user found'
                        data={agencyUsersData.data}
                        defaultPageSize={this.state.pageSize}
                        pages={agencyUsersData.last_page}
                        manual
                        onFetchData={(state) => {
                            var sort = state.sorted.length === 0 ? '' : state.sorted[0].id + ',desc:' + state.sorted[0].desc;
                            this.props.getAgencyUsersCall(User.getProperty('agency.id'), state.page + 1, state.filtered, sort, state.pageSize);
                        }}
                    /> */}
                    <div className='row'>
                        <Pagination
                            ref={(p) => this.pagination = p}
                            showPagination={false}
                            columns={columns}
                            pageSize={10}
                            getDataCall={this.paginationCall}
                            filterView={false}
                            noDataText='No Program Type'
                            // downloadData={true}
                            // downloadFileName={'Orders'}
                            // lowerContent = {null}
                            // filterPlaceHolder = {'Orders'}
                            getRowProps={this.getRowProps}
                        // showAllToggle={true}
                        />
                    </div>
                </ThemeModal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { agencyRoles } = state.rolesReducer;
    const { editAgency } = state.agenciesReducer;
    const { agencyUsersData, editAgencyUser, updatedAgencyUser, current_page, loadingAgencyUser, deleteAgencyUser } = state.agencyUserReducer;
    return {
        agencyRoles,
        agencyUsersData,
        editAgencyUser,
        updatedAgencyUser,
        current_page,
        loadingAgencyUser,
        deleteAgencyUser,
        editAgency,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAllRolesCall: () => { dispatch(getAgencyRoles()) },
        getAgencyUsersCall: (agency_id, page, filter, sort, pageSize) => { return dispatch(getAgencyUsersPagination(agency_id, page, filter, sort, pageSize)) },
        storeAgencyUserCall: (agencyId, user) => { return dispatch(storeAgencyUser(agencyId, user)) },
        editAgencyUserCall: (userData, id, agency_id) => { return dispatch(editAgencyUser(userData, id, agency_id)) },
        getAgencyUserCall: (agency_id, id) => { dispatch(getAgencyUser(agency_id, id)) },
        deleteAgencyUserCall: (agency_id, id) => { dispatch(deleteAgencyUser(agency_id, id)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersList)
