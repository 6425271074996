import React, { Component } from "react";
import { connect } from 'react-redux';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import FileUpload from "components/Upload/FileUpload";
import SimpleReactValidator from 'simple-react-validator';
import ImagePreview from "components/Upload/ImagePreview";
import { Button } from "react-bootstrap";
import Select from 'react-select';
import { toggleModal, openModal, createFormData, focusInput, returnKeyWithColumnMatch, returnLabelsWithValues, closeModalAfterApi, returnLabelWithValue } from 'helper/helperFunctions';
import { storeProShowFile, updateProShowFileCall } from 'redux/actions/proShowFile-action';
import DestinationForm from "components/Admin/Destination/DestinationForm";
import { getDestinations } from 'redux/actions/settings-actions';
import ThemeModal from 'components/ThemeComponents/ThemeModal';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { settingsService } from 'services/settings';

const promiseOptions = (inputValue) =>
	new Promise((resolve) => {
		setTimeout(() => {
			resolve(settingsService.getDestinationsAsync({ inputValue: inputValue }));
		}, 1000);
	}
	);


class ProShowFormPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pro_show_file: { ...this.props.pro_show_file },
			submitted: false,
			formType: this.props.formType || '',
			pro_show_file_id: this.props.pro_show_file_id,
			newDestination: OC.DESTINATION,/*Modal for destination code*/
			addModalDestination: false,/*Modal for destination code*/
			onCloseAfterApi: false,/*Modal for destination code*/
		};
		this.validatorProShowFile = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
		this.handleProShowFile = this.handleProShowFile.bind(this);
		this.props.getDestinationsCall();

		/*Modal for destination code*/
		this.toggleModal = toggleModal.bind(this);
		this.openModal = openModal.bind(this);
		/*Modal for destination code*/
	}
	setResourceFile = (key, value) => {
		this.setState(prevState => ({
			pro_show_file: {
				...prevState.pro_show_file,
				file: value
			}
		}), () => {
			console.log('pro show file', this.state.pro_show_file);
		});
	}
	removeFile = (key, value) => {
		this.setState(prevState => ({
			pro_show_file: {
				...prevState.pro_show_file,
				file: ''
			}
		}), () => {
			console.log('pro show file', this.state.pro_show_file);
		});
	}
	handleProShowFileSubmit = (e) => {
		if (this.validatorProShowFile.allValid()) {
			this.setState({ submitted: true }, function () {
				const { formType, pro_show_file } = this.state;
				const fdData = createFormData({
					'file': pro_show_file.file,
					'agencies[]': pro_show_file.agencies,
					'agency_types[]': pro_show_file.agency_types,
					'destinations[]': pro_show_file.destinations,
					'range_id': pro_show_file.range_id,
					'title': pro_show_file.title,
					'isDefault': pro_show_file.isDefault,
					'_method': formType === 'edit' ? 'PUT' : 'POST',
				});
				if (formType === 'add') {
					this.props.storeProShowFileCall(fdData).then(
						response => {
							this.props.closeModel();
							this.props.getPagination();
						}
					);
				}
				else if (formType === 'edit') {
					this.props.updateProShowFileCall(fdData, pro_show_file.id).then(
						response => {
							this.props.closeModel();
							this.props.getPagination();
						}
					);
				}
			});
		} else {
			// console.log('state data', this.state.pro_show_file);
			// console.log('error message', this.validatorProShowFile.getErrorMessages());
			this.setState({ submitted: false });
			focusInput(this.validatorProShowFile.getErrorMessages());
			this.validatorProShowFile.showMessages();
			this.forceUpdate();
		}
	}
	handleProShowFile(e) {
		const { name, value, type } = e.target;
		const destinationState = { ...this.state.pro_show_file };
		destinationState[name] = type === 'checkbox' ? e.target.checked ? true : false : value;
		this.setState({ pro_show_file: destinationState });
	}
	handleChangeProShowFileDropDown = (selectedOption, info) => {
		console.log('selected option', selectedOption);
		console.log('selected option info', info)
		if (info.action === 'select-option') {
			if (typeof info.option === 'undefined') {
				this.setState(prevState => ({
					pro_show_file: {
						...prevState.pro_show_file,
						[info.name]: selectedOption.value
					}
				}));
			} else {
				this.setState(prevState => ({
					pro_show_file: {
						...prevState.pro_show_file,
						[info.name]: [...prevState.pro_show_file[info.name], info.option.value]
					}
				}));
			}
		}
		if (info.action === 'remove-value') {
			const agencyTypesTemp = this.state.pro_show_file[info.name];
			agencyTypesTemp.splice(agencyTypesTemp.indexOf(info.removedValue.value), 1);
			this.setState(prevState => ({
				pro_show_file: {
					...prevState.pro_show_file,
					[info.name]: agencyTypesTemp
				}
			}))
		}

		/*Modal for destination code*/
		if (info.action === 'create-option') {
			var key = returnKeyWithColumnMatch('__isNew__', selectedOption, true)
			this.setState(prevState => ({
				newDestination: {
					...prevState.newDestination,
					title: selectedOption[key]['label']
				}
			}), () => {
				this.openModal('addModalDestination');
			})
		}
		/*Modal for destination code*/

	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevState.pro_show_file.isDefault && this.state.pro_show_file.isDefault) {
			this.setState(prevState => ({
				pro_show_file: {
					...prevState.pro_show_file,
					destinations: [],
					agencies: Object.keys(this.state.pro_show_file.agencies).length >= 1 ? [this.state.pro_show_file.agencies[0]] : [],
					agency_types: [],
				}
			}));
		}

		/*Modal for destination code*/
		if (!prevState.addModalDestination && this.state.addModalDestination) {
			this.setState({ onCloseAfterApi: false })
		}
		if (prevState.addModalDestination && !this.state.addModalDestination && this.state.onCloseAfterApi) {
			this.props.getDestinationsCall();
		}
		if (prevProps.storedDestination !== this.props.storedDestination) {
			this.setState(prevState => ({
				pro_show_file: {
					...prevState.pro_show_file,
					destinations: [...prevState.pro_show_file.destinations, this.props.storedDestination.id]
				}
			}));
		}
		/*Modal for destination code*/
	}
	render() {
		this.validatorProShowFile.purgeFields();
		const { pro_show_file, newDestination } = this.state;
		const { agency_type_id, agenciesOption, ranges, destinations } = this.props;

		return (
			<div className="row section-Frontend">
				<form>
					<div className="col-md-6" id="title">
						<div className='form-group'>
							<label htmlFor="name">Title<span className="requiredClass">*</span></label>
							<input type="text" name="title" className="form-control" value={pro_show_file.title} onChange={this.handleProShowFile} />
							{this.validatorProShowFile.message('title', pro_show_file.title, 'required')}
						</div>
					</div>

					<div className="col-md-6" id="agencies">
						<div className="row">
							<div className='form-group'>
								<div className="clearfix">
									<div className="col-md-12 lbl_container">
										<label htmlFor="name">Agency<span className="requiredClass">*</span> </label>
									</div>

									<div className="col-md-12">
										<Select
											value={returnLabelsWithValues(pro_show_file.agencies, agenciesOption)}
											onChange={this.handleChangeProShowFileDropDown}
											options={agenciesOption}
											isSearchable={true}
											isMulti={true}
											name='agencies'
											placeholder={'Select Agency'}

										/>
										{
											!pro_show_file.isDefault
												?
												this.validatorProShowFile.message('agencies', pro_show_file.agencies, 'required|min:1,array')
												:
												this.validatorProShowFile.message('agencies', pro_show_file.agencies, 'required|min:1,array|max:1,array')
										}
									</div>
									<span></span>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-6" id="range">
						<div className="row">
							<div className='form-group'>
								<div className="clearfix">
									<div className="col-md-12 lbl_container">
										<label htmlFor="name">Ranges<span className="requiredClass">*</span> </label>
									</div>

									<div className="col-md-12">
										<Select
											value={returnLabelWithValue(pro_show_file.range_id, ranges)}
											onChange={this.handleChangeProShowFileDropDown}
											options={ranges}
											isSearchable={true}
											isMulti={false}
											name='range_id'
											placeholder={'Select Range'}

										/>
										{
											!pro_show_file.isDefault &&
											this.validatorProShowFile.message('range', pro_show_file.range_id, 'required')
										}
									</div>
									<span></span>
								</div>
							</div>
						</div>
					</div>
					{
						!pro_show_file.isDefault &&
						<React.Fragment>
							<div className="col-md-6" id="agency_types">
								<div className="row">
									<div className='form-group'>
										<div className="clearfix">
											<div className="col-md-12 lbl_container">
												<label htmlFor="name">Agency Type<span className="requiredClass">*</span> </label>
											</div>

											<div className="col-md-12">
												<Select
													value={returnLabelsWithValues(pro_show_file.agency_types, agency_type_id)}
													onChange={this.handleChangeProShowFileDropDown}
													options={agency_type_id}
													isSearchable={true}
													isMulti={true}
													name='agency_types'
													placeholder={'Select Agency Type'}

												/>
												{this.validatorProShowFile.message('agency_types', pro_show_file.agency_types, 'required|min:' + parseInt(!pro_show_file.isDefault ? 0 : 1, 10) + ',array')}
											</div>
											<span></span>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-6" id="destinations">
								<label htmlFor="glemail">Select Destination<span className="requiredClass">*</span></label>
								{/*
								<CreatableSelect
									isMulti={true}
									value={returnLabelsWithValues(pro_show_file.destinations, destinations)}
							        options={ destinations }
							        onChange={this.handleChangeProShowFileDropDown}
							        className="selectUser"
							        name='destinations'
									placeholder="Select Destination"

						      	/>
								*/}
								<AsyncCreatableSelect
									isMulti={true}
									// cacheOptions
									defaultOptions
									defaultValue={pro_show_file.destinationDefault}
									// defaultInputValue
									loadOptions={promiseOptions}
									onChange={this.handleChangeProShowFileDropDown}
									className="selectUser"
									placeholder="Select Destination"
									name='destinations'
								/>
								{
									this.validatorProShowFile.message('destinations', pro_show_file.destinations, 'required|min:' + parseInt(!pro_show_file.isDefault ? 0 : 1, 10) + ',array')
								}

								<div className='mt-md'>
									<label className="container-check" style={{ paddingLeft: "30px" }}> Is Default?
					                  <input type="checkbox" name="isDefault" className="checkbox" value={pro_show_file.isDefault ? 1 : 0} onChange={this.handleProShowFile} checked={pro_show_file.isDefault ? 1 : 0} />
										<span className="checkmark"></span>
									</label>
									{/* {this.validatorPermission.message('isDefault', user.isDefault, 'required')} */}
								</div>
							</div>

						</React.Fragment>
					}

					<div className="col-md-6" id="File">
						<div className="form-group">
							<div className="row">
								<div className="col-md-12 lbl_container">
									<label htmlFor="name">Upload File<span className="requiredClass">*</span> </label>
								</div>
							</div>
							<span className="customUploader"></span>
							{
								!pro_show_file.file
									?
									<FileUpload
										setResourceFile={this.setResourceFile}
										multiple={false}
										allowedFormatsStringMimePro={'application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip'}
										allowedFormatsStringExtPro={'zip'}
										//allowedFormatsStringMimePro={'image/png'}
										//allowedFormatsStringExtPro={'png'}
										//allowedFormatsStringMimePro={'application/octet-stream'}
										//allowedFormatsStringExtPro={'psh'}
										title={'full video'}
										type={'full_video'}
									/>
									:
									<ImagePreview
										filesToPreview={pro_show_file.file}
										removeFile={this.removeFile}
									/>

							}
							{
								this.validatorProShowFile.message('File', pro_show_file.file, 'required')
							}
						</div>
					</div>

					<div className="row">
						<div className="col-md-12">
							<div className='text-center'>
								<Button onClick={this.handleProShowFileSubmit} bsSize="large" className="backButton pt-sm no_radius pb-sm success btn btn-lg ml-sm mt-sm btn btn-default" >Save</Button>
								<Button bsSize="large" onClick={() => this.props.closeModel()} className="backButton pt-sm no_radius pb-sm info btn btn-lg btn-info ml-sm mt-sm" >Cancel</Button>
							</div>
						</div>
					</div>
				</form>
				{/*Add Destination Modal Start*/}
				<ThemeModal modalClass="" title='Add Destination' show={this.state.addModalDestination} hide={() => { this.toggleModal('addModalDestination') }} >
					<DestinationForm
						formTypePro="add"
						destinationPro={newDestination}
						closeModel={closeModalAfterApi.bind(this, "addModalDestination", "onCloseAfterApi")}
					/>
				</ThemeModal>
				{/*Add Destination Modal End*/}
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return ({
		storeProShowFileCall: (data) => { return dispatch(storeProShowFile(data)) },
		updateProShowFileCall: (data, id) => { return dispatch(updateProShowFileCall(data, id)) },
		getDestinationsCall: () => { dispatch(getDestinations()) },

	});
};
function mapStateToProps(state) {
    const { agenciesOption } = state.agenciesReducer;
    // console.log('agenciesOption', agenciesOption);
	const { loadingSettings, agency_type_id, ranges, destinations } = state.settingsReducer;
    const { loadingDestination, storedDestination } = state.destinationsReducer;

	return {
		agenciesOption,
		loadingSettings, agency_type_id, ranges, destinations,
		loadingDestination, storedDestination
	};
}


const ProShowForm = connect(mapStateToProps, mapDispatchToProps)(ProShowFormPage);
export default ProShowForm;
