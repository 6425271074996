import React, { Component } from 'react'
import { connect } from "react-redux";
import FileUpload from 'components/Upload/FileUpload';
import ImagePreview from 'components/Upload/ImagePreview';
import { returnExtFromImage, returnFormats, getFormattedDate, closeModal, returnLabelWithValue } from 'helper/helperFunctions';
import { getOrderResourceFormats, storeOrderResource, uploadFile, getOrderResources, orderResourceDelete, orderResourceUploadCancel, getFolderOrderResources } from 'redux/actions/order-actions';
import simpleReactValidator from "simple-react-validator";
import { Button, Table } from "react-bootstrap";
import { confirmAlert } from 'react-confirm-alert';
import ConfirmAlertForDelete from 'components/ThemeComponents/confirmAlertForDelete';
import DownloadFile from 'components/ThemeComponents/downloadFile';
import DatePicker from "react-datepicker";
import ReactValidator from "simple-react-validator";
import GlobalDocuments from './GlobalDocuments';
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
import { SC } from 'helper/ServerCall';
import { MdClose } from 'react-icons/md';
import { alertActions } from '../../../redux/actions/alert-actions';
import Select from 'react-select';
import { arrayMove } from 'react-sortable-hoc';
import Folder from '../Folder';
var Modal = require('react-bootstrap-modal');
const moment = window.moment;
class OrderDocument extends React.Component {
    constructor(props) {
        super(props);
        this.dateValidator = new ReactValidator();
        var date = moment(new Date()).format("YYYY-MM-DD");

        this.state = {
            schDate: date,
            resource: {},
            files: {},
            submitted: false,
            order_folder_id: null,
            callData: [],
            addFolderModal: false,
            folderName: '',
            selectedFolder: 'Please select a folder',
            expanded: [],
            is_folder: true,
            folders:[],

        }
        this.props.onOrderResourceFormatsCall('order_documents');
        this.handleOrderResoruceSubmit = this.handleOrderResoruceSubmit.bind(this);
        this.form = React.createRef();
        this.validator = new simpleReactValidator({ autoForceUpdate: this, locale: 'en' });
        if (this.props.orderId) {
            this.props.onGetOrderResourcesCall(this.props.orderId, 'order_documents');
        }
        this.show(this.props.orderId).then(response =>{
            console.log("response", response);
            this.setState({
                callData: response.data.data,
                folders: response.data.folder_array
            });
        })
        // this.getAgencyFolderResources(206);
        // this.showFolder(this.props.orderId).then(response =>{
        //     console.log("response", response);
        //     this.setState({
        //         folders: response.data
        //     });
        // })
    }
    openAddFoderModal = () => {
        this.setState({
            addFolderModal: !this.state.addFolderModal
        })
    }
    storeFodlerhandler = (e) => {
        console.log("value", e.target.value);
        // var folderName = this.state.folderName;
        // folderName = e.target.value;
        this.setState({
            folderName: e.target.value
        })
    }
    folderSubmitHandler = () => {
        if(this.state.folderName !== "") {
            const data = {name: this.state.folderName, order_id: this.props.orderId}
            this.storeFolder(data).then(
                response => {

                    this.props.success(response.message);
                    this.setState({
                        addFolderModal: false,
                        folderName: ''
                    },()=>{
                        this.show(this.props.orderId).then(response =>{
                            this.setState({
                                callData: response.data.data,
                                folders: response.data.folder_array
                            });
                        })
                    });

                }
            );
        }
    }
    storeFolder = (data) => {
        return SC.postCall('orderFolder' , data );
    }
    updateFolder = (data) => {
        return SC.postCall('updateFolder' , data );
    }
    storeAgencyFolder = (data) => {
        return SC.postCall('storeAgencyFolder' , data );
    }
    show = (id) => {
        return SC.getCall(`getResources/${id}` );
    }
    getAgencyFolderResources = (id) => {
        return SC.getCall(`getAgencyFolderResources/${id}` );
    }
    showFolder = (id) => {
        return SC.getCall(`getOrderFolder/${id}` );
    }

    DateChangeHandler = (value, field) => {
        this.setState({ [field]: value === null ? '' : moment(value).format("YYYY-MM-DD") }, () => {
            if (this.dateValidator.allValid()) {
                //this.pagination.dataCall();;
                console.log("dateChange", value);
                console.log("schDate", this.state.schDate);
            } else {
                this.dateValidator.showMessages();
                this.forceUpdate();
            }
        })
    }
    dateElement(label, name, value, rules) {
        var selectedDate = this.state[name] !== '' ? new Date(this.state[name]) : '';
        //const { fromDate, toDate } = this.state;
        return (
            <div className='form-group fullCalendar' id={name}>
                <label>{label}</label>
                <DatePicker className="form-control calender_icon" minDate={Date.now()} selected={selectedDate} name={name} onChange={(event) => this.DateChangeHandler(event, name)} />

            </div>
        );
    }

    addFilePreview = files => {

        const uploadedFiles = files.map(file => Object.assign(file, { preview: URL.createObjectURL(file) }));
        this.props.onFileUpload(uploadedFiles);
    };

    handleOrderResoruceSubmit = () => {
        if (this.validator.allValid()) {
            const fd = new FormData();
            console.log(this.state.files)
            fd.append('schDate', this.state.schDate);
            fd.append('file', this.state.files);
            fd.append('resource_type_id', this.props.resourceType.id);
            fd.append('order_folder_id', this.state.order_folder_id);
            this.props.onStoreOrderResourceCall(fd, this.props.orderId).then(
                response => {
                    this.setState({
                        resource: {}
                    });
                    this.show(this.props.orderId).then(response =>{
                        this.setState({
                            callData: response.data.data,
                            // folders: response.data.folder_array
                        });
                    })
                });

        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    setResourceFile = (key, value) => {
        const resourceState = { ...this.state.resource };
        resourceState['file'] = value.file;
        resourceState['name'] = value.name;
        resourceState['size'] = value.size;
        resourceState['extension'] = returnExtFromImage(value.name);
        resourceState['preview'] = value.preview;
        resourceState['type'] = value.type;
        resourceState['lastModified'] = value.lastModified;
        this.setState({ resource: resourceState, files: value });

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.submittedResource !== prevProps.submittedResource) {
            this.props.onGetOrderResourcesCall(this.props.orderId, 'order_documents');
        }
        if (this.props.submit !== prevProps.submit && this.props.submit === true) {
            if (Object.entries(this.state.files).length > 0)
                this.handleOrderResoruceSubmit();
        }

        if (this.state.folderName !== prevState.folderName) {
            this.setState({ folderName: this.state.folderName})
        }

        if (this.state.expanded !== prevState.expanded) {

            if(this.state.expanded.length === 0) {
                this.setState({
                    selectedFolder: 'Please select a folder',
                    order_folder_id: null
                })
            }
        }
        if (this.state.is_folder !== prevState.is_folder) {

            if(this.state.is_folder === false) {
                this.setState({
                    selectedFolder: 'Please select a folder',
                    order_folder_id: null
                })
            }
        }
        if(this.props.orderFolderDocuments !== prevProps.orderFolderDocuments) {
            this.setState({
                callData: this.props.orderFolderDocuments.data
            });
        }
    }

    onResourceDeleteHandler = (index, id) => {
        const orderResourcesDocuments = [...this.props.orderResourcesDocuments];
        orderResourcesDocuments.splice(index, 1);
        this.props.onResourceDeleteCall(orderResourcesDocuments, id).then(response => {
            this.props.onGetOrderResourcesCall(this.props.orderId, 'order_documents');
            this.show(this.props.orderId).then(response =>{
                this.setState({
                    callData: response.data.data,
                    folders: response.data.folder_array
                });
            })
        });
    }

    confirmResourceDelete = (index, id) => {
        confirmAlert({
            customUI: ({ onClose }) => <ConfirmAlertForDelete typeName='Resource' onClosePro={onClose} deleteType={this.onResourceDeleteHandler.bind(this, index, id)} />
        })
    }

    cancelResourceUploadHandler = () => {
        this.setState({
            resource: {}
        }, () => {
            this.props.onUploadCancelCall();
        });
    }
    onFolderClick = (e, data, nodes, nodeId) => {
        const removeId = e.target.getAttribute('Mui-expanded');
        // console.log("removedId", e.target);
        console.log("data", data);
        // console.log("action", action);
        console.log("nodes", nodes);
        const expandingNodes = nodes.filter(x => !this.state.expanded.includes(x));
        console.log("expandingNodes", expandingNodes);
        // if ()
        console.log(expandingNodes)
        this.setState({
            expanded:expandingNodes,
            selectedFolder: data.folder_name,
            order_folder_id: data.folder_id,
            is_folder: true
        })
    }

    selectedFolderHandle = (e) => {
    //   console.log("clicked", e.target.checked);
      const value = e.target.checked ? true : false;
      this.setState({
        is_folder: value
      })
    }
    handleChangeFolder = (selectedOptionType, info) => {
        // console.log("selected option type: " , selectedOptionType);
        // console.log("info " , info);
        if ( selectedOptionType.value === "-1")
        {
            this.setState({
                order_folder_id: null,
                selectedFolder: selectedOptionType.label,
            })
        }else{
            this.setState({
                selectedFolder: selectedOptionType.label,
                order_folder_id: selectedOptionType.value
            })
        }
    }

    render() {
        const { orderResourcesDocuments } = this.props;
        const { schDate, callData, expanded, selectedFolder, addFolderModal, folders } = this.state;
        const formatsMime = returnFormats(this.props.resourceType.resource_formats, 'mime_type').join(',');
        const extensionsMime = returnFormats(this.props.resourceType.resource_formats, 'extension').join(',');
        let itemId = 1;
        console.log("expppp", expanded);
        const resources = callData.map((resource, index) => {
            if (resource.file_path)
            return (
                <tr key={resource.id} >
                    <td><a style={{ wordBreak: 'break-all' }} className='blue_link' target="_blank" href={resource.file_path} rel="noopener noreferrer">{resource.original_file_name}</a></td>
                    <td>{resource.is_scheduled === 0 ? 'Sent' : 'Scheduled'}</td>
                    <td className="text-center">
                        <DownloadFile multiple={false} title={<React.Fragment>&nbsp;</React.Fragment>} files={{
                            'file_title': resource.file_title,
                            'file_path': resource.file_path
                        }} />

                        <button className="orderDetailsAction deleteIcon" type="button" onClick={this.confirmResourceDelete.bind(this, index, resource.id)} >&nbsp;</button>
                    </td>
                </tr>
            );
        });
        return (
            <div>

                <GlobalDocuments agency_id={this.props.agency_id} />

                <div class="secHeading">Order Documents</div>
                <form id='docForm' encType='multipart/form-data' >
                    <div className="col-md-12">
                        <FileUpload
                            setResourceFile={this.setResourceFile}
                            allowedFormatsStringMimePro={formatsMime}
                            allowedFormatsStringExtPro={extensionsMime}
                            multiple={false}
                        />
                        <div className="row">
                            <div className="col-md-2">
                                <ImagePreview filesToPreview={this.state.resource} />
                                {
                                    this.validator.message('File', this.state.resource.file, 'required')
                                }
                            </div>
                            <div className="col-md-10">
                                {/* FolderUpload */}
                                {
                                    'frontend' in this.props && this.props.frontend
                                        ?
                                        null
                                        :
                                        Object.keys(this.state.resource).length > 0
                                            ?
                                            <div>
                                                <div className="col-md-4">
                                                    <div className='row flexElem flexResponsive align-items-center' >
                                                        <div className="col-md-8" >
                                                            {
                                                                this.dateElement('Schedule Date', 'schDate', schDate !== '' && moment(schDate, 'YYYY-MM-DD'), [{ before_or_equal: moment(this.state.schDate) }])
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    folders &&
                                                    <div className="col-md-4">
                                                        <div className='row flexElem flexResponsive align-items-center' >
                                                            <div className="col-md-8" >
                                                                <div className='form-group' id="folderId">
                                                                    <label htmlFor="name">Select Folder</label>
                                                                        <Select
                                                                            value={returnLabelWithValue(this.state.order_folder_id, folders)}
                                                                            onChange={this.handleChangeFolder}
                                                                            options={folders}
                                                                            // options={options.itemTypes}
                                                                            isSearchable={true}
                                                                            isMulti={false}
                                                                            placeholder={'Select Folder'}
                                                                            isClearable={false}
                                                                            name="order_folder_id"
                                                                        />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="col-md-4">
                                                    <div className='btn-toolbar text-center mb-lg'>
                                                        <Button onClick={this.handleOrderResoruceSubmit} className="backButton pt-sm no_radius pb-sm success btn btn-lg ml-sm mt-xlg btn-default" >Save</Button>
                                                        <Button className="backButton pt-sm no_radius pb-sm primary btn btn-lg ml-sm mt-xlg btn-info" onClick={this.cancelResourceUploadHandler} >Cancel</Button>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            ''
                                }
                            </div>
                        </div>
                    </div>

                </form>
                {
                    orderResourcesDocuments.length > 0 ?
                        <Table className="blueStyle" bordered striped size="sm">
                            <thead>
                                <tr>
                                    <td>File Name</td>
                                    <td>Status</td>
                                    <td className="text-center">Action</td>
                                </tr>
                            </thead>
                            <tbody>
                                {resources}
                            </tbody>
                        </Table> : <p className='text-center text-danger font-weight-bold' >No resources found</p>
                }



                {
                    <>
                        {
                            callData &&

                            callData.map((item, key , index) => {
                                // console.log("index: " , key);
                                // console.log("item: " , item);
                                if (item.folder_id){
                                    return (
                                        <Folder key = {itemId} item = {item} orderId = {this.props.orderId} updatebackend = {this.updatebackenddrag} ex = {expanded} onFolderClick = {this.onFolderClick} confirmResourceDelete = {this.confirmResourceDelete} dragable = {true}/>

                                    )
                                }
                            })

                        }

                        <div className="flexElem respFlex spaceBetween form_tile_row">
                            <div className="col-md-12">
                                <div className="col-md-5">
                                    <button onClick={this.openAddFoderModal} className="backButton pt-sm no_radius pb-sm primary mt-md mb-md btn btn btn-info  btn btn-default">Add New Folder</button>
                                </div>
                            </div>
                        </div>

                        {/* <div style={{ display: 'list-item' , listStyle: 'none' }}className="">
                            {
                                expanded.length > 0 &&
                                <label className="container-check mb-sm mt-sm">
                                    <span className="ml-xl mb-xl">Upload in <strong>{selectedFolder}?</strong></span>
                                    <input type="checkbox" name="is_active" className="checkbox" value={this.state.is_folder} onChange={this.selectedFolderHandle} checked={this.state.is_folder}
                                    /><span className="checkmark"></span>
                                </label>
                            }
                            <div className='selecteFolder mt-md mb-md'>
                                <span>{selectedFolder}</span>
                            </div>
                        </div> */}

                        {/* {

                            <Table className="blueStyle" bordered striped size="sm">
                                <thead>
                                    <tr className="border-bottom">
                                        <th style={{color:"#fff"}}>File Name</th>
                                        <th style={{color:"#fff"}}>Status</th>
                                        <th className="text-center" style={{color:"#fff"}}>Action</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        callData.map((item, index) => {
                                            // console.log("itemmmm", item),

                                            if (item.data){
                                            return console.log("not found");
                                            } else if (item.file_path){

                                                return(
                                                <tr key={item.id}>
                                                    <td><a style={{ wordBreak: 'break-all' }} className='blue_link' target="_blank" href={item.file_path} rel="noopener noreferrer">{item.original_file_name}</a></td>

                                                    <td>{item.is_scheduled === 0 ? 'Sent' : 'Scheduled'}</td>
                                                    <td>
                                                        <a className='mr-md' href={item.file_path}><i className='fa fa-download'></i></a>
                                                        <button className="orderDetailsAction deleteIcon" onClick={this.confirmResourceDelete.bind(this, index, item.id)} >&nbsp;</button>
                                                    </td>
                                                </tr>
                                            )
                                            }else{
                                                return null
                                            }
                                    })
                                    }
                                </tbody>
                            </Table>
                        } */}
                    </>
                }

            {/* Add Folder Model */}
            <Modal size="sm" backdrop={'static'} show={addFolderModal} id="" onHide={closeModal.bind(this, "addFolderModal")} aria-labelledby="ModalHeader" className="" >
                <Modal.Header closeButton>
                    <Modal.Title id='ModalHeader' className="headerTitle">Add New Folder</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="clearfix">
                        <input type="text" value={this.state.folderName} onChange={this.storeFodlerhandler} className="form-control" placeholder="Enter Folder Name"/>
                        <br />
                        {/* <button onClick={closeModal.bind(this, "addFolderModal")}  className="folderBtn mr-md "> Back</button> */}


                        <button onClick={() => this.folderSubmitHandler()} style={{float: "right"}} className="backButton pt-sm no_radius pb-sm primary mt-none btn btn btn-info  btn btn-default">Submit</button>
                    </div>
                </Modal.Body>
            </Modal>




            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { resourceType, resourceFiles, filesPreview, resource, orderResourcesDocuments, submittedResource, orderFolderDocuments } = state.ordersReducer;
    return {
        resourceType, resourceFiles, filesPreview, resource, orderResourcesDocuments, submittedResource, orderFolderDocuments
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onOrderResourceFormatsCall: (type) => dispatch(getOrderResourceFormats(type)),
        onStoreOrderResourceCall: (resource, id) => dispatch(storeOrderResource(resource, id)),
        onFileUpload: (files) => dispatch(uploadFile(files)),
        onGetOrderResourcesCall: (orderId, type) => dispatch(getOrderResources(orderId, type)),
        onResourceDeleteCall: (resources, id) => { return dispatch(orderResourceDelete(resources, id)) },
        success: (message) => { return dispatch(alertActions.success(message))},
        onUploadCancelCall: () => dispatch(orderResourceUploadCancel()),
        onGetOrderFolderResourcesCall: (orderId) => { return dispatch(getFolderOrderResources(orderId)) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderDocument);
