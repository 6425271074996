import React, { Component } from 'react';
import {
    Col,
    OverlayTrigger,
    ButtonToolbar,
    Popover,
    Collapse
} from "react-bootstrap";
import {BsExclamationTriangle} from 'react-icons/bs';
import ToggleButton from 'react-toggle-button';
import ThemeModal from '../../ThemeComponents/ThemeModal';
import MoreButtonForm from "../MoreButton/MoreButtonForm";
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { connect } from 'react-redux'
import { getMoreButtonsPagination, deleteMoreButton, updateMoreButtonStatus } from '../../../redux/actions/more-buttons-action';
import Confirmalertfordelete from 'components/ThemeComponents/confirmAlertForDelete';
import { createSortString, closeModal, helperFunctions as HF } from '../../../helper/helperFunctions'
import { themeCSSConstants } from 'redux/constant/themeCSS';

import 'react-table/react-table.css';
import { confirmAlert } from 'react-confirm-alert';
import MoreButtonsPagination from '../../ThemeComponents/MoreButtonsPagination'
import { SC } from 'helper/ServerCall';
import MoreButtonsWeTravel from './MoreButtonsWeTravel';

class MoreButtonsList extends Component {

    constructor(props) {
        super(props);
        this.table = React.createRef();
        this.state = {
            pageSize: 10,
            filtered: [],
            moreButtonEditModal: false,
            moreButtonCreateModal: false,
            moreButtonEditData: {},
            is_active: '1',
            agency: false,
            length:0,
			collapseMoreButtons: false,
            // agency: OC.AGENCY
            // moreButtons: []
        }
        this.showModal = this.showModal.bind(this);
    }

    editWeTravelSetting(data) {
        return SC.postCall('editWeTravelSetting', data);
    }

    updateMoreButton = (id, data) => {
        return SC.postCall('updateMoreButton/' + id, data );
    }

    updateMoreButtonsSortOrders = (data) => {
        return SC.postCall('updateMoreButtonsSortOrders' , data );
    }

    handleOptionAtOrderPlacement = (name, value, id,data) => {
        let tempValue = !value;
        tempValue = tempValue ? 1 : 0;

        if (name === 'show_on_all_orders' || name === 'option_at_order_placement') {
            			var WtOrder = '';
            			if (name === 'show_on_all_orders'){
            				WtOrder = 'option_at_order_placement';
                        }
            			else{
            				WtOrder = 'show_on_all_orders';
                        }
                        let newData = {
                            ...data,
                            [name] : tempValue,
                            [WtOrder] : 0,
                        }
                        this.updateMoreButton(id, newData).then(res=>{
                            this.pagination.dataCall();
                        })
            		}


	}
    handleOptions = (name, value) => {
		let tempValue = !value;
        console.log(name, value);
        tempValue = tempValue ? 1 : 0;

        this.editWeTravelSetting({key:name, value:tempValue, agency_id:this.state.agency.id});

		this.setState(prevState => ({
            ...prevState, agency: {
                ...prevState.agency, settings:{
                    ...prevState.agency.settings, [name]: {
                        value: tempValue
                    }
                },
            }
		})
        	,
			() => {
				if (name === 'wt_show_on_all_orders' || name === 'wt_option_at_order_placement') {
					var WtOrder = 'wt_show_on_all_orders';
					if (name === WtOrder)
						WtOrder = 'wt_option_at_order_placement';

                    console.log('anonymous', WtOrder, value);
                    this.setState(prevState => ({
                        ...prevState, agency: {
                            ...prevState.agency, settings:{
                                ...prevState.agency.settings, [WtOrder]: {
                                    value: 0
                                }
                            }
                        }
                    }));
				}
                else if (name === 'accepts_wt_payments' && tempValue == 0) {
                    this.setState(prevState => ({
                        ...prevState, agency: {
                            ...prevState.agency, settings:{
                                ...prevState.agency.settings, ['wt_show_on_all_orders']: {
                                    value: 0
                                },
                                ['wt_option_at_order_placement']: {
                                    value: 0
                                }
                            }
                        }
                    }));
                }
            });
	}

    showModal(data = '', e) {
        let newData = {...data};
        newData.file_path = {
            preview: data.file_path,
            name: data.file_name,
            type: data.mime_type,
            id: data.id
        }

        this.setState({
            [e.target.name]: true,
            moreButtonEditData: newData,
        });
    }
    deleteConfirmMoreButton = (id) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <Confirmalertfordelete typeName="Button" description="Are you sure you want to delete this button" onClosePro={onClose} deleteType={() => this.deleteMoreButtonHandler(id)} />
                )
            }
        })
    }

    deleteMoreButtonHandler(id) {
        this.props.deleteMoreButtonCall(id).then(res => {
            this.pagination.dataCall();
        });
    }

    paginationCall = (data) => {
        let sorted = data.sort.split(',');
        let { sort, orderType } = createSortString(sorted);
        return this.props.getMoreButtonsPaginationCall(data.page, data.filter, sort, data.pageSize, orderType,this.props.agency_id);

    }
    handleInactiveStatusUpdate = (moreButtonId, e) => {
        this.props.updateMoreButtonStatusCall(moreButtonId, { is_active: e.target.value ? true : false }).then(
          response => {
            this.pagination.dataCall();
          }
        );
      }
      handleActiveStatusUpdate = (moreButtonId, e) => {
        this.props.updateMoreButtonStatusCall(moreButtonId, { is_active: e.target.value ? false : true }).then(
          response => {
            this.pagination.dataCall();
          }
        );
      }
      componentDidUpdate(prevProps, prevState) {
      if (this.state.is_active !== prevState.is_active) {
        this.pagination.dataCall();
      }
      if( this.props.agency !=  prevProps.agency) {
        this.setState({
            agency: {...this.props.agency}
        });
      }
      if(this.state.length !== prevState.length){
        // console.log("function scucess");/\
        this.setState({
            collapseMoreButtons: true
        })
        }
    }
    handleAgencyLink = (name, value) => {
		this.setState(prevState => ({
            ...prevState, agency: {
                ...prevState.agency, settings:{
                    ...prevState.agency.settings, [name]: {
                        value: value
                    }
                },
                // [name]: tempValue
            }
		}))
	}
    handleCallback = (childData) =>{
        this.setState({length: childData})
    }

    render() {
        const agency = this.state.agency
        const { moreButtons , is_active} = this.state;

        const columns = [
                    {
                        Header: 'Title',
                        accessor: 'title',
                        className: "action-center",
                        width: 120,
                        height: 100,

                    },
                    // {
                    //     Header: 'Description',
                    //     accessor: 'description',
                    //     className: "action-center",
                    // },

                    // {
                    //     Header: 'File Path',
                    //     accessor: 'file_path',
                    //     className: "action-center",
                    // },
                    // {
                    //     Header: 'File Name',
                    //     accessor: 'file_name',
                    //     className: "action-center",
                    //     // Cell: ({ original }) => {
                    //     //     console.log(original, "data");
                    //     //     return <span>{original.file_name}</span>
                    //     // }
                    // },
                    {
                        Header: 'Show on all orders',
                        accessor: 'show_on_all_orders',
                        className: "action-center",
                        Cell: ({ original,row }) => {
                            return (

                                <div className="togglePadding">
                                    <ToggleButton
                                        inactiveLabel={''}
                                        activeLabel={''}
                                        colors={themeCSSConstants.THUMBICONSTYLE}
                                        trackStyle={{ height: '10px', width: '30px' }}
                                        thumbStyle={themeCSSConstants.THUMBSTYLE}
                                        name={'moreButtonEditModal'}
                                        thumbAnimateRange={[0, 15]}
                                        value={original.show_on_all_orders}
                                        disabled={false}
                                        readOnly={true}
                                        onToggle={() => this.handleOptionAtOrderPlacement("show_on_all_orders", original.show_on_all_orders, original.id,original)}
                                    />
                                </div>
                            )
                        }
                    },
                    {
                        Header: 'Option at order placement',
                        accessor: 'option_at_order_placement',
                        className: "action-center",
                        Cell: ({ original, row }) => {
                            // console.log(value,'option cell value')
                            return (
                                <div className="togglePadding">
                                    <ToggleButton
                                        inactiveLabel={''}
                                        activeLabel={''}
                                        colors={themeCSSConstants.THUMBICONSTYLE}
                                        trackStyle={{ height: '10px', width: '30px' }}
                                        thumbStyle={themeCSSConstants.THUMBSTYLE}
                                        name={'moreButtonEditModal'}
                                        thumbAnimateRange={[0, 15]}
                                        // value={moreButtons && moreButtons[row._index].option_at_order_placement}
                                        value={original.option_at_order_placement}
                                        disabled={false}
                                        readOnly={true}
                                        // onToggle={() => this.handleOptionAtOrderPlacement("option_at_order_placement", moreButtons && moreButtons[row._index].option_at_order_placement, original.id, original)}
                                        onToggle={() => this.handleOptionAtOrderPlacement("option_at_order_placement", original.option_at_order_placement, original.id, original)}
                                    />
                                </div>
                            )
                        },
                    },
                    {
                        Header: 'Status',
                        accessor: 'is_active',
                        className: "action-center",
                        Cell: ({ original , value }) => {
                            return (
                                parseInt(value, 10) == 1 ? 'Active' : 'In-Active'
                            )
                        },
                    },
                    {
                        Header: "Actions",
                        accessor: "id",
                        className: "action-center",
                        Cell: ({ original, value }) => {

                         return(

                            <div>

                                <ButtonToolbar >
                                    <OverlayTrigger trigger="focus" placement="bottom" overlay={
                                        <Popover id="tooltip">
                                            <table className="table text-left actions_table">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                                <button name='moreButtonEditModal' onClick={(e) => { this.showModal(original, e) }} type='button' className="orderDetailsAction editIcon" >Edit</button>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                                <button onClick={() => this.deleteConfirmMoreButton(value)} className="btn_link text-danger"><i className="fa fa-trash text-danger " /> Delete</button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                                <button onClick={(e) => this.handleActiveStatusUpdate(value, e)} className="btn_link"><i className="fa fa-check-square-o"/> Active</button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                                <button onClick={(e) => { this.handleInactiveStatusUpdate(value, e) }} className="btn_link text-danger" ><i className="fa fa-ban text-danger " />Inactive</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </Popover>
                                    }>
                                        <span>
                                            <button type='button' className="action-button-size" ><i className="fa fa-bars" /></button>
                                        </span>
                                    </OverlayTrigger>
                                </ButtonToolbar>

                            </div>
                        )},

                        className: "action-center",
                        sortable: false,
                        excExp: false,
                    }
                ]

        return (
            <div>
                <div className="form_tile mb-2">
                    <div className="form_tile_heading flexElem alignCenter spaceBetween" style={{cursor: 'pointer'}} onClick={() => {this.setState({collapseMoreButtons: !this.state.collapseMoreButtons})}}>
                        <div className="flexElem alignCenter">
                            <i className="fa fa-delicious" style={{opacity:0.8}}></i> &nbsp;&nbsp;
                            <span>List of 'More' Buttons on App</span>
                        </div>
                        <div>
                            <i className="fa fa-chevron-down" style={{fontSize: 'smaller', transition: '0.2s all ease', transform: this.state.collapseMoreButtons ? 'rotate(180deg)' : 'rotate(0deg)'}}></i>
                        </div>
                    </div>
                    <Collapse in={this.state.collapseMoreButtons}>
                        <div>
                            <div className="form_tile_heading  alignCenter justifyCenter" style={{border:"1px solid #aaa", background: 'linear-gradient(135deg,  #f07f70 0%,#e1422e 100%)', fontSize:"90%"}}>
                                <BsExclamationTriangle /> &nbsp; Changes Here Affect All Your Group’s Apps
                            </div>
                            <div className="form_tile_content">
                                <React.Fragment>
                                    <div className='noMarginTable frontEndTable noBorderTable'>
                                        {/* <div className="form_tile mt-0">
                                            <MoreButtonsWeTravel
                                                agency_id={this.props.agency_id}
                                            />
                                        </div> */}
                                        <MoreButtonsPagination
                                            noCols={true}
                                            addNewBtn={
                                                <div className="mb-sm">
                                                <button type="button" onClick={(e) => this.showModal('', e)} name='moreButtonCreateModal' className='pl-xlg pr-xlg themeBtn_12 purple_gradient hover_dark' ><i className="fa fa-plus" />Create New ‘More’ Button</button>
                                                </div>
                                            }
                                            ref={(p) => this.pagination = p}
                                            showPagination={false}
                                            columns={columns}
                                            pageSize={100}
                                            noDataText='No Item found'
                                            getDataCall={this.paginationCall}
                                            updateSort={this.updateMoreButtonsSortOrders}
                                            getRowProps={this.getRowProps}
                                            parentCallback = {this.handleCallback}
                                        />
                                    </div>
                                </React.Fragment>
                                    <div className="clearfix"></div>
                            </div>
                        </div>
                    </Collapse>
                </div>

                <ThemeModal size='lg' show={this.state.moreButtonEditModal} hide={closeModal.bind(this, 'moreButtonEditModal')} title='Edit More Button'>
                    <MoreButtonForm getPagination={this.pagination ? this.pagination.dataCall : null} formType='edit' hide={closeModal.bind(this, 'moreButtonEditModal')} data={this.state.moreButtonEditData} agency_id={this.props.agency_id}  />
                </ThemeModal>

                <ThemeModal size='lg' show={this.state.moreButtonCreateModal} hide={closeModal.bind(this, 'moreButtonCreateModal')} title='Create More Button'>
                    <MoreButtonForm getPagination={this.pagination ? this.pagination.dataCall : null} hide={closeModal.bind(this, 'moreButtonCreateModal')} formType='add' agency_id={this.props.agency_id}/>
                </ThemeModal>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    const { moreButtonsPagination } = state.moreButtonReducer;
    return { moreButtonsPagination  }
}

const mapDispatchToProps = dispatch => {
    return {
        getMoreButtonsPaginationCall: (page, filter, sort, pageSize, orderType, agency_id) => { return dispatch(getMoreButtonsPagination(page, filter, sort, pageSize, orderType, agency_id)) },
        updateMoreButtonStatusCall: (moreButtonId, data) => { return dispatch(updateMoreButtonStatus(moreButtonId, data)) },
        deleteMoreButtonCall: (id) => { return dispatch(deleteMoreButton(id)) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MoreButtonsList)
